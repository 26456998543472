import _ from 'lodash'
import { excelDownloadObjects } from '../Search/SearchQueries'
import { excelDownloadDateFields } from '../Search/SearchQueries'
import moment from 'moment'
import produce from 'immer'

export function getDownloadResults(
  downloadCases: any | undefined
): any | undefined {
  let resultsData: any = produce(downloadCases, (draftState: any) => {
    const todayDate = moment().startOf('day')
    let duration: any
    draftState.forEach(function (o: any) {
      let caseAgingObject: any = {}
      let agingDate: any
      Object.keys(o).forEach(function (key: any) {
        if (key === 'agingDate' && o[key] !== null) {
          agingDate = moment(o[key]).startOf('day')
          duration = moment.duration(todayDate.diff(agingDate)).asDays()
          duration = `${duration} Days`
        }
        if (key === 'resolutionDate' && o[key] !== null) {
          const resolveDate = moment(o[key]).startOf('day')
          duration = moment.duration(resolveDate.diff(agingDate)).asDays()
          duration = `${duration} Days`
        }
        if (key === 'isDocumentsAvailable') {
          if (o[key] === true) {
            o[key] = 'Available'
          } else if (o[key] === false) {
            o[key] = 'Not Available'
          }
        }
        if (key === 'resolutionText') {
          if (o[key]?.includes('"')) {
            o[key] = o[key].replaceAll(/"/g, '')
          }
        }
        excelDownloadObjects.forEach((element: any) => {
          if (key === element.key && o[key] === null) {
            o[key] = element.object
          }
        })
        if (o[key] === null) {
          o[key] = ''
        }
        if (_.isObject(o[key])) {
          Object.keys(o[key]).forEach(function (childkey: any) {
            if (o[key][childkey] === null) {
              o[key][childkey] = ''
            }
          })
        }
      })
      caseAgingObject.caseAging = duration ? duration : ''
      _.merge(o, caseAgingObject)
      Object.keys(o).forEach(function (key: any) {
        excelDownloadDateFields.forEach((element: any) => {
          if (o[key] !== '' && key === element.key) {
            o[key] = moment(o[key]).format('YYYY-MM-DD')
          }
          if (_.isObject(o[key])) {
            Object.keys(o[key]).forEach(function (childkey: any) {
              if (o[key][childkey] !== '' && childkey === element.key) {
                o[key][childkey] = moment(o[key][childkey]).format('YYYY-MM-DD')
              }
            })
          }
        })
      })
    })
  })

  return resultsData
}
