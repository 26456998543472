import { EnvConfig } from '../../../config/appConfig'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import { useContext } from 'react'
import { AttachmentContext } from '../Context/AttachmentContext'
import { gql, useApolloClient } from '@apollo/client'
import produce from 'immer'

const CREATE_ATTACHMENT_REFRENCE = gql`
  mutation CreateAttachmentRefrence($input: CreateAttachmentReferenceRequest!) {
    createAttachmentReference(createAttachmentReferenceRequest: $input) {
      attachmentId
      fileName
      attachmentReferenceId
      attachmentSource
      caseId
      vendorId
      documentType
      documentNumber
      createdBy
      createdTimestamp
      isInternal
    }
  }
`

export default function useAttachment() {
  const env = useEnv() as EnvConfig
  const auth = useAuth()
  const client = useApolloClient()

  const { attachmentDetails, setAttachmentDetails } =
    useContext(AttachmentContext)

  const attachmentClient = axios.create({
    baseURL: `${env.attachments.attachmentServiceUrl}`,
    params: { key: env.apiKey },
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-api-key': `${env?.apiKey}`,
      Authorization: `Bearer ${auth?.session?.accessToken}`,
    },
  })

  const uploadAttachment = async (
    caseId: string,
    vendorNumber: number | undefined
  ) => {
    let allAttachment = await produce(
      [...attachmentDetails.attachments],
      async (allAttachments) => {
        let submitStatuses = attachmentDetails.submitStatuses
        if (submitStatuses.length === 0) {
          submitStatuses.push({
            message: 'Preparing to upload Documents',
            status: true,
          })
        }
        allAttachments.forEach((attachment: any) => {
          submitStatuses.push({
            message: `uploading attachment ${attachment.file?.name}`,
          })
        })

        updateStatus(submitStatuses)

        // Custom retry delay
        axiosRetry(attachmentClient, {
          retries: 2,
          retryDelay: () => {
            return 1000
          },
          retryCondition: () => {
            return true
          },
        })

        if (allAttachments) {
          for (const { index, attachment } of allAttachments.map(
            (attachment: any, index: number) => ({
              index,
              attachment,
            })
          )) {
            if (attachment?.documentSource === 'DR') {
              const { data, errors } = await client.mutate({
                mutation: CREATE_ATTACHMENT_REFRENCE,
                variables: {
                  input: {
                    attachmentReferenceId: attachment?.recordId,
                    fileName: attachment?.file?.name,
                    attachmentSource: 'DR',
                    documentType: attachment?.documentType,
                    documentNumber: attachment?.documentNumber,
                    caseId: caseId,
                    vendorId: vendorNumber,
                    isInternal: attachment?.isInternal,
                  },
                },
              })

              if (data) {
                submitStatuses[index + 1].status = true
                allAttachments[index].isUploaded = true
              }
              if (errors) {
                submitStatuses[index + 1].status = false
                allAttachments[index].isUploaded = false
              }
              updateStatus(submitStatuses)
            } else {
              let metadata = {
                document_type: attachment?.documentType,
                document_number: attachment?.documentNumber,
                is_internal: attachment?.isInternal,
                case_id: caseId,
                vendor_id: vendorNumber,
              }

              // creating form metadata
              const formData = new FormData()
              if (attachment?.documentSource === 'CB') {
                await axios
                  .get(
                    `${env.attachments.attachmentServiceUrl}files/${attachment?.documentNumber}?document_source=${attachment?.documentSource}&vendor_id=${vendorNumber}&document_id=${attachment?.documentNumber}`,
                    {
                      method: 'GET',
                      responseType: 'blob',
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-api-key': `${env?.apiKey}`,
                        Authorization: `Bearer ${auth?.session?.accessToken}`,
                      },
                    }
                  )
                  .then(async (res: any) => {
                    if (res.status === 200) {
                      let fileName = `${attachment?.documentNumber}.pdf`
                      let blob
                      blob = new Blob([res.data], { type: 'application/pdf' })
                      var file = new File([blob], fileName)
                      formData.append('file', file)
                      formData.append('metadata', JSON.stringify(metadata))
                    }
                    await attachmentClient
                      .post('', formData)
                      .then(() => {
                        submitStatuses[index + 1].status = true
                        allAttachments[index].isUploaded = true
                      })
                      .catch(() => {
                        submitStatuses[index + 1].status = false
                        allAttachments[index].isUploaded = false
                      })
                      .finally(() => {
                        updateStatus(submitStatuses)
                      })
                  })
                  .catch((error: any) => {
                    console.log('Error while fetching CB')
                    submitStatuses[index + 1].status = false
                    allAttachments[index].isUploaded = false
                  })
              } else {
                formData.append('file', attachment?.file)
                formData.append('metadata', JSON.stringify(metadata))
                await attachmentClient
                  .post('', formData)
                  .then(() => {
                    submitStatuses[index + 1].status = true
                    allAttachments[index].isUploaded = true
                  })
                  .catch(() => {
                    submitStatuses[index + 1].status = false
                    allAttachments[index].isUploaded = false
                  })
                  .finally(() => {
                    updateStatus(submitStatuses)
                  })
              }
            }
          }
        }
      }
    )

    updateAttachmentsList(
      allAttachment.filter(
        (attachment: { isUploaded: boolean }) => attachment.isUploaded === false
      )
    )
  }

  const retryUpload = async (
    caseId: string,
    vendorNumber: number | undefined,
    attachmentIndex: number
  ) => {
    updateRetryFlag(true)
    let failedAttachment = attachmentDetails.attachments[attachmentIndex]
    let metadata = {
      document_type: failedAttachment?.documentType,
      document_number: failedAttachment?.documentNumber,
      is_internal: failedAttachment?.isInternal,
      case_id: caseId,
      vendor_id: vendorNumber,
    }

    // creating form metadata
    const formData = new FormData()
    formData.append('file', failedAttachment?.file)
    formData.append('metadata', JSON.stringify(metadata))

    await attachmentClient
      .post('', formData)
      .then(() => {
        updateAttachmentsList(
          attachmentDetails.attachments.splice(attachmentIndex, 1)
        )
      })
      .catch(() => {
        updateRetryFlag(false)
      })
  }

  const updateStatus = (statuses: any[]) => {
    setAttachmentDetails({
      attachments: attachmentDetails.attachments,
      submitStatuses: statuses,
      uploadingAttachments: true,
      attachmentTypeNotSet: false,
    })
  }

  const updateAttachmentsList = (attachmentList: any[]) => {
    let attachmentTypeNotSet = false
    attachmentList.forEach((attachment: any) => {
      if (attachment.documentType.localeCompare('Choose One') === 0) {
        attachmentTypeNotSet = true
      }
    })
    setAttachmentDetails({
      attachments: attachmentList,
      submitStatuses: attachmentDetails.submitStatuses,
      uploadingAttachments: false,
      retryFlag: false,
      attachmentTypeNotSet: attachmentTypeNotSet,
    })
  }

  const updateAttachmentsToDefaults = () => {
    setAttachmentDetails({
      attachments: [],
      submitStatuses: [],
      uploadingAttachments: false,
      retryFlag: false,
      attachmentTypeNotSet: false,
    })
  }

  const updateRetryFlag = (retryFlag: boolean) => {
    setAttachmentDetails({
      attachments: attachmentDetails.attachments,
      submitStatuses: attachmentDetails.submitStatuses,
      uploadingAttachments: attachmentDetails.uploadingAttachments,
      retryFlag: retryFlag,
      attachmentTypeNotSet: attachmentDetails.attachmentTypeNotSet,
    })
  }

  return {
    uploadAttachment,
    retryUpload,
    updateStatus,
    attachmentDetails: attachmentDetails,
    updateAttachmentsList,
    updateAttachmentsToDefaults,
  }
}
