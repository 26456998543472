import React, { useEffect, useReducer } from 'react'
import {
  AdminAction,
  AdminDispatchContext,
  AdminState,
  AdminStateContext,
} from './AdminContext'

const reducer = (state: AdminState, action: AdminAction): any => {
  switch (action.type) {
    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab: action.tab }
    case 'SHOW_EDIT_FORM':
      return {
        ...state,
        tabs: state.tabs.map((tab) => {
          if (tab.id === action.tabId) {
            return { ...tab, editFormVisible: true }
          }
          return tab
        }),
      }
    case 'HIDE_EDIT_FORM':
      return {
        ...state,
        tabs: state.tabs.map((tab) => {
          if (tab.id === action.tabId) {
            return { ...tab, editFormVisible: false }
          }
          return tab
        }),
      }
    case 'SHOW_CREATE_FORM':
      return {
        ...state,
        tabs: state.tabs.map((tab) => {
          if (tab.id === action.tabId) {
            return { ...tab, createFormVisible: true }
          }
          return tab
        }),
      }
    case 'HIDE_CREATE_FORM':
      return {
        ...state,
        tabs: state.tabs.map((tab) => {
          if (tab.id === action.tabId) {
            return { ...tab, createFormVisible: false }
          }
          return tab
        }),
      }
    case 'SET_EDITABLE_ROW':
      return {
        ...state,
        tabs: state.tabs.map((tab) => {
          if (tab.id === action.tabId) {
            return { ...tab, editableRow: action.row }
          }
          return tab
        }),
      }
    case 'RESET':
      return {
        ...state,
        ...action.initialState,
      }
    default:
      return state
  }
}

interface AdminProviderProps {
  initialState: AdminState
  children: React.ReactNode
}

const AdminProvider: React.FC<AdminProviderProps> = ({
  children,
  initialState,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'RESET', initialState: { ...initialState } })
  }, [initialState])

  return (
    <>
      <AdminStateContext.Provider value={state}>
        <AdminDispatchContext.Provider value={dispatch}>
          {children}
        </AdminDispatchContext.Provider>
      </AdminStateContext.Provider>
    </>
  )
}

function useAdminState() {
  const context = React.useContext(AdminStateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CaseSearchProvider')
  }
  return context
}
function useAdminDispatch() {
  const context = React.useContext(AdminDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CaseSearchProvider')
  }
  return context
}

function useAdminProps(): [AdminState, React.Dispatch<AdminAction>] {
  return [useAdminState() as AdminState, useAdminDispatch()]
}

export { AdminProvider, useAdminProps }
