import * as qs from 'qs'
import _ from 'lodash'
import { ReportContextValue } from './ReportContext'

export function buildReportUrl(history: any, payload: any): string {
  let queryObject: any = qs.parse(history.search.replace('?', ''))
  const { filter, searchField, event, type } = payload

  if (type === 'search_group') {
    if (queryObject[filter] === undefined) {
      queryObject[filter] = { [searchField]: [event.id] }
    } else {
      queryObject[filter][searchField] = _.uniq([
        ...(queryObject[filter][searchField] ?? []),
        event.id,
      ])
    }
  }

  // checkbox
  if (type === 'checkbox') {
    if (queryObject[filter] === undefined) {
      queryObject[filter] = [event.target.value]
    } else {
      const filters = Object.values(queryObject[filter])
      const value = event.target.value
      if (filters.length === _.pull(filters, value).length) {
        filters.push(value)
      }
      queryObject[filter] = [...filters]
    }
  }
  // radio & status
  if (type === 'radio') {
    queryObject['radio'] = [event]
  }

  // date ranges
  if (type === 'date') {
    queryObject[filter] = event
  }

  // main search
  if (type === 'search') {
    if (queryObject['search'] === undefined) {
      queryObject['search'] = [payload.id]
    } else {
      queryObject['search'] = _.uniq([...queryObject['search'], payload.id])
    }
  }

  if (type === 'ageingWindow') {
    queryObject['ageingWindow'] = event
  }

  if (type === 'consolidateType') {
    queryObject['consolidateType'] = event
  }

  return qs.stringify(queryObject)
}

export const buildReportQuery = (state: ReportContextValue): string | null => {
  const checkboxFilters = state.report?.sideFilters
    .filter((filter) => filter.type === 'checkbox')
    .map(
      (key) =>
        `${key.id}: (` +
        key.values
          ?.filter((value) => value.isChecked === true)
          .map((value) => `(${addEscapeChar(value.id)})`)
          .join(' OR ')
          .concat(')')
    )
    .filter((value) => !value.includes('()'))
    .join(' AND ')

  let dateFilter = ''
  if (state.report?.dateFilter.active !== undefined) {
    const filter = state.report?.dateFilter

    dateFilter = `${filter.id}: [${filter.active?.from ?? ''} TO ${
      filter.active?.to ?? ''
    }]`
  }

  var arr = [checkboxFilters ?? '', dateFilter]
    .filter((value: string) => !(value === '') && !value.includes('()'))
    .join(' AND ')

  return arr ? arr : null
}

export function addEscapeChar(value: string): string {
  const specialChars = ['/', '-', '=']
  let id = value

  specialChars.forEach((char) => {
    if (id.includes(char)) {
      id = id.replaceAll(char, '\\' + char)
    }
  })
  return id
}

export function sortByMonthYear(a: any, b: any) {
  var as = a.rootType.split(','),
    bs = b.rootType.split(','),
    ad = new Date(as[0] + ' 1,' + as[1]),
    bd = new Date(bs[0] + ' 1,' + bs[1])
  return ad.getTime() - bd.getTime()
}
