import React from 'react'
import { UserProfile } from './userProfle'

export interface UserProfileContextValue {
  userId?: String
  userRole?: Role
  userProfile?: UserProfile
  hasAnyAdminRole: boolean
}

export enum Role {
  SADMIN,
  ADMIN,
  USER,
  VENDOR,
}

const UserProfileContext = React.createContext<UserProfileContextValue>({
  hasAnyAdminRole: false,
})

UserProfileContext.displayName = 'UserProfileContext'

export default UserProfileContext
