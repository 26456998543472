import * as React from 'react'
import {
  Grid,
  Heading,
  Form,
  ExpandableSection,
} from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { FormikValues } from 'formik'
import { CR_VENDOR_HEADING, SET_FIELD } from '../Common/Constants'
import { CommonSearch } from '../Common/CommonSearch'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import _ from 'lodash'
import { CheckCircleIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface MainProp {
  formik: FormikValues
  handleCrVendorSearch: () => void
}

const CrVendorDetails: React.FC<MainProp> = ({
  formik,
  handleCrVendorSearch,
}) => {
  const [state, dispatch] = useGlobalForm()
  const formData = formik?.values?.claimDetails
  return (
    <div className="hc-pa-normal hc-pa-none hc-ma-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="dense">
        <Heading size={6}>
          {CR_VENDOR_HEADING}
          {state.isCrVendShowable && (
            <EnterpriseIcon
              icon={CheckCircleIcon}
              color="green"
              className="hc-va-sub"
            />
          )}
        </Heading>
        <ExpandableSection.Content>
          {state.isDocNextFlag && (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10}>
                <Grid.Container>
                  <Grid.Item className="hc-pt-none">
                    <Form.Field
                      id="isCrVendor"
                      label="Do you want to Enter Carrier vendor and SCAC details?"
                      type="checkbox"
                      checked={state.isCrVendor}
                      onChange={(event: any) => {
                        dispatch({
                          type: SET_FIELD,
                          payload: [
                            { id: 'isCrVendor', value: !state.isCrVendor },
                          ],
                        })
                      }}
                      value={state.isCrVendor}
                      disabled={
                        state.isDocShowable ||
                        formik?.values?.caseSubType === 'Transport Claims'
                      }
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
          {state.isCrVendor && (
            <React.Fragment>
              <Grid.Container className="hc-mv-none hc-ml-expanded hc-pa-none">
                <CommonSearch
                  buttonId="documentIdSearch"
                  inputId="claimDetails.carrierVendorId"
                  label="Vendor ID"
                  onClick={(e: any) => {
                    handleCrVendorSearch()
                  }}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseFloat(e.target.value))
                      .toString()
                      .slice(0, 10)
                  }}
                  onBlur={(e: any) => {
                    handleCrVendorSearch()
                  }}
                  onChange={formik.handleChange}
                  value={formData.carrierVendorId || ''}
                  size={5}
                  type="number"
                  validSearchIconCheck
                  inValidSearchIconCheck={state.isCrVendorNextFlag}
                  checkIconCheck={state.isCrVendorNextFlag}
                  errorFlag={
                    formik.errors.claimDetails?.carrierVendorId !== undefined
                  }
                  fieldErrorText={formik.errors.claimDetails?.carrierVendorId}
                  docDisable={state.isDocShowable}
                  isRequired
                  isVendorSearch
                />
                {state.isCrVendorNextFlag && (
                  <Grid.Item xs={5}>
                    <Form.Field
                      id="Name"
                      label="Name"
                      className="inputtype hc-ta-left"
                      value={formData?.carrierVendorName}
                      disabled={true}
                    />
                  </Grid.Item>
                )}
              </Grid.Container>
              <Grid.Container className="hc-mv-none hc-ml-expanded hc-pt-none">
                <Grid.Item xs={10} md={5} className="hc-pt-none hc-pb-none">
                  <Form.Field
                    id="claimDetails.carrierScacCode"
                    label="SCAC Code"
                    className="hc-ta-left"
                    type="text"
                    value={formData.carrierScacCode}
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        'claimDetails.carrierScacCode',
                        _.toUpper(event.target.value)
                      )
                    }}
                    maxLength="35"
                    disabled={state.isCrVendShowable}
                  />
                </Grid.Item>
              </Grid.Container>
            </React.Fragment>
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default CrVendorDetails
