export enum CaseStatus {
  NEW = 'New',
  INPROGRESS = 'InProgress',
  AWAITINGINFO = 'AwaitingInfo',
  RESOLVED = 'Resolved',
  SUBMITTED = 'Submitted',
  CLOSED = 'Closed',
  REOPEN = 'ReOpen',
}

export enum CaseType {
  DISPUTE = 'Dispute',
  INTERNALPROCESSING = 'Internal Processing',
  REQUESTFORINFO = 'Request a Copy/Info',
}

export enum CaseText {
  NEWACTIVITY = 'New Case Creation',
  NEWREQACTIVITY = 'New Request Creation',
}

export enum Claim {
  IMPORTCLAIM = 4,
  IMPORTCASESUBTYPE = 'Claim',
}
export const defaultValues = [
  {
    id: 999,
    value: 999,
    label: 'NA',
  },
]

export const dvsWorkflow = 'TGTCOM'

export const TrailerType = [
  { id: 'Drop', value: 'Drop Trailer', label: 'Drop Trailer' },
  { id: 'Live', value: 'Live Trailer', label: 'Live Trailer' },
]

export const ClaimType = [
  { id: 'D', value: 'Damage', label: 'Damage' },
  { id: 'S', value: 'Shortage', label: 'Shortage' },
  { id: 'P', value: 'Pilferage', label: 'Pilferage' },
]

export const DocumentsNotification = [
  {
    id: '1',
    value: 'No External Damage Notification available',
    label: 'No External Damage Notification available',
  },
  {
    id: '2',
    value: 'BOL system down/not working',
    label: 'BOL system down/not working',
  },
  {
    id: '3',
    value: 'Open receipt DO NOT USE (Explain)',
    label: 'Open receipt DO NOT USE (Explain)',
  },
  {
    id: '4',
    value: 'No Delivery Receipt copy available',
    label: 'No Delivery Receipt copy available',
  },
  {
    id: '5',
    value: 'BOL not found in system',
    label: 'BOL not found in system',
  },
]

export enum FormSections {
  VENDOR = 'vendor',
  DOCUMENT = 'document',
  CLAIM = 'claim',
  ATTACHMENT = 'attachment',
  SHORTAGE = 'shortage',
  REVIEW = 'review',
  CARRIER = 'carrier',
}

export const CaseSubTypes = [
  {
    caseType: 'Request a Copy/Info',
    caseSubType: 'Import Claims',
    ImportFlag: 'Y',
    DocTypeReqFlag: 'Y',
    amountValidation: 'N',
    reasonCode: 'Y',
  },
  {
    caseType: 'Request a Copy/Info',
    caseSubType: 'Vendor Income',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
  },
  {
    caseType: 'Request a Copy/Info',
    caseSubType: 'Delivery Receipt',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
  },
  {
    caseType: 'Request a Copy/Info',
    caseSubType: 'Proof Of Return',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
  },
  {
    caseType: 'Request a Copy/Info',
    caseSubType: 'Vendor Freight Audit',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
  },
  {
    caseType: 'Dispute',
    caseSubType: 'Import Claims',
    ImportFlag: 'Y',
    DocTypeReqFlag: 'Y',
    amountValidation: 'N',
    reasonCode: 'Y',
    PO: 'N',
  },
  {
    caseType: 'Dispute',
    caseSubType: 'Unpaid Invoice',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
    PO: 'N',
  },
  {
    caseType: 'Dispute',
    caseSubType: 'Deduction',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
    PO: 'N',
  },
  {
    caseType: 'Dispute',
    caseSubType: 'Vendor Credit',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
    PO: 'N',
  },
  {
    caseType: 'Dispute',
    caseSubType: 'Vendor Income',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
    PO: 'N',
  },
  {
    caseType: 'Dispute',
    caseSubType: 'Vendor Compliance',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
    PO: 'Y',
  },
  {
    caseType: 'Dispute',
    caseSubType: 'Vendor Freight Audit',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    reasonCode: 'Y',
    PO: 'Y',
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Import Claims',
    ImportFlag: 'Y',
    DocTypeReqFlag: 'Y',
    amountValidation: 'Y',
    PO: 'N',
    Location: 'N',
    carrierVendor: 'Y',
    CarrierPO: 'Y',
    reasonCode: 'Y',
    sourceDocumentAmount: 'Y',
    disputedAmount: 'Y',
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'CBDC',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    PO: 'N',
    Location: 'Y',
    carrierVendor: 'N',
    CarrierPO: 'N',
    reasonCode: 'N',
    sourceDocumentAmount: 'N',
    disputedAmount: 'N',
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'Y',
    PO: 'Y',
    Location: 'N',
    carrierVendor: 'Y',
    CarrierPO: 'N',
    reasonCode: 'Y',
    sourceDocumentAmount: 'Y',
    disputedAmount: 'Y',
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'PO Correction',
    ImportFlag: 'N',
    DocTypeReqFlag: 'N',
    amountValidation: 'N',
    PO: 'Y',
    Location: 'Y',
    carrierVendor: 'N',
    CarrierPO: 'N',
    reasonCode: 'N',
    sourceDocumentAmount: 'N',
    disputedAmount: 'N',
  },
]

export const FieldsMapping = [
  {
    caseType: 'Internal Processing',
    caseSubType: 'Import Claims',
    documentTypeId: 22,
    documentTypeText: 'DCR -DC RETURNS',
    documentSubTypeId: null,
    documentSubTypeText: null,
    claimType: 'DCR',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: true,
      retailAmount: true,
      inventoryRemovalId: true,
    },
    mandatoryFields: {
      documentTypeId: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: false,
      departmentId: true,
      locationId: true,
      classId: true,
      carrierPurchaseOrderNumber: true,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: true,
      retailAmount: true,
      inventoryRemovalId: false,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Import Claims',
    documentTypeId: 20,
    documentTypeText: 'DAM -DAMAGE',
    documentSubTypeId: null,
    documentSubTypeText: null,
    claimType: 'DAM',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      locationId: false,
    },
    mandatoryFields: {
      documentTypeId: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      classId: true,
      carrierPurchaseOrderNumber: true,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      locationId: false,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Import Claims',
    documentTypeId: 48,
    documentTypeText: 'SHT -SHORTAGE',
    documentSubTypeId: null,
    documentSubTypeText: null,
    claimType: 'SHT',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      locationId: false,
    },
    mandatoryFields: {
      documentTypeId: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      classId: true,
      carrierPurchaseOrderNumber: true,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      locationId: false,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Import Claims',
    documentTypeId: 26,
    documentTypeText: 'EXR -EXPENSE RECOVERY',
    documentSubTypeId: null,
    documentSubTypeText: null,
    claimType: 'EXR',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      locationId: false,
    },
    mandatoryFields: {
      documentTypeId: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      classId: true,
      carrierPurchaseOrderNumber: true,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      locationId: false,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Import Claims',
    documentTypeId: null,
    documentTypeText: null,
    documentSubTypeId: null,
    documentSubTypeText: null,
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
    },
    mandatoryFields: {
      documentTypeId: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: false,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: true,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'CBDC',
    documentTypeId: null,
    documentTypeText: null,
    documentSubTypeId: null,
    documentSubTypeText: null,
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: false,
      purchaseOrderNumber: false,
      departmentId: true,
      locationId: true,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: false,
      originalDocumentAmount: false,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: null,
    documentTypeText: null,
    documentSubTypeId: null,
    documentSubTypeText: null,
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'PO Correction',
    documentTypeId: null,
    documentTypeText: null,
    documentSubTypeId: null,
    documentSubTypeText: null,
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: false,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: false,
      originalDocumentAmount: false,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 10,
    documentTypeText: 'Domestic',
    documentSubTypeId: 2,
    documentSubTypeText: 'Shortage',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 12,
    documentTypeText: 'Non Retail Claims',
    documentSubTypeId: 3,
    documentSubTypeText: 'Damage',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 13,
    documentTypeText: 'External Damages',
    documentSubTypeId: 4,
    documentSubTypeText: 'Domestic',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 11,
    documentTypeText: 'C3',
    documentSubTypeId: 3,
    documentSubTypeText: 'Damage',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 11,
    documentTypeText: 'C3',
    documentSubTypeId: 2,
    documentSubTypeText: 'Shortage',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 13,
    documentTypeText: 'External Damages',
    documentSubTypeId: 5,
    documentSubTypeText: 'Imports',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 12,
    documentTypeText: 'Non Retail Claims',
    documentSubTypeId: 2,
    documentSubTypeText: 'Shortage',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 11,
    documentTypeText: 'C3',
    documentSubTypeId: 1,
    documentSubTypeText: 'Accidents',
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
  {
    caseType: 'Internal Processing',
    caseSubType: 'Transport Claims',
    documentTypeId: 14,
    documentTypeText: 'Import Damage',
    documentSubTypeId: null,
    documentSubTypeText: null,
    displayFields: {
      originalDocumentAmount: true,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: true,
      classId: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      receiptDate: true,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
      documentNotification: true,
      claimReceipts: true,
    },
    mandatoryFields: {
      documentTypeId: false,
      disputedDocumentAmount: true,
      purchaseOrderNumber: true,
      departmentId: true,
      locationId: false,
      classId: false,
      carrierPurchaseOrderNumber: false,
      reasonCode: true,
      originalDocumentAmount: true,
      cbdcDocumentDate: false,
      retailAmount: false,
      inventoryRemovalId: false,
      invoiceDate: true,
      shipDate: true,
      receiptDate: false,
      cartonsBilled: true,
      cartonsReceived: true,
      shortageCartons: true,
      shortageAmount: true,
      trailerNumber: true,
      trackingNumber: true,
      trailerType: true,
      claimType: true,
      itemLevelShortage: true,
    },
  },
]
