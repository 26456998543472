import React, { useEffect } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useMutation } from '@apollo/client'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import BaseLayout from '../BaseLayout'
import {
  GET_USER_PROFILE,
  CreateUserProfile,
  CREATE_USER_PROFILE,
} from './userProfle'
import { Button, Form, Layout, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { NewUserProfileRequest } from './userProfle'
import { useFormik } from 'formik'
import { useTeamsOrVendors } from './UserProfileProvider'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'

export const CreateProfile: React.FC = () => {
  const { session } = useAuth()
  const { isAuthorized }: any = useAuth()
  const env = useEnv() as EnvConfig
  const [isVendor, setIsVendor] = useState<boolean>()
  const autoCompleteValues = useTeamsOrVendors(
    isVendor,
    session?.userInfo?.lanId ?? ''
  )

  const [createProfile, profile] = useMutation<
    CreateUserProfile,
    { input: NewUserProfileRequest }
  >(CREATE_USER_PROFILE)

  const formik = useFormik<NewUserProfileRequest>({
    initialValues: {
      userId: session?.userInfo?.lanId ?? '',
      firstName: session?.userInfo?.firstName ?? '',
      secondName: session?.userInfo?.lastName ?? '',
      teamId: undefined,
      defaultVendorId: undefined,
      defaultVendorName: undefined,
    },
    validate: (values) => {
      const errors: { teamId?: String } = {}
      if (values.teamId === '') {
        errors.teamId = 'Please Select Workflow id'
      }

      return errors
    },
    onSubmit: async (values) => {
      await createProfile({
        variables: {
          input: values,
        },
        refetchQueries: [
          {
            query: GET_USER_PROFILE,
            variables: { userId: values.userId },
          },
        ],
        awaitRefetchQueries: true,
      })
    },
  })

  useEffect(() => {
    if (isAuthorized(env.auth.vendorRole)) {
      setIsVendor(true)
    } else {
      setIsVendor(false)
    }
  }, [isAuthorized, env.auth.vendorRole])

  if (profile.loading) {
    return (
      <Layout.Body>
        <BaseLayout centerAlign>
          <Spinner />
        </BaseLayout>
      </Layout.Body>
    )
  }

  return (
    <Layout.Body>
      <BaseLayout>
        <Form onSubmit={formik.handleSubmit}>
          <Autocomplete
            id={isVendor ? 'defaultVendorId' : 'teamId'}
            options={autoCompleteValues}
            label={
              isVendor
                ? 'Select the default Vendor number'
                : 'Choose default Workflow'
            }
            errorText={
              isVendor ? formik.errors.defaultVendorId : formik.errors.teamId
            }
            onUpdate={(id, value) => {
              if (value) {
                formik.setFieldValue(id, Number(value.value))
                formik.setFieldValue(
                  'defaultVendorName',
                  isVendor ? value.defaultVendorName : undefined
                )
              } else {
                formik.setFieldValue(id, undefined)
                formik.setFieldValue('defaultVendorName', undefined)
              }
            }}
          />
          <Button
            fullWidth
            type="submit"
            disabled={
              !formik.isValid ||
              (formik.values.defaultVendorId === undefined &&
                formik.values.teamId === undefined)
            }
          >
            Create Profile
          </Button>
        </Form>
      </BaseLayout>
    </Layout.Body>
  )
}
