import {
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { GET_REASONCODES, ReasonCodes } from '../../Cases/Common/Queries'
import { CREATE_REASONCODE, CreateReasonCodeVar } from '../common/AdminQueries'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'

export interface ReasonCodeForm {
  reasonCodeId?: string
  reasonCodeText?: string
  supportDocList?: string
  active?: boolean
}

const schema = Yup.object().shape<ReasonCodeForm>({
  reasonCodeId: Yup.string()
    .required('Enter Reason Code Id')
    .min(2, 'Length shoud be minimum 2 chars')
    .max(10, 'Length should be max 10 chars')
    .test(
      'Fine',
      'Reason code Id already exist. Please enter a unique Reason code Id',
      function (value) {
        const context = this.options.context as any[]

        return !context.some((reason: any) => reason.reasonCodeId === value)
      }
    ),
  reasonCodeText: Yup.string()
    .required('Enter Reason Code Text')
    .min(5, 'Length shoud be minimum 5 chars')
    .max(100, 'Length should be max 100 chars')
    .test(
      'Fine',
      'Reason code text already exists. Please enter a unique Reason code text',
      function (value) {
        const context = this.options.context as any[]

        return !context.some((reason: any) => reason.reasonCodeText === value)
      }
    ),
  supportDocList: Yup.string()
    .optional()
    .max(500, 'Supporting document text length should be max 500 chars'),
})

const initialValues: ReasonCodeForm = {
  reasonCodeId: undefined,
  reasonCodeText: undefined,
  supportDocList: undefined,
  active: true,
}

export const AddReasonCode = (props: any) => {
  const formik = useFormik<ReasonCodeForm>({
    initialValues: { ...initialValues },
    validate: async (values) => {
      try {
        await validateYupSchema<ReasonCodeForm>(
          values,
          schema,
          true,
          props.reasonCodes
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      console.log(values)
      await updateReasonCode({
        variables: {
          input: {
            reasonCodeId: values.reasonCodeId!!,
            reasonCodeText: values.reasonCodeText!!,
            active: values.active,
            supportDocList: values.supportDocList,
          },
        },
        refetchQueries: [
          {
            query: GET_REASONCODES,
          },
        ],
        awaitRefetchQueries: true,
      }).then(() => props.handleClose())
    },
  })

  const [updateReasonCode, { loading }] = useMutation<
    ReasonCodes,
    CreateReasonCodeVar
  >(CREATE_REASONCODE, {
    onCompleted: (data) => {
      console.log(data)
    },
  })

  return (
    <Modal
      headingText="Create Reason Code"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible
    >
      <Form onSubmit={formik.handleSubmit}>
        <Grid.Container justify={'center'} className="hc-pa-normal">
          <Grid.Item xs={11}>
            <Grid.Container>
              <Grid.Item xs={6}>
                <Form.Field
                  id={'reasonCodeId'}
                  label={'Reason Code Id'}
                  type="text"
                  required
                  onChange={(e: any) =>
                    formik.setFieldValue(
                      'reasonCodeId',
                      e.target.value?.toUpperCase()
                    )
                  }
                  value={formik.values.reasonCodeId}
                  error={
                    formik.touched.reasonCodeId &&
                    formik.errors.reasonCodeId !== undefined
                  }
                  errorText={formik.errors.reasonCodeId}
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Form.Field
                  id={'reasonCodeText'}
                  label={'Reason Code Text'}
                  type="text"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.reasonCodeText}
                  error={
                    formik.touched.reasonCodeText &&
                    formik.errors.reasonCodeText !== undefined
                  }
                  errorText={formik.errors.reasonCodeText}
                />
              </Grid.Item>
              <Grid.Item xs={12}>
                <Form.Field
                  id={'supportDocList'}
                  label="Supporting Document Text"
                  type="textarea"
                  onChange={formik.handleChange}
                  value={formik.values.supportDocList}
                  error={
                    formik.touched.supportDocList &&
                    formik.errors.supportDocList !== undefined
                  }
                  errorText={formik.errors.supportDocList}
                  size="dense"
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <GridItem xs={11}>
            <GridContainer align="center">
              <GridItem xs={3}>
                <Form.Field
                  id="status"
                  type="toggle"
                  label={'Active'}
                  value={formik.values.active}
                  onChange={() => {
                    formik.setFieldValue('active', !formik.values.active)
                  }}
                ></Form.Field>
              </GridItem>
              <GridItem justify="flex-end" xs={9}>
                <GridContainer direction="row-reverse" spacing="dense">
                  <GridItem>
                    <Button type="submit" disabled={loading}>
                      Save
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => {
                        props.handleClose()
                      }}
                    >
                      Cancel
                    </Button>
                  </GridItem>

                  {loading && (
                    <GridItem>
                      <Spinner></Spinner>
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </Grid.Container>
      </Form>
    </Modal>
  )
}
