import { CaseSubTypes } from '../Services/ConstantData'
import _ from 'lodash'

export function findRequired(formikValues: any): any {
  const returnValues = _.find(CaseSubTypes, function (o) {
    return (
      o.caseType === formikValues.caseType &&
      o.caseSubType === formikValues.caseSubType
    )
  })
  return returnValues
}
export function findExceptionVendorDetails(
  exceptionVendorValues: Array<any>,
  vendor: number | undefined
): any {
  let returnValues = _.find(exceptionVendorValues, function (exceptionVendor) {
    return Number(exceptionVendor.vendor) === Number(vendor)
  })
  if (returnValues !== undefined) {
    return returnValues
  }
  returnValues = _.find(exceptionVendorValues, function (exceptionVendor) {
    return exceptionVendor.vendor === 'ALL'
  })

  return returnValues
}
