import React, { ReactNode, useEffect, useState } from 'react'
import {
  Button,
  Divider,
  GridContainer,
  GridItem,
  Heading,
  Layout,
  Spinner,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { ReportSideFilters } from './ReportSideFilters'
import { DateFilter, SideFilter } from './ReportContext/ReportContext'
import ReportTable from './ReportTable'
import { ColDef } from 'ag-grid-community'
import GraphView from './GraphView'
import { Value } from '../Cases/Search/SearchContext/SearchInitialValues'
import { CSVLink } from 'react-csv'
import { RangeFilter } from './RangeFilter'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  ChartBarVerticalIcon,
  DataTableIcon,
  DownloadIcon,
  EnterpriseIcon,
} from '@enterprise-ui/icons'

const baseLayout = css`
  height: 80vh;
  overflow: hidden;
`

export enum VisualizationTypes {
  TABLE = 'table',
  GRAPH = 'graph',
}

export interface DynamicFilter {
  enabled: boolean
  filterName: string
  id: string
  handleDynamicFilterChange?: any
}

export interface ReportBaseLayoutProps {
  reportName: string
  loading?: boolean
  visualizationTypes?: VisualizationTypes[]
  sideFilters?: SideFilter[]
  data: any[]
  chartData: any[]
  uniqueSubTypes: any[]
  columns: ColDef[]
  customSideFilters?: ReactNode
  reportType: 'aggregation' | 'Comparison' | 'workflow_management'
  dynamicFilter?: DynamicFilter
  dateFilter: DateFilter
  handleQueryChange: any
  buildDrillDownUrl?: any
}

export const ReportBaseLayout: React.FC<ReportBaseLayoutProps> = ({
  reportName,
  loading = false,
  visualizationTypes = [VisualizationTypes.TABLE, VisualizationTypes.GRAPH],
  sideFilters = [],
  data,
  columns,
  reportType,
  chartData,
  uniqueSubTypes,
  customSideFilters,
  dynamicFilter,
  dateFilter,
  buildDrillDownUrl,
}) => {
  const [showFilters, setShowFilters] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState('tab_01')
  const [gridData, setGridData] = useState(data)
  const [dynamicFilters, setDynamicFilters] = useState<Value[]>([])
  const [activeUniqueSubTypes, setActiveUniqueSubTypes] = useState<any[]>([])
  const [csvHeaders, setCsvHeaders] = useState<any[]>([])

  useEffect(() => {
    setGridData([...data])
    setCsvHeaders(
      columns.map((column) => {
        return { key: column.field, label: column.headerName ?? column.field }
      })
    )
  }, [data, columns])

  useEffect(() => {
    setDynamicFilters(
      uniqueSubTypes.map((filter) => {
        const date: Value = {
          id: filter,
          label: filter,
          value: filter,
          isChecked: false,
        }
        return date
      })
    )
    setActiveUniqueSubTypes(uniqueSubTypes)
  }, [uniqueSubTypes])

  const handleDynamicFilterChange = (
    id: string,
    type: string,
    event: any,
    searchField?: string
  ) => {
    const dynamicFilterUpdate = dynamicFilters.map((value) => {
      if (value.value === event.target.value) {
        return { ...value, isChecked: !value.isChecked }
      } else return { ...value }
    })

    const activeFilters = dynamicFilterUpdate
      .filter((value) => value.isChecked)
      .map((value) => value.value)

    if (activeFilters.length > 0) {
      const filterdata = data.filter((row) =>
        activeFilters.includes(row[dynamicFilter?.id ?? 'aggregation'])
      )
      setGridData(filterdata)
      setActiveUniqueSubTypes(activeFilters)
    } else {
      setGridData(data)
      setActiveUniqueSubTypes(uniqueSubTypes)
    }
    setDynamicFilters(dynamicFilterUpdate)
  }

  const toggleFilters = () => {
    setShowFilters((filter) => !filter)
  }

  return (
    <>
      <Layout.SectionHeader>
        <GridContainer justify="space-between">
          <GridItem>
            <Heading size={2}>{reportName}</Heading>
          </GridItem>
          <GridItem>
            <GridContainer>
              <GridItem>
                <RangeFilter dateFilter={dateFilter} />
              </GridItem>
              <GridItem>
                <CSVLink
                  data={gridData}
                  filename={`${reportName}-${new Date()}.csv`}
                  headers={csvHeaders}
                >
                  <Button iconOnly aria-label="Download">
                    <EnterpriseIcon icon={DownloadIcon} />
                  </Button>
                </CSVLink>
              </GridItem>
              <GridItem>
                <Button type="primary" onClick={toggleFilters}>
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Layout.SectionHeader>

      <Layout.Body includeRail={!showFilters}>
        <Tabs
          activeTab={activeTab}
          onTabSelect={(event: any, tab: any) => {
            setActiveTab(tab.name)
          }}
        >
          {visualizationTypes.includes(VisualizationTypes.TABLE) && (
            <Tabs.Item name="tab_01">
              <p>
                <EnterpriseIcon icon={DataTableIcon} inline />
                &nbsp; Table
              </p>
            </Tabs.Item>
          )}
          {visualizationTypes.includes(VisualizationTypes.GRAPH) && (
            <Tabs.Item name="tab_02">
              <p>
                <EnterpriseIcon icon={ChartBarVerticalIcon} inline />
                &nbsp; Graph
              </p>
            </Tabs.Item>
          )}
          <Divider />
          {visualizationTypes.includes(VisualizationTypes.TABLE) && (
            <Tabs.Content name="tab_01" className="hc-ph-none">
              {!loading ? (
                <ReportTable data={gridData} columns={columns} />
              ) : (
                <GridContainer justify="center" align="center" css={baseLayout}>
                  <GridItem>
                    <Spinner />
                  </GridItem>
                </GridContainer>
              )}
            </Tabs.Content>
          )}
          {visualizationTypes.includes(VisualizationTypes.GRAPH) && (
            <Tabs.Content name="tab_02">
              {!loading ? (
                <GraphView
                  reportType={reportType}
                  chartData={chartData}
                  uniqueSubType={activeUniqueSubTypes}
                  buildDrillDownUrl={buildDrillDownUrl}
                />
              ) : (
                <GridContainer justify="center" align="center" css={baseLayout}>
                  <GridItem>
                    <Spinner />
                  </GridItem>
                </GridContainer>
              )}
            </Tabs.Content>
          )}
        </Tabs>
      </Layout.Body>

      {showFilters && (
        <Layout.Rail>
          <ReportSideFilters
            sideFilters={sideFilters}
            dynamicFilter={
              dynamicFilter && {
                ...dynamicFilter,
                handleDynamicFilterChange: handleDynamicFilterChange,
              }
            }
            dynamicFilters={dynamicFilters}
            customSideFilters={customSideFilters}
          />
        </Layout.Rail>
      )}
    </>
  )
}
