import React from 'react'
import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { NewCaseRequest } from './Queries'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import _ from 'lodash'
import { CASE_SUMMARY_HEADING, NO_PAYMENT_DETAILS } from './Constants'
import { Section, SectionItem, SummaryHeading } from '../Common/Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'

import { useGlobalForm } from '../Context/GlobalFormStateContext'

const container = css`
  height: 30px;
  overflow-x: hidden;
  overflow-y: auto;
`

interface MainProp {
  caseSummaryDetails: NewCaseRequest
  isVendor: any
  docTypeReqFlag?: any
  importVendor?: boolean
}

const CaseSummary: React.FC<MainProp> = ({
  caseSummaryDetails,
  isVendor,
  docTypeReqFlag,
  importVendor,
}) => {
  const documentAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(caseSummaryDetails?.originalDocumentAmount))
  const disputedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(caseSummaryDetails?.disputedDocumentAmount))
  const departmentId =
    caseSummaryDetails?.departmentId === 0
      ? null
      : _.padStart(caseSummaryDetails.departmentId?.toString(), 3, '0')
  const locationId = caseSummaryDetails.locationId
    ? _.padStart(caseSummaryDetails.locationId?.toString(), 4, '0')
    : null
  const [state] = useGlobalForm()

  return (
    <React.Fragment>
      {state.isVendShowable && !isVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={CASE_SUMMARY_HEADING} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Vendor ID">
              {caseSummaryDetails.vendorNumber}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails.vendorName}
            </SectionItem>
            <SectionItem label="Type">
              {caseSummaryDetails.vendorType}
            </SectionItem>
            <SectionItem label="Contact">
              {caseSummaryDetails.vendorContactName}
            </SectionItem>
            <SectionItem label="Email">
              {caseSummaryDetails.vendorEmailAddress}
            </SectionItem>
            {caseSummaryDetails?.bankName !== null && (
              <SectionItem label="Bank Name">
                {caseSummaryDetails.bankName ?? 'None'}
              </SectionItem>
            )}
          </Section>
        </div>
      )}
      {isVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={CASE_SUMMARY_HEADING} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Vendor ID">
              {caseSummaryDetails.vendorNumber}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails.vendorName}
            </SectionItem>
            <SectionItem label="Type">
              {caseSummaryDetails.vendorType}
            </SectionItem>
            <SectionItem label="Contact">
              {caseSummaryDetails.vendorContactName}
            </SectionItem>
            <SectionItem label="Email">
              {caseSummaryDetails.vendorEmailAddress}
            </SectionItem>
            {caseSummaryDetails?.bankName !== null && (
              <SectionItem label="Bank Name">
                {caseSummaryDetails.bankName ?? 'None'}
              </SectionItem>
            )}
          </Section>
        </div>
      )}
      {state.showDocuments && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Dispute Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Document #">
              {caseSummaryDetails.originalDocumentNumber}
            </SectionItem>
            <SectionItem label="Case Sub Type">
              {caseSummaryDetails.caseSubType}
            </SectionItem>
            {(docTypeReqFlag === 'Y' || !isVendor) && (
              <SectionItem label="Claim/Doc Type">
                {caseSummaryDetails.documentTypeText}
              </SectionItem>
            )}
            {!isVendor && !importVendor && (
              <SectionItem label="Document Sub Type">
                {caseSummaryDetails.documentSubTypeText}
              </SectionItem>
            )}
            <SectionItem label="Reason">
              {caseSummaryDetails.reasonCodeText}
            </SectionItem>
            <Grid.Container>
              <Grid.Item xs={12} className="hc-pt-dense hc-pb-dense">
                <Input.Label className="hc-clr-grey02 hc-fs-xs">
                  Case Description :
                </Input.Label>
                <Input.Label css={container} className="hc-clr-black hc-fs-xs">
                  {caseSummaryDetails.caseDescription}
                </Input.Label>
              </Grid.Item>
            </Grid.Container>
          </Section>
        </div>
      )}
      {state.isDocShowable && state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Payment document amount">
              {caseSummaryDetails?.originalCheckAmount}
            </SectionItem>
            <SectionItem label="Payment invoice">
              {caseSummaryDetails.originalCheckNumber}
            </SectionItem>
            <SectionItem label="Payment date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.originalCheckDate}
                format="L"
              />
            </SectionItem>
          </Section>
        </div>
      )}
      {state.showDocuments && !state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Details'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem label="Payment Details">
              {NO_PAYMENT_DETAILS}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Document Details'} />
          <Section labelGridSize={4} detail={false}>
            <SectionItem label="Document amount">{documentAmount}</SectionItem>
            <SectionItem label="Disputed amount">{disputedAmount}</SectionItem>
            <SectionItem label="Purchased order #">
              {caseSummaryDetails.purchaseOrderNumber}
            </SectionItem>
            <SectionItem label="Department">{departmentId}</SectionItem>
            <SectionItem label="Class">
              {caseSummaryDetails.classId}
            </SectionItem>
            <SectionItem label="Location">{locationId}</SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Receipt Details'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem label="Receipt #">
              {caseSummaryDetails.receipts?.join(', ')}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && !isVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Workflow Details'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem label="Workflow">
              {caseSummaryDetails.workflow}
            </SectionItem>
            <SectionItem label="Assignee">{state.name}</SectionItem>
          </Section>
        </div>
      )}
    </React.Fragment>
  )
}

export default CaseSummary
