import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReportBaseLayout } from './ReportBaseLayout'
import { useReport } from './ReportContext/ReportProvider'
import { UPDATE_FILTER } from '../Cases/Search/SearchContext/ActionTypes'
import * as qs from 'qs'
import { useLazyQuery } from '@apollo/client'
import {
  AggregationAgeVariables,
  GetAgeAggregation,
  GET_AGE_AGGREGATION,
} from '../Dashboard/Queries'
import { ASSIGNED_TO } from './ReportConstants'
import { convertToCurrency } from './ReportsUtils'
import { ColDef } from 'ag-grid-community'
import flatten from 'lodash/fp/flatten'
import uniqBy from 'lodash/fp/uniqBy'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import { addEscapeChar, buildReportQuery } from './ReportContext/ReportUtils'
import {
  Divider,
  ExpandableSection,
  Input,
  Heading,
} from '@enterprise-ui/canvas-ui-react'

interface ReportData {
  gridRows: any[]
  gridColumns: ColDef[]
  chartData?: any[]
  uniqueSubTypes: (string | undefined)[]
}

interface ReportSwitchProps {
  id: string
  reportTypes: any[] | undefined
  consolidateType?: string
}

const ReportSwitch: React.FC<ReportSwitchProps> = ({ id, reportTypes }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <ExpandableSection padding="dense" startExpanded toggleLocation="left">
        <Heading className="hc-clr-black" size={6}>
          Switch Reports
        </Heading>
        <ExpandableSection.Content className="hc-ml-sm hc-mr-sm">
          <div className="hc-mb-dense">
            <Input.Label> Status </Input.Label>
            <Input.Radio
              id="Status"
              value={id}
              options={reportTypes ?? []}
              onUpdate={(id: any, value: any) => {
                navigate('/workflow_management/' + value + location.search)
              }}
            />
          </div>
        </ExpandableSection.Content>
      </ExpandableSection>
      <Divider />
    </>
  )
}

export const WorkflowManagementReport = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [state, dispatch] = useReport()
  const [reportData, setReportData] = useState<ReportData | undefined>()

  const [loadAggregation, { loading }] = useLazyQuery<
    GetAgeAggregation,
    AggregationAgeVariables
  >(GET_AGE_AGGREGATION, {
    fetchPolicy: 'network-only',
    onCompleted: (aggregationDetails) => {
      const aggregation = [...(aggregationDetails.getAggregatedAgeNew ?? [])]
      const uniqueRows = aggregation
        .map((rows) => rows.rootType)
        .sort(function (x, y) {
          if (x !== 'Unassigned') {
            return -1
          }
          return 0
        })
      const uniqueColumns: string[] = flow(
        map((aggs: any) => aggs.subType),
        flatten,
        uniqBy('rootType'),
        map((aggs: any) => aggs?.rootType)
      )(aggregation)
      const gridData = uniqueRows.map((row) => {
        let gridRow: any = {
          aggregation: row,
        }
        uniqueColumns.forEach((column) => {
          const value =
            aggregation
              .find((aggs) => aggs.rootType === row)
              ?.subType?.find((aggs) => aggs.rootType === column)?.value ?? 0
          gridRow[column as string] = value
        })
        return gridRow
      })
      const chartData = aggregation.map((value) => {
        let subCategory: any = {}
        value.subType?.forEach((type) => {
          subCategory[type['rootType'] as string] =
            state.report?.consolidateType === 'COUNT'
              ? type['value']
              : type['value']?.toFixed(2)
        })
        subCategory.x_axis_data = value.rootType
        subCategory.total = value.value
        return subCategory
      })

      // set grid data
      setReportData({
        gridRows: gridData,
        gridColumns: [
          {
            field: 'aggregation',
            headerName: 'Assigned to/ Aging',
            onCellClicked: (props) => {
              navigate(
                `/cases?&search%5B0%5D=${buildDrillDownUrl(props.value)}`
              )
            },
          },
          ...uniqueColumns.map((column) => {
            let col: ColDef = {
              field: column,
              valueFormatter:
                state.report?.consolidateType === 'AMOUNT'
                  ? (params) => {
                      return `${convertToCurrency(params.value)}`
                    }
                  : undefined,
            }
            return col
          }),
        ],
        chartData: chartData,
        uniqueSubTypes: uniqueColumns,
      })
    },
  })

  useEffect(() => {
    dispatch({
      type: UPDATE_FILTER,
      payload: qs.parse(location.search.replace('?', '')),
    })
  }, [dispatch, location.search])

  useEffect(() => {
    handleQueryChange(buildReportQuery(state) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleQueryChange = (query: string) => {
    loadAggregation({
      variables: {
        query: query + ` AND status: ((${state.id}))`,
        aggregateBy: ASSIGNED_TO,
        field: state.report?.ageingField,
        agingInterval: state.report?.ageingWindow,
      },
    })
  }

  const buildDrillDownUrl = (
    row: string,
    column?: string,
    isGraph: boolean = false
  ): string => {
    const query: any = qs.parse(location.search.replace('?', ''))
    const dateFilter = !isGraph
      ? `(${state.report?.dateFilter.id}: [${state.report?.dateFilter.active?.from} TO ${state.report?.dateFilter.active?.to}])`
      : ''

    const sideFilters = Object.keys(query)
      .filter((key) => key !== 'consolidateType')
      .map((key) => {
        if (query[key] instanceof Array) {
          const filters = query[key]
            .map((filter: string) => `(${addEscapeChar(filter)})`)
            .join(' OR ')
          return `(${key}: ${filters})`
        } else {
          return ''
        }
      })
      .filter((value) => value !== '')
      .join(' AND ')

    const idFilter = `(${ASSIGNED_TO}: (${addEscapeChar(row)}))`
    const lan_id = column !== undefined ? `(${ASSIGNED_TO}: (${column}))` : ''
    const status = `status: ((${state.id}))`
    var arr = [sideFilters, dateFilter, idFilter, lan_id, status]
      .filter((value: string) => !(value === '') && !value.includes('()'))
      .join(' AND ')

    return arr
  }

  if (state.report == null) {
    return <div>Not Found</div>
  }

  return (
    <ReportBaseLayout
      reportName={state.report.reportName}
      sideFilters={state.report.sideFilters.filter(
        (filter) => filter.id !== 'status'
      )}
      data={reportData?.gridRows ?? []}
      columns={reportData?.gridColumns ?? []}
      chartData={reportData?.chartData ?? []}
      uniqueSubTypes={reportData?.uniqueSubTypes ?? []}
      reportType={'workflow_management'}
      handleQueryChange={handleQueryChange}
      loading={loading}
      dateFilter={state.report.dateFilter}
      buildDrillDownUrl={buildDrillDownUrl}
      customSideFilters={
        <ReportSwitch
          id={state.id}
          reportTypes={
            state.report?.sideFilters?.find(
              (o: { id: string }) => o.id === 'status'
            )?.values
          }
          consolidateType={state.report.consolidateType}
        />
      }
    />
  )
}
