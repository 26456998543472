import { filter, uniqBy } from 'lodash'
import { DocPrefixMaps } from '../../Cases/Common/Queries'

export interface CaseTypeOption {
  id: string | number
  value: string | number
  label: string
}

export function getUniqueCaseTypes(
  cases: DocPrefixMaps[] | undefined
): CaseTypeOption[] {
  if (!cases) return []
  return uniqBy(cases, 'caseType').map(({ caseType }) => ({
    id: caseType,
    value: caseType,
    label: caseType,
  }))
}

export function getUniqueCaseSubTypes(
  cases: DocPrefixMaps[] | undefined,
  caseType: string | undefined
): CaseTypeOption[] {
  if (!cases) return []
  return uniqBy(filter(cases, { caseType }), 'caseSubType').map(
    ({ caseSubType }) => ({
      id: caseSubType,
      value: caseSubType,
      label: caseSubType,
    })
  )
}

export function getUniqueDocumentTypes(
  cases: DocPrefixMaps[] | undefined,
  caseType: string | undefined,
  caseSubType: string | undefined
): CaseTypeOption[] {
  if (!cases) return []
  return uniqBy(
    filter(cases, { caseType, caseSubType }),
    (c) => `${c.documentTypeId}-${c.documentTypeText}`
  )
    .map(({ documentTypeId, documentTypeText }) => ({
      id: documentTypeId,
      value: documentTypeId,
      label: documentTypeText,
    }))
    .filter((value) => value.id !== null)
}

export function getUniqueDocumentSubTypes(
  cases: DocPrefixMaps[] | undefined,
  caseType: string | undefined,
  caseSubType: string | undefined,
  documentTypeId: number | null | undefined
): CaseTypeOption[] {
  if (!cases) return []
  return uniqBy(
    filter(
      cases,
      (c) =>
        c.caseType === caseType &&
        c.caseSubType === caseSubType &&
        c.documentTypeId === documentTypeId
    ),
    (c) => `${c.documentSubTypeId}-${c.documentSubTypeText}`
  )
    .map(({ documentSubTypeId, documentSubTypeText }) => ({
      id: documentSubTypeId,
      value: documentSubTypeId,
      label: documentSubTypeText,
    }))
    .filter((value) => value.id !== null)
}
