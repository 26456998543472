import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Button,
  Form,
  GridContainer,
  GridItem,
  Heading,
  Input,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Case,
  CaseResponse,
  GET_REOPENCODES,
  GetReopenCodes,
  ReopenCodes,
  UpdateCaseRequest,
} from '../../Common/Queries'
import { CaseStatus } from '../../Services/ConstantData'
import { FormFieldType } from '../../Common/ReopenModal'
import { uniqBy } from 'lodash'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useAuth } from '@praxis/component-auth'
import { convertToUpdateRequests } from '../../Common/PrepareUpdateRequest'
import { useProfile } from '../../../Layout/UserProfile/UserProfileProvider'

export const formFieldMargin = css`
  margin-top: 13px;
`
export const reopenFilter = (status: string | undefined) => {
  const filter = status === CaseStatus.RESOLVED || status === CaseStatus.CLOSED
  return filter
}

export interface ReopenCaseProps {
  validSelectedCases: CaseResponse[] | undefined
  allSelectedCases: Case[] | undefined
  onSave: any
  loading: any
  setVisible: any
  visible: any
}

export const ReopenCase: React.FC<ReopenCaseProps> = ({
  validSelectedCases,
  onSave,
  loading,
  setVisible,
  visible,
}) => {
  const { userProfile } = useProfile()
  const { session } = useAuth()
  const activityDescription = 'Case is Bulk Reopened'
  const [reopenReason, setReopenReason] = useState<FormFieldType>({
    id: '',
    value: '',
    label: '',
  })
  const [workNote, setWorkNote] = useState<string>('')
  const [isInternalChecked, setIsInternalChecked] = useState<boolean>(true)

  const { data: reopenCodesData } = useQuery<GetReopenCodes>(GET_REOPENCODES)

  function getReopenCodeList(
    reopenCodesList: ReopenCodes[] | undefined
  ): FormFieldType[] {
    if (!reopenCodesList) return []
    return uniqBy(reopenCodesList, 'reopenCodeText')
      .filter((value) => value.active !== false)
      .map(({ reopenCodeId, reopenCodeText, reopenCodeDescription }) => ({
        id: reopenCodeId ?? '',
        value: reopenCodeText ?? '',
        label: reopenCodeText ?? '',
      }))
  }

  function handleAuditForReopen(casee: UpdateCaseRequest) {
    const adAuditEntry = casee.auditEntries
      ? casee.auditEntries.map(function (auditEntries: any) {
          return {
            activityDescription: auditEntries.activityDescription,
            userId: auditEntries.userId,
            workflow: auditEntries.workflow,
            status: auditEntries.status,
            assignUserId: auditEntries.assignUserId,
            activityTimestamp: auditEntries.activityTimestamp,
          }
        })
      : []

    adAuditEntry.unshift({
      activityDescription: activityDescription,
      userId: session?.userInfo?.fullName,
      workflow: casee?.workflow,
      status: casee?.status,
      assignUserId: casee?.lanId,
      activityTimestamp: new Date().toISOString(),
    })
    return adAuditEntry
  }

  function handleWorkNoteForReopen(casee: UpdateCaseRequest) {
    let currentNotes = casee?.notes
      ? casee?.notes.map(function (notes) {
          return {
            userId: notes.userId,
            userName: notes.userName,
            commentBody: notes.commentBody,
            isInternal: notes.isInternal,
            commentDate: notes.commentDate,
          }
        })
      : []

    currentNotes.push({
      userId: userProfile?.userId,
      userName: `${userProfile?.firstName}${' '}${userProfile?.secondName}`,
      commentBody: workNote,
      isInternal: userProfile?.isVendor ? false : isInternalChecked,
      commentDate: new Date().toISOString(),
    })
    return currentNotes
  }

  function handleSave() {
    const updateRequest = convertToUpdateRequests(validSelectedCases ?? []).map(
      (casee: UpdateCaseRequest) => ({
        ...casee,
        reopenDetails: {
          reopenReasonId: reopenReason?.id !== '' ? reopenReason?.id : null,
          reopenReason: reopenReason?.value,
          reopenDate: new Date().toISOString(),
          reopenUser: session?.userInfo?.lanId,
          previousResolutionDate: casee?.resolutionDate,
          previousResolutionUser: casee?.lanId,
          previousResolutionCode: casee?.resolutionCode,
          previousResolutionCodeText: casee?.resolutionCodeText,
          previousResolutionText: casee?.resolutionText,
          previousResolutionAmount: casee?.resolutionAmount,
          previousResolutionDocumentNumber: '', //previousResolutionDocumentNumber,
        },
        resolutionDate: null,
        resolutionCode: undefined,
        resolutionCodeText: null,
        resolutionText: undefined,
        resolutionAmount: null,
        lanId: null,
        status: CaseStatus.REOPEN,
        isReopen: true,
        auditEntries: handleAuditForReopen(casee),
        notes: handleWorkNoteForReopen(casee),
      })
    )
    onSave(updateRequest)
    clearValues()
  }

  function clearValues() {
    setVisible(false)
    setReopenReason({ id: '', value: '', label: '' })
    setIsInternalChecked(true)
    setWorkNote('')
  }

  return (
    <React.Fragment>
      <Modal
        headingText={'Reopen Cases'}
        isVisible={visible}
        onRefuse={() => {
          clearValues()
        }}
        className="reopenModal"
      >
        <GridContainer justify="center" className="hc-pa-normal">
          <GridItem xs={11} className={'hc-pt-md'}>
            <Autocomplete
              id="reopenCodeList"
              label={'Select the reason for reopening these cases?'}
              onUpdate={(id, value) => {
                setReopenReason(value)
              }}
              value={reopenReason}
              options={getReopenCodeList(reopenCodesData?.getReopenCodes) ?? []}
            />
          </GridItem>
          <GridItem xs={8}>
            <Form.Field
              id="bulk_work_note"
              label={'Add a work note'}
              errorText="Please add a work note"
              onChange={(e: any) => {
                setWorkNote(e.target.value)
              }}
              type="textarea"
              size="dense"
              value={workNote}
            />
          </GridItem>
          <GridItem className="hc-pt-xl" xs={3}>
            <Input.Checkbox
              id="bulk_work_note_internal"
              label="Viewable To Vendor"
              checked={!isInternalChecked}
              onChange={() => {
                setIsInternalChecked(!isInternalChecked)
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <GridContainer align="center" className="hc-ph-md">
              <GridItem xs>
                <Heading size={6}>
                  {validSelectedCases?.length} Selected
                </Heading>
              </GridItem>
              <GridItem justify="flex-end">
                <GridContainer direction="row-reverse" spacing="dense">
                  <GridItem>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSave()
                      }}
                      disabled={loading}
                    >
                      Save
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => {
                        clearValues()
                      }}
                    >
                      Cancel
                    </Button>
                  </GridItem>
                  {loading && (
                    <GridItem>
                      <Spinner></Spinner>
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Modal>
    </React.Fragment>
  )
}
