import React, { useState, useEffect } from 'react'
import { Grid, Modal, Button, Form } from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { useAuth } from '@praxis/component-auth'
import { activityData } from '../Services/saveActivity'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'

interface MainProp {
  headingText: String
  formik: FormikValues
  saveStatus: any
}

const EditDescriptionModal: React.FC<MainProp> = ({
  headingText,
  formik,
  saveStatus,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const activityDescription = 'Modified Description Summary'
  const [summary, setSummary] = useState<string>('')
  const [state, dispatch] = useGlobalForm()
  function saveActivty() {
    formik.setFieldValue('caseDescription', summary)
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    clearValues()
    saveStatus()
  }
  const clearValues = () => {
    setSummary(formik.values.caseDescription)
  }
  useEffect(() => {
    if (formik.values.caseDescription) {
      setSummary(formik.values.caseDescription)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.caseDescription])
  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.caseDescriptionFlag}
        onRefuse={() => {
          clearValues()
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'caseDescriptionFlag', value: false }],
          })
        }}
      >
        <div className="hc-pa-normal hc-pa-lg">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={12} className="hc-pb-none">
                  <Form.Field
                    id="caseDescription"
                    label="Case Brief Explanation"
                    type="textarea"
                    onChange={(event: any) => {
                      setSummary(event.target.value)
                    }}
                    maxLength="500"
                    value={summary}
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item xs></Grid.Item>
                <Grid.Item justify="flex-end">
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          saveActivty()
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={() => {
                          clearValues()
                          dispatch({
                            type: SET_FIELD,
                            payload: [
                              { id: 'caseDescriptionFlag', value: false },
                            ],
                          })
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}

export default EditDescriptionModal
