import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import { useGlobalForm } from '../Context/GlobalFormStateContext'

const SEARCH_ATTACHMENTS = gql`
  query SearchAttachments($receipts: [String]!, $location: String!) {
    searchAttachments(receipts: $receipts, location: $location) {
      fileName
      recordId
      receipt
      location
    }
  }
`
const SEARCH_CHARGEBACK = gql`
  query SearchChargeback(
    $reportName: String!
    $vendorId: String!
    $documentId: String!
  ) {
    searchChargeback(
      reportName: $reportName
      vendorId: $vendorId
      documentId: $documentId
    ) {
      reportName
      result {
        documentNumber
        vendorId
      }
    }
  }
`
export interface SearchAttachments {
  searchAttachments: [SearchAttachment]
}

export interface SearchAttachment {
  fileName: string
  recordId: string
  receipt: string
  location: string
}

interface SearchAttachmentsVariable {
  receipts: [string]
  location: string
}

interface SearchChargeback {
  searchChargeback: CBResponse
}

interface CBResponse {
  reportName: string
  result: [SearchChargebackResult]
}

interface SearchChargebackResult {
  documentNumber: string
  vendorId: string
}

export interface SearchChargebackVariable {
  reportName: string
  vendorId: string
  documentId: string
}

export default function useAutoExtractedDocuments(
  attchmentSearchVisible: boolean
): [any] {
  const [state] = useGlobalForm()
  const client = useApolloClient()
  const [deliveryReceipts, setDeliveryReceipts] = useState<any>([])
  const [chargeback, setChargeback] = useState<any>([])
  const [allAttachments, setAllAttachments] = useState<any>([])

  const getDeliveryReceipts = async () => {
    try {
      if (state.location !== undefined) {
        const { data } = await client.query<
          SearchAttachments,
          SearchAttachmentsVariable
        >({
          query: SEARCH_ATTACHMENTS,
          variables: {
            receipts: state.receipts,
            location: state.location,
          },
          fetchPolicy: 'network-only',
        })
        const drAttachments = data?.searchAttachments.map((attch: any) => {
          return {
            ...attch,
            documentSource: 'DR',
            documentType: 'Delivery Receipt',
            documentId: attch?.receipt,
          }
        })
        setDeliveryReceipts(drAttachments)
      }
    } catch (errors: any) {
      console.log('Error while searching for DR', errors?.message)
    }
  }

  const getChargeback = async () => {
    try {
      const { data } = await client.query<
        SearchChargeback,
        SearchChargebackVariable
      >({
        query: SEARCH_CHARGEBACK,
        variables: {
          reportName: 'credit_debit',
          vendorId: state?.vendorNumber,
          documentId: state?.documentNumber,
        },
        fetchPolicy: 'network-only',
      })
      console.log('cb results', data?.searchChargeback)
      const chargeback = [
        {
          fileName: `${state?.documentNumber}.pdf`,
          vendorId: state?.vendorNumber,
          documentId: state?.documentNumber,
          documentSource: 'CB',
          documentType: 'Chargeback',
          location: state?.location,
        },
      ]
      setChargeback(chargeback)
    } catch (errors: any) {
      console.log('Error while searching for CB', errors?.message)
    }
  }

  useEffect(() => {
    if (state?.searchAttachmentType?.includes('DR')) {
      getDeliveryReceipts()
    }
    if (state?.searchAttachmentType?.includes('CB')) {
      getChargeback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attchmentSearchVisible])

  useEffect(() => {
    setAllAttachments([...deliveryReceipts, ...chargeback])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryReceipts, chargeback])

  return allAttachments
}
