import React, { useState, useEffect } from 'react'
import {
  Grid,
  Modal,
  Button,
  Form,
  Divider,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { useAuth } from '@praxis/component-auth'
import { activityData } from '../Services/saveActivity'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import _ from 'lodash'
import { getNum, twoDecimals } from '../Services/TwoDecimalPlaces'
import { CaseResponse } from './Queries'
import { CaseStatus } from '../Services/ConstantData'
import { EnterpriseIcon, MinusIcon, PlusIcon } from '@enterprise-ui/icons'

interface MainProp {
  headingText: String
  formik: FormikValues
  saveStatus: any
  caseData?: CaseResponse | undefined
}

const UpdateRefundDetailsModal: React.FC<MainProp> = ({
  headingText,
  formik,
  saveStatus,
  caseData,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const [state, dispatch] = useGlobalForm()
  const [approvedReversalAmount, setApprovedReversalAmount] = useState<any>()
  const [writeOffAmount, setWriteOffAmount] = useState<any>()
  const [domesticVendorId, setDomesticVendorId] = useState<any>()
  const [domesticAccountAmount, setDomesticAccountAmount] = useState<any>()
  const [wireRefundAmount, setWireRefundAmount] = useState<any>()
  const [associatedOpenClaims, setAssociatedOpenClaims] = useState([
    {
      id: _.uniqueId(),
      claimId: '',
      claimDeductionAmount: '',
    },
  ])
  const [approvedPendingReversalAmount, setApprovedPendingReversalAmount] =
    useState(0.0)

  const openClaims =
    formik?.values?.claimDetails?.importClaimReversal?.openClaimAmountDetails

  function handleInitialValues() {
    let claimDetails = formik?.values?.claimDetails?.importClaimReversal
    let total = claimDetails?.approvedReversalAmount
    setApprovedReversalAmount(total)
    let writeOffAmt = claimDetails?.writeOffAmount
    setWriteOffAmount(writeOffAmt)
    setDomesticVendorId(claimDetails?.domesticAccount?.domesticVendorId)
    let domesticAccAmt = claimDetails?.domesticAccount?.domesticAccountAmount
    setDomesticAccountAmount(domesticAccAmt)
    let wireRefundAmt = claimDetails?.wireRefundAmount
    setWireRefundAmount(wireRefundAmt)
    let openClaimsAmt = associatedOpenClaims?.reduce(
      (accumulator, current) =>
        accumulator + getNum(current.claimDeductionAmount),
      0
    )
    let pendingAmount =
      total - writeOffAmt - domesticAccAmt - wireRefundAmt - openClaimsAmt
    setApprovedPendingReversalAmount(parseFloat(pendingAmount.toFixed(2)))
  }

  function handleInitialOpenClaims() {
    if (openClaims !== undefined && openClaims !== null) {
      let initClaims = openClaims?.map((claim: any) => {
        return {
          id: _.uniqueId(),
          claimDeductionAmount: claim.claimDeductionAmount,
          claimId: claim.claimId,
        }
      })
      setAssociatedOpenClaims(initClaims)
    } else {
      setAssociatedOpenClaims([
        {
          id: _.uniqueId(),
          claimId: '',
          claimDeductionAmount: '',
        },
      ])
    }
  }

  useEffect(() => {
    handleInitialOpenClaims()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openClaims, state?.refundDetailsUpdateFlag])

  useEffect(() => {
    let openClaimsAmt = associatedOpenClaims?.reduce(
      (accumulator, current) =>
        accumulator + getNum(current.claimDeductionAmount),
      0
    )
    let pendingAmount =
      approvedReversalAmount -
      getNum(writeOffAmount) -
      getNum(domesticAccountAmount) -
      getNum(wireRefundAmount) -
      openClaimsAmt
    setApprovedPendingReversalAmount(parseFloat(pendingAmount.toFixed(2)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedOpenClaims])

  useEffect(() => {
    handleInitialValues()
    handleInitialOpenClaims()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values.claimDetails, state?.refundDetailsUpdateFlag])

  function clearValues() {
    handleInitialValues()
    handleInitialOpenClaims()
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'refundDetailsUpdateFlag', value: false }],
    })
  }

  useEffect(() => {
    let openClaimsAmount = associatedOpenClaims?.reduce(
      (accumulator, current) =>
        accumulator + getNum(current.claimDeductionAmount),
      0
    )
    let pendingAmount =
      getNum(approvedReversalAmount) -
      getNum(writeOffAmount) -
      getNum(domesticAccountAmount) -
      getNum(wireRefundAmount) -
      openClaimsAmount
    setApprovedPendingReversalAmount(parseFloat(pendingAmount.toFixed(2)))
  }, [
    approvedReversalAmount,
    writeOffAmount,
    domesticAccountAmount,
    wireRefundAmount,
    associatedOpenClaims,
  ])

  function saveActivty() {
    formik.validateForm().then(() => {
      if (approvedReversalAmount > Number(caseData?.originalDocumentAmount)) {
        formik.setErrors({
          approvedReversalAmount:
            'Approved Reversal Amount cannot be greater than the Original Document Amount',
        })
      } else if (
        associatedOpenClaims?.filter(
          (claim) =>
            claim.claimDeductionAmount !== '' && claim.claimId?.length !== 11
        )?.length !== 0
      ) {
        formik.setErrors({
          openClaimAmountDetails: 'Please enter Claim Id(s)',
        })
      } else {
        formik?.setFieldValue(
          'claimDetails.importClaimReversal.approvedReversalAmount',
          Number(approvedReversalAmount)
        )
        formik?.setFieldValue(
          'claimDetails.importClaimReversal.writeOffAmount',
          Number(writeOffAmount)
        )
        formik?.setFieldValue(
          'claimDetails.importClaimReversal.openClaimAmountDetails',
          associatedOpenClaims
            ?.filter((claim) => claim.claimDeductionAmount !== '')
            ?.map((claim) => {
              return {
                claimId: claim.claimId,
                claimDeductionAmount:
                  claim.claimDeductionAmount !== ''
                    ? Number(claim.claimDeductionAmount)
                    : '',
              }
            })
        )
        formik?.setFieldValue(
          'claimDetails.importClaimReversal.domesticAccount',
          {
            domesticVendorId:
              domesticVendorId != null ? Number(domesticVendorId) : null,
            domesticAccountAmount:
              domesticAccountAmount != null
                ? Number(domesticAccountAmount)
                : null,
          }
        )
        formik?.setFieldValue(
          'claimDetails.importClaimReversal.wireRefundAmount',
          Number(wireRefundAmount)
        )
        formik?.setFieldValue(
          'claimDetails.importClaimReversal.approvedPendingReversalAmount',
          Number(approvedPendingReversalAmount)
        )
        const activityDescription = 'Import Claim Details are updated'
        const adAuditEntry = activityData(
          userName,
          activityDescription,
          formik?.values
        )
        formik?.setFieldValue('auditEntries', adAuditEntry)
        formik.setErrors({
          approvedReversalAmount: undefined,
        })
        saveStatus()
        clearValues()
      }
    })
  }

  const isImportDetailsFieldDisabled = () => {
    return (
      caseData?.status === CaseStatus.RESOLVED ||
      caseData?.status === CaseStatus.CLOSED
    )
  }

  const handleAddOpenClaim = () => {
    setAssociatedOpenClaims([
      ...associatedOpenClaims,
      {
        id: _.uniqueId(),
        claimId: '',
        claimDeductionAmount: '',
      },
    ])
  }
  const handleRemoveOpenClaim = (id: any) => {
    const values = [...associatedOpenClaims]
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    )
    setAssociatedOpenClaims(values)
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.refundDetailsUpdateFlag}
        onRefuse={() => {
          clearValues()
        }}
      >
        <div className="hc-pa-normal hc-pa-lg">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={12} className="hc-pb-none">
                  <Form.Field
                    id="claimDetails.approvedReversalAmount"
                    type="number"
                    onChange={(e: any) => {
                      setApprovedReversalAmount(
                        twoDecimals(e.target.value).toString()
                      )
                    }}
                    error={formik.errors?.approvedReversalAmount}
                    errorText={formik.errors?.approvedReversalAmount}
                    value={approvedReversalAmount ?? ''}
                    className="inputtype hc-ta-left"
                    disabled={isImportDetailsFieldDisabled()}
                    label="Approved Reversal Amount"
                  />
                </Grid.Item>
                <Grid.Item xs={12} className="hc-pb-none">
                  <Form.Field
                    id="writeOffAmount"
                    type="number"
                    onChange={(e: any) => {
                      setWriteOffAmount(twoDecimals(e.target.value).toString())
                    }}
                    value={writeOffAmount ?? ''}
                    className="inputtype hc-ta-left"
                    disabled={isImportDetailsFieldDisabled()}
                    label="Write-Off Amount"
                  />
                </Grid.Item>
                <Divider className="hc-ph-sm" />
                <Grid.Item xs={12} className="hc-pb-none">
                  <Input.Label className="hc-pb-none">
                    <strong>Amounts Applied to Open Claims:</strong>
                  </Input.Label>
                  <Grid.Container xs={12} className="hc-pl-none">
                    <Grid.Item xs={5}>
                      <Input.Label>Claim #</Input.Label>
                    </Grid.Item>
                    <Grid.Item xs={4}>
                      <Input.Label>Claim Amount $</Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                  {associatedOpenClaims?.map((openClaim: any, index: any) => (
                    <Grid.Container xs={12} className="hc-pl-none" key={index}>
                      <Grid.Item xs={5} className="hc-pb-none">
                        <Form.Field
                          id={`openClaim-${index}-id`}
                          name="claimId"
                          type="number"
                          onChange={(e: any) => {
                            const newInputFields = associatedOpenClaims.map(
                              (claim: any) => {
                                if (claim.id === openClaim.id) {
                                  claim.claimId = e.target.value
                                }
                                return claim
                              }
                            )
                            setAssociatedOpenClaims(newInputFields)
                          }}
                          value={openClaim.claimId ?? ''}
                          className="inputtype hc-ta-left"
                          error={
                            (openClaim?.claimId?.length > 0 &&
                              openClaim?.claimId.length !== 11) ||
                            (openClaim?.claimId === '' &&
                              openClaim.claimDeductionAmount !== '')
                          }
                          errorText="Document # should be 11 digits for Import Claims"
                          disabled={isImportDetailsFieldDisabled()}
                        />
                      </Grid.Item>
                      <Grid.Item xs={4} className="hc-pb-md">
                        <Form.Field
                          id={`openClaim-${index}-amount`}
                          name="claimDeductionAmount"
                          type="number"
                          onChange={(e: any) => {
                            const newInputFields = associatedOpenClaims.map(
                              (claim: any) => {
                                if (claim.id === openClaim.id) {
                                  claim.claimDeductionAmount = twoDecimals(
                                    e.target.value
                                  ).toString()
                                }
                                return claim
                              }
                            )
                            setAssociatedOpenClaims(newInputFields)
                          }}
                          value={openClaim.claimDeductionAmount ?? ''}
                          className="inputtype hc-ta-left"
                          disabled={isImportDetailsFieldDisabled()}
                        />
                      </Grid.Item>
                      <Grid.Item xs>
                        <Grid.Container>
                          <Grid.Item>
                            <Button
                              iconOnly
                              aria-label="Remove Open Claim"
                              size="dense"
                              onClick={async () => {
                                handleRemoveOpenClaim(openClaim.id)
                              }}
                              disabled={
                                associatedOpenClaims.length === 1 ||
                                isImportDetailsFieldDisabled()
                              }
                            >
                              <EnterpriseIcon icon={MinusIcon} color="black" />
                            </Button>
                          </Grid.Item>
                          <Grid.Item className="hc-pl-none">
                            <Button
                              iconOnly
                              aria-label="Add Open Claim"
                              size="dense"
                              onClick={async () => handleAddOpenClaim()}
                              disabled={isImportDetailsFieldDisabled()}
                            >
                              <EnterpriseIcon icon={PlusIcon} color="black" />
                            </Button>
                          </Grid.Item>
                        </Grid.Container>
                      </Grid.Item>
                    </Grid.Container>
                  ))}
                </Grid.Item>
                <Divider className="hc-ph-sm" />
                <Grid.Container className="hc-ph-sm hc-pt-sm">
                  <Grid.Item xs={12}>
                    <Input.Label size={8}>
                      <strong>Domestic Account Details:</strong>
                    </Input.Label>
                  </Grid.Item>
                  <Grid.Item xs={6} className="hc-pv-none">
                    <Form.Field
                      id="domesticVendorId"
                      type="number"
                      onChange={(e: any) => {
                        setDomesticVendorId(e.target.value)
                      }}
                      value={domesticVendorId ?? ''}
                      className="inputtype hc-ta-left"
                      disabled={isImportDetailsFieldDisabled()}
                      label="Domestic Vendor #"
                    />
                  </Grid.Item>
                  <Grid.Item xs={6} className="hc-pv-none">
                    <Form.Field
                      id="domesticAccountAmount"
                      type="number"
                      onChange={(e: any) => {
                        setDomesticAccountAmount(
                          twoDecimals(e.target.value).toString()
                        )
                      }}
                      value={domesticAccountAmount ?? ''}
                      className="inputtype hc-ta-left"
                      disabled={isImportDetailsFieldDisabled()}
                      label="Amount applied to Domestic Account"
                    />
                  </Grid.Item>
                </Grid.Container>
                <Divider className="hc-ph-sm" />
                <Grid.Item xs={12} className="hc-pb-none hc-pt-lg">
                  <Form.Field
                    id="wireRefundAmount"
                    type="number"
                    onChange={(e: any) => {
                      setWireRefundAmount(
                        twoDecimals(e.target.value).toString()
                      )
                    }}
                    value={wireRefundAmount ?? ''}
                    className="inputtype hc-ta-left"
                    disabled={isImportDetailsFieldDisabled()}
                    label="Amount refunded via Wire"
                  />
                </Grid.Item>
                <Grid.Item xs>
                  <Form.Field
                    id="approvedPendingReversalAmount"
                    type="number"
                    value={approvedPendingReversalAmount ?? ''}
                    className="inputtype hc-ta-left"
                    disabled={true}
                    label="Approved Pending Reversal Amount"
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item xs></Grid.Item>
                <Grid.Item justify="flex-end">
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          saveActivty()
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={() => {
                          clearValues()
                          dispatch({
                            type: SET_FIELD,
                            payload: [
                              { id: 'refundDetailsUpdateFlag', value: false },
                            ],
                          })
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}
export default UpdateRefundDetailsModal
