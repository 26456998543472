import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReportBaseLayout } from './ReportBaseLayout'
import { useReport } from './ReportContext/ReportProvider'
import { UPDATE_FILTER } from '../Cases/Search/SearchContext/ActionTypes'
import * as qs from 'qs'
import { useLazyQuery } from '@apollo/client'
import {
  AggregationVariables,
  GetAggregation,
  GET_AGGREGATION,
} from '../Dashboard/Queries'
import {
  FISCAL_MONTH_YEAR,
  RESOLUTION_FISCAL_MONTH_YEAR,
} from './ReportConstants'
import { convertToCurrency, sortByMonthYear } from './ReportsUtils'
import { ColDef } from 'ag-grid-community'
import flatten from 'lodash/fp/flatten'
import uniqBy from 'lodash/fp/uniqBy'
import flow from 'lodash/fp/flow'
import sortBy from 'lodash/fp/sortBy'
import map from 'lodash/fp/map'
import {
  addEscapeChar,
  buildReportQuery,
  buildReportUrl,
} from './ReportContext/ReportUtils'
import {
  Divider,
  ExpandableSection,
  Input,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { aggregatedReports } from './ReportContext/AggregatedReportInitalValues'
import { Report } from './ReportContext/ReportContext'

interface ReportData {
  gridRows: any[]
  gridColumns: ColDef[]
  chartData?: any[]
  uniqueSubTypes: string[]
}

interface ReportSwitchProps {
  id: string
  reportTypes: Report[]
  consolidateType?: string
}

const ReportSwitch: React.FC<ReportSwitchProps> = ({
  id,
  reportTypes,
  consolidateType,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const reportTypeValues = useMemo(
    () =>
      reportTypes.map((report) => {
        return { value: report.id, label: report.reportName }
      }),
    [reportTypes]
  )

  const consolidateTypes = [
    { value: 'COUNT', label: 'Count' },
    { value: 'AMOUNT', label: 'Amount' },
  ]

  return (
    <>
      <ExpandableSection padding="dense" startExpanded toggleLocation="left">
        <Heading className="hc-clr-black" size={6}>
          Switch Reports
        </Heading>
        <ExpandableSection.Content className="hc-ml-sm hc-mr-sm">
          <div className="hc-mb-dense">
            <Input.Label> Select Report By</Input.Label>
            <Input.Select
              value={id}
              id={'reportType'}
              defaultValue={id}
              options={reportTypeValues}
              onUpdate={(id: any, value: any) => {
                navigate(
                  '/reports/aggregated_reports/' + value + location.search
                )
              }}
            />
          </div>
          <Divider />
          <Input.Radio
            id="Aging Interval"
            value={consolidateType}
            options={consolidateTypes}
            onUpdate={(id: any, value: any) => {
              navigate(
                `${location.pathname}?` +
                  buildReportUrl(location, {
                    filter: 'consolidateType',
                    event: value,
                    type: 'consolidateType',
                  })
              )
            }}
          />
        </ExpandableSection.Content>
      </ExpandableSection>
      <Divider />
    </>
  )
}

export const AggregatedReport = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [state, dispatch] = useReport()
  const [reportData, setReportData] = useState<ReportData | undefined>()
  const [loadAggregation, { loading }] = useLazyQuery<
    GetAggregation,
    AggregationVariables
  >(GET_AGGREGATION, {
    fetchPolicy: 'network-only',
    onCompleted: (aggregationDetails) => {
      const aggregation = [...(aggregationDetails.getAggregation ?? [])]

      const uniqueRows: string[] = flow(
        map((aggs: any) => aggs.subType),
        flatten,
        uniqBy('rootType'),
        map((aggs: any) => aggs?.rootType),
        sortBy((row) => row)
      )(aggregation)

      const uniqueColumns = aggregation
        .sort(sortByMonthYear)
        .map((column) => column.rootType)

      const gridData = uniqueRows.map((row) => {
        let gridRow: any = {
          aggregation: row,
        }
        uniqueColumns.forEach((column) => {
          const value =
            aggregation
              .find((aggs) => aggs.rootType === column)
              ?.subType?.find((aggs) => aggs.rootType === row)?.value ?? 0
          gridRow[column as string] = value
        })

        return gridRow
      })

      const chartData = aggregation.sort(sortByMonthYear).map((value) => {
        let subCategory: any = {}
        value.subType?.forEach((type) => {
          subCategory[type['rootType'] as string] =
            state.report?.consolidateType === 'COUNT'
              ? type['value']
              : type['value']?.toFixed(2)
        })
        subCategory.x_axis_data = value.rootType
        subCategory.total = value.value
        return subCategory
      })

      // set grid data
      setReportData({
        gridRows: gridData,
        gridColumns: [
          {
            field: 'aggregation',
            headerName: state.id.includes('resolution')
              ? 'Aggregation/ Resolved Month, Year'
              : 'Aggregation/ Filed Month, Year',
            onCellClicked: (props) => {
              navigate(
                `/cases?&search%5B0%5D=${buildDrillDownUrl(props.value)}`
              )
            },
            sortable: true,
          },
          ...uniqueColumns.map((colum) => {
            let col: ColDef = {
              field: colum,
              onCellClicked: (props) => {
                navigate(
                  `/cases?&search%5B0%5D=${buildDrillDownUrl(
                    props.data.aggregation,
                    props.colDef.field
                  )}`
                )
              },
              valueFormatter:
                state.report?.consolidateType === 'AMOUNT'
                  ? (params) => {
                      return `${convertToCurrency(params.value)}`
                    }
                  : undefined,
            }

            return col
          }),
        ],
        chartData: chartData,
        uniqueSubTypes: uniqueRows,
      })
    },
  })

  useEffect(() => {
    dispatch({
      type: UPDATE_FILTER,
      payload: qs.parse(location.search.replace('?', '')),
    })
  }, [dispatch, location.search])

  useEffect(() => {
    handleQueryChange(buildReportQuery(state) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleQueryChange = (query: string) => {
    loadAggregation({
      variables: {
        aggregateBy: [
          `${
            state.report?.id !== 'resolution_code_text.keyword'
              ? FISCAL_MONTH_YEAR
              : RESOLUTION_FISCAL_MONTH_YEAR
          },${state.report?.id}`,
        ],
        consolidateType: state.report?.consolidateType,
        query: query,
        numberOfPartitions: state.partitions,
      },
    })
  }

  const buildDrillDownUrl = (
    row: string,
    column?: string,
    isGraph: boolean = false
  ): string => {
    const query: any = qs.parse(location.search.replace('?', ''))
    const dateFilter = !isGraph
      ? `(${state.report?.dateFilter.id}: [${state.report?.dateFilter.active?.from} TO ${state.report?.dateFilter.active?.to}])`
      : ''

    const sideFilters = Object.keys(query)
      .filter((key) => key !== 'consolidateType')
      .map((key) => {
        if (query[key] instanceof Array) {
          const filters = query[key]
            .map((filter: string) => `(${addEscapeChar(filter)})`)
            .join(' OR ')
          return `(${key}: ${filters})`
        } else {
          return ''
        }
      })
      .filter((value) => value !== '')
      .join(' AND ')

    const idFilter = `(${state.report?.id}: (${addEscapeChar(row)}))`
    const fisicalCalander =
      column !== undefined ? `(${FISCAL_MONTH_YEAR}: (${column}))` : ''
    var arr = [sideFilters, dateFilter, idFilter, fisicalCalander]
      .filter((value: string) => !(value === '') && !value.includes('()'))
      .join(' AND ')

    return arr
  }

  if (state.report == null) {
    return <div>Not Found</div>
  }

  return (
    <ReportBaseLayout
      reportName={state.report.reportName}
      sideFilters={state.report.sideFilters}
      data={reportData?.gridRows ?? []}
      columns={reportData?.gridColumns ?? []}
      chartData={reportData?.chartData ?? []}
      uniqueSubTypes={reportData?.uniqueSubTypes ?? []}
      reportType={'aggregation'}
      dynamicFilter={state.report.dynamicFilter}
      handleQueryChange={handleQueryChange}
      loading={loading}
      dateFilter={state.report.dateFilter}
      customSideFilters={
        <ReportSwitch
          id={state.id}
          reportTypes={aggregatedReports}
          consolidateType={state.report.consolidateType}
        />
      }
      buildDrillDownUrl={buildDrillDownUrl}
    />
  )
}
