import React from 'react'
import { CaseResponse } from './Queries'
import { EDIT_RECEIPT_HEADING } from './Constants'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Section, SectionItem } from './Section'
import { BottomBorderDiv } from '../Common/SynergyDivComponent'

interface MainProp {
  receiptDetails: CaseResponse | undefined
}

const EditRequestReceiptDetails: React.FC<MainProp> = ({ receiptDetails }) => {
  const recieptData = receiptDetails?.requestDetails
  const isDeliveryReceipt =
    receiptDetails?.caseSubType === 'Delivery Receipt' ? true : false
  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={4}
        detail
        heading={EDIT_RECEIPT_HEADING}
        expandable
      >
        <SectionItem
          label={
            isDeliveryReceipt ? 'Receipt ID / Appointment Number' : 'Receipt ID'
          }
        >
          {receiptDetails?.receipts?.join(',')}
        </SectionItem>
        <SectionItem label="Receipt / Shipment Date">
          <DateFormatter
            //@ts-ignore
            date={recieptData?.shipmentDate}
            format="L"
          />
        </SectionItem>
        {!isDeliveryReceipt && (
          <React.Fragment>
            <SectionItem label="Carrier Name">
              {recieptData?.carrierVendorName}
            </SectionItem>
            <SectionItem label="Email">
              {recieptData?.carrierVendorContactEmail}
            </SectionItem>
            <SectionItem label="Fax #">{recieptData?.faxNumber}</SectionItem>
            <SectionItem label="Proof Of Delivery">
              {recieptData?.proofOfDelivery}
            </SectionItem>
            <SectionItem label="Proof Of Shipment">
              {recieptData?.proofOfShipment}
            </SectionItem>
          </React.Fragment>
        )}
      </Section>
    </BottomBorderDiv>
  )
}

export default EditRequestReceiptDetails
