import React, { useState } from 'react'
import { Grid, Modal, Button } from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { useAuth } from '@praxis/component-auth'
import {
  CaseResponse,
  GET_REOPENCODES,
  GetReopenCodes,
  ReopenCodes,
} from './Queries'
import { CaseStatus, CaseType } from '../Services/ConstantData'
import { activityData } from '../Services/saveActivity'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import { useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

interface MainProp {
  headingText: String
  formik: FormikValues
  reopenDetails: CaseResponse | undefined
  saveStatus: any
}

export interface FormFieldType {
  id: number | string
  value: string
  label: string
}

const ReopenModal: React.FC<MainProp> = ({
  headingText,
  formik,
  reopenDetails,
  saveStatus,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const activityDescription = 'Case was reopened'
  const [state, dispatch] = useGlobalForm()
  const [reopenReason, setReopenReason] = useState<FormFieldType>({
    id: '',
    value: '',
    label: '',
  })

  const { data: reopenCodesData } = useQuery<GetReopenCodes>(
    GET_REOPENCODES,
    {}
  )

  function setReopenValues() {
    if (
      reopenDetails?.resolutionAmount ||
      reopenDetails?.resolutionText ||
      reopenDetails?.resolutionCode ||
      reopenDetails?.resolutionDate ||
      reopenDetails?.claimDetails?.referenceDocumentNumber ||
      reopenDetails?.disputeDetails?.newDocumentNumber
    ) {
      formik.setFieldValue('reopenDetails.reopenDate', new Date().toISOString())
      formik.setFieldValue('reopenDetails.reopenUser', session?.userInfo?.lanId)
      formik.setFieldValue(
        'reopenDetails.previousResolutionDate',
        reopenDetails?.resolutionDate
      )
      formik.setFieldValue(
        'reopenDetails.previousResolutionUser',
        reopenDetails?.userProfile?.userId
      )
      formik.setFieldValue(
        'reopenDetails.previousResolutionCode',
        reopenDetails?.resolutionCode
      )
      formik.setFieldValue(
        'reopenDetails.previousResolutionCodeText',
        reopenDetails?.resolutionCodeText
      )
      formik.setFieldValue(
        'reopenDetails.previousResolutionText',
        reopenDetails?.resolutionText === null
          ? undefined
          : reopenDetails.resolutionText
      )
      formik.setFieldValue(
        'reopenDetails.previousResolutionAmount',
        reopenDetails?.resolutionAmount
      )
      if (reopenDetails?.caseType === CaseType.INTERNALPROCESSING) {
        formik.setFieldValue(
          'reopenDetails.previousResolutionDocumentNumber',
          reopenDetails?.claimDetails?.referenceDocumentNumber
        )
      } else {
        formik.setFieldValue(
          'reopenDetails.previousResolutionDocumentNumber',
          reopenDetails?.disputeDetails?.newDocumentNumber
        )
      }
      formik.setFieldValue('resolutionDate', null)
      formik.setFieldValue('resolutionCode', undefined)
      formik.setFieldValue('resolutionCodeText', null)
      formik.setFieldValue('resolutionText', undefined)
      formik.setFieldValue('resolutionAmount', null)
      formik.setFieldValue('claimDetails.referenceDocumentNumber', null)
      formik.setFieldValue('disputeDetails.newDocumentNumber', null)
      formik.setFieldValue('claimDetails.referenceDocumentCreateDate', null)
      formik.setFieldValue('lanId', null)
      formik.setFieldValue('status', CaseStatus.REOPEN)
    } else {
      formik.setFieldValue('status', CaseStatus.REOPEN)
      formik.setFieldValue('reopenDetails.reopenDate', new Date().toISOString())
      formik.setFieldValue('reopenDetails.reopenUser', session?.userInfo?.lanId)
    }
    saveActivty()
  }

  function saveActivty() {
    formik.setFieldValue('isReopen', true)
    formik.setFieldValue('reopenDetails.reopenReason', reopenReason?.value)
    formik.setFieldValue('reopenDetails.reopenReasonId', reopenReason?.id)
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    clearValues()
    saveStatus()
  }

  function getReopenCodeList(
    reopenCodesList: ReopenCodes[] | undefined
  ): FormFieldType[] {
    if (!reopenCodesList) return []
    return uniqBy(reopenCodesList, 'reopenCodeText')
      .filter((value) => value.active !== false)
      .map(({ reopenCodeId, reopenCodeText, reopenCodeDescription }) => ({
        id: reopenCodeId ?? '',
        value: reopenCodeText ?? '',
        label: reopenCodeText ?? '',
      }))
  }

  const clearValues = () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'reOpenModalFlag', value: false }],
    })
    setReopenReason({ id: '', value: '', label: '' })
  }
  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.reOpenModalFlag}
        onRefuse={() => clearValues()}
        className="reopenModal"
      >
        <div className="hc-pa-normal">
          <Grid.Container className="hc-pa-md">
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={8}>
                  <Autocomplete
                    id="reopenCodeList"
                    label={'Select the reason for reopening this case?'}
                    onUpdate={(id, value) => {
                      setReopenReason(value)
                    }}
                    options={
                      getReopenCodeList(reopenCodesData?.getReopenCodes) ?? []
                    }
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item xs></Grid.Item>
                <Grid.Item justify="flex-end">
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          setReopenValues()
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={() => {
                          clearValues()
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}

export default ReopenModal
