import _ from 'lodash'
import { defaultValues } from './ConstantData'

function deriveResoutionCodes(resolutionMappingValues: Array<any>): Array<any> {
  const values = resolutionMappingValues?.map((resolutionCodes: any) => ({
    id: resolutionCodes.resolutionCodeId,
    value: resolutionCodes.resolutionCodeId,
    label: resolutionCodes.resolutionCodeText,
  }))
  return values
}

export function findResolutionCodeValues(
  resolutionMappingValues: Array<any> | undefined,
  formikValues: any
): any {
  let resolutionMappingValuesList = _.find(
    resolutionMappingValues,
    function (resolutionCodes) {
      return (
        resolutionCodes.caseType === formikValues.caseType &&
        resolutionCodes.caseSubType === formikValues.caseSubType &&
        Number(resolutionCodes.documentTypeId) ===
          Number(formikValues.documentTypeId) &&
        Number(resolutionCodes.documentSubTypeId) ===
          Number(formikValues.documentSubTypeId)
      )
    }
  )
  if (resolutionMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionCodes(
      resolutionMappingValuesList?.resolutionCodeList
    )
    return mappingValues
  }
  resolutionMappingValuesList = _.find(
    resolutionMappingValues,
    function (resolutionCodes) {
      return (
        resolutionCodes.caseType === formikValues.caseType &&
        resolutionCodes.caseSubType === formikValues.caseSubType &&
        Number(resolutionCodes.documentTypeId) ===
          Number(formikValues.documentTypeId) &&
        resolutionCodes.documentSubTypeId === null
      )
    }
  )
  if (resolutionMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionCodes(
      resolutionMappingValuesList?.resolutionCodeList
    )
    return mappingValues
  }
  resolutionMappingValuesList = _.find(
    resolutionMappingValues,
    function (resolutionCodes) {
      return (
        resolutionCodes.caseType === formikValues.caseType &&
        resolutionCodes.caseSubType === formikValues.caseSubType &&
        resolutionCodes.documentTypeId === null &&
        resolutionCodes.documentSubTypeId === null
      )
    }
  )
  if (resolutionMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionCodes(
      resolutionMappingValuesList?.resolutionCodeList
    )
    return mappingValues
  } else return defaultValues
}
