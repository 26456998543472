import { useState, useEffect } from 'react'
import {
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Input,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import {
  GET_RESOLUTIONCODES,
  ResolutionCodes,
} from '../../Cases/Common/Queries'
import {
  UpdateUpdateResolutionCodeVar,
  UPDATE_RESOLUTIONCODE,
} from '../common/AdminQueries'
import { useMutation } from '@apollo/client'

const resolutionCodeMap = {
  active: false,
  resolutionCodeText: '',
}

export const EditResolutionCodeForm = (props: any) => {
  const [columns, setColumns] = useState<any>(resolutionCodeMap)

  useEffect(() => {
    setColumns({
      active: props.editableRow?.active,
    })
  }, [props.editableRow, props.formVisible])

  const [updateResolutionCode, { loading }] = useMutation<
    ResolutionCodes,
    UpdateUpdateResolutionCodeVar
  >(UPDATE_RESOLUTIONCODE)

  const handleSave = async (row: ResolutionCodes, columns: any) => {
    await updateResolutionCode({
      variables: {
        id: row.resolutionCodeId!,
        input: {
          resolutionCodeText: row.resolutionCodeText!,
          active: columns.active,
        },
      },
      refetchQueries: [
        {
          query: GET_RESOLUTIONCODES,
        },
      ],
      awaitRefetchQueries: true,
    }).then(() => props.handleClose())
  }

  return (
    <Modal
      headingText="Edit Resolution Code"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible={props.formVisible}
    >
      <Grid.Container justify={'center'} className="hc-pa-normal">
        <Grid.Item xs={11}>
          <Grid.Container>
            <Grid.Item xs={6}>
              <Input.Label>Resolution Code</Input.Label>
              <Form.Field
                id="resolutionCode"
                value={props?.editableRow?.resolutionCodeId ?? ''}
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={6}>
              <Input.Label>Resolution Code Text</Input.Label>
              <Form.Field
                id="resolutionCodeText"
                value={props?.editableRow?.resolutionCodeText ?? ''}
                disabled
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <GridItem xs={11}>
          <GridContainer align="center">
            <GridItem xs={3}>
              <Form.Field
                id={'resolution_status'}
                type="toggle"
                label={'Active'}
                value={columns.active ?? false}
                onChange={(e: any) => {
                  setColumns({
                    ...columns,
                    active: !columns.active,
                  })
                }}
              ></Form.Field>
            </GridItem>
            <GridItem justify="flex-end" xs={9}>
              <GridContainer direction="row-reverse" spacing="dense">
                <GridItem>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSave(props.editableRow, columns)
                    }}
                    // disabled={loading}
                  >
                    Save
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    onClick={() => {
                      props.handleClose()
                    }}
                  >
                    Cancel
                  </Button>
                </GridItem>

                {loading && (
                  <GridItem>
                    <Spinner></Spinner>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </Grid.Container>
    </Modal>
  )
}
