import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReportBaseLayout } from './ReportBaseLayout'
import { useReport } from './ReportContext/ReportProvider'
import { UPDATE_FILTER } from '../Cases/Search/SearchContext/ActionTypes'
import * as qs from 'qs'
import { useLazyQuery } from '@apollo/client'
import {
  CompareAggregationVariables,
  GetCompareAggregation,
  GET_COMPARE_AGGREGATION,
} from '../Dashboard/Queries'
import { FISCAL_MONTH_YEAR } from './ReportConstants'
import { convertToCurrency, sortByMonthYear } from './ReportsUtils'
import { ColDef } from 'ag-grid-community'
import flatten from 'lodash/fp/flatten'
import uniqBy from 'lodash/fp/uniqBy'
import flow from 'lodash/fp/flow'
import sortBy from 'lodash/fp/sortBy'
import map from 'lodash/fp/map'
import { addEscapeChar, buildReportQuery } from './ReportContext/ReportUtils'
import _ from 'lodash'

interface ReportData {
  gridRows: any[]
  gridColumns: ColDef[]
  chartData?: any[]
  uniqueSubTypes: string[]
}

export const CompareReport = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [state, dispatch] = useReport()
  const [reportData, setReportData] = useState<ReportData | undefined>()
  const [loadAggregation, { loading }] = useLazyQuery<
    GetCompareAggregation,
    CompareAggregationVariables
  >(GET_COMPARE_AGGREGATION, {
    fetchPolicy: 'network-only',
    onCompleted: (aggregationDetails) => {
      const aggregation = [...(aggregationDetails.getCompareAggregation ?? [])]
      const uniqueRows: string[] = flow(
        map((aggs: any) => aggs.subType),
        flatten,
        uniqBy('rootType'),
        map((aggs: any) => aggs?.rootType),
        sortBy((row) => row)
      )(aggregation)

      const chartData = aggregation.sort(sortByMonthYear).map((value) => {
        let subCategory: any = {}
        value.subType?.forEach((type) => {
          subCategory[type['rootType'] as string] =
            state.report?.consolidateType === 'COUNT'
              ? type['value']
              : type['value']?.toFixed(2)
        })
        subCategory.x_axis_data = value.rootType
        subCategory.total = value.value
        return subCategory
      })

      const gridData = aggregation.sort(sortByMonthYear).map((value) => {
        let subCategory: any = {}
        value.subType?.forEach((type) => {
          subCategory[type['rootType'] as string] =
            state.report?.consolidateType === 'COUNT'
              ? type['value']
              : type['value']?.toFixed(2)
        })
        subCategory.filed_month_year = value.rootType
        subCategory.total = value.value
        return subCategory
      })

      // set grid data
      setReportData({
        gridRows: gridData,
        gridColumns: [
          {
            field: 'filed_month_year',
            headerName: 'Filed Month, Year/Comparison',
            onCellClicked: (props) => {
              navigate(
                `/cases?&search%5B0%5D=${buildDrillDownUrl('', props.value)}`
              )
            },
          },
          ...uniqueRows.map((colum) => {
            let col: ColDef = {
              field: colum,
              headerName: `${_.startCase(colum)}`,
              onCellClicked: (props) => {
                navigate(
                  `/cases?&search%5B0%5D=${buildDrillDownUrl(
                    '',
                    props.data.filed_month_year
                  )}`
                )
              },
              valueFormatter: (params) => {
                return `${convertToCurrency(params.value)}`
              },
            }
            return col
          }),
        ],
        chartData: chartData,
        uniqueSubTypes: uniqueRows,
      })
    },
  })

  useEffect(() => {
    dispatch({
      type: UPDATE_FILTER,
      payload: qs.parse(location.search.replace('?', '')),
    })
  }, [dispatch, location.search])

  useEffect(() => {
    handleQueryChange(buildReportQuery(state) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleQueryChange = (query: string) => {
    loadAggregation({
      variables: {
        query: query,
        aggregateBy: FISCAL_MONTH_YEAR,
        compareFields: ['resolution_amount', 'disputed_document_amount'],
      },
    })
  }

  const buildDrillDownUrl = (
    _: string,
    row: string,
    isGraph: boolean = false
  ): string => {
    const query: any = qs.parse(location.search.replace('?', ''))
    const dateFilter = !isGraph
      ? `(${state.report?.dateFilter.id}: [${state.report?.dateFilter.active?.from} TO ${state.report?.dateFilter.active?.to}])`
      : ''

    const sideFilters = Object.keys(query)
      .filter((key) => key !== 'consolidateType')
      .map((key) => {
        if (query[key] instanceof Array) {
          const filters = query[key]
            .map((filter: string) => `(${addEscapeChar(filter)})`)
            .join(' OR ')
          return `(${key}: ${filters})`
        } else {
          return ''
        }
      })
      .filter((value) => value !== '')
      .join(' AND ')

    const fisicalCalander = `(${FISCAL_MONTH_YEAR}: (${row}))`
    var arr = [sideFilters, dateFilter, fisicalCalander]
      .filter((value: string) => !(value === '') && !value.includes('()'))
      .join(' AND ')

    return arr
  }

  if (state.report == null) {
    return <div>Not Found</div>
  }

  return (
    <ReportBaseLayout
      reportName={state.report.reportName}
      sideFilters={state.report.sideFilters}
      data={reportData?.gridRows ?? []}
      columns={reportData?.gridColumns ?? []}
      chartData={reportData?.chartData ?? []}
      uniqueSubTypes={reportData?.uniqueSubTypes ?? []}
      reportType={'Comparison'}
      dynamicFilter={state.report.dynamicFilter}
      handleQueryChange={handleQueryChange}
      loading={loading}
      dateFilter={state.report.dateFilter}
      buildDrillDownUrl={buildDrillDownUrl}
    />
  )
}
