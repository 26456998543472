import React from 'react'
import {
  Drawer,
  Grid,
  Form,
  Button,
  Card,
  Modal,
  Input,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useEffect, useState } from 'react'
import { twoDecimals } from '../../../Services/TwoDecimalPlaces'
import { useQuery } from '@apollo/client'
import {
  CaseResponse,
  GET_RESOLUTIONCODES,
  GET_RESOLUTIONNOTESMAP,
  GetResolutionCodes,
  ResolutionCodes,
  UpdateCaseRequest,
} from '../../../Common/Queries'
import { ResolutionItem } from './ResolutionItem'
import _ from 'lodash'
import { CaseStatus, CaseType } from '../../../Services/ConstantData'
import { convertToUpdateRequests } from '../../../Common/PrepareUpdateRequest'
import { useAuth } from '@praxis/component-auth'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

export const resolveFilter = (
  status: string | undefined,
  assignee: string | undefined
) => {
  const filter =
    (status === CaseStatus.INPROGRESS && assignee != null) ||
    (status === CaseStatus.NEW && assignee != null) ||
    (status === CaseStatus.REOPEN && assignee != null)
  return filter
}

export interface ResolveCaseProps {
  validSelectedCases: any
  onSave: any
  loading: any
  setVisible: any
  visible: any
}

export interface GlobalResolveStateProps {
  resolutionAmount: number | null
  isResAmountEqDispAmount: boolean
  documentNumber: string | null
  isDocNumEqPrevDocNum: boolean
  resCode: string | undefined
  resCodeText: string | undefined
  settlementNote: string | undefined
  finalSettlementNote: string | undefined
}

const InitialGlobalState = {
  resolutionAmount: null,
  isResAmountEqDispAmount: false,
  documentNumber: null,
  isDocNumEqPrevDocNum: false,
  resCode: '',
  resCodeText: '',
  settlementNote: '',
  finalSettlementNote: '',
}

export interface FormFieldType {
  id: number | string
  value: string | undefined
  label: string
}

export const getDocNumField = (caseData: any) => {
  return caseData?.caseType === CaseType.INTERNALPROCESSING
    ? 'claimDetails.referenceDocumentNumber'
    : caseData?.caseType === CaseType.DISPUTE
    ? 'disputeDetails.newDocumentNumber'
    : null
}

export const getResolveCaseDateField = (caseData: any) => {
  return caseData?.caseType === CaseType.INTERNALPROCESSING
    ? 'claimDetails.referenceDocumentCreateDate'
    : null
}

export const ResolveCase: React.FC<ResolveCaseProps> = ({
  validSelectedCases,
  onSave,
  loading,
  setVisible,
  visible,
}) => {
  const { session } = useAuth()
  const [resolveCases, setResolveCases] = useState<any>([])
  const [actionModalVisible, setActionModalVisible] = useState(false)
  const [docNum, setDocNum] = useState<any>(null)
  const [isDocNumEqPrevDocNum, setDocNumEqPrevDocNum] = useState(false)
  const [resAmt, setResAmt] = useState<any>(null)
  const [isResAmountEqDispAmount, setResAmountEqDispAmount] = useState(false)
  const [resolutionCode, setResolutionCode] = useState<any>({
    id: '',
    value: '',
    label: '',
  })
  const [settlementNote, setSettlementNote] = useState<any>('')
  const [finalSettlementNote, setFinalSettlementNote] = useState<any>('')

  const [globalActions, setGlobalActions] =
    useState<GlobalResolveStateProps>(InitialGlobalState)
  const [bulkResolveError, setBulkResolveError] = useState(false)

  document.getElementsByTagName('html')[0].style.overflow = visible
    ? 'hidden'
    : 'initial'

  useEffect(() => {
    validSelectedCases && setResolveCases(_.cloneDeep(validSelectedCases))
  }, [validSelectedCases])

  const { data: resolutionCodeData } = useQuery<GetResolutionCodes>(
    GET_RESOLUTIONCODES,
    {}
  )
  const [resolutionCodeList, setResolutionCodeList] = useState<any>([])
  useEffect(() => {
    if (resolutionCodeData?.getResolutionCodes) {
      setResolutionCodeList(
        resolutionCodeData?.getResolutionCodes
          ?.filter((code: ResolutionCodes) => code.active)
          .map((code: ResolutionCodes) => {
            return {
              id: code.resolutionCodeId,
              label: code.resolutionCodeText,
              value: code.resolutionCodeText ?? '',
            }
          })
      )
    }
  }, [resolutionCodeData])

  const { data: resolutionnotemapvalues } = useQuery(GET_RESOLUTIONNOTESMAP, {})
  const [resolutionNoteList, setResolutionNoteList] = useState<any>([])
  useEffect(() => {
    if (resolutionnotemapvalues?.getResolutionNotesMap) {
      let masterList = resolutionnotemapvalues?.getResolutionNotesMap
      const finalList = [
        ...new Set(
          ...masterList.map((notesMap: any) => notesMap.resolutionNoteList)
        ),
      ]
      setResolutionNoteList(
        finalList.map((note: any) => {
          return {
            id: note.resolutionNoteId,
            label: note.resolutionNoteText ?? '',
            value: note.resolutionNoteText ?? '',
          }
        })
      )
    }
  }, [resolutionnotemapvalues?.getResolutionNotesMap])

  function validateBulkUpdates() {
    return !resolveCases.some(function (casee: any) {
      return (
        casee?.resolutionCodeText === '' ||
        casee?.resolutionCodeText == null ||
        casee?.resolutionAmount > casee?.disputedDocumentAmount
      )
    })
  }

  function applyGlobalUpdates() {
    let actions = {
      documentNumber: docNum,
      isDocNumEqPrevDocNum: isDocNumEqPrevDocNum,
      resolutionAmount: resAmt,
      isResAmountEqDispAmount: isResAmountEqDispAmount,
      resCode: resolutionCode?.id,
      resCodeText: resolutionCode?.value,
      settlementNote: settlementNote,
      finalSettlementNote: finalSettlementNote,
    }
    setGlobalActions(actions)
    handleUpdateFields(actions)
    setActionModalVisible(false)
  }

  function resetGlobalUpdates() {
    setDocNum(globalActions?.documentNumber)
    setDocNumEqPrevDocNum(globalActions?.isDocNumEqPrevDocNum)
    setResAmt(globalActions?.resolutionAmount)
    setResAmountEqDispAmount(globalActions?.isResAmountEqDispAmount)
    setResolutionCode({
      id: globalActions?.resCode,
      value: globalActions?.resCodeText,
      label: globalActions?.resCodeText,
    })
    setSettlementNote(globalActions?.settlementNote)
    setFinalSettlementNote(globalActions?.finalSettlementNote)
    setActionModalVisible(false)
  }

  function clearGlobalUpdates() {
    setDocNum(null)
    setDocNumEqPrevDocNum(false)
    setResAmt(null)
    setResAmountEqDispAmount(false)
    setResolutionCode({
      id: '',
      value: '',
      label: '',
    })
    setSettlementNote('')
    setFinalSettlementNote('')
    setActionModalVisible(false)
  }

  function clearResolveUpdates() {
    setGlobalActions(InitialGlobalState)
    setResolveCases(_.cloneDeep(validSelectedCases))
    setBulkResolveError(false)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  function getFormattedDocNum(actions: any, caseData: any) {
    let finalDocNum = ''
    if (actions?.isDocNumEqPrevDocNum) {
      finalDocNum = caseData?.originalDocumentNumber
    }
    if (actions.documentNumber != null) {
      finalDocNum = actions?.documentNumber + finalDocNum
    }
    return finalDocNum
  }

  function handleUpdateFields(actions: any) {
    let updatedCases = resolveCases.map((caseData: any) => {
      let obj: any = {}
      if (getDocNumField(caseData) != null) {
        _.set(
          obj,
          getDocNumField(caseData) ?? '',
          getFormattedDocNum(actions, caseData)
        )
      }
      if (caseData?.caseType !== CaseType.REQUESTFORINFO) {
        obj['resolutionAmount'] = actions?.isResAmountEqDispAmount
          ? caseData.disputedDocumentAmount
          : actions.resolutionAmount
      }
      obj['resolutionCode'] = actions?.resCode ?? ''
      obj['resolutionCodeText'] = actions?.resCodeText ?? ''
      obj['resolutionText'] = actions?.finalSettlementNote ?? ''
      return Object.assign({}, caseData, obj)
    })
    setResolveCases([...updatedCases])
  }

  const handleIndividualCase = (caseData: any, field: string, value: any) => {
    let updatedCase = _.set(caseData, field, value)
    setResolveCases(
      resolveCases.map((casee: CaseResponse) =>
        casee?.caseId === caseData?.caseId ? updatedCase : casee
      )
    )
  }

  function handleAuditForResolve(casee: UpdateCaseRequest) {
    const adAuditEntry = casee.auditEntries
      ? casee.auditEntries.map(function (auditEntries: any) {
          return {
            activityDescription: auditEntries.activityDescription,
            userId: auditEntries.userId,
            workflow: auditEntries.workflow,
            status: auditEntries.status,
            assignUserId: auditEntries.assignUserId,
            activityTimestamp: auditEntries.activityTimestamp,
          }
        })
      : []

    adAuditEntry.unshift({
      activityDescription: 'Case is Bulk Resolved',
      userId: session?.userInfo?.fullName,
      workflow: casee?.workflow,
      status: casee?.status,
      assignUserId: casee?.lanId,
      activityTimestamp: new Date().toISOString(),
    })
    return adAuditEntry
  }

  function handleSave() {
    if (validateBulkUpdates()) {
      const updateRequest = convertToUpdateRequests(resolveCases ?? []).map(
        (casee: UpdateCaseRequest) => ({
          ...casee,
          resolutionDate: new Date().toISOString(),
          status: CaseStatus.RESOLVED,
          auditEntries: handleAuditForResolve(casee),
        })
      )
      onSave(updateRequest)
      setBulkResolveError(false)
      clearGlobalUpdates()
      clearResolveUpdates()
      setVisible(false)
    } else {
      setBulkResolveError(true)
    }
  }

  return (
    <>
      <Drawer
        headingText={<span>Resolve Cases</span>}
        spacing="normal"
        location="left"
        isVisible={visible}
        xs={12}
        style={{ scroll: 'none' }}
        onRequestClose={() => {
          clearGlobalUpdates()
          clearResolveUpdates()
          setVisible(false)
        }}
      >
        <Grid.Container>
          <Grid.Item className="hc-ph-lg" xs={12}>
            {/* GLOBAL action bar */}
            <div
              style={{
                height: '82vh',
                overflowY: 'auto',
              }}
            >
              {resolveCases.map((caseData: any) => (
                <ResolutionItem
                  index={caseData?.caseId}
                  caseData={caseData}
                  handleIndividualCase={handleIndividualCase}
                  globalActions={globalActions}
                  resolutionCodeList={resolutionCodeList}
                  resolutionNoteList={resolutionNoteList}
                  bulkResolveError={bulkResolveError}
                />
              ))}
            </div>
            <Card
              className="hc-pa-lg hc-ph-4x hc-bg-grey06"
              style={{ borderTop: '2px solid black' }}
            >
              <Grid.Container direction={'row-reverse'}>
                <Grid.Item>
                  <Button
                    type="destructive"
                    onClick={() => {
                      clearGlobalUpdates()
                      clearResolveUpdates()
                    }}
                  >
                    Clear
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSave()
                    }}
                    disabled={loading}
                  >
                    Resolve All
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    outlined
                    type="secondary"
                    onClick={() => {
                      setActionModalVisible(true)
                    }}
                  >
                    Global Actions
                  </Button>
                </Grid.Item>
                <Grid.Item xs>
                  <Grid.Item>
                    <Input.Label style={{ fontWeight: 'bold' }}>
                      Selected Cases
                    </Input.Label>
                  </Grid.Item>
                  <Grid.Item>{resolveCases?.length ?? '-'}</Grid.Item>
                </Grid.Item>
              </Grid.Container>
            </Card>
          </Grid.Item>
        </Grid.Container>
      </Drawer>
      {/* START: GLOBAL actions-apply filters for all selected cases */}
      <Modal
        headingText="Actions (Applied to all selected cases)"
        isVisible={actionModalVisible}
        onRefuseModal={() => {
          resetGlobalUpdates()
        }}
      >
        <Grid.Container className="hc-ph-lg hc-pv-md">
          <Grid.Item xs={12} className="hc-pv-xs">
            <Grid.Container align="center">
              <Grid.Item xs={6}>
                <Form.Field
                  id="global_documentNumber"
                  label={'Prefix/Document Number (#)'}
                  type="text"
                  onChange={(event: any) => {
                    setDocNum(event.target.value)
                  }}
                  className="inputtype hc-ta-left"
                  value={docNum || ''}
                />
              </Grid.Item>
              <Grid.Item className={'hc-pt-lg'}>
                <Tooltip
                  content="Prefix/Doc # will be applied to all cases"
                  location="top"
                >
                  <EnterpriseIcon icon={InfoIcon} />
                </Tooltip>
              </Grid.Item>
              <Grid.Item xs className={'hc-pt-md'}>
                <Input.Toggle
                  id="docNumEqPrevDocNum"
                  label="Use existing Doc # as the New Doc #"
                  onChange={(event: any) => {
                    setDocNumEqPrevDocNum(event.target.checked)
                  }}
                  value={isDocNumEqPrevDocNum}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item xs={12} className="hc-pv-xs">
            <Grid.Container align="center">
              <Grid.Item xs={6} className="hc-pv-none">
                <Form.Field
                  id="global_resolutionAmount"
                  label={'Resolution/Claim Amount ($)'}
                  type="number"
                  onChange={(event: any) => {
                    setResAmt(twoDecimals(event.target.value))
                    setResAmountEqDispAmount(false)
                  }}
                  className="inputtype hc-ta-left"
                  value={resAmt || ''}
                />
              </Grid.Item>
              <Grid.Item xs className={'hc-pt-none'}>
                <Input.Toggle
                  id="resAmountEqDispAmount"
                  label="Use Disputed Amount as the Resolution Amount"
                  onChange={(event: any) => {
                    setResAmt(null)
                    setResAmountEqDispAmount(event.target.checked)
                  }}
                  value={isResAmountEqDispAmount}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item xs={12} className={'hc-pb-none'}>
            <Autocomplete
              id="global_resnote"
              showSearchIcon
              label="Select a Settlement/Resolution Explanation for all"
              options={resolutionCodeList}
              errorText="Please select an resolution explanation"
              onUpdate={(id, value) => {
                setResolutionCode(value)
              }}
              value={resolutionCode}
            />
          </Grid.Item>
          <Grid.Item xs={12} className={'hc-pv-none hc-pt-xs'}>
            <Autocomplete
              id="global_settlementnote"
              showSearchIcon
              label="Select a Settlement Note for all"
              options={resolutionNoteList ?? []}
              errorText="Please select a note"
              onUpdate={(id, value) => {
                setSettlementNote(value?.label)
                setFinalSettlementNote(value?.label)
              }}
              value={{
                id: settlementNote ?? '',
                value: settlementNote ?? '',
                label: settlementNote ?? '',
              }}
            />
          </Grid.Item>
          <Grid.Item xs={12} className="hc-pv-none">
            <Form.Field
              id="global_finalSettlementNote"
              label="Final Settlement Note for all"
              type="textarea"
              size="dense"
              onChange={(e: any) => {
                setFinalSettlementNote(e.target.value)
              }}
              value={finalSettlementNote}
              maxLength="500"
            />
          </Grid.Item>
          <Grid.Container direction="row-reverse" className="hc-ph-md hc-pv-sm">
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  applyGlobalUpdates()
                }}
              >
                Save
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  resetGlobalUpdates()
                }}
              >
                Cancel
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Container>
      </Modal>
      {/* END: GLOBAL actions-apply filters for all selected cases */}
    </>
  )
}
