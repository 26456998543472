import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  Form,
  Card,
  List,
  GridContainer,
  Chip,
  Divider,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { NoteInput } from './Queries'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useAuth } from '@praxis/component-auth'
import { activityData } from '../Services/saveActivity'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../Common/Constants'
import { CaseStatus, CaseType } from '../Services/ConstantData'

interface MainProp {
  formik: FormikValues
  notes: NoteInput[]
  userProfile: any
  saveComment: any
}

const Comments: React.FC<MainProp> = ({
  formik,
  notes,
  userProfile,
  saveComment,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const [state, dispatch] = useGlobalForm()

  function handleAdd() {
    let currentNotes = notes
      ? notes.map(function (notes) {
          return {
            userId: notes.userId,
            userName: notes.userName,
            commentBody: notes.commentBody,
            isInternal: notes.isInternal,
            commentDate: notes.commentDate,
          }
        })
      : []

    currentNotes.push({
      userId: userProfile?.userId,
      userName: `${userProfile.firstName}${' '}${userProfile.secondName}`,
      commentBody: newComment,
      isInternal: userProfile.isVendor ? false : isInternalChecked,
      commentDate: new Date().toISOString(),
    })

    formik.setFieldValue('notes', currentNotes)
    setIsInternalChecked(true)
    setAddNewCommentFlag(!addNewCommentFlag)
    setNewComment('')
  }

  function saveActivity() {
    const activityDescription = 'Added a Work Note'
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    // saving Activity and comment with one call
    saveComment()
  }

  const [isInternalChecked, setIsInternalChecked] = useState<boolean>(true)
  const [addNewCommentFlag, setAddNewCommentFlag] = useState<boolean>(false)
  const [newComment, setNewComment] = useState<string>('')
  const [isNewCommentEnabled, setIsNewCommentEnabled] = useState<boolean>(false)

  useEffect(() => {
    if (
      (state.caseStatus === CaseStatus.CLOSED ||
        state.caseStatus === CaseStatus.RESOLVED) &&
      userProfile?.isVendor
    ) {
      setIsNewCommentEnabled(true)
    }
  }, [state.caseStatus, userProfile])

  return (
    <div
      className="hc-pa-normal hc-pt-normal hc-pl-dense"
      style={{ width: '100%' }}
    >
      <Grid.Container className="hc-pa-normal">
        {(!notes || notes.length === 0) && (
          <Grid.Item xs={12} className="hc-bg-grey07">
            <Input.Label>No Notes to display!</Input.Label>
          </Grid.Item>
        )}
        {notes && notes.length !== 0 && (
          <Grid.Item xs={12} className="">
            {!userProfile?.isVendor && (
              <React.Fragment>
                <List>
                  {[...notes].reverse().map((note, index) => (
                    <List.Item
                      selected
                      className="hc-mb-dense hc-bg-grey07"
                      key={index}
                    >
                      <Input.Label className="hc-fs-caption hc-mt-dense">
                        {note.userName} added a note -
                        <DateFormatter
                          format="dateTime"
                          timezone="America/Chicago"
                          //@ts-ignore
                          date={note.commentDate?.concat('Z')}
                        />{' '}
                        {!note.isInternal && (
                          <Chip size="dense" color="alert">
                            Viewable by Vendor
                          </Chip>
                        )}
                      </Input.Label>
                      <div className="hc-pa-dense hc-fs-body">
                        {note.commentBody}
                      </div>
                    </List.Item>
                  ))}
                </List>
              </React.Fragment>
            )}

            {userProfile?.isVendor && (
              <React.Fragment>
                <List className="hc-ma-none">
                  {[...notes]
                    .reverse()
                    .filter(function (note) {
                      return note.isInternal === false
                    })
                    .map((note, index) => (
                      <List.Item
                        selected
                        className="hc-mb-dense hc-bg-grey07"
                        key={index}
                      >
                        <Input.Label className="hc-fs-caption">
                          {note.userId === userProfile?.userId
                            ? note.userName
                            : `Target`}{' '}
                          added a comment -
                          <DateFormatter
                            timezone="America/Chicago"
                            format="dateTime"
                            //@ts-ignore
                            date={note.commentDate?.concat('Z')}
                          />
                        </Input.Label>
                        <div className="hc-pa-dense hc-fs-body">
                          {note.commentBody}
                        </div>
                      </List.Item>
                    ))}
                </List>
              </React.Fragment>
            )}
          </Grid.Item>
        )}
        <Grid.Item xs={12}>
          {!addNewCommentFlag && (
            <Button
              type="primary"
              size="dense"
              onClick={() => {
                setAddNewCommentFlag(!addNewCommentFlag)
              }}
              disabled={isNewCommentEnabled}
            >
              Add Note
            </Button>
          )}
          {addNewCommentFlag && (
            <Card className="hc-pa-normal">
              <Grid.Item xs={12}>
                <Form.Field
                  id={'addNewComment'}
                  type="textarea"
                  placeholder="Enter your note here..."
                  maxLength="500"
                  value={newComment}
                  onChange={(event: any) => {
                    setNewComment(event.target.value)
                  }}
                  autoFocus
                />
              </Grid.Item>
              <GridContainer direction="row-reverse">
                <Grid.Item>
                  <Button
                    className="hc-ma-none"
                    type="primary"
                    size="dense"
                    onClick={() => {
                      setAddNewCommentFlag(!addNewCommentFlag)
                      setIsInternalChecked(true)
                      setNewComment('')
                    }}
                  >
                    CANCEL
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    className="hc-ma-none"
                    type="primary"
                    size="dense"
                    disabled={newComment === ''}
                    onClick={() => {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'comments', value: true }],
                      })
                      handleAdd()
                      saveActivity()
                    }}
                  >
                    ADD
                  </Button>
                </Grid.Item>
                {!userProfile?.isVendor &&
                  state.requestType !== CaseType.INTERNALPROCESSING && (
                    <React.Fragment>
                      <Divider
                        direction="vertical"
                        width={2}
                        className="hc-mt-normal hc-mb-normal"
                      />
                      <Grid.Item>
                        <Input.Checkbox
                          className="hc-ma-none"
                          id="isInternal"
                          label="Viewable To Vendor"
                          checked={!isInternalChecked}
                          onChange={() => {
                            setIsInternalChecked(!isInternalChecked)
                          }}
                        />
                      </Grid.Item>
                    </React.Fragment>
                  )}
              </GridContainer>
            </Card>
          )}
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default Comments
