import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import { EnterpriseIcon, LinkIcon } from '@enterprise-ui/icons'

interface Props {}

export const QuickLinks: React.FC<Props> = (props: any) => {
  const navigate = useNavigate()
  const { userProfile } = useProfile()

  return (
    <Dropdown location="bottom-right" size="expanded">
      <Button className="hc-mr-xs" iconOnly aria-label="Quick links">
        <EnterpriseIcon icon={LinkIcon} color="black" />
      </Button>
      <Dropdown.Menu>
        <Dropdown.MenuItem
          onClick={() => {
            let assignedToMe = encodeURI(
              `lan_id[0]=${userProfile?.userId.toUpperCase()}&` +
                'status[0]=Resolved&' +
                'status[1]=Closed&' +
                'resolution_date[from]=now-7d&' +
                'resolution_date[to]=now'
            )
            navigate(`?${assignedToMe}`)
          }}
        >
          Cases Resolved by Me in last 7 days
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          onClick={() =>
            navigate(
              `?${encodeURI(
                'case_type[0]=Internal Processing&case_type[1]=Dispute'
              )}`
            )
          }
        >
          Filing & Dispute
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          onClick={() => {
            let expiredTclaim = encodeURI(
              'case_type[0]=Internal Processing&' +
                'workflow[0]=TCLAIM&' +
                'status[0]=New&' +
                'status[1]=InProgress&' +
                'claim_details.ship_date[from]=*&' +
                'claim_details.ship_date[to]=now-270d'
            )
            navigate(`?${expiredTclaim}`)
          }}
        >
          Expired Transportation Claims ({' >'}270 days )
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          onClick={() => {
            let refundProcess = encodeURI(
              'search[0]=(_exists_:claim_details.import_claim_reversal.approved_reversal_amount)&' +
                'case_type[0]=Dispute'
            )
            navigate(`?${refundProcess}`)
          }}
        >
          Cases with Refund Process Details
        </Dropdown.MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  )
}
