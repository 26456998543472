import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import CaseView from './CaseView/index'
import CreateRequest from './CreateCase/CreateRequest'
import CreateDispute from './CreateCase/CreateDispute'
import CreateClaim from './CreateCase/CreateClaim'
import CreateTclaim from './CreateCase/CreateTclaim'
import Cases from './Search'

interface Props {}

const CaseList: React.FC<Props> = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Cases />} />
      <Route path="/:id" element={<CaseView />} />
      <Route path="/create/claim" element={<CreateClaim />} />
      <Route path="/create/request" element={<CreateRequest />} />
      <Route path="/create/dispute" element={<CreateDispute />} />
      <Route path="/create/tclaim" element={<CreateTclaim />} />
    </Routes>
  )
}

export default CaseList
