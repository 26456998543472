import { Button, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { useState } from 'react'
import { ViewSearchAttachment } from './ViewSearchAttachment'
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import { AgGridColumn } from 'ag-grid-react'
import { CaseType } from '../../Services/ConstantData'
import { SET_FIELD } from '../Constants'
const agGrid = css`
  .ag-cell {
    font-size: 10px !important;
  }
  .ag-header-cell-text {
    font-size: 10px !important;
  }
`
interface AutoExtractAttachmentsProps {
  setAttachmetSearchVisible: (_: boolean) => void
  attachData?: any
}

export const AutoExtractAttachments: React.FC<AutoExtractAttachmentsProps> = ({
  setAttachmetSearchVisible,
  attachData,
}) => {
  const [state, dispatch] = useGlobalForm()
  const [isVisible, setIsVisible] = useState(false)
  const [viewFile, setViewFile] = useState<{
    attachmentId: string
    fileName: string
    documentSource: string
    documentId: string
  }>({ attachmentId: '', fileName: '', documentSource: '', documentId: '' })
  const [gridApi, setGridApi] = useState<any>(null)

  const onGridReady = (params: any) => {
    setGridApi(params.api)
  }

  const handleViewAttachment = (data: any) => {
    setViewFile({
      attachmentId: data?.recordId,
      fileName: data?.fileName,
      documentSource: data?.documentSource,
      documentId: data?.documentId,
    })

    setIsVisible(true)
  }

  const handleAttachmentClick = () => {
    const parts = [
      new Blob(['Dummy File'], {
        type: 'text/plain',
      }),
    ]
    const selectedRows: any = gridApi.getSelectedRows().map((row: any) => {
      return {
        file: new File(parts, row.fileName),
        documentNumber: row.documentId,
        documentType: row.documentType ?? undefined,
        isInternal: state.requestType === CaseType.INTERNALPROCESSING,
        documentSource: row.documentSource,
        recordId: row?.recordId,
      }
    })
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'selectedSearchAttachments', value: [...selectedRows] }],
    })
    setAttachmetSearchVisible(false)
  }

  const handleCloseAttachment = () => {
    setAttachmetSearchVisible(false)
  }

  const ViewAttachment = (params: any) => (
    <Button
      onClick={() => {
        handleViewAttachment(params?.data)
      }}
    >
      View
    </Button>
  )

  return (
    <>
      <div className="hc-pa-normal">
        <Grid.Container>
          <Grid.Item xs={12}>
            <div className="ag-theme-alpine">
              <AgGridReact
                css={agGrid}
                frameworkComponents={{
                  viewAttachment: ViewAttachment,
                }}
                defaultColDef={{
                  flex: 1,
                  minWidth: 100,
                  resizable: true,
                  headerCheckboxSelection: isFirstColumn,
                  checkboxSelection: isFirstColumn,
                }}
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={5}
                rowData={attachData}
                rowSelection={'multiple'}
                domLayout={'autoHeight'}
                onGridReady={onGridReady}
              >
                <AgGridColumn field="fileName" minWidth={120} />
                <AgGridColumn
                  headerName={'Document Number'}
                  field="documentId"
                  minWidth={120}
                />
                <AgGridColumn
                  headerName={'Document Type'}
                  field="documentType"
                  minWidth={120}
                />
                <AgGridColumn field="location" minWidth={160} />
                <AgGridColumn
                  headerName="View"
                  field="recordId"
                  minWidth={100}
                  cellRenderer="viewAttachment"
                />
              </AgGridReact>
            </div>
          </Grid.Item>
          <Grid.Item xs={12}>
            <Grid.Container direction="row-reverse" className={'hc-pb-none'}>
              <Grid.Item>
                <Button type="primary" onClick={() => handleAttachmentClick()}>
                  Attach Files
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="secondary"
                  onClick={() => handleCloseAttachment()}
                >
                  Close
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
      {isVisible && (
        <Modal
          isVisible={isVisible}
          onRefuse={() => setIsVisible(false)}
          onApproveModal={() => setIsVisible(false)}
        >
          <ViewSearchAttachment
            attachmentId={viewFile.attachmentId}
            fileName={viewFile.fileName}
            documentSource={viewFile.documentSource}
            vendorId={state?.vendorNumber}
            documentId={viewFile.documentId}
          />
        </Modal>
      )}
    </>
  )
}

function isFirstColumn(params: any) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns()
  var thisIsFirstColumn = displayedColumns[0] === params.column
  return thisIsFirstColumn
}
