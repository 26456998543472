import React from 'react'
import {
  Chip,
  Grid,
  ExpandableSection,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'

interface MainProp {
  heading: string
  duplicatesCases: any
}

const DuplicateCaseSummary: React.FC<MainProp> = ({
  heading,
  duplicatesCases,
}) => {
  const navigate = useNavigate()
  return (
    <div className="hc-pa-normal hc-pl-none hc-pr-none hc-pt-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="none">
        <Heading size={6}>{heading}</Heading>
        <ExpandableSection.Content>
          <Grid.Container className="hc-pt-none hc-ml-normal">
            {duplicatesCases.length > 0 ? (
              <div className="hc-pa-normal">
                {duplicatesCases?.map(
                  (duplicatesCasesList: any, index: number) => (
                    <Chip
                      clickable
                      key={duplicatesCasesList?.caseId}
                      onClick={() =>
                        navigate(`/cases/${duplicatesCasesList?.caseId}`)
                      }
                    >
                      {duplicatesCasesList?.caseId}
                    </Chip>
                  )
                )}
              </div>
            ) : (
              <div className="hc-pa-normal">
                <Chip>None</Chip>{' '}
              </div>
            )}
          </Grid.Container>
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default DuplicateCaseSummary
