import React, { useState } from 'react'

interface AttachmentDetails {
  attachments: any[]
  uploadingAttachments: false
  submitStatuses: any[]
  retryFlag: false
  attachmentTypeNotSet: false
}

type AttachmentDetailsType = {
  attachmentDetails: {
    attachments: any[]
    uploadingAttachments: false
    submitStatuses: any[]
    retryFlag: false
    attachmentTypeNotSet: false
  }
  setAttachmentDetails: Function
}

const AttachmentContext = React.createContext<AttachmentDetailsType>({
  attachmentDetails: {
    attachments: [],
    uploadingAttachments: false,
    retryFlag: false,
    submitStatuses: [],
    attachmentTypeNotSet: false,
  },
  setAttachmentDetails: () => {},
})

const AttachmentProvider = (props: any) => {
  const [attachmentDetails, setAttachmentDetails] = useState<AttachmentDetails>(
    {
      attachments: [],
      submitStatuses: [],
      uploadingAttachments: false,
      retryFlag: false,
      attachmentTypeNotSet: false,
    }
  )

  return (
    <AttachmentContext.Provider
      value={{ attachmentDetails, setAttachmentDetails }}
    >
      {props.children}
    </AttachmentContext.Provider>
  )
}

export { AttachmentContext, AttachmentProvider }
