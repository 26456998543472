import React from 'react'
import { Grid, Modal, Button, Heading } from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { FormikValues } from 'formik'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import { CaseStatus, CaseType } from '../Services/ConstantData'
import { useAuth } from '@praxis/component-auth'

interface MainProp {
  heading: String
  headingText: String
  formik: FormikValues
}
const AutoClosableModal: React.FC<MainProp> = ({
  headingText,
  heading,
  formik,
}) => {
  const [state, dispatch] = useGlobalForm()
  const cTS = new Date().toISOString()
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const cDesc = `${'Created a New '}${CaseType.REQUESTFORINFO}${' Case '}`
  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.autoClosableModal}
        onRefuse={() => {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'autoClosableModal', value: false }],
          })
        }}
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs className="hc-pl-lg">
              <Heading size={6}>{heading}</Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container
            direction="row-reverse"
            spacing="dense"
            className="hc-pa-sm"
          >
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  dispatch({
                    type: SET_FIELD,
                    payload: [{ id: 'autoClosableModal', value: false }],
                  })
                  formik.setFieldValue('status', CaseStatus.CLOSED)
                  const adAuditEntry = [
                    {
                      activityDescription: 'Case was Auto-Closed',
                      userId: 'System',
                      workflow: 'DCIMAG',
                      status: 'Closed',
                      assignUserId: null,
                      activityTimestamp: cTS,
                    },
                    {
                      activityDescription: cDesc,
                      userId: userName,
                      status: CaseStatus.NEW,
                      activityTimestamp: cTS,
                    },
                  ]
                  formik.setFieldValue('auditEntries', adAuditEntry)
                  formik.setFieldValue('lanId', 'system')
                  formik.handleSubmit()
                }}
              >
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  formik.handleSubmit()
                  dispatch({
                    type: SET_FIELD,
                    payload: [{ id: 'autoClosableModal', value: false }],
                  })
                }}
              >
                No, I want to submit a copy request
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}
export default AutoClosableModal
