import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import { useQuery, useApolloClient, ApolloError } from '@apollo/client'
import { Case, CaseVariable, GET_CASE } from '../Common/Queries'
import { CaseType } from '../Services/ConstantData'
import TMDisputeView from './TMDisputeView'
import TMClaimView from './TMClaimView'
import TMRequestView from './TMRequestView'
import BPRequestView from './BPRequestView'
import BPDisputeView from './BPDisputeView'
import { AttachmentProvider } from '../Context/AttachmentContext'
import { GlobalFormProvider } from '../Context/GlobalFormStateContext'
import SpinnerLoad from '../Common/SpinnerLoad'
import { Layout, useToaster } from '@enterprise-ui/canvas-ui-react'
import {
  GetSearchResults,
  SearchVariable,
  GET_SEARCH_RESULTS,
} from '../../Dashboard/Queries'

const CaseView: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { userProfile } = useProfile()
  const client = useApolloClient()
  const makeToast = useToaster()
  const [duplicateCases, setDuplicateCases] = useState<any>([])
  const { data: casedetails, loading } = useQuery<Case, CaseVariable>(
    GET_CASE,
    {
      variables: {
        id: parseInt(params.id!!),
      },
      onCompleted: (caseeResponse: Case) => {
        duplicateCaseCheck(caseeResponse)
      },
      onError: (error: ApolloError) => {
        makeToast({
          type: 'success',
          heading: 'Case Not Found',
          messagetext: error?.graphQLErrors[0]?.message,
        })
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  const duplicateCaseCheck = async (caseeResponse: Case) => {
    const casee = caseeResponse.getCase
    let caseType = casee?.caseType ? casee.caseType.replace(/\//g, '\\/') : ''
    try {
      const { data } = await client.query<GetSearchResults, SearchVariable>({
        query: GET_SEARCH_RESULTS,
        variables: {
          query: `case_type: (${caseType}) AND vendor_number: (${casee?.vendorNumber}) AND original_document_number.keyword: (${casee?.originalDocumentNumber}) `,
          sort: 'create_timestamp',
          page: 1,
          perPage: 250,
          vendorId: `${casee?.vendorNumber}`,
        },
        fetchPolicy: 'network-only',
      })
      const getSearchResults = data?.getSearchResults?.cases
      if (getSearchResults !== null) {
        setDuplicateCases(getSearchResults)
      } else {
        setDuplicateCases([])
      }
    } catch (errors: any) {
      console.log('duplicateCaseCheck', errors?.message)
    }
  }

  if (loading) {
    return (
      <Layout.Body includeRail>
        <SpinnerLoad case />
      </Layout.Body>
    )
  }

  return (
    <React.Fragment>
      {!userProfile?.isVendor &&
        casedetails?.getCase?.caseType === CaseType.DISPUTE && (
          <AttachmentProvider>
            <GlobalFormProvider>
              <TMDisputeView
                id={params.id!!}
                duplicatesCases={duplicateCases}
              />
            </GlobalFormProvider>
          </AttachmentProvider>
        )}
      {!userProfile?.isVendor &&
        casedetails?.getCase?.caseType === CaseType.INTERNALPROCESSING && (
          <AttachmentProvider>
            <GlobalFormProvider>
              <TMClaimView id={params.id!!} duplicatesCases={duplicateCases} />
            </GlobalFormProvider>
          </AttachmentProvider>
        )}
      {!userProfile?.isVendor &&
        casedetails?.getCase?.caseType === CaseType.REQUESTFORINFO && (
          <AttachmentProvider>
            <GlobalFormProvider>
              <TMRequestView
                id={params.id!!}
                duplicatesCases={duplicateCases}
              />
            </GlobalFormProvider>
          </AttachmentProvider>
        )}
      {userProfile?.isVendor &&
        casedetails?.getCase?.caseType === CaseType.DISPUTE && (
          <AttachmentProvider>
            <GlobalFormProvider>
              <BPDisputeView
                id={params.id!!}
                duplicatesCases={duplicateCases}
              />
            </GlobalFormProvider>
          </AttachmentProvider>
        )}
      {userProfile?.isVendor &&
        casedetails?.getCase?.caseType === CaseType.REQUESTFORINFO && (
          <AttachmentProvider>
            <GlobalFormProvider>
              <BPRequestView
                id={params.id!!}
                duplicatesCases={duplicateCases}
              />
            </GlobalFormProvider>
          </AttachmentProvider>
        )}
    </React.Fragment>
  )
}
export default CaseView
