import React from 'react'
import { useImmerReducer } from 'use-immer'
import { globalReducer } from './GlobalReducer'

export interface GlobalType {
  showDocuments: boolean
  disputeSectionFlag: boolean
  paymentSectionFlag: boolean
  receiptsSectionFlag: boolean
  searchAttachments: false
  searchAttachmentType: any[]
  selectedSearchAttachments: any[]
  receipts?: [string]
  location?: [string]
  vendorNumber: string
  documentNumber: string
  documentHintText: string
  vendorIdHintText: string
  isDocNextFlag: boolean
  isVendorNextFlag: boolean
  isDocShowable: boolean
  vendorIdSearch: boolean
  isSubmit: boolean
  disputeAmountCheck: boolean
  creatingCase: boolean
  docSupportingFlag: boolean
  isVendShowable: boolean
  vendorNbrflag: boolean
  isCrVendorNextFlag: boolean
  cvendorIdSearch: boolean
  isCrVendor: boolean
  isCrVendShowable: boolean
  isCancel: boolean
  cvendorNbrflag: boolean
  documentTypeText: string
  documentSubTypeText: string
  reasonCodeText: string
  name: string
  isClaimNextFlag: boolean
  userFlag?: boolean
  resolutionFlag?: boolean
  documentCategoryFlag?: boolean
  documentCategoryErrFlag?: boolean
  docReferenceFlag?: boolean
  carrVendorDetailsFlag?: boolean
  caseDescriptionFlag?: boolean
  reOpenModalFlag?: boolean
  vendorHintText?: string
  downloadingAttachment?: number
  comments?: boolean
  showDialog?: boolean
  documentFound?: boolean
  attachmentAlert?: boolean
  attachementMessage?: string
  activeDuplicate?: boolean
  savedAttachments?: any[]
  viewAttachment?: number
  setAttachmentView: boolean
  isAttachmentNotViewable: boolean
  requestType: string
  resCodeCheck: boolean
  departmentCheck: boolean
  departmentClassCheck: boolean
  caseCreatedFlag: boolean
  docCreateDateFlag: boolean
  invoiceDueDateFlag: boolean
  documentAlert: boolean
  createCase: boolean
  isLocationRequired: boolean
  isPORequired: boolean
  isCarrierVendor: boolean
  documentPrefix?: any[]
  resAmount?: number
  dvsVendor?: boolean
  statusAlert?: boolean
  changeAwaitingInfo?: boolean
  alertComponent?: string
  assignLanId?: string
  assignWorkflow?: string
  isSourceDocumentAmountRequired?: boolean
  isDisputedAmountRequired?: boolean
  attachmentsError?: boolean
  caseStatus: string
  maxRangeVendors: any
  toastHeading: string
  toastMessage: string
  fileUrl: string
  mediaType: string
  fileName: string
  format: boolean
  fileRowIndex?: number
  loadAttachmentList?: boolean
  paymentPaid?: boolean
  partialResolution?: string
  autoClosableModal: boolean
  invalidReceiptsFlag: boolean
  invoiceItemDetails: boolean
  isShortageNext: boolean
  dpciShortage: any[]
}

export const initialState: GlobalType = {
  showDocuments: false,
  disputeSectionFlag: false,
  paymentSectionFlag: false,
  receiptsSectionFlag: false,
  searchAttachments: false,
  searchAttachmentType: [],
  selectedSearchAttachments: [],
  receipts: undefined,
  location: undefined,
  vendorNumber: '',
  documentNumber: '',
  isDocNextFlag: false,
  isVendorNextFlag: false,
  isDocShowable: false,
  vendorIdSearch: false,
  isSubmit: false,
  disputeAmountCheck: false,
  creatingCase: false,
  docSupportingFlag: false,
  isVendShowable: false,
  vendorNbrflag: false,
  isCrVendorNextFlag: false,
  cvendorIdSearch: false,
  isCrVendor: false,
  isCrVendShowable: false,
  isCancel: false,
  cvendorNbrflag: false,
  documentHintText: '',
  vendorIdHintText: '',
  documentTypeText: '',
  documentSubTypeText: '',
  reasonCodeText: '',
  name: '',
  isClaimNextFlag: false,
  userFlag: false,
  resolutionFlag: false,
  documentCategoryFlag: false,
  caseDescriptionFlag: false,
  reOpenModalFlag: false,
  downloadingAttachment: undefined,
  documentCategoryErrFlag: false,
  docReferenceFlag: false,
  carrVendorDetailsFlag: false,
  comments: false,
  showDialog: false,
  documentFound: false,
  attachmentAlert: false,
  attachementMessage: '',
  activeDuplicate: false,
  savedAttachments: [],
  viewAttachment: undefined,
  setAttachmentView: false,
  isAttachmentNotViewable: false,
  requestType: '',
  resCodeCheck: false,
  departmentCheck: false,
  departmentClassCheck: false,
  caseCreatedFlag: false,
  docCreateDateFlag: false,
  invoiceDueDateFlag: false,
  documentAlert: false,
  createCase: false,
  isLocationRequired: false,
  isPORequired: false,
  isCarrierVendor: false,
  documentPrefix: [],
  resAmount: undefined,
  dvsVendor: false,
  statusAlert: false,
  changeAwaitingInfo: false,
  alertComponent: '',
  assignLanId: '',
  assignWorkflow: '',
  isSourceDocumentAmountRequired: false,
  isDisputedAmountRequired: false,
  attachmentsError: false,
  caseStatus: '',
  maxRangeVendors: undefined,
  toastHeading: '',
  toastMessage: '',
  fileUrl: '',
  mediaType: '',
  fileName: '',
  format: false,
  fileRowIndex: undefined,
  paymentPaid: false,
  partialResolution: '',
  autoClosableModal: false,
  invalidReceiptsFlag: false,
  invoiceItemDetails: false,
  isShortageNext: false,
  dpciShortage: [],
}

const GlobalFormStateContext = React.createContext<any>(undefined)
const GlobalFormDispatchContext = React.createContext<any>(undefined)

const GlobalFormProvider = (props: any) => {
  const [state, dispatch] = useImmerReducer<GlobalType, any>(
    globalReducer,
    initialState
  )

  return (
    <GlobalFormStateContext.Provider value={state}>
      <GlobalFormDispatchContext.Provider value={dispatch}>
        {props.children}
      </GlobalFormDispatchContext.Provider>
    </GlobalFormStateContext.Provider>
  )
}

function useGlobalFormState() {
  const context = React.useContext(GlobalFormStateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a GlobalStateContext')
  }
  return context
}
function useGlobalFormDispatch() {
  const context = React.useContext(GlobalFormDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useCountDispatch must be used within a GlobalFormDispatchContext'
    )
  }
  return context
}

function useGlobalForm() {
  return [useGlobalFormState(), useGlobalFormDispatch()]
}

export {
  GlobalFormStateContext,
  GlobalFormDispatchContext,
  GlobalFormProvider,
  useGlobalForm,
  useGlobalFormDispatch,
  useGlobalFormState,
}
