export enum Security {
  INTERNAL,
  EXTERNAL,
  BOTH,
}

export interface SideFilter {
  id: string
  displayName: string
  type: 'search_group' | 'checkbox' | 'date' | 'radio'
  security: Security
  searchFields?: SearchField[]
  placeHolder?: string
  values?: Value[]
  active?: DateRange
  startExpanded?: boolean
}

export interface SearchField {
  field: string
  suggestField?: string
  label: string
  placeHolder: string
  values: Value[]
}

export interface Value {
  id: string
  label: string
  value: string | DateRange
  isChecked?: boolean
}

export interface DateRange {
  from: string
  to: string
}

export interface SearchContextValue {
  sideFilters: SideFilter[]
  searchFilters: any
  loading: boolean
}

export interface Filter {
  fiterName: string
  filterValues: string[]
}

const dateFilters: Value[] = [
  {
    id: '7d',
    label: 'Last 7 days',
    value: {
      from: 'now-7d',
      to: 'now',
    },
  },
  {
    id: '30d',
    label: 'Last 30 Days',
    value: {
      from: 'now-30d',
      to: 'now',
    },
  },
  {
    id: '7-14d',
    label: 'Between 7 to 14 Days',
    value: {
      from: 'now-14d',
      to: 'now-7d',
    },
  },
]

const shipDateFilters: Value[] = [
  {
    id: '45d',
    label: 'Expiring in 45 days',
    value: {
      from: 'now-270d',
      to: 'now-225d',
    },
  },
  {
    id: '270d',
    label: 'Older than 270 days',
    value: {
      from: '*',
      to: 'now-270d',
    },
  },
]

export const initialValue: SearchContextValue = {
  searchFilters: [],
  loading: false,
  sideFilters: [
    {
      id: 'status',
      displayName: 'Status',
      security: Security.BOTH,
      values: [
        {
          id: 'New',
          value: 'New',
          label: 'New',
          isChecked: false,
        },
        {
          id: 'InProgress',
          value: 'InProgress',
          label: 'In Progress',
          isChecked: false,
        },
        {
          id: 'ReOpen',
          value: 'ReOpen',
          label: 'Re Opened',
          isChecked: false,
        },
        {
          id: 'AwaitingInfo',
          value: 'AwaitingInfo',
          label: 'Awaiting Info',
          isChecked: false,
        },
        {
          id: 'Resolved',
          value: 'Resolved',
          label: 'Resolved',
          isChecked: false,
        },
        {
          id: 'Closed',
          value: 'Closed',
          label: 'Closed',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'case_assignment',
      displayName: 'Case Assignment',
      security: Security.INTERNAL,
      values: [
        {
          id: 'Cases Assigned to Me',
          value: 'Cases Assigned to Me',
          label: 'Cases Assigned to Me',
          isChecked: false,
        },
      ],
      type: 'radio',
    },
    {
      id: 'case_type',
      displayName: 'Case Type',
      security: Security.INTERNAL,
      values: [
        {
          id: 'Internal Processing',
          value: 'Internal Processing',
          label: 'Internal Processing',
          isChecked: false,
        },
        {
          id: 'Dispute',
          value: 'Dispute',
          label: 'Dispute',
          isChecked: false,
        },
        {
          id: 'Request a Copy/Info',
          value: 'Request a Copy/Info',
          label: 'Request a Copy/Info',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'case_type',
      displayName: 'Case Type',
      security: Security.EXTERNAL,
      values: [
        {
          id: 'Dispute',
          value: 'Dispute',
          label: 'Dispute',
          isChecked: false,
        },
        {
          id: 'Request a Copy/Info',
          value: 'Request a Copy/Info',
          label: 'Request a Copy/Info',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'is_documents_available',
      displayName: 'Attachments',
      security: Security.BOTH,
      values: [
        {
          id: 'true',
          value: 'true',
          label: 'Cases with Documents',
          isChecked: false,
        },
        {
          id: 'false',
          value: 'false',
          label: 'Cases without Documents',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'case_id',
      displayName: 'Case ID',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: true,
      searchFields: [
        {
          field: 'case_id',
          placeHolder: '',
          label: 'Case #',
          values: [],
        },
      ],
    },
    {
      id: 'workflow',
      displayName: 'Workflows',
      security: Security.INTERNAL,
      values: [],
      type: 'checkbox',
    },
    {
      id: 'document_type_id',
      displayName: 'Claim/Doc Type',
      security: Security.INTERNAL,
      values: [],
      type: 'checkbox',
      startExpanded: false,
    },
    {
      id: 'document_sub_type_id',
      displayName: 'Claim/Doc Sub Type',
      security: Security.INTERNAL,
      values: [],
      type: 'checkbox',
      startExpanded: false,
    },
    {
      id: 'reason_code',
      displayName: 'Reason for Disputing/Claim/Request Copy',
      security: Security.BOTH,
      values: [],
      type: 'checkbox',
    },
    {
      id: 'resolution_code',
      displayName: 'Resolution Codes',
      security: Security.BOTH,
      values: [],
      type: 'checkbox',
    },
    {
      id: 'create_timestamp',
      displayName: 'Filed Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'claim_details.ship_date',
      displayName: 'Ship Date',
      security: Security.INTERNAL,
      values: shipDateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'update_timestamp',
      displayName: 'Updated Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'resolution_date',
      displayName: 'Resolved Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'document_filter',
      displayName: 'Source Document',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'original_document_number',
          placeHolder: '',
          label: 'Document #',
          values: [],
          suggestField: 'original_document_number.suggest',
        },
      ],
    },
    {
      id: 'purchase_order_number',
      displayName: 'Purchase Order',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'purchase_order_number',
          placeHolder: '',
          label: 'PO #',
          values: [],
          suggestField: 'purchase_order_number.suggest',
        },
      ],
    },
    {
      id: 'location_id',
      displayName: 'Location',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'location_id',
          placeHolder: '',
          label: 'Location #',
          values: [],
          suggestField: 'location_id.suggest',
        },
      ],
    },
    {
      id: 'department_id',
      displayName: 'Department',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'department_id',
          placeHolder: '',
          label: 'Department #',
          values: [],
          suggestField: 'department_id.suggest',
        },
      ],
    },
    {
      id: 'class_id',
      displayName: 'Class',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'class_id',
          placeHolder: '',
          label: 'Class #',
          values: [],
          suggestField: 'class_id.suggest',
        },
      ],
    },
    {
      id: 'claim_details.import_claim_reversal.approved_pending_reversal_amount',
      displayName: 'Approved Pending Reversal Amount',
      security: Security.INTERNAL,
      values: [
        {
          id: '(>0 AND *)',
          value: '(>0 AND *)',
          label: 'Greater than 0',
          isChecked: false,
        },
        {
          id: '(0 AND *)',
          value: '(0 AND *)',
          label: 'Equal to 0',
          isChecked: false,
        },
        {
          id: '(<0 AND *)',
          value: '(<0 AND *)',
          label: 'Less than 0',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'vendor_filters',
      displayName: 'Vendor Attributes',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'vendor_number',
          placeHolder: '',
          label: 'Vendor Number',
          values: [],
          suggestField: 'vendor_number.suggest',
        },
        {
          field: 'vendor_name.keyword',
          placeHolder: '',
          label: 'Vendor Name',
          values: [],
          suggestField: 'vendor_name.suggest',
        },
        {
          field: 'claim_details.carrier_vendor_id',
          placeHolder: '',
          label: 'Carrier Vendor Number',
          values: [],
          suggestField: 'claim_details.carrier_vendor_id.suggest',
        },
        {
          field: 'claim_details.carrier_vendor_name',
          placeHolder: '',
          label: 'Carrier Vendor Name',
          values: [],
          suggestField: 'claim_details.carrier_vendor_name.suggest',
        },
      ],
    },
    {
      id: 'lan_id',
      displayName: 'Team Member',
      security: Security.INTERNAL,
      values: [],
      type: 'checkbox',
    },
  ],
}
