import React from 'react'
import { AdminState } from '../common/AdminContext'
import {
  Button,
  Divider,
  GridContainer,
  GridItem,
  Heading,
  Layout,
  Spinner,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useAdminProps } from '../common/AdminProvider'
import ReportTable from '../../Reports/ReportTable'
import { ColDef } from 'ag-grid-community'
import { useQuery } from '@apollo/client'
import { GET_REOPENCODES, GetReopenCodes } from '../../Cases/Common/Queries'
import { AddReopenCode } from './AddReopenCode'
import { EditReopenCode } from './EditReopenCode'
import {
  CancelIcon,
  CheckIcon,
  EnterpriseIcon,
  PencilIcon,
} from '@enterprise-ui/icons'

const baseLayout = css`
  height: 80vh;
  overflow: hidden;
`

export const reopenCodesInitialState: AdminState = {
  activeTab: 'tab_01',
  tabs: [
    {
      id: 'tab_01',
      tabName: 'Reopen Codes',
      editFormVisible: false,
      createFormVisible: false,
      editableRow: null,
    },
  ],
}

export const ReopenCodesAdmin: React.FC = () => {
  const [state, dispatch] = useAdminProps()

  const { data: reopenCodeData, loading: reopenCodeLoading } =
    useQuery<GetReopenCodes>(GET_REOPENCODES, {})
  const reopenCodeColumn: ColDef[] = [
    {
      headerName: 'Action',
      pinned: 'left',
      cellStyle: {
        textAlign: 'center',
      },
      maxWidth: 100,
      cellRenderer: (props: any) => {
        return (
          <Button
            iconOnly
            aria-label="Edit"
            onClick={() => {
              dispatch({
                type: 'SET_EDITABLE_ROW',
                row: props.data,
                tabId: 'tab_01',
              })
              dispatch({
                type: 'SHOW_EDIT_FORM',
                tabId: 'tab_01',
              })
            }}
          >
            <EnterpriseIcon icon={PencilIcon} />
          </Button>
        )
      },
    },
    {
      field: 'reopenCodeText',
      headerName: 'Reopen Code',
      sortable: true,
      minWidth: 300,
    },
    {
      field: 'reopenCodeDescription',
      headerName: 'Reopen Code Description',
      sortable: true,
      minWidth: 600,
      resizable: true,
      wrapText: true,
    },
    {
      field: 'active',
      headerName: 'Active',
      sortable: true,
      maxWidth: 100,
      cellRenderer: (props: any) => {
        if (props.value === true) {
          return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
        }
        return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
      },
    },
  ]

  const getTabData = (tabId: string): [ColDef[], boolean, any] => {
    return [
      reopenCodeColumn,
      reopenCodeLoading,
      reopenCodeData?.getReopenCodes ?? [],
    ]
  }

  const handleEditClose = (tabId: string) => {
    dispatch({ type: 'HIDE_EDIT_FORM', tabId: tabId })
  }

  const handleCreateClose = (tabId: string) => {
    dispatch({ type: 'HIDE_CREATE_FORM', tabId: tabId })
  }

  return (
    <>
      <Layout.SectionHeader>
        <GridContainer justify="space-between">
          <GridItem>
            <Heading size={2}>Manage Reopen Codes</Heading>
          </GridItem>
          <GridItem>
            <GridContainer>
              <GridItem>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch({ type: 'SHOW_CREATE_FORM', tabId: 'tab_01' })
                  }}
                >
                  Add Reopen Codes
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Layout.SectionHeader>
      <Layout.Body includeRail>
        <Tabs
          activeTab={state.activeTab}
          onTabSelect={(event: any, tab: any) => {
            dispatch({ type: 'SET_ACTIVE_TAB', tab: tab.name })
          }}
        >
          {state.tabs.map((tab) => (
            <Tabs.Item name={tab.id} key={tab.id}>
              {tab?.tabName}
            </Tabs.Item>
          ))}
          <Divider />
          {state.tabs.map((tab) => {
            const [columns, loading, data] = getTabData(tab.id)
            return (
              <Tabs.Content name={tab.id} key={tab.id} className="hc-ph-none">
                {!loading ? (
                  <ReportTable data={data ?? []} columns={columns} />
                ) : (
                  <GridContainer
                    justify="center"
                    align="center"
                    css={baseLayout}
                  >
                    <GridItem>
                      <Spinner />
                    </GridItem>
                  </GridContainer>
                )}
              </Tabs.Content>
            )
          })}
        </Tabs>
        {state.tabs[0] && (
          <EditReopenCode
            handleClose={() => handleEditClose('tab_01')}
            formVisible={state.tabs[0]?.editFormVisible}
            editableRow={state.tabs[0]?.editableRow}
          />
        )}
        {state.tabs[0] && state.tabs[0].createFormVisible && (
          <AddReopenCode
            handleClose={() => handleCreateClose('tab_01')}
            reopenCodes={reopenCodeData?.getReopenCodes}
          />
        )}
      </Layout.Body>
    </>
  )
}
