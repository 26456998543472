import React from 'react'
import {
  Table,
  GridContainer,
  GridItem,
  Caption,
  Heading,
  Chip,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CaseStatus, CaseType } from '../Services/ConstantData'
import { getChipClassNames, getStatusColor } from './utils'

interface CaseListItemProps {
  casee: any
  toggleRow: any
  isSelected: any
}

interface VendorInfoProps {
  vendorName?: string
  vendorId?: string | number
}

const vendorInfoStyle = css`
  max-width: 200px;
  overflow: hidden;
`

export const VendorInfo: React.FC<VendorInfoProps> = ({
  vendorName,
  vendorId,
}) => {
  return (
    <div css={vendorInfoStyle} className="hc-to-ellipsis hc-ws-no-wrap">
      {vendorId ? `${vendorId} - ${vendorName}` : 'NA'}
    </div>
  )
}

export function formatText(
  value?: any,
  valueType: 'date' | 'amount' | 'text' = 'text'
) {
  switch (valueType) {
    case 'date':
      return value ? <DateFormatter date={value} format="L" /> : 'NA'
    case 'amount': {
      const amount = value ?? 0.0
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(amount))
    }
    case 'text':
      return value ?? 'NA'
  }
}
export const CaseSearchItem: React.FC<CaseListItemProps> = ({
  casee,
  toggleRow,
  isSelected,
}) => {
  const navigate = useNavigate()
  const { userProfile } = useProfile()
  const isCaseDisabled = false

  // casee?.status === CaseStatus.RESOLVED || casee?.status === CaseStatus.CLOSED
  const isDocumentsAvailable =
    casee.isDocumentsAvailable === true
      ? 'Available'
      : casee.isDocumentsAvailable === false
      ? 'Not Available'
      : 'NA'
  return (
    <Table.Row
      key={casee.caseId}
      onClick={() => navigate(`/cases/${casee.caseId}`)}
    >
      <Table.Data xs={12} className="hc-pt-none hc-pb-none hc-pr-none">
        <div className="hc-pa-dense">
          <GridContainer spacing="dense">
            <GridItem xs={12}>
              <GridContainer spacing="dense">
                <GridItem className="hc-pt-none" xs={6}>
                  <div style={{ display: 'flex' }}>
                    {!userProfile?.isVendor && (
                      <div
                        style={{ display: 'flex' }}
                        onClick={(e: any) => e.stopPropagation()}
                      >
                        <Input.Checkbox
                          id={`select_${casee.caseId}`}
                          css={{ width: 'auto' }}
                          onChange={() => toggleRow()}
                          checked={!isCaseDisabled && isSelected}
                          disabled={isCaseDisabled}
                        ></Input.Checkbox>
                      </div>
                    )}
                    <Caption
                      below={casee.caseDescription}
                      css={{ marginLeft: '10px' }}
                    >
                      <Heading size={6} className="hc-lh-dense">
                        CASE-{casee.caseId}
                        <Chip
                          className={`hc-ma-dense ${getChipClassNames(
                            casee.caseType
                          )}`}
                          size="dense"
                        >
                          <strong>{casee.caseType}</strong>
                        </Chip>
                      </Heading>
                    </Caption>
                  </div>
                </GridItem>
                <GridItem className="hc-ta-right hc-mt-dense" xs={6}>
                  {!userProfile?.isVendor && (
                    <React.Fragment>
                      <Chip size="dense" className="hc-bg-grey04">
                        {casee.lanId ?? 'Unassigned'}
                      </Chip>
                      <Chip size="dense" className="hc-bg-grey04">
                        {casee.workflow}
                      </Chip>
                    </React.Fragment>
                  )}

                  <Chip
                    size="dense"
                    color={getStatusColor(casee.status)}
                    className="hc-txt-uppercase"
                  >
                    {casee.status}
                  </Chip>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer spacing="dense">
                    {casee.caseType === CaseType.DISPUTE && (
                      <React.Fragment>
                        <GridItem>
                          <Caption above="Document Number">
                            {casee.originalDocumentNumber}
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Vendor" className="hc-to-ellipsis">
                            <VendorInfo
                              vendorId={casee.vendorNumber}
                              vendorName={casee.vendorName}
                            />
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption
                            above="Reason For Dispute"
                            className="hc-to-ellipsis"
                          >
                            {casee.reasonCodeText}
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Received Date">
                            <DateFormatter
                              date={casee?.createTimestamp}
                              format="L"
                            />
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Dispute Amount">
                            {formatText(
                              casee?.disputedDocumentAmount,
                              'amount'
                            )}
                          </Caption>
                        </GridItem>
                        {(casee.status === CaseStatus.RESOLVED ||
                          casee.status === CaseStatus.CLOSED) && (
                          <React.Fragment>
                            <GridItem>
                              <Caption above="Resolution Code">
                                {formatText(casee.resolutionCodeText)}
                              </Caption>
                            </GridItem>
                            <GridItem>
                              <Caption above="Resolution Date">
                                {formatText(casee?.resolutionDate, 'date')}
                              </Caption>
                            </GridItem>
                            <GridItem>
                              <Caption above="Resolution Amount">
                                {formatText(casee.resolutionAmount, 'amount')}
                              </Caption>
                            </GridItem>
                          </React.Fragment>
                        )}
                        <GridItem>
                          <Caption above="Attachments">
                            {isDocumentsAvailable}
                          </Caption>
                        </GridItem>
                      </React.Fragment>
                    )}

                    {casee.caseType === CaseType.INTERNALPROCESSING && (
                      <React.Fragment>
                        <GridItem>
                          <Caption above="Document Number">
                            {casee.originalDocumentNumber}
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Vendor" className="hc-to-ellipsis">
                            <VendorInfo
                              vendorId={casee.vendorNumber}
                              vendorName={casee.vendorName}
                            />
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption
                            above="Carrier Vendor"
                            className="hc-to-ellipsis"
                          >
                            <VendorInfo
                              vendorId={casee.claimDetails?.carrierVendorId}
                              vendorName={casee.claimDetails?.carrierVendorName}
                            />
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption
                            above="Reason For Claim"
                            className="hc-to-ellipsis"
                          >
                            {casee.reasonCodeText}
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Filed Date">
                            <DateFormatter
                              date={casee?.createTimestamp}
                              format="L"
                            />
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Ship Date">
                            {formatText(casee?.claimDetails?.shipDate, 'date')}
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Claim Amount">
                            {formatText(
                              casee?.disputedDocumentAmount,
                              'amount'
                            )}
                          </Caption>
                        </GridItem>
                        {(casee.status === CaseStatus.RESOLVED ||
                          casee.status === CaseStatus.CLOSED) && (
                          <React.Fragment>
                            <GridItem>
                              <Caption above="Resolution Code">
                                {formatText(casee.resolutionCodeText)}
                              </Caption>
                            </GridItem>
                            <GridItem>
                              <Caption above="Resolution Date">
                                {formatText(casee?.resolutionDate, 'date')}
                              </Caption>
                            </GridItem>
                            <GridItem>
                              <Caption above="Resolution Amount">
                                {formatText(casee.resolutionAmount, 'amount')}
                              </Caption>
                            </GridItem>
                          </React.Fragment>
                        )}
                        <GridItem>
                          <Caption above="Attachments">
                            {isDocumentsAvailable}
                          </Caption>
                        </GridItem>
                      </React.Fragment>
                    )}

                    {casee.caseType === CaseType.REQUESTFORINFO && (
                      <React.Fragment>
                        <GridItem>
                          <Caption above="Document Number">
                            {casee.originalDocumentNumber}
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Vendor" className="hc-to-ellipsis">
                            <VendorInfo
                              vendorId={casee.vendorNumber}
                              vendorName={casee.vendorName}
                            />
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption
                            above="Reason For Dispute"
                            className="hc-to-ellipsis"
                          >
                            {casee.reasonCodeText}
                          </Caption>
                        </GridItem>
                        <GridItem>
                          <Caption above="Received Date">
                            {formatText(casee?.createTimestamp, 'date')}
                          </Caption>
                        </GridItem>
                        {(casee.status === CaseStatus.RESOLVED ||
                          casee.status === CaseStatus.CLOSED) && (
                          <React.Fragment>
                            <GridItem>
                              <Caption above="Resolution Code">
                                {formatText(casee.resolutionCodeText)}
                              </Caption>
                            </GridItem>
                            <GridItem>
                              <Caption above="Resolution Date">
                                {formatText(casee?.resolutionDate, 'date')}
                              </Caption>
                            </GridItem>
                          </React.Fragment>
                        )}
                        <GridItem>
                          <Caption above="Attachments">
                            {isDocumentsAvailable}
                          </Caption>
                        </GridItem>
                      </React.Fragment>
                    )}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </Table.Data>
    </Table.Row>
  )
}
