import _ from 'lodash'
import { defaultValues } from './ConstantData'

function deriveResoutionNotes(
  resolutionNoteMappingValues: Array<any>
): Array<any> {
  const values = resolutionNoteMappingValues?.map((resolutionNotes: any) => ({
    id: resolutionNotes.resolutionNoteId,
    value: resolutionNotes.resolutionNoteId,
    label: resolutionNotes.resolutionNoteText,
  }))
  return values
}

export function findResolutionNotesValues(
  resolutionNoteMappingValues: Array<any> | undefined,
  formikValues: any,
  resCode: any
): any {
  let resolutionNoteMappingValuesList = _.find(
    resolutionNoteMappingValues,
    function (resolutionNoteCodes) {
      return (
        resolutionNoteCodes.caseType === formikValues.caseType &&
        resolutionNoteCodes.caseSubType === formikValues.caseSubType &&
        Number(resolutionNoteCodes.documentTypeId) ===
          Number(formikValues.documentTypeId) &&
        Number(resolutionNoteCodes.documentSubTypeId) ===
          Number(formikValues.documentSubTypeId) &&
        resolutionNoteCodes.reasonCodeId === formikValues.reasonCode &&
        Number(resolutionNoteCodes.resolutionCodeId) === Number(resCode)
      )
    }
  )
  if (resolutionNoteMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionNotes(
      resolutionNoteMappingValuesList?.resolutionNoteList
    )
    return mappingValues
  }
  resolutionNoteMappingValuesList = _.find(
    resolutionNoteMappingValues,
    function (resolutionNoteCodes) {
      return (
        resolutionNoteCodes.caseType === formikValues.caseType &&
        resolutionNoteCodes.caseSubType === formikValues.caseSubType &&
        Number(resolutionNoteCodes.documentTypeId) ===
          Number(formikValues.documentTypeId) &&
        Number(resolutionNoteCodes.documentSubTypeId) ===
          Number(formikValues.documentSubTypeId) &&
        resolutionNoteCodes.reasonCodeId === formikValues.reasonCode &&
        resolutionNoteCodes.resolutionCodeId === null
      )
    }
  )
  if (resolutionNoteMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionNotes(
      resolutionNoteMappingValuesList?.resolutionNoteList
    )
    return mappingValues
  }
  resolutionNoteMappingValuesList = _.find(
    resolutionNoteMappingValues,
    function (resolutionNoteCodes) {
      return (
        resolutionNoteCodes.caseType === formikValues.caseType &&
        resolutionNoteCodes.caseSubType === formikValues.caseSubType &&
        Number(resolutionNoteCodes.documentTypeId) ===
          Number(formikValues.documentTypeId) &&
        Number(resolutionNoteCodes.documentSubTypeId) ===
          Number(formikValues.documentSubTypeId) &&
        resolutionNoteCodes.reasonCodeId === null &&
        resolutionNoteCodes.resolutionCodeId === null
      )
    }
  )
  if (resolutionNoteMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionNotes(
      resolutionNoteMappingValuesList?.resolutionNoteList
    )
    return mappingValues
  }
  resolutionNoteMappingValuesList = _.find(
    resolutionNoteMappingValues,
    function (resolutionNoteCodes) {
      return (
        resolutionNoteCodes.caseType === formikValues.caseType &&
        resolutionNoteCodes.caseSubType === formikValues.caseSubType &&
        Number(resolutionNoteCodes.documentTypeId) ===
          Number(formikValues.documentTypeId) &&
        resolutionNoteCodes.documentSubTypeId === null &&
        resolutionNoteCodes.reasonCodeId === null &&
        resolutionNoteCodes.resolutionCodeId === null
      )
    }
  )
  if (resolutionNoteMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionNotes(
      resolutionNoteMappingValuesList?.resolutionNoteList
    )
    return mappingValues
  }

  resolutionNoteMappingValuesList = _.find(
    resolutionNoteMappingValues,
    function (resolutionNoteCodes) {
      return (
        resolutionNoteCodes.caseType === formikValues.caseType &&
        resolutionNoteCodes.caseSubType === formikValues.caseSubType &&
        resolutionNoteCodes.documentTypeId === null &&
        resolutionNoteCodes.documentSubTypeId === null &&
        resolutionNoteCodes.reasonCodeId === null &&
        resolutionNoteCodes.resolutionCodeId === null
      )
    }
  )
  if (resolutionNoteMappingValuesList !== undefined) {
    const mappingValues = deriveResoutionNotes(
      resolutionNoteMappingValuesList?.resolutionNoteList
    )
    return mappingValues
  } else return defaultValues
}
