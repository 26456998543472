export function twoDecimals(val: String): String {
  if (val.indexOf('.') > -1 && val.split('.')[1].length > 2) {
    const decimal = val.split('.')[1].substring(0, 2)
    val = val.split('.')[0] + '.' + decimal
  }
  return val
}

export function getNum(val: any) {
  if (isNaN(val) || val === '' || val === null || val === undefined) {
    return 0
  }
  return parseFloat(val)
}

export function getCurrency(val: any) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(val))
}
