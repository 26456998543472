import {
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import {
  GET_RESOLUTIONCODES,
  ResolutionCodes,
} from '../../Cases/Common/Queries'
import {
  CreateResolutionCodeVar,
  CREATE_RESOLUTIONCODE,
} from '../common/AdminQueries'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'

export interface ResolutionCodeForm {
  resolutionCodeText?: string
  active?: boolean
}

const schema = Yup.object().shape<ResolutionCodeForm>({
  resolutionCodeText: Yup.string()
    .required('Enter Resolution Code Id')
    .min(5, 'Length shoud be minimum 5 Chars')
    .max(100, 'Length should be max 100 Chars')
    .test(
      'Fine',
      'Resolution code text already exists. Please enter unique Resolution code text',
      function (value) {
        const context = this.options.context as any[]

        return !context.some(
          (reason: any) => reason.resolutionCodeText === value
        )
      }
    ),
})

const initialValues: ResolutionCodeForm = {
  resolutionCodeText: undefined,
  active: true,
}

export const AddResolutionCode = (props: any) => {
  const formik = useFormik<ResolutionCodeForm>({
    initialValues: { ...initialValues },
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema<ResolutionCodeForm>(
          values,
          validationSchema,
          true,
          props.resolutionCodes
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      await updateResolutionCode({
        variables: {
          input: {
            resolutionCodeText: values.resolutionCodeText!!,
            active: values.active,
          },
        },
        refetchQueries: [
          {
            query: GET_RESOLUTIONCODES,
          },
        ],
        awaitRefetchQueries: true,
      }).then(() => props.handleClose())
    },
  })

  const [updateResolutionCode, { loading }] = useMutation<
    ResolutionCodes,
    CreateResolutionCodeVar
  >(CREATE_RESOLUTIONCODE, {
    onCompleted: (data) => {
      console.log(data)
    },
  })

  return (
    <Modal
      headingText="Create Resolution Code"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible
    >
      <Form onSubmit={formik.handleSubmit}>
        <Grid.Container justify={'center'} className="hc-pa-normal">
          <Grid.Item xs={11}>
            <Grid.Container>
              <Grid.Item xs={6}>
                <Form.Field
                  id={'resolutionCodeText'}
                  label={'Resolution Code Text'}
                  type="text"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.resolutionCodeText}
                  error={
                    formik.touched.resolutionCodeText &&
                    formik.errors.resolutionCodeText !== undefined
                  }
                  errorText={formik.errors.resolutionCodeText}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <GridItem xs={11}>
            <GridContainer align="center">
              <GridItem xs={3}>
                <Form.Field
                  id="status"
                  type="toggle"
                  label={'Active'}
                  value={formik.values.active}
                  onChange={() => {
                    formik.setFieldValue('active', !formik.values.active)
                  }}
                ></Form.Field>
              </GridItem>
              <GridItem justify="flex-end" xs={9}>
                <GridContainer direction="row-reverse" spacing="dense">
                  <GridItem>
                    <Button type="submit" disabled={loading}>
                      Save
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => {
                        props.handleClose()
                      }}
                    >
                      Cancel
                    </Button>
                  </GridItem>

                  {loading && (
                    <GridItem>
                      <Spinner></Spinner>
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </Grid.Container>
      </Form>
    </Modal>
  )
}
