import * as qs from 'qs'
import _ from 'lodash'

function buildReportUrl(
  history: any,
  id: any,
  values: any,
  type: string
): string {
  let queryObject: any = qs.parse(history.search.replace('?', ''))
  if (type === 'checkboxes') {
    const filters: any[] = []
    if (filters.length === _.pull(filters, values).length) {
      values.forEach((value: any) => {
        filters.push(value)
      })
    }
    queryObject[id] = [...filters]
  } else {
    queryObject[id] = values
  }
  return qs.stringify(queryObject)
}

function sortByMonthYear(a: any, b: any) {
  var as = a.rootType.split(','),
    bs = b.rootType.split(','),
    ad = new Date(as[0] + ' 1,' + as[1]),
    bd = new Date(bs[0] + ' 1,' + bs[1])
  return ad.getTime() - bd.getTime()
}

function convertToCurrency(amount: string | number | undefined) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(amount ?? 0))
}

export { buildReportUrl, sortByMonthYear, convertToCurrency }
