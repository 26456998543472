import React from 'react'
import { Card, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { TargetLogo } from '@enterprise-ui/canvas-ui-react'
import classnames from 'classnames'

interface BaseLayoutProps {
  centerAlign?: Boolean
  children: React.ReactNode
}

const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  const finalClasses = classnames(
    'hc-pt-none',
    'hc-pa-normal',
    {
      'hc-ta-center': props.centerAlign,
    },
    'hc-bg-white'
  )

  return (
    <Grid.Container
      className="praxcss-height100vh"
      align="center"
      justify="center"
    >
      <Grid.Item xs={10} md={6} lg={4}>
        <Card>
          <div className="hc-pa-normal hc-ta-center">
            <Heading className="hc-ws-no-wrap">
              <TargetLogo size="inline" /> S Y N E R G Y
            </Heading>
          </div>
          <div className={finalClasses}>{props.children}</div>
        </Card>
      </Grid.Item>
    </Grid.Container>
  )
}

export default BaseLayout
