import React, { useState, useEffect } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useApolloClient } from '@apollo/client'
import {
  GetCaseSuggestion,
  SuggestionRequest,
  GET_CASE_SUGGESTION,
} from '../SearchQueries'
import { debounce, uniqBy } from 'lodash'

interface FilterSerchProps {
  id: string
  label: string
  placeHolder: string
  suggestField?: string
  onUpdate: any
}

export const FilterSearch: React.FC<FilterSerchProps> = ({
  id,
  label,
  suggestField,
  onUpdate,
  placeHolder,
}) => {
  const client = useApolloClient()

  const [searchTerm, setSearchTerm] = useState('')
  const [options, setOptions] = useState<any[]>([])

  const setInputSearchTerm = debounce((input) => setSearchTerm(input), 250)

  useEffect(() => {
    const getValidOptions = async () => {
      const validOptions = await handleSuggestionOptions(searchTerm)
      setOptions(validOptions)
    }

    getValidOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  const handleSuggestionOptions = async (searchText: string) => {
    if (searchText === '') {
      return []
    }

    const result = []
    result.push({
      id: searchText,
      value: searchText,
      label: searchText,
    })

    if (suggestField === undefined) {
      return result
    }

    try {
      const { data } = await client.query<
        GetCaseSuggestion,
        { input: SuggestionRequest }
      >({
        query: GET_CASE_SUGGESTION,
        variables: {
          input: {
            limit: 10,
            query: searchText,
            fieldName: suggestField,
          },
        },
      })

      if (data.getCaseSuggestion !== null) {
        data.getCaseSuggestion.forEach((suggestion) => {
          result.push({
            id: suggestion.suggestion,
            value: suggestion.suggestion,
            label: suggestion.suggestion,
          })
        })
      }
    } catch (_: any) {
      console.warn('Suggest Failed')
    }

    return uniqBy(result, 'id')
  }

  const handleSearchSuggestion = (id: string, value: any) => {
    if (value) {
      onUpdate(id, value)
    }
  }

  return (
    <div style={{ zIndex: 100 }}>
      <Autocomplete
        id={id}
        options={(searchText?: string) => {
          setInputSearchTerm(searchText)
          return options
        }}
        label={label}
        onUpdate={(id, value) => {
          if (value?.isNew === true) {
            let option = {
              id: value.value,
              label: value.label,
              value: value.value,
            }
            handleSearchSuggestion(id, option)
          } else {
            handleSearchSuggestion(id, value)
          }
        }}
        placeholder={placeHolder}
        showSearchIcon
        retainSearchText
      />
    </div>
  )
}
