import React from 'react'
import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { NewCaseRequest } from './Queries'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CASE_SUMMARY_HEADING, NO_PAYMENT_DETAILS } from './Constants'
import { Section, SectionItem, SummaryHeading } from './Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'

import { useGlobalForm } from '../Context/GlobalFormStateContext'

const container = css`
  height: 30px;
  overflow-x: hidden;
  overflow-y: auto;
`

interface MainProp {
  caseSummaryDetails: NewCaseRequest
  isVendor: any
  docTypeReqFlag: any
  importVendor?: boolean
}

const RequestSummary: React.FC<MainProp> = ({
  caseSummaryDetails,
  isVendor,
  docTypeReqFlag,
  importVendor,
}) => {
  const [state] = useGlobalForm()
  const isDeliveryReceipt =
    caseSummaryDetails?.caseSubType === 'Delivery Receipt' ? true : false
  return (
    <React.Fragment>
      {state?.isVendShowable && !isVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={CASE_SUMMARY_HEADING} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Vendor Id">
              {caseSummaryDetails?.vendorNumber}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails?.vendorName}
            </SectionItem>
            <SectionItem label="Type">
              {caseSummaryDetails?.vendorType}
            </SectionItem>
            <SectionItem label="Contact">
              {caseSummaryDetails?.vendorContactName}
            </SectionItem>
            <SectionItem label="Email">
              {caseSummaryDetails?.vendorEmailAddress}
            </SectionItem>
          </Section>
        </div>
      )}
      {isVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={CASE_SUMMARY_HEADING} />
          <Section labelGridSize={4} detail={false}>
            <SectionItem label="Vendor ID">
              {caseSummaryDetails.vendorNumber}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails.vendorName}
            </SectionItem>
            <SectionItem label="Type">
              {caseSummaryDetails.vendorType}
            </SectionItem>
            <SectionItem label="Contact">
              {caseSummaryDetails.vendorContactName}
            </SectionItem>
            <SectionItem label="Email">
              {caseSummaryDetails.vendorEmailAddress}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.showDocuments && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Request Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Document #">
              {caseSummaryDetails.originalDocumentNumber}
            </SectionItem>
            <SectionItem label="Case Sub Type">
              {caseSummaryDetails.caseSubType}
            </SectionItem>
            {(docTypeReqFlag === 'Y' || !isVendor) && (
              <SectionItem label="Document Type">
                {caseSummaryDetails.documentTypeText}
              </SectionItem>
            )}
            {!isVendor && !importVendor && (
              <SectionItem label="Document Sub Type">
                {caseSummaryDetails.documentSubTypeText}
              </SectionItem>
            )}
            <SectionItem label="Reason">
              {caseSummaryDetails.reasonCodeText}
            </SectionItem>
            <Grid.Container>
              <Grid.Item xs={12} className="hc-pt-dense hc-pb-dense">
                <Input.Label className="hc-clr-grey02 hc-fs-xs">
                  Case Description :
                </Input.Label>
                <Input.Label className="hc-clr-black hc-fs-xs" css={container}>
                  {caseSummaryDetails.caseDescription}
                </Input.Label>
              </Grid.Item>
            </Grid.Container>
          </Section>
        </div>
      )}
      {state.isDocShowable && state.disputeAmountCheck && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Document Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Purchased order #">
              {caseSummaryDetails.purchaseOrderNumber}
            </SectionItem>
            <SectionItem label="Department">
              {caseSummaryDetails.departmentId}
            </SectionItem>
            <SectionItem label="Location">
              {caseSummaryDetails.locationId}
            </SectionItem>
            <SectionItem label="Document date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.originalDocumentCreateDate}
                format="L"
              />
            </SectionItem>
            <SectionItem
              label={
                isDeliveryReceipt
                  ? 'Receipt ID / Appointment Number'
                  : 'Receipt ID'
              }
            >
              {caseSummaryDetails.receipts?.join(',')}
            </SectionItem>
            <SectionItem label="Receipt /Shipment Date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.requestDetails?.shipmentDate}
                format="L"
              />
            </SectionItem>
            {!isDeliveryReceipt && (
              <React.Fragment>
                <SectionItem size={4} label="Carrier Name">
                  {caseSummaryDetails.requestDetails?.carrierVendorName}
                </SectionItem>
                <SectionItem label="Email">
                  {caseSummaryDetails.requestDetails?.carrierVendorContactEmail}
                </SectionItem>
                <SectionItem label="Fax #">
                  {caseSummaryDetails.requestDetails?.faxNumber}
                </SectionItem>
                <SectionItem label="Proof Of Delivery">
                  {caseSummaryDetails.requestDetails?.proofOfDelivery}
                </SectionItem>
                <SectionItem label="Proof Of Shipment">
                  {caseSummaryDetails.requestDetails?.proofOfShipment}
                </SectionItem>
              </React.Fragment>
            )}
          </Section>
        </div>
      )}
      {state.showDocuments && state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Payment document amount">
              {caseSummaryDetails?.originalCheckAmount}
            </SectionItem>
            <SectionItem label="Payment invoice">
              {caseSummaryDetails.originalCheckNumber}
            </SectionItem>
            <SectionItem label="Payment date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.originalCheckDate}
                format="L"
              />
            </SectionItem>
          </Section>
        </div>
      )}
      {state.showDocuments && !state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Detail'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem label="Payment Details">
              {NO_PAYMENT_DETAILS}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && !isVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Workflow Details'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem label="Workflow">
              {caseSummaryDetails.workflow}
            </SectionItem>
            <SectionItem label="Assigned User">{state.name}</SectionItem>
          </Section>
        </div>
      )}
    </React.Fragment>
  )
}

export default RequestSummary
