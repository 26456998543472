import React from 'react'
import { AdminState } from '../common/AdminContext'
import { useAdminProps } from '../common/AdminProvider'
import {
  Button,
  Divider,
  GridContainer,
  GridItem,
  Heading,
  Layout,
  Spinner,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { useQuery } from '@apollo/client'
import {
  GetResolutionCodes,
  GET_RESOLUTIONCODEMAPS,
  GET_RESOLUTIONCODES,
  ResolutionCodeMapResponse,
} from '../../Cases/Common/Queries'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import PopupCellRenderers from './PopupCellRenderers'
import { ColDef } from 'ag-grid-community'
import ReportTable from '../../Reports/ReportTable'
import { EditForm } from './EditResolutionCodeMap'
import { EditResolutionCodeForm } from './EditResolutionCode'
import { AddResolutionCode } from './AddResolutionCode'
import { AddResolutionCodeMap } from './AddResolutionCodeMap'
import {
  CancelIcon,
  CheckIcon,
  EnterpriseIcon,
  PencilIcon,
} from '@enterprise-ui/icons'

const baseLayout = css`
  height: 80vh;
  overflow: hidden;
`

export interface ResolutionCode {
  resolutionCodeId: string
  resolutionCodeText: string
}

export const resolutionCodesInitailState: AdminState = {
  activeTab: 'tab_01',
  tabs: [
    {
      id: 'tab_01',
      tabName: 'Resolution Code Mapping',
      editFormVisible: false,
      createFormVisible: false,
      editableRow: null,
    },
    {
      id: 'tab_02',
      tabName: 'Resolution Codes',
      editFormVisible: false,
      createFormVisible: false,
      editableRow: null,
    },
  ],
}

export const ResolutionCodesAdmin: React.FC = () => {
  const [state, dispatch] = useAdminProps()

  const { data: resolutionCodeMapData, loading: resolutionCodeMapLoading } =
    useQuery<ResolutionCodeMapResponse>(GET_RESOLUTIONCODEMAPS)

  const { data: resolutionCodeData, loading: resolutionCodeLoading } =
    useQuery<GetResolutionCodes>(GET_RESOLUTIONCODES, {})

  const handleEditClose = (tabId: string) => {
    dispatch({ type: 'HIDE_EDIT_FORM', tabId: tabId })
  }

  const handleCreateClose = (tabId: string) => {
    dispatch({ type: 'HIDE_CREATE_FORM', tabId: tabId })
  }

  const resolutionCodeMapColumns: ColDef[] = [
    {
      headerName: 'Action',
      pinned: 'left',
      cellStyle: {
        textAlign: 'center',
      },
      maxWidth: 100,
      cellRenderer: (props: any) => {
        return (
          <Button
            iconOnly
            aria-label="Edit"
            onClick={() => {
              dispatch({
                type: 'SET_EDITABLE_ROW',
                row: props.data,
                tabId: 'tab_01',
              })
              dispatch({
                type: 'SHOW_EDIT_FORM',
                tabId: 'tab_01',
              })
            }}
          >
            <EnterpriseIcon icon={PencilIcon}></EnterpriseIcon>
          </Button>
        )
      },
    },

    { field: 'caseType', headerName: 'Case Type', sortable: true },
    { field: 'caseSubType', headerName: 'Case Sub Type', sortable: true },
    { field: 'documentTypeText', headerName: 'Document Type', sortable: true },
    {
      field: 'documentSubTypeText',
      headerName: 'Document Sub Type',
      sortable: true,
    },
    {
      field: 'active',
      headerName: 'Active',
      sortable: true,
      maxWidth: 100,
      cellRenderer: (props: any) => {
        if (props.value === true) {
          return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
        }
        return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
      },
    },
    {
      field: 'resolutionCodeList',
      headerName: 'Resolution Code',
      cellRenderer: PopupCellRenderers,
      minWidth: 500,
      refData: { key: 'hello' },
    },
  ]

  const resolutionCodeColumn: ColDef[] = [
    {
      headerName: 'Action',
      pinned: 'left',
      cellStyle: {
        textAlign: 'center',
      },
      maxWidth: 100,
      cellRenderer: (props: any) => {
        return (
          <Button
            iconOnly
            aria-label="Edit"
            onClick={() => {
              dispatch({
                type: 'SET_EDITABLE_ROW',
                row: props.data,
                tabId: 'tab_02',
              })
              dispatch({
                type: 'SHOW_EDIT_FORM',
                tabId: 'tab_02',
              })
            }}
          >
            <EnterpriseIcon icon={PencilIcon}></EnterpriseIcon>
          </Button>
        )
      },
    },
    {
      field: 'resolutionCodeId',
      headerName: 'Resolution Code Id',
      sortable: true,
    },
    {
      field: 'resolutionCodeText',
      headerName: 'Resolution Code Text',
      sortable: true,
      minWidth: 300,
    },
    {
      field: 'active',
      headerName: 'Active',
      sortable: true,
      maxWidth: 100,
      cellRenderer: (props: any) => {
        if (props.value === true) {
          return <EnterpriseIcon icon={CheckIcon} color="#32864e" size="lg" />
        }
        return <EnterpriseIcon icon={CancelIcon} color="#ae570f" size="lg" />
      },
    },
  ]

  const getTabData = (tabId: string): [ColDef[], boolean, any] => {
    if (tabId === 'tab_01') {
      return [
        resolutionCodeMapColumns,
        resolutionCodeMapLoading,
        resolutionCodeMapData?.getResolutionCodesMap ?? [],
      ]
    }
    return [
      resolutionCodeColumn,
      resolutionCodeLoading,
      resolutionCodeData?.getResolutionCodes ?? [],
    ]
  }

  return (
    <>
      <Layout.SectionHeader>
        <GridContainer justify="space-between">
          <GridItem>
            <Heading size={2}>Manage Resolution Codes</Heading>
          </GridItem>
          <GridItem>
            <GridContainer>
              <GridItem>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch({ type: 'SHOW_CREATE_FORM', tabId: 'tab_01' })
                  }}
                >
                  Add Resolution Code Mapping
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch({ type: 'SHOW_CREATE_FORM', tabId: 'tab_02' })
                  }}
                >
                  Add Resolution Codes
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Layout.SectionHeader>
      <Layout.Body includeRail>
        <Tabs
          activeTab={state.activeTab}
          onTabSelect={(event: any, tab: any) => {
            dispatch({ type: 'SET_ACTIVE_TAB', tab: tab.name })
          }}
        >
          {state.tabs.map((tab) => (
            <Tabs.Item name={tab.id} key={tab.id}>
              {tab.tabName}
            </Tabs.Item>
          ))}
          <Divider />
          {state.tabs.map((tab) => {
            const [columns, loading, data] = getTabData(tab.id)
            return (
              <Tabs.Content name={tab.id} key={tab.id} className="hc-ph-none">
                {!loading ? (
                  <ReportTable data={data ?? []} columns={columns} />
                ) : (
                  <GridContainer
                    justify="center"
                    align="center"
                    css={baseLayout}
                  >
                    <GridItem>
                      <Spinner />
                    </GridItem>
                  </GridContainer>
                )}
              </Tabs.Content>
            )
          })}
        </Tabs>
        <EditForm
          handleClose={() => handleEditClose('tab_01')}
          formVisible={state.tabs[0].editFormVisible}
          resolutionCodes={resolutionCodeData?.getResolutionCodes}
          editableRow={state.tabs[0].editableRow}
        />
        {state.tabs[1] && (
          <EditResolutionCodeForm
            handleClose={() => handleEditClose('tab_02')}
            formVisible={state.tabs[1].editFormVisible}
            editableRow={state.tabs[1].editableRow}
          />
        )}
        {state.tabs[1] && state.tabs[1].createFormVisible && (
          <AddResolutionCode
            handleClose={() => handleCreateClose('tab_02')}
            resolutionCodes={resolutionCodeData?.getResolutionCodes}
          />
        )}
        {state.tabs[0].createFormVisible && (
          <AddResolutionCodeMap
            handleClose={() => handleCreateClose('tab_01')}
            resolutionCodes={resolutionCodeData?.getResolutionCodes}
          />
        )}
      </Layout.Body>
    </>
  )
}
