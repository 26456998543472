import React from 'react'
import { CaseResponse } from '../../Common/Queries'
import { REFERENCE_HEADING } from '../../Common/Constants'
import { Section, SectionItem } from '../../Common/Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { BottomBorderDiv } from '../../Common/SynergyDivComponent'

interface MainProp {
  referenceDetails: CaseResponse | undefined
}

const EditReferenceDetails: React.FC<MainProp> = ({ referenceDetails }) => {
  const reference = referenceDetails?.claimDetails
  return (
    <BottomBorderDiv>
      <Section labelGridSize={4} detail heading={REFERENCE_HEADING} expandable>
        <SectionItem label="Reference Document Number">
          {reference?.referenceDocumentNumber}
        </SectionItem>
        <SectionItem label="Reference Document Create Date">
          <DateFormatter
            //@ts-ignore
            date={reference?.referenceDocumentCreateDate}
            format="L"
          />
        </SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}

export default EditReferenceDetails
