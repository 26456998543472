import { useEffect, useReducer, useCallback } from 'react'
import {
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Modal,
  Spinner,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { ResolutionCode } from './ResolutionCodesAdmin'
import {
  GET_RESOLUTIONCODEMAPS,
  ResolutionCodeMapResponse,
  ResolutionCodeMaps,
} from '../../Cases/Common/Queries'
import {
  UpdateUpdateResolutionCodeMapVar,
  UPDATE_RESOLUTIONCODE_MAP,
} from '../common/AdminQueries'
import { useMutation } from '@apollo/client'
import DragNDropList from '../common/DragNDropList'

export interface InitialResolutioncodes {
  all_resolution: {
    title: string
    items: ResolutionCode[]
  }
  selected_resolutions: {
    title: string
    items: ResolutionCode[]
  }
}

export interface ResolutionCodeMapState {
  initialResolutioncodes: InitialResolutioncodes
  updatedResolutionCodes: InitialResolutioncodes
  active: boolean
}

const initialState: ResolutionCodeMapState = {
  initialResolutioncodes: {
    all_resolution: {
      title: 'All Resolution Codes',
      items: [],
    },
    selected_resolutions: {
      title: 'Selected Resolution Codes',
      items: [],
    },
  },
  updatedResolutionCodes: {
    all_resolution: {
      title: 'All Resolution Codes',
      items: [],
    },
    selected_resolutions: {
      title: 'Selected Resolution Codes',
      items: [],
    },
  },
  active: false,
}

type Action =
  | {
      type: 'SET_INITIAL_RESOLUTION_CODES'
      payload: {
        initialResolutionCodes: {
          all_resolution: {
            title: string
            items: ResolutionCode[]
          }
          selected_resolutions: {
            title: string
            items: ResolutionCode[]
          }
        }
        active: boolean
      }
    }
  | { type: 'SET_UPDATED_RESOLUTION_CODES'; payload: InitialResolutioncodes }
  | { type: 'SET_ACTIVE_STATUS'; payload: boolean }

const resolutionCodeMapReducer = (
  state: ResolutionCodeMapState,
  action: Action
): ResolutionCodeMapState => {
  switch (action.type) {
    case 'SET_INITIAL_RESOLUTION_CODES':
      return {
        ...state,
        initialResolutioncodes: { ...action.payload.initialResolutionCodes },
        updatedResolutionCodes: { ...action.payload.initialResolutionCodes },
        active: action.payload.active,
      }
    case 'SET_UPDATED_RESOLUTION_CODES':
      return { ...state, updatedResolutionCodes: action.payload }
    case 'SET_ACTIVE_STATUS':
      return { ...state, active: action.payload }
    default:
      return state
  }
}

export const EditForm = (props: any) => {
  const [state, dispatch] = useReducer(resolutionCodeMapReducer, initialState)

  useEffect(() => {
    const selectedResolutionCodes = (
      props.editableRow?.resolutionCodeList || []
    ).map(
      (resolutionCode: any) =>
        ({
          resolutionCodeId: resolutionCode.resolutionCodeId.toString(),
          resolutionCodeText: resolutionCode.resolutionCodeText,
        } as ResolutionCode)
    )

    const resolutionCodes = (props.resolutionCodes || [])
      .filter((resolutionCode: any) => resolutionCode.active)
      .filter(
        (resolutionCode: any) =>
          !selectedResolutionCodes.some(
            (selected: any) =>
              selected.resolutionCodeId === resolutionCode.resolutionCodeId
          )
      )
      .map(
        (resolutionCode: any) =>
          ({
            resolutionCodeId: resolutionCode.resolutionCodeId.toString(),
            resolutionCodeText: resolutionCode.resolutionCodeText,
          } as ResolutionCode)
      )

    dispatch({
      type: 'SET_INITIAL_RESOLUTION_CODES',
      payload: {
        initialResolutionCodes: {
          all_resolution: {
            title: 'All Resolution Codes',
            items: [...resolutionCodes],
          },
          selected_resolutions: {
            title: 'Selected Resolution Codes',
            items: [...selectedResolutionCodes],
          },
        },
        active: props.editableRow?.active,
      },
    })
  }, [props.resolutionCodes, props.editableRow, props.formVisible])

  const [updateResolutionCodeMap, { loading }] = useMutation<
    ResolutionCodeMapResponse,
    UpdateUpdateResolutionCodeMapVar
  >(UPDATE_RESOLUTIONCODE_MAP)

  const handleSave = async (
    row: ResolutionCodeMaps,
    selectedResolutionCodes: any[],
    active: any
  ) => {
    await updateResolutionCodeMap({
      variables: {
        id: row.resolutionMapId,
        input: {
          caseType: row.caseType,
          caseSubType: row.caseSubType,
          documentTypeId: row.documentTypeId,
          documentTypeText: row.documentTypeText,
          documentSubTypeId: row.documentSubTypeId,
          documentSubTypeText: row.documentSubTypeText,
          active: active,
          //resolutionCodeList: [...selectedResolutionCodes],
          resolutionCodeList: selectedResolutionCodes.map((reslnCode) => ({
            resolutionCodeId: Number(reslnCode.resolutionCodeId.toString()),
            resolutionCodeText: reslnCode.resolutionCodeText,
          })),
        },
      },
      refetchQueries: [
        {
          query: GET_RESOLUTIONCODEMAPS,
        },
      ],
      awaitRefetchQueries: true,
    }).then(() => props.handleClose())
  }

  const handleListChange = useCallback((updatedColumns: any) => {
    dispatch({ type: 'SET_UPDATED_RESOLUTION_CODES', payload: updatedColumns })
  }, [])

  return (
    <Modal
      headingText="Edit Resolution Code Mapping"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible={props.formVisible}
    >
      <Grid.Container justify={'center'} className="hc-pa-normal">
        <Grid.Item xs={11}>
          <Grid.Container>
            <Grid.Item xs={6}>
              <Input.Label>Case Type</Input.Label>
              <Input.Text
                id={'caseType'}
                value={props?.editableRow?.caseType}
                type="select"
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={6}>
              <Input.Label>Case Sub Type</Input.Label>
              <Input.Text
                id={'caseSubType'}
                value={props?.editableRow?.caseSubType}
                type="select"
                disabled
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Input.Label>Document Type</Input.Label>
              <Input.Text
                id={'documetType'}
                value={props?.editableRow?.documentTypeText ?? ''}
                type="select"
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={6}>
              <Input.Label>Document Sub Type</Input.Label>
              <Input.Text
                id={'documentSubType'}
                value={props?.editableRow?.documentSubTypeText ?? ''}
                type="select"
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={12}>
              <div>
                <DragNDropList
                  initialColumns={state.initialResolutioncodes}
                  onChange={handleListChange}
                />
              </div>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <GridItem xs={11}>
          <GridContainer align="center">
            <GridItem xs={3}>
              <Form.Field
                id={'status'}
                type="toggle"
                label={'Active'}
                value={state.active}
                onChange={(_: any) => {
                  dispatch({
                    type: 'SET_ACTIVE_STATUS',
                    payload: !state.active,
                  })
                }}
              ></Form.Field>
            </GridItem>
            <GridItem justify="flex-end" xs={9}>
              <GridContainer direction="row-reverse" spacing="dense">
                <GridItem>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSave(
                        props.editableRow,
                        state.updatedResolutionCodes.selected_resolutions.items,
                        state.active
                      )
                    }}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    onClick={() => {
                      props.handleClose()
                    }}
                  >
                    Cancel
                  </Button>
                </GridItem>

                {loading && (
                  <GridItem>
                    <Spinner></Spinner>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </Grid.Container>
    </Modal>
  )
}
