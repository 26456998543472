import { gql } from '@apollo/client'

export const GET_VENDORS_BY_BP = gql`
  query GetVendors($businessPartnerId: String!) {
    getVendorsByBusinessPartner(id: $businessPartnerId) {
      gmsVendorNumber
      vendorName
    }
  }
`
export interface GetVendors {
  getVendorsByBusinessPartner: [Vendor]
}

export interface Vendor {
  gmsVendorNumber: string
  vendorName: string
}
