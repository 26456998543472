import React, { useState } from 'react'
import {
  Grid,
  Heading,
  Form,
  Caption,
  ExpandableSection,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { FormikValues } from 'formik'
import { DOCUMENT_HEADING, SET_FIELD } from './Constants'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import _ from 'lodash'
import { twoDecimals } from '../Services/TwoDecimalPlaces'
import { useApolloClient } from '@apollo/client'
import {
  GetValidDepartment,
  DepartmentRequest,
  GET_DEPARTMENT,
  GetValidDepartmentClass,
  DepartmentClassRequest,
  GET_DEPARTMENT_CLASS,
} from './Queries'
import { CheckCircleIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface MainProp {
  formik: FormikValues
  importVendor?: boolean
}

const DocumentDetails: React.FC<MainProp> = ({ formik, importVendor }) => {
  const formData = formik?.values
  const documentAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(formData.originalDocumentAmount))
  const [state, dispatch] = useGlobalForm()
  const PORequired = state.isPORequired ? true : false
  function validateFields(errors: any, fields: string[]): boolean {
    const keys = _.keysIn(errors)
    return !fields.some((el) => keys.includes(el))
  }
  const client = useApolloClient()
  const [userReceipts, setUserReceipts] = useState('')

  const onDepartmentChange = async (departmentId: number) => {
    if (Number(departmentId) !== 0 && departmentId < 1000) {
      try {
        const { data } = await client.query<
          GetValidDepartment,
          DepartmentRequest
        >({
          query: GET_DEPARTMENT,
          variables: { departmentId: departmentId },
          fetchPolicy: 'network-only',
        })
        const getDepartmentDetails = data?.getValidDepartment
        if (getDepartmentDetails !== null) {
          await dispatch({
            type: SET_FIELD,
            payload: [{ id: 'departmentCheck', value: true }],
          })

          _.delay(() => {
            formik.validateForm().then((res: any) => {
              const fields = ['departmentId']
              if (validateFields(res, fields)) {
                formik.setErrors({ res })
              } else {
                formik.setErrors({ departmentId: res.departmentId })
              }
            })
          }, 0)
        }
      } catch (errors) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'departmentCheck', value: false }],
        })
        _.delay(
          () =>
            formik.validateForm().then((res: any) => {
              const fields = ['departmentId']
              if (validateFields(res, fields)) {
                formik.setErrors({ res })
              } else {
                formik.setErrors({ departmentId: res.departmentId })
              }
            }),
          0
        )
      }
    } else if (
      (Number(departmentId) === 0 && importVendor) ||
      departmentId >= 1000
    ) {
      await dispatch({
        type: SET_FIELD,
        payload: [{ id: 'departmentCheck', value: true }],
      })
      _.delay(
        () =>
          formik.validateForm().then((res: any) => {
            const fields = ['departmentId']
            if (validateFields(res, fields)) {
              formik.setErrors({ res })
            } else {
              formik.setErrors({ departmentId: res.departmentId })
            }
          }),
        0
      )
    } else if (Number(departmentId) === 0) {
      await dispatch({
        type: SET_FIELD,
        payload: [{ id: 'departmentCheck', value: false }],
      })
      _.delay(
        () =>
          formik.validateForm().then((res: any) => {
            const fields = ['departmentId']
            if (validateFields(res, fields)) {
              formik.setErrors({ res })
            } else {
              formik.setErrors({ departmentId: res.departmentId })
            }
          }),
        0
      )
    }
  }

  const onDepartmentClassChange = async (
    departmentId: number,
    classId: number
  ) => {
    if (departmentId !== 0 && departmentId < 1000) {
      try {
        const { data } = await client.query<
          GetValidDepartmentClass,
          DepartmentClassRequest
        >({
          query: GET_DEPARTMENT_CLASS,
          variables: { departmentId: departmentId, classId: classId },
          fetchPolicy: 'network-only',
        })
        const getDepartmentClassDetails = data?.getValidDepartmentClass
        if (getDepartmentClassDetails !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'departmentClassCheck', value: true }],
          })
          _.delay(
            () =>
              formik.validateForm().then((res: any) => {
                const fields = ['classId']
                if (validateFields(res, fields)) {
                  formik.setErrors({ res })
                } else {
                  formik.setErrors({ classId: res.classId })
                }
              }),
            0
          )
        }
      } catch (errors) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'departmentClassCheck', value: false }],
        })
        _.delay(
          () =>
            formik.validateForm().then((res: any) => {
              const fields = ['classId']
              if (validateFields(res, fields)) {
                formik.setErrors({ res })
              } else {
                formik.setErrors({ classId: res.classId })
              }
            }),
          0
        )
      }
    } else if (departmentId >= 1000 || departmentId === 0) {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'departmentClassCheck', value: true }],
      })
    }
  }

  const handleReceiptChange = (e: any) => {
    setUserReceipts(e.target.value)
    let receipts = e.target.value?.split(',')?.filter((id: any) => id !== '')
    formik.setFieldValue(
      'receipts',
      e.target.value !== '' ? receipts : undefined
    )
  }

  return (
    <div className="hc-pa-normal hc-pa-none hc-ma-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="dense">
        <Heading size={6}>
          {DOCUMENT_HEADING}{' '}
          {state.isDocShowable && (
            <EnterpriseIcon
              icon={CheckCircleIcon}
              color="green"
              className="hc-va-sub"
            />
          )}
          <Caption below="* Payment info (if applicable) is displayed on the summary section." />
        </Heading>
        <ExpandableSection.Content>
          {state.disputeSectionFlag && (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10} md={7}>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Document Amount </Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {documentAmount}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Department</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {formData?.departmentId}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Purchased order #</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {formData?.purchaseOrderNumber}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Location</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {formData?.locationId}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
                {state.receiptsSectionFlag && (
                  <Grid.Container>
                    <Grid.Item xs={5}>
                      <Input.Label>Receipts</Input.Label>
                    </Grid.Item>
                    <Grid.Item xs>
                      <Input.Label>: {state?.receipts?.join(', ')}</Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                )}
              </Grid.Item>
              <Grid.Item xs={3}>
                <Grid.Container>
                  <Grid.Item xs={12} className="hc-pb-none hc-pt-none">
                    <Form.Field
                      id="disputedDocumentAmount"
                      label="Disputed Amount $"
                      type="number"
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          'disputedDocumentAmount',
                          twoDecimals(event.target.value)
                        )
                        dispatch({
                          type: SET_FIELD,
                          payload: [
                            {
                              id: 'disputeAmountCheck',
                              value: false,
                            },
                          ],
                        })
                      }}
                      value={formData?.disputedDocumentAmount}
                      className="inputtype hc-ta-left"
                      error={formik.errors.disputedDocumentAmount !== undefined}
                      errorText={formik.errors.disputedDocumentAmount}
                      disabled={state.isDocShowable}
                      required
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item>
                    <Form.Field
                      id="disputeAmountCheck"
                      label="Dispute Full Amount"
                      type="checkbox"
                      checked={state.disputeAmountCheck}
                      onChange={(event: any) => {
                        dispatch({
                          type: SET_FIELD,
                          payload: [
                            {
                              id: 'disputeAmountCheck',
                              value: !state.disputeAmountCheck,
                            },
                          ],
                        })
                      }}
                      value={state?.disputeAmountCheck}
                      disabled={state.isDocShowable}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
          {state.disputeSectionFlag && (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10}>
                <Grid.Container>
                  <Grid.Item xs={10} md={4} className="hc-pb-none">
                    <Form.Field
                      id="classId"
                      label="Class"
                      type="number"
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          'classId',
                          event.target.value ? event.target.value : undefined
                        )
                      }}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseFloat(e.target.value))
                          .toString()
                          .slice(0, 2)
                      }}
                      onBlur={(e: any) => {
                        if (formik.values.departmentId !== undefined) {
                          onDepartmentClassChange(
                            formik.values.departmentId,
                            e.target.value
                          )
                        }
                      }}
                      value={formData?.classId ?? ''}
                      className="inputtype hc-ta-left"
                      error={formik.errors.classId !== undefined}
                      errorText={formik.errors.classId}
                      disabled={state.isDocShowable}
                    />
                  </Grid.Item>
                  {!importVendor && state.receiptsSectionFlag && (
                    <Grid.Item xs={10} md={4} className="hc-pb-none">
                      <Form.Field
                        id="receipts"
                        label="Receipt #"
                        className="inputtype hc-ta-left"
                        type="text"
                        onChange={(e: any) => handleReceiptChange(e)}
                        value={userReceipts}
                        maxLength="100"
                        disabled={state.isDocShowable}
                      />
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
          {!state.disputeSectionFlag && (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10}>
                <Grid.Container>
                  <Grid.Item xs={10} md={6} className="hc-pb-md">
                    <Form.Field
                      id="originalDocumentAmount"
                      label="Original Document Amount $"
                      type="number"
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          'originalDocumentAmount',
                          twoDecimals(e.target.value)
                        )
                      }}
                      value={formData?.originalDocumentAmount ?? ''}
                      className="inputtype hc-ta-left"
                      error={formik.errors.originalDocumentAmount !== undefined}
                      errorText={formik.errors.originalDocumentAmount}
                      disabled={state.isDocShowable}
                      required
                    />
                  </Grid.Item>
                  <Grid.Item xs={10} md={6} className="hc-pb-md">
                    <Form.Field
                      id="disputedDocumentAmount"
                      label="Disputed Amount $"
                      type="number"
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          'disputedDocumentAmount',
                          twoDecimals(e.target.value)
                        )
                      }}
                      value={formData?.disputedDocumentAmount ?? ''}
                      className="inputtype hc-ta-left"
                      error={formik.errors.disputedDocumentAmount !== undefined}
                      errorText={formik.errors.disputedDocumentAmount}
                      disabled={state.isDocShowable}
                      required
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  {state.dvsVendor ? (
                    <Grid.Item xs={10} md={6} className="hc-pb-md">
                      <Form.Field
                        id="dvsPurchaseOrderId"
                        label="Purchase Order #"
                        value={formData?.originalDocumentNumber}
                        disabled
                        className="inputtype hc-ta-left"
                      />
                    </Grid.Item>
                  ) : (
                    <Grid.Item xs={10} md={6} className="hc-pb-md">
                      <Form.Field
                        id="purchaseOrderNumber"
                        label="Purchase Order #"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'purchaseOrderNumber',
                            event.target.value ? event.target.value : undefined
                          )
                        }}
                        onInput={(e: any) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 11)
                        }}
                        value={formData?.purchaseOrderNumber ?? ''}
                        className="inputtype hc-ta-left"
                        error={formik.errors.purchaseOrderNumber !== undefined}
                        errorText={formik.errors.purchaseOrderNumber}
                        required={PORequired}
                        disabled={state.isDocShowable}
                      />
                    </Grid.Item>
                  )}
                  <Grid.Item xs={10} md={6} className="hc-pb-md">
                    <Form.Field
                      id="departmentId"
                      label="Department"
                      type="number"
                      onChange={(event: any) => {
                        formik.setFieldValue('departmentId', event.target.value)
                      }}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseFloat(e.target.value))
                          .toString()
                          .slice(0, 4)
                      }}
                      onBlur={(e: any) => {
                        onDepartmentChange(e.target.value)
                      }}
                      value={formData?.departmentId ?? ''}
                      className="inputtype hc-ta-left"
                      error={formik.errors.departmentId !== undefined}
                      errorText={formik.errors.departmentId}
                      disabled={state.isDocShowable}
                      required={!state.dvsVendor}
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item
                    xs={10}
                    md={importVendor ? 6 : 4}
                    className="hc-pb-none"
                  >
                    <Form.Field
                      id="classId"
                      label="Class"
                      type="number"
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          'classId',
                          event.target.value ? event.target.value : undefined
                        )
                      }}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseFloat(e.target.value))
                          .toString()
                          .slice(0, 2)
                      }}
                      onBlur={(e: any) => {
                        if (
                          formik.values.departmentId !== undefined &&
                          formik.values.departmentId
                        ) {
                          onDepartmentClassChange(
                            formik.values.departmentId,
                            e.target.value
                          )
                        }
                      }}
                      value={formData.classId ?? ''}
                      className="inputtype hc-ta-left"
                      error={formik.errors.classId !== undefined}
                      errorText={formik.errors.classId}
                      disabled={state.isDocShowable}
                    />
                  </Grid.Item>
                  <Grid.Item
                    xs={10}
                    md={importVendor ? 6 : 4}
                    className="hc-pb-none"
                  >
                    <Form.Field
                      id="locationId"
                      label="Location"
                      type="number"
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          'locationId',
                          event.target.value ? event.target.value : undefined
                        )
                      }}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseFloat(e.target.value))
                          .toString()
                          .slice(0, 4)
                      }}
                      value={formData?.locationId ?? ''}
                      className="inputtype hc-ta-left"
                      disabled={state.isDocShowable}
                    />
                  </Grid.Item>
                  {!importVendor && !state.receiptsSectionFlag && (
                    <Grid.Item xs={10} md={4} className="hc-pb-none">
                      <Form.Field
                        id="receipts"
                        label="Receipt #"
                        className="inputtype hc-ta-left"
                        type="text"
                        onChange={(e: any) => handleReceiptChange(e)}
                        value={userReceipts}
                        maxLength="100"
                        disabled={state.isDocShowable}
                      />
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default DocumentDetails
