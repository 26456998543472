import { gql } from '@apollo/client'

export const GET_INVOICE = gql`
  query GetInvoicePayments($vendorId: Long!, $documentId: String!) {
    getInvoiceDetails(vendorId: $vendorId, documentId: $documentId) {
      vendorId
      documentNumber
      originalDocAmount
      poId
      locId
      deptId
      caseSubType
      docType
      docSubType
      createDate
      invoiceDate
      invoiceDueDate
      payment {
        checkNumber
        checkDate
        checkAmount
      }
      receipts {
        receiptNumber
      }
    }
  }
`
export interface InvoiceRequest {
  vendorId?: number
  documentId?: string
}

export interface GetInvoicePayments {
  getInvoiceDetails: Invoice
}
export interface Invoice {
  vendorId: number
  documentNumber: string
  originalDocAmount: number
  poId: number
  locId: number
  deptId: number
  caseSubType: string
  docType: string
  docSubType: string
  createDate: string
  invoiceDate: string
  invoiceDueDate: string
  payment: Payment
  receipts: [Receipt]
}
export interface Payment {
  vendorId?: number
  documentNumber?: string
  checkNumber: string
  checkDate: string
  checkAmount: string
}

export const GET_INVOICE_ITEM_DETAILS = gql`
  query GetInvoiceItemDetails($vendorId: Long!, $documentId: String!) {
    getInvoiceFullDetails(vendorId: $vendorId, documentId: $documentId) {
      vendorId
      documentNumber
      originalDocAmount
      poId
      locId
      deptId
      classId
      itemId
      invoiceType
      matchObjId
      matchStatus
      reasonCode
      createDate
      invoiceDate
      invoiceDueDate
      quantity
      itemCost
    }
  }
`

export interface GetInvoiceItemDetails {
  getInvoiceFullDetails: [InvoiceDetails]
}
export interface InvoiceDetails {
  vendorId: number
  documentNumber: string
  originalDocAmount: number
  poId: number
  locId: number
  deptId: number
  classId: number
  itemId: number
  invoiceType: string
  matchObjId: number
  matchStatus: number
  reasonCode: string
  createDate: string
  invoiceDate: string
  invoiceDueDate: string
  quantity: number
  itemCost: number
}

export interface Receipt {
  receiptNumber: number
}

export interface InvoiceVars {
  vendorId: number
  documentId: string
}

export const GET_RECEIPTS = gql`
  query GetReceipts($vendorId: Long!, $poId: Long!, $locationId: Long!) {
    getReceipts(vendorId: $vendorId, poId: $poId, locationId: $locationId) {
      receiptNumber
    }
  }
`

export interface GetReceipts {
  getReceipts: [Receipt]
}

export interface ReceiptRequest {
  vendorId?: number
  poId?: number
  locationId?: number
}

export const GET_IMPORTCLAIM = gql`
  query getImportClaim($vendorId: Long!, $claimId: String!) {
    getImportClaim(vendorId: $vendorId, claimId: $claimId) {
      claimTypeCode
      claimAmount
      purchaseOrderId
      departmentId
      classId
      consolidatedLocationId
    }
  }
`
export interface ImportClaimRequest {
  vendorId?: number
  claimId?: string
}

export interface GetImportClaim {
  getImportClaim: ImportClaim
}

export interface ImportClaim {
  claimTypeCode: string
  claimAmount: number
  purchaseOrderId: number
  departmentId: number
  classId: number
  consolidatedLocationId: number
}

export const VALIDATE_RECEIPTS = gql`
  query validateReceipts(
    $receipts: [Long]
    $locationId: String
    $vendorId: String
  ) {
    validateReceipts(
      receipts: $receipts
      locationId: $locationId
      vendorId: $vendorId
    ) {
      receiptNumber
      valid
    }
  }
`

export interface ValidateReceipts {
  validateReceipts: [ReceiptValidity]
}

export interface ReceiptValidityRequest {
  receipts?: [string]
  locationId?: number
  vendorId?: number
}

export interface ReceiptValidity {
  receiptNumber: number
  valid: boolean
}

export const GET_VENDOR = gql`
  query getVendor($id: Long!) {
    getVendor(id: $id) {
      vendorId
      vendorName
      importVendorFlag
      vendorTypeCode
      vendorTypeName
      dvsFlag
      bankName
    }
  }
`

export interface VendorRequest {
  id?: number
}

export interface VendorResponse {
  getVendor: GetVendor
}

export interface GetVendor {
  vendorId: number
  vendorName: string
  importVendorFlag: string
  vendorTypeCode: string
  vendorTypeName: string
  dvsFlag: string
  bankName?: string
}

export const GET_CASE_ATTACHMENTS = gql`
  query getCaseAttachments($caseId: Long!) {
    getCaseAttachments(caseId: $caseId) {
      attachmentId
      fileName
      caseId
      createdTimestamp
      createdBy
      documentType
      documentNumber
    }
  }
`

export const GET_TAP_WORKFLOW = gql`
  query getTapWorkflow($vendorId: Long!) {
    getTapWorkflow(vendorId: $vendorId) {
      vendorId
      vendorName
      vendorType
      vendorTapWorkflow
    }
  }
`

export interface TapWorkflowRequest {
  vendorId?: number
}

interface GetTapWorkFlow {
  vendorId: number
  vendorName: string
  vendorType: string
  vendorTapWorkflow: string
}

export interface TapWorkflowResponse {
  getTapWorkflow: GetTapWorkFlow
}

export const GET_WORKFLOWS = gql`
  query getWorkflows($teamId: String) {
    getWorkflows(teamId: $teamId) {
      value: workflow
      label: workflow
    }
  }
`

export const GET_USERPROFILES = gql`
  query GetUserProfiles {
    getUserProfiles {
      userId
      firstName
      secondName
      fullName
      isVendor
    }
  }
`
export interface GetUserProfiles {
  getUserProfiles: [UserProfiles]
}

export interface UserProfiles {
  userId: string
  firstName: string
  secondName: string
  fullName: string
  isVendor: boolean
}

export interface WorkFlowResponse {
  getWorkflows: WorkFlowDetails
}

export interface WorkFlowDetails {
  workflowId: string
  workflow: string
}

export const GET_CASE = gql`
  query GetCase($id: Long!) {
    getCase(id: $id) {
      caseId
      caseType
      caseSubType
      caseDescription
      reasonCode
      reasonCodeText
      status
      lanId
      workflow
      resolutionDate
      resolutionCode
      resolutionCodeText
      resolutionText
      departmentId
      purchaseOrderNumber
      locationId
      classId
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      originalDocumentNumber
      originalDocumentAmount
      originalDocumentCreateDate
      disputedDocumentAmount
      resolutionAmount
      originalCheckNumber
      originalCheckDate
      originalCheckAmount
      receiptId
      receipts
      vendorName
      vendorNumber
      vendorContactName
      vendorEmailAddress
      vendorType
      bankName
      isReopen
      createUser
      createTimestamp
      updateUser
      updateTimestamp
      agingDate
      userProfile {
        userId
        firstName
        secondName
        fullName
      }
      notes {
        userId
        userName
        commentBody
        isInternal
        commentDate
      }
      disputeDetails {
        newDocumentNumber
        newPaidDate
        newDocumentAmount
        newCheckNumber
      }
      claimDetails {
        shipDate
        carrierVendorId
        carrierVendorName
        carrierVendorContact
        carrierVendorContactEmail
        carrierScacCode
        referenceDocumentNumber
        referenceDocumentCreateDate
        isMappedVendor
        cbdcDocumentDate
        retailAmount
        inventoryRemovalId
        importClaimReversal {
          approvedReversalAmount
          writeOffAmount
          openClaimAmountDetails {
            claimId
            claimDeductionAmount
          }
          domesticAccount {
            domesticVendorId
            domesticAccountAmount
          }
          wireRefundAmount
          approvedPendingReversalAmount
        }
        invoiceDate
        receiptDate
        cartonsBilled
        cartonsReceived
        shortageCartons
        shortageAmount
        trailerNumber
        trackingNumber
        trailerType
        claimType
        itemLevelShortage {
          department
          classId
          item
          quantityShortage
          unitCost
          invoiceQuantity
          casePack
          cartonCount
          shortageCarton
          shortageCost
        }
        claimReceipts {
          location
          poId
          receiptId
          cartonsCount
          cartonCost
        }
        documentNotification
      }
      requestDetails {
        receiptId
        shipmentDate
        carrierVendorName
        carrierVendorContactEmail
        faxNumber
        proofOfDelivery
        proofOfShipment
      }
      auditEntries {
        activityDescription
        userId
        workflow
        status
        assignUserId
        activityTimestamp
      }
      reopenDetails {
        reopenReasonId
        reopenReason
        reopenDate
        reopenUser
        previousResolutionDate
        previousResolutionUser
        previousResolutionCode
        previousResolutionCodeText
        previousResolutionText
        previousResolutionAmount
        previousResolutionDocumentNumber
      }
    }
  }
`

export interface Case {
  getCase?: CaseResponse
}

export interface CaseVariable {
  id: number
}

export interface ReasonCodeMapResponse {
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  reasonCodeList: ReasonCodeSnapshot[]
}
export interface ReasonCodeSnapshot {
  reasonCodeId: string
  reasonCodeText: string
}

export interface GetResolutionCodesMap {
  getResolutionCodesMap: [ResolutionCodeMapResponse]
}

export interface ResolutionCodeMapResponse {
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  resolutionCodeList: ResolutionCodeSnapshot[]
}
export interface ResolutionCodeSnapshot {
  resolutionCodeId: number
  resolutionCodeText: string
}

export interface ResolutionNotesMaps {
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  reasonCodeId: string
  resolutionCodeId: number
  resolutionNoteList: ResolutionNoteSnapshot[]
}

export interface ResolutionNoteSnapshot {
  resolutionNoteId: number
  resolutionNoteText: string
}

export interface ResolutionNoteMapResponse {
  getResolutionNotesMap: ResolutionCodeMaps[]
}

export interface ResolutionNoteMapResponse {
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  reasonCodeId: string
  resolutionCodeId: number
  resolutionNoteList: ResolutionNoteSnapshot[]
}

export const GET_WORKFLOWEVALUATORS = gql`
  query GetWorkflowEvaluators {
    getWorkflowEvaluators {
      caseType
      caseSubType
      docTypeId
      docSubTypeId
      vendorType
      reasonCodeId
      departmentId
      workflowId
      workflow
    }
  }
`

export interface GetWorkflowEvaluators {
  getWorkflowEvaluators: [WorkflowEvaluatorResponse]
}

export interface WorkflowEvaluatorResponse {
  caseType: string
  caseSubType: string
  docTypeId: number
  docSubTypeId: number
  vendorType: string
  reasonCodeId: string
  departmentId: number
  workflowId: number
  workflow: string
}

export const GET_DOCPREFIXMAPS = gql`
  query GetDocumentTypeMaps {
    getDocumentTypeMaps {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      documentPrefix
      documentSource
      workflow
    }
    getDocumentTypes {
      documentTypeId
      documentTypeText
    }
    getDocumentSubTypes {
      documentSubTypeId
      documentSubTypeText
    }
    getReasonCodeMaps {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      reasonCodeList {
        reasonCodeId
        reasonCodeText
      }
    }
    getWorkflows {
      value: workflow
      label: workflow
    }
    getResolutionCodesMap {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      resolutionCodeList {
        resolutionCodeId
        resolutionCodeText
      }
    }
    getResolutionNotesMap {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      reasonCodeId
      resolutionCodeId
      resolutionNoteList {
        resolutionNoteId
        resolutionNoteText
      }
    }
  }
`
export const GET_DOCPREFIXMAPS_VENDOR = gql`
  query GetDocumentTypeMaps {
    getDocumentTypeMaps {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      documentPrefix
      documentSource
      workflow
    }
    getDocumentTypes {
      documentTypeId
      documentTypeText
    }
    getDocumentSubTypes {
      documentSubTypeId
      documentSubTypeText
    }
    getReasonCodeMaps {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      reasonCodeList {
        reasonCodeId
        reasonCodeText
      }
    }
  }
`
export interface DocPrefixMapsResponse {
  getDocumentTypeMaps: DocPrefixMaps[]
  getDocumentSubTypes: DocumentSubTypeResponse[]
  getDocumentTypes: DocumentTypeResponse[]
  getReasonCodeMaps: ReasonCodeMapResponse[]
  getResolutionCodesMap: ResolutionCodeMapResponse[]
  getResolutionNotesMap: ResolutionNoteMapResponse[]
  getWorkflows: WorkFlowResponse[]
}

export interface VendorDocPrefixMapsResponse {
  getDocumentTypeMaps: DocPrefixMaps[]
  getDocumentSubTypes: DocumentSubTypeResponse[]
  getDocumentTypes: DocumentTypeResponse[]
  getReasonCodeMaps: ReasonCodeMapResponse[]
}

export interface DocPrefixMaps {
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  documentPrefix: string
  documentSource: string
  workflow: string
}

export const GET_REASONCODEMAPS = gql`
  query GetReasonCodeMaps {
    getReasonCodeMaps {
      reasonMapId
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      active
      reasonCodeList {
        reasonCodeId
        reasonCodeText
      }
    }
  }
`

export interface ReasonCodeMapResponse {
  getReasonCodeMaps: ReasonCodeMaps[]
}

export interface ReasonCodeMaps {
  reasonMapId: number
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  active: boolean
  reasonCodeList: ReasonCodeSnapshot[]
}

export const GET_RESOLUTIONCODEMAPS = gql`
  query GetResolutionCodesMap {
    getResolutionCodesMap {
      resolutionMapId
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      resolutionCodeList {
        resolutionCodeId
        resolutionCodeText
      }
      active
    }
  }
`

export interface ResolutionCodeMapResponse {
  getResolutionCodesMap: ResolutionCodeMaps[]
}

export interface ResolutionCodeMaps {
  resolutionMapId: number
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  resolutionCodeList: ResolutionCodeSnapshot[]
}

export const GET_RESOLUTIONNOTESMAP = gql`
  query GetResolutionNotesMap {
    getResolutionNotesMap {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      reasonCodeId
      resolutionCodeId
      resolutionNoteList {
        resolutionNoteId
        resolutionNoteText
      }
    }
  }
`

export const CREATE_CASE = gql`
  mutation CreateCase($input: NewCaseRequest!) {
    createCase(newCaseRequest: $input) {
      caseId
      caseType
      caseSubType
      caseDescription
      reasonCode
      reasonCodeText
      status
      lanId
      workflow
      resolutionDate
      resolutionCode
      resolutionCodeText
      resolutionText
      departmentId
      purchaseOrderNumber
      locationId
      classId
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      originalDocumentNumber
      originalDocumentAmount
      originalDocumentCreateDate
      disputedDocumentAmount
      resolutionAmount
      originalCheckNumber
      originalCheckDate
      originalCheckAmount
      receiptId
      receipts
      vendorName
      vendorNumber
      vendorContactName
      vendorEmailAddress
      vendorType
      bankName
      isReopen
      createUser
      createTimestamp
      updateUser
      updateTimestamp
      claimDetails {
        shipDate
        carrierVendorId
        carrierVendorName
        carrierVendorContact
        carrierVendorContactEmail
        carrierScacCode
        referenceDocumentNumber
        referenceDocumentCreateDate
        isMappedVendor
        cbdcDocumentDate
        retailAmount
        inventoryRemovalId
        importClaimReversal {
          approvedReversalAmount
          writeOffAmount
          openClaimAmountDetails {
            claimId
            claimDeductionAmount
          }
          domesticAccount {
            domesticVendorId
            domesticAccountAmount
          }
          wireRefundAmount
          approvedPendingReversalAmount
        }
        invoiceDate
        receiptDate
        cartonsBilled
        cartonsReceived
        shortageCartons
        shortageAmount
        trailerNumber
        trackingNumber
        trailerType
        claimType
        itemLevelShortage {
          department
          classId
          item
          quantityShortage
          unitCost
        }
        claimReceipts {
          location
          poId
          receiptId
          cartonsCount
          cartonCost
        }
        documentNotification
      }
      auditEntries {
        activityDescription
        userId
        workflow
        status
        assignUserId
        activityTimestamp
      }
    }
  }
`
export const UPDATE_CASE = gql`
  mutation UpdateCase($id: Long!, $input: UpdateCaseRequest!) {
    updateCase(id: $id, updateCaseRequest: $input) {
      caseId
      caseType
      caseSubType
      caseDescription
      reasonCode
      reasonCodeText
      status
      lanId
      workflow
      resolutionDate
      resolutionCode
      resolutionCodeText
      resolutionText
      departmentId
      purchaseOrderNumber
      locationId
      classId
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      originalDocumentNumber
      originalDocumentAmount
      originalDocumentCreateDate
      disputedDocumentAmount
      resolutionAmount
      originalCheckNumber
      originalCheckDate
      originalCheckAmount
      receiptId
      receipts
      vendorName
      vendorNumber
      vendorContactName
      vendorEmailAddress
      vendorType
      bankName
      isReopen
      createUser
      createTimestamp
      updateUser
      updateTimestamp
      agingDate
      notes {
        userId
        userName
        commentBody
        isInternal
        commentDate
      }
      disputeDetails {
        newDocumentNumber
        newPaidDate
        newDocumentAmount
        newCheckNumber
      }
      claimDetails {
        shipDate
        carrierVendorId
        carrierVendorName
        carrierVendorContact
        carrierVendorContactEmail
        carrierScacCode
        referenceDocumentNumber
        referenceDocumentCreateDate
        isMappedVendor
        cbdcDocumentDate
        retailAmount
        inventoryRemovalId
        importClaimReversal {
          approvedReversalAmount
          writeOffAmount
          openClaimAmountDetails {
            claimId
            claimDeductionAmount
          }
          domesticAccount {
            domesticVendorId
            domesticAccountAmount
          }
          wireRefundAmount
          approvedPendingReversalAmount
        }
        invoiceDate
        receiptDate
        cartonsBilled
        cartonsReceived
        shortageCartons
        shortageAmount
        trailerNumber
        trackingNumber
        trailerType
        claimType
        itemLevelShortage {
          department
          classId
          item
          quantityShortage
          unitCost
          invoiceQuantity
          casePack
          cartonCount
          shortageCarton
          shortageCost
        }
        claimReceipts {
          location
          poId
          receiptId
          cartonsCount
          cartonCost
        }
        documentNotification
      }
      auditEntries {
        activityDescription
        userId
        workflow
        status
        assignUserId
        activityTimestamp
      }
    }
  }
`

export interface CreateCase {
  createCase: CaseResponse
}

export interface UpdateCase {
  updateCase: CaseResponse
}

export interface UpdateCaseVar {
  id: number
  input: UpdateCaseRequest
}

export interface CaseResponse {
  caseId?: number
  caseType?: string
  caseSubType?: string
  caseDescription?: string
  reasonCode: string
  reasonCodeText: string
  status?: string
  lanId?: string
  workflow?: string
  resolutionDate?: string
  resolutionAmount?: number
  resolutionCode?: number
  resolutionCodeText?: string
  resolutionText?: string
  departmentId?: number
  purchaseOrderNumber?: number
  locationId?: number
  classId?: number
  documentTypeId?: number
  documentTypeText: string
  documentSubTypeId?: number
  documentSubTypeText: string
  originalDocumentNumber?: string
  originalDocumentAmount?: number
  originalDocumentCreateDate?: string
  disputedDocumentAmount?: number
  originalCheckAmount?: number
  originalCheckNumber?: string
  originalCheckDate?: string
  receiptId?: string
  receipts?: [string]
  vendorName?: string
  vendorNumber?: number
  vendorContactName?: string
  vendorEmailAddress?: string
  vendorType?: string
  bankName?: string
  updateTimestamp?: string
  createTimestamp?: string
  createUser?: string
  isReopen: boolean
  agingDate?: string
  disputeDetails?: Dispute
  claimDetails?: ClaimInput
  notes?: NoteInput[]
  requestDetails?: RequestInput
  auditEntries?: AuditEntryInput[]
  documentType?: DocumentTypeResponse
  documentSubType?: DocumentSubTypeResponse
  reasonCodeDetails?: ReasonCodeResponse
  userProfile?: UserProfile
  reopenDetails?: ReopenData
}
export interface DocumentTypeResponse {
  documentTypeId?: number
  documentTypeText: string
}
export interface DocumentTypeIdResponse {
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
}
export interface DocumentSubTypeResponse {
  documentSubTypeId?: number
  documentSubTypeText: string
}
export interface ReasonCodeResponse {
  reasonCodeText?: string
  reasonCodeId?: number
}
export interface UserProfile {
  userId?: string
  firstName?: string
  secondName?: string
  fullName?: string
}

export interface AttachmentDetails {
  attachmentId?: string
  fileName?: string
  documentType?: string
  documentNumber?: string
}

export interface CaseAttachments {
  getCaseAttachments?: AttachmentDetails[]
}

export interface CaseAttachmentVariable {
  caseId?: string
}

export interface Dispute {
  newDocumentNumber?: string
  newDocumentAmount?: number
  newPaidDate?: string
  newCheckNumber?: string
}

export interface ReopenData {
  reopenReasonId?: number
  reopenReason?: string
  reopenDate?: string
  reopenUser?: string
  previousResolutionDate?: string
  previousResolutionUser?: string
  previousResolutionCode?: number
  previousResolutionCodeText?: string
  previousResolutionText?: string
  previousResolutionAmount?: number
  previousResolutionDocumentNumber?: string
}

export interface NewCaseRequest {
  caseType?: string
  caseSubType?: string
  caseDescription?: string
  reasonCode?: string
  reasonCodeText?: string
  status?: string
  lanId?: string
  workflow?: string
  departmentId?: number
  purchaseOrderNumber?: number
  locationId?: number
  classId?: number
  documentTypeId?: number
  documentTypeText?: string
  documentSubTypeId?: number
  documentSubTypeText?: string
  originalDocumentNumber?: string
  originalDocumentAmount?: number
  originalDocumentCreateDate?: string
  disputedDocumentAmount?: number
  originalCheckAmount?: number
  originalCheckNumber?: string
  originalCheckDate?: string
  receiptId?: string
  receipts?: [string]
  vendorName?: string
  vendorNumber?: number
  vendorContactName?: string
  vendorEmailAddress?: string
  vendorType?: string
  bankName?: string
  disputeDetails?: DisputeInput
  claimDetails?: ClaimInput
  requestDetails?: RequestInput
  auditEntries?: AuditEntryInput
}

export interface DisputeInput {
  newDocumentNumber?: string
  newDocumentAmount?: number
  newPaidDate?: string
  newCheckNumber?: string
}

export interface RequestInput {
  receiptId?: string
  shipmentDate?: string
  carrierVendorName?: string
  carrierVendorContactEmail?: string
  faxNumber?: string
  proofOfDelivery?: string
  proofOfShipment?: string
}

export interface NoteInput {
  userId?: string
  userName?: string
  commentBody?: string
  isInternal?: boolean
  commentDate?: string
}

export interface UpdateCaseRequest {
  caseId?: number
  caseDescription?: string
  reasonCode?: string
  reasonCodeText?: string
  documentTypeId?: number
  documentTypeText?: string
  documentSubTypeId?: number
  documentSubTypeText?: string
  status?: string
  lanId?: string
  workflow?: string
  resolutionDate?: string
  resolutionCode?: number
  resolutionCodeText?: string
  resolutionText?: string
  departmentId?: number
  purchaseOrderNumber?: number
  locationId?: number
  classId?: number
  disputedDocumentAmount?: number
  resolutionAmount?: number | null
  newDocumentNumber?: string
  receiptId?: string
  receipts?: [string]
  originalDocumentCreateDate?: string
  vendorContactName?: string
  vendorEmailAddress?: string
  notes?: NoteInput[]
  auditEntries?: AuditEntryInput[]
  claimDetails?: ClaimInput
  disputeDetails?: DisputeInput
  requestDetails?: RequestInput
  isReopen?: boolean
  reopenDetails?: ReopenDataInput
  agingDate?: string
}

export interface ClaimInput {
  shipDate?: string
  carrierVendorId?: string
  carrierVendorName?: string
  carrierVendorContact?: string
  carrierVendorContactEmail?: string
  carrierScacCode?: string
  referenceDocumentNumber?: string
  referenceDocumentCreateDate?: string
  isMappedVendor?: boolean
  importClaimReversal?: ImportClaimReversal
  cbdcDocumentDate?: string
  retailAmount?: number
  inventoryRemovalId?: string
  invoiceDate?: String
  receiptDate?: String
  cartonsBilled?: number
  cartonsReceived?: number
  shortageCartons?: number
  shortageAmount?: number
  trailerNumber?: String
  trackingNumber?: number
  trailerType?: String
  claimType?: String
  itemLevelShortage?: ItemLevelShortage[]
  claimReceipts?: ClaimReceipts[]
  documentNotification?: [String]
}

export interface ImportClaimReversal {
  approvedReversalAmount?: number
  writeOffAmount?: number
  openClaimAmountDetails?: OpenClaimAmount[]
  domesticAccount: DomesticAccount
  wireRefundAmount?: number
  approvedPendingReversalAmount?: number
}

export interface OpenClaimAmount {
  claimId?: number
  claimDeductionAmount?: number
}

export interface DomesticAccount {
  domesticVendorId?: number
  domesticAccountAmount?: number
}

export interface ItemLevelShortage {
  department?: number
  classId?: number
  item?: number
  quantityShortage?: number
  unitCost?: number
  invoiceQuantity: number
  casePack: number
  cartonCount: number
  shortageCarton: number
  shortageCost: number
}

export interface ClaimReceipts {
  location?: number
  poId?: number
  receiptId?: String
  cartonsCount?: number
  cartonCost?: number
}

export interface ReopenDataInput {
  reopenReasonId?: number
  reopenReason?: string
  reopenDate?: string
  reopenUser?: string
  previousResolutionDate?: string
  previousResolutionUser?: string
  previousResolutionCode?: number
  previousResolutionCodeText?: string
  previousResolutionText?: string
  previousResolutionAmount?: number
  previousResolutionDocumentNumber?: string
}

export interface AuditEntryInput {
  activityDescription?: String
  userId?: String
  workflow?: String
  status?: String
  assignUserId?: String
  activityTimestamp?: String
}

export const GET_DEPARTMENT = gql`
  query GetValidDepartment($departmentId: Long!) {
    getValidDepartment(depId: $departmentId) {
      departmentId
      departmentName
    }
  }
`
export interface DepartmentRequest {
  departmentId: number
}

export interface GetValidDepartment {
  getValidDepartment: DepartmentResponse
}

export interface DepartmentResponse {
  departmentId: number
  departmentName: string
}

export const GET_DEPARTMENT_CLASS = gql`
  query GetValidDepartmentClass($departmentId: Long!, $classId: Long!) {
    getValidDepartmentClass(depId: $departmentId, classId: $classId) {
      classId
      className
      departmentId
      departmentName
    }
  }
`
export interface DepartmentClassRequest {
  departmentId: number
  classId?: number
}

export interface GetValidDepartmentClass {
  getValidDepartmentClass: DepartmentClassResponse
}

export interface DepartmentClassResponse {
  classId?: number
  className?: string
  departmentId: number
  departmentName: string
}

export const GET_REASONCODES = gql`
  query GetReasonCodes {
    getReasonCodes {
      reasonCodeId
      reasonCodeText
      supportDocList
      active
    }
  }
`
export interface GetReasonCodes {
  getReasonCodes: ReasonCodes[]
}
export interface ReasonCodes {
  reasonCodeId?: string
  reasonCodeText?: string
  supportDocList?: string
  active: boolean
}

export const GET_RESOLUTIONCODES = gql`
  query GetResolutionCodes {
    getResolutionCodes {
      resolutionCodeId
      resolutionCodeText
      active
    }
  }
`

export interface GetResolutionCodes {
  getResolutionCodes: ResolutionCodes[]
}

export interface ResolutionCodes {
  resolutionCodeId?: number
  resolutionCodeText?: string
  active: boolean
}

export const GET_REOPENCODES = gql`
  query GetReopenCodes {
    getReopenCodes {
      reopenCodeId
      reopenCodeText
      reopenCodeDescription
      active
    }
  }
`

export interface GetReopenCodes {
  getReopenCodes: ReopenCodes[]
}

export interface ReopenCodes {
  reopenCodeId?: number
  reopenCodeText?: string
  reopenCodeDescription?: string
  active: boolean
}

export const GET_REOPENCODE = gql`
  query GetReopenCode($id: Long!) {
    getReopenCode(id: $id) {
      reopenCodeId
      reopenCodeText
      reopenCodeDescription
      active
    }
  }
`

export interface GetReopenCode {
  getReopenCode: ReopenCode
}

export interface ReopenCodeVariable {
  id?: number
}

export interface ReopenCode {
  reopenCodeId?: number
  reopenCodeText?: string
  reopenCodeDescription?: string
  active: boolean
}

export const FILE_CLAIM = gql`
  mutation FileClaim($id: Long!) {
    fileClaim(id: $id) {
      caseId
      caseType
      caseSubType
      caseDescription
      reasonCode
      reasonCodeText
      status
      lanId
      workflow
      resolutionDate
      resolutionCode
      resolutionCodeText
      resolutionText
      departmentId
      purchaseOrderNumber
      locationId
      classId
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      originalDocumentNumber
      originalDocumentAmount
      originalDocumentCreateDate
      disputedDocumentAmount
      resolutionAmount
      originalCheckNumber
      originalCheckDate
      originalCheckAmount
      receiptId
      receipts
      vendorName
      vendorNumber
      vendorContactName
      vendorEmailAddress
      vendorType
      bankName
      isReopen
      createUser
      createTimestamp
      updateUser
      updateTimestamp
      agingDate
      userProfile {
        userId
        firstName
        secondName
        fullName
      }
      notes {
        userId
        userName
        commentBody
        isInternal
        commentDate
      }
      disputeDetails {
        newDocumentNumber
        newPaidDate
        newDocumentAmount
        newCheckNumber
      }
      claimDetails {
        shipDate
        carrierVendorId
        carrierVendorName
        carrierVendorContact
        carrierVendorContactEmail
        carrierScacCode
        referenceDocumentNumber
        referenceDocumentCreateDate
        isMappedVendor
        cbdcDocumentDate
        retailAmount
        inventoryRemovalId
        importClaimReversal {
          approvedReversalAmount
          writeOffAmount
          openClaimAmountDetails {
            claimId
            claimDeductionAmount
          }
          domesticAccount {
            domesticVendorId
            domesticAccountAmount
          }
          wireRefundAmount
          approvedPendingReversalAmount
        }
      }
      requestDetails {
        receiptId
        shipmentDate
        carrierVendorName
        carrierVendorContactEmail
        faxNumber
        proofOfDelivery
        proofOfShipment
      }
      auditEntries {
        activityDescription
        userId
        workflow
        status
        assignUserId
        activityTimestamp
      }
      reopenDetails {
        reopenReasonId
        reopenReason
        reopenDate
        reopenUser
        previousResolutionDate
        previousResolutionUser
        previousResolutionCode
        previousResolutionCodeText
        previousResolutionText
        previousResolutionAmount
        previousResolutionDocumentNumber
      }
    }
  }
`

export interface FileClaim {
  fileClaim: CaseResponse
}

export interface FileClaimVar {
  id: number
}
