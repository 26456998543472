import React from 'react'
import { Grid, Modal, Button } from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useAuth } from '@praxis/component-auth'
import { DOCUMENT_CATEGORY_HEADING, SET_FIELD } from './Constants'
import { activityData } from '../Services/saveActivity'
import { useGlobalForm } from '../Context/GlobalFormStateContext'

interface MainProp {
  formik: FormikValues
  saveStatus: any
  docTypeValues: any
  docSubTypeValues: any
}

const EditDocumentCategoryModal: React.FC<MainProp> = ({
  formik,
  docTypeValues,
  docSubTypeValues,
  saveStatus,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const [state, dispatch] = useGlobalForm()
  const activityDescription = 'Modified Document Details'
  function saveActivty() {
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    saveStatus()
  }

  const currentDocType = docTypeValues.find(
    (docTypeValues: any) =>
      Number(docTypeValues?.id) === Number(formik.values.documentTypeId)
  )
  const currentDocSubType = docSubTypeValues.find(
    (docSubTypeValues: any) =>
      docSubTypeValues?.id === formik.values.documentSubTypeId
  )
  return (
    <div>
      <Modal
        headingText={DOCUMENT_CATEGORY_HEADING}
        isVisible={state.documentCategoryFlag}
        onRefuse={() => {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'documentCategoryFlag', value: false }],
          })
        }}
      >
        <div className="hc-pa-normal hc-pa-lg">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={6} className="hc-pb-none">
                  <Autocomplete
                    id="documentTypeId"
                    label="Document Category"
                    onUpdate={(id, value) => {
                      formik.setFieldValue('documentTypeId', value?.value)
                      formik.setFieldValue('documentTypeText', value?.label)
                    }}
                    value={currentDocType}
                    options={docTypeValues}
                  />
                </Grid.Item>
                <Grid.Item xs={6} className="hc-pb-none">
                  <Autocomplete
                    id="documentSubTypeId"
                    label="Document Sub Category"
                    onUpdate={(id, value) => {
                      formik.setFieldValue('documentSubTypeId', value?.value)
                      formik.setFieldValue('documentSubTypeText', value?.label)
                    }}
                    value={currentDocSubType}
                    options={docSubTypeValues}
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item xs></Grid.Item>
                <Grid.Item justify="flex-end">
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          saveActivty()
                        }}
                        disabled={
                          Number(formik.values.documentTypeId) === 99 ||
                          Number(formik.values.documentSubTypeId) === 99
                        }
                      >
                        Save
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={() => {
                          dispatch({
                            type: SET_FIELD,
                            payload: [
                              { id: 'documentCategoryFlag', value: false },
                            ],
                          })
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}

export default EditDocumentCategoryModal
