import React from 'react'
import { CaseResponse } from '../../Common/Queries'
import { TCLAIM_ITEM_LEVEL_SHORTAGE_HEADING } from '../../Common/Constants'
import { BottomBorderDiv } from '../../Common/SynergyDivComponent'
import {
  ExpandableSection,
  Grid,
  Heading,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import _ from 'lodash'

interface MainProp {
  tclaim: CaseResponse | undefined
}

const EditClaimItemLevelDetails: React.FC<MainProp> = ({ tclaim }) => {
  const tclaimReceipts = tclaim?.claimDetails
  return tclaimReceipts?.itemLevelShortage !== null ? (
    <BottomBorderDiv>
      <div className="hc-pa-normal hc-pl-none hc-pr-none hc-pt-none">
        <ExpandableSection startExpanded toggleLocation="left" padding="none">
          <Heading size={6}>{TCLAIM_ITEM_LEVEL_SHORTAGE_HEADING}</Heading>
          <ExpandableSection.Content className="hc-pl-lg hc-pv-normal">
            <Grid.Container className="hc-pt-none ">
              <Grid.Item xs={12} md={12}>
                <Table name="Item level shortage">
                  <Table.Head>
                    <Table.Row>
                      <Table.Header xs={2}>DPCI</Table.Header>
                      <Table.Header xs={2}>Invoice Qty</Table.Header>
                      <Table.Header xs={1}>Unit Cost</Table.Header>
                      <Table.Header xs={1}>Case Pack</Table.Header>
                      <Table.Header xs={2}>Carton Count</Table.Header>
                      <Table.Header xs={1}>Shortage Qty</Table.Header>
                      <Table.Header xs={1}>Shortage Ctn</Table.Header>
                      <Table.Header xs={2}>Shortage Cost</Table.Header>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {tclaimReceipts?.itemLevelShortage?.map(
                      (shortage: any, index: number) => (
                        <Table.Row>
                          <Table.Data xs={2}>
                            {_.padStart(shortage.department, 4, '0')
                              .concat('-')
                              .concat(_.padStart(shortage.classId, 2, '0'))
                              .concat('-')
                              .concat(_.padStart(shortage.item, 4, '0'))}
                          </Table.Data>
                          <Table.Data xs={2}>
                            {shortage?.invoiceQuantity}
                          </Table.Data>
                          <Table.Data xs={1}>{shortage?.unitCost}</Table.Data>
                          <Table.Data xs={1}>{shortage?.casePack}</Table.Data>
                          <Table.Data xs={2}>
                            {shortage?.cartonCount}
                          </Table.Data>
                          <Table.Data xs={1}>
                            {shortage?.quantityShortage}
                          </Table.Data>
                          <Table.Data xs={1}>
                            {shortage?.shortageCarton}
                          </Table.Data>
                          <Table.Data xs={2}>
                            {shortage?.shortageCost}
                          </Table.Data>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Item>
            </Grid.Container>
          </ExpandableSection.Content>
        </ExpandableSection>
      </div>
    </BottomBorderDiv>
  ) : (
    <></>
  )
}

export default EditClaimItemLevelDetails
