import React, { useRef, useState, useEffect } from 'react'
/** @jsxImportSource @emotion/react */
import {
  Grid,
  Button,
  Form,
  Dropdown,
  Spinner,
  Layout,
  Input,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { useQuery, useMutation } from '@apollo/client'
import '../Custom.css'
import {
  UpdateCaseRequest,
  GET_CASE,
  GET_USERPROFILES,
  UPDATE_CASE,
  UpdateCaseVar,
  CaseResponse,
  GET_CASE_ATTACHMENTS,
  CaseAttachments,
  CaseAttachmentVariable,
  GET_DOCPREFIXMAPS,
  Case,
  CaseVariable,
  AuditEntryInput,
  NoteInput,
  GetUserProfiles,
  DocPrefixMapsResponse,
} from '../Common/Queries'
import {
  findDocumentSubTypeValues,
  findDocumentTypeIdValues,
} from '../Services/DocumentPrefixMapping'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import EditDisputeDetails from '../Common/EditDisputeDetails'
import EditVendorDetails from '../Common/EditVendorDetails'
import EditDocumentDetails from '../Common/EditDocumentDetails'
import EditPaymentDetails from '../Common/EditPaymentDetails'
import EditRecieptDetails from '../Common/EditRecieptDetails'
import EditWorkflowDetails from '../Common/EditWorkflowDetails'
import EditResolutionDetails from '../Common/EditResolutionDetails'
import EditStatusModal from '../Common/EditStatusModal'
import EditResolutionModal from '../Common/EditResolutionModal'
import EditDescriptionModal from '../Common/EditDescriptionModal'
import { findResolutionCodeValues } from '../Services/ResolutionMapping'
import { CaseStatus } from '../Services/ConstantData'
import AttachmentDetails from '../Common/AttachmentDetails'
import DownloadAttachments from '../Common/DownloadAttachments'
import useAttachment from '../Hooks/useAttachment'
import EditDocumentCategoryModal from '../Common/EditDocumentCategoryModal'
import { useAuth } from '@praxis/component-auth'
import NotesTab from '../Common/NotesTab'
import {
  BottomBorderDiv,
  editButtons,
  editButtonsGroup,
} from '../Common/SynergyDivComponent'
import ReopenModal from '../Common/ReopenModal'
import {
  WORKFLOW_SUMMARY_HEADING,
  RESET,
  SET_FIELD,
  WORKFLOW,
  STATUS,
  RESOLVE,
  REOPEN,
  DESCR,
  DOCCATEGORY,
  COMMENTS,
  ATTACHMENTS,
  EDIT_DISPUTE_HEADING,
  REFUNDUPDATE,
} from '../Common/Constants'
import ReopenDetails from '../Common/ReopenDetails'
import CaseDateSummary from '../Common/CaseDateSummary'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import BreadCrumb from '../Common/BreadCrumb'
import { activityData } from '../Services/saveActivity'
import SpinnerLoad from '../Common/SpinnerLoad'
import DuplicateCaseSummary from '../Common/DuplicateCaseSummary'
import { findDuplicates } from '../Services/DuplicateCases'
import * as Yup from 'yup'
import SupportingAlert from '../Common/SupportingAlert'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'
import _ from 'lodash'
import moment from 'moment'
import UpdateRefundDetailsModal from '../Common/UpdateRefundDetailsModal'
import { CaretDownIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface Props {
  id: string
  duplicatesCases?: any
}

const TMDisputeView: React.FC<Props> = (props) => {
  const { userProfile, hasAnyAdminRole } = useProfile()
  const makeToast = useToaster()
  const showActivity = useRef<any>(null)
  const myDivToFocus = useRef<any>(null)

  const [assignedUserValues, setAssignedUserValues] = useState<any>([])
  const [resolutionMappingValues, setresolutionMappingValues] = useState<any>(
    []
  )
  const [filteredDocTypeValues, setFilteredDocTypeValues] = useState<any>([])
  const [filteredDocSubTypeValues, setFilteredDocSubTypeValues] = useState<any>(
    []
  )
  const [caseUpdate, setCaseUpdate] = useState<string>('')
  const { session } = useAuth()
  const env = useEnv() as EnvConfig
  const userName = session?.userInfo?.fullName ?? ''
  const schema = Yup.object().shape<UpdateCaseRequest>({
    resolutionCode: Yup.number().test(
      'Fine',
      'Enter Valid Resolution Explanation',
      function (value) {
        if ((value === undefined || value === null) && state.resCodeCheck) {
          return false
        }
        return true
      }
    ),
    resolutionAmount: Yup.number()
      .nullable(true)
      .test(
        'Fine',
        'Resolution Amount cannot be greater than the Disputed Amount',
        function (value) {
          const context = this.options.context as any
          if (value && value > context?.disputedDocumentAmount) {
            return false
          }
          return true
        }
      ),
    resolutionText: Yup.string().test(
      'Fine',
      'Settlement Note is required for Denial/Partial resolution.',
      function (value) {
        const context = this.options.context as any
        //full and partial denial
        if (
          state?.resolutionFlag &&
          (context?.resolutionAmount == null ||
            (Number(context?.resolutionAmount) >= 0 &&
              Number(context?.resolutionAmount) <
                context?.disputedDocumentAmount)) &&
          state.partialResolution === '' &&
          (caseData?.caseSubType === 'Unpaid Invoice' ||
            caseData?.caseSubType === 'Deduction')
        ) {
          return false
        }
        return true
      }
    ),
  })
  const { data: mappingValues } = useQuery<DocPrefixMapsResponse>(
    GET_DOCPREFIXMAPS,
    {}
  )
  const [state, dispatch] = useGlobalForm()
  const { data: casedetails, refetch: caseRefetch } = useQuery<
    Case,
    CaseVariable
  >(GET_CASE, {
    variables: {
      id: parseInt(props.id),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (disputeDetails: Case) => {
      const caseData = disputeDetails?.getCase
      formik.setFieldValue('documentTypeId', caseData?.documentTypeId)
      formik.setFieldValue('documentSubTypeId', caseData?.documentSubTypeId)
      formik.setFieldValue('claimDetails', caseData?.claimDetails)
      if (caseData?.auditEntries !== null) {
        formik.setFieldValue('auditEntries', caseData?.auditEntries)
      }
      formik.setFieldValue(
        'disputedDocumentAmount',
        caseData?.disputedDocumentAmount
      )
      if (caseData?.resolutionAmount === null) {
        formik.setFieldValue('resolutionAmount', undefined)
      }
      dispatch({
        type: SET_FIELD,
        payload: [
          {
            id: 'resAmount',
            value: caseData?.resolutionAmount
              ? caseData?.resolutionAmount
              : undefined,
          },
          {
            id: 'savedAttachments',
            value: dataattachments?.getCaseAttachments?.map(function (
              attachmentData: any
            ) {
              return {
                fileName: attachmentData.fileName,
                documentType: attachmentData.documentType,
                documentNumber: attachmentData.documentNumber,
                attachmentId: attachmentData.attachmentId,
                createdBy: attachmentData.createdBy,
                createdTimestamp: moment(
                  attachmentData?.createdTimestamp
                ).format('MM/DD/YYYY'),
              }
            }),
          },
          {
            id: 'maxRangeVendors',
            value: _.find(
              env.attachments.maxRangeVendors,
              function (maxRangeVendors) {
                return (
                  Number(maxRangeVendors) === Number(caseData?.vendorNumber)
                )
              }
            ),
          },
        ],
      })
    },
    fetchPolicy: 'network-only',
  })
  const duplicateCases = findDuplicates(props.duplicatesCases, props.id)
  const caseData = casedetails?.getCase

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useQuery<GetUserProfiles>(GET_USERPROFILES, {
    onCompleted: (datauser: GetUserProfiles) => {
      const values = datauser?.getUserProfiles?.map((userProfile: any) => ({
        id: userProfile.userId,
        value: userProfile.userId,
        label: userProfile.fullName,
        vendor: userProfile.isVendor,
      }))
      const userValues = values.filter(function (userValue: any) {
        return !userValue.vendor
      })
      setAssignedUserValues(userValues)
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: dataattachments,
    refetch: refetchAttachments,
    loading: attachmentLoading,
  } = useQuery<CaseAttachments, CaseAttachmentVariable>(GET_CASE_ATTACHMENTS, {
    variables: {
      caseId: props.id,
    },
    fetchPolicy: 'network-only',
  })

  const [updateCaseDispute, { loading: updateLoading }] = useMutation<
    CaseResponse,
    UpdateCaseVar
  >(UPDATE_CASE, {
    onCompleted: () => {
      dispatch({
        type: RESET,
      })
      refetchAttachments()
      makeToast({
        type: 'success',
        heading: caseUpdate,
        message: 'Success',
      })
    },
  })

  useEffect(() => {
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'savedAttachments',
          value: dataattachments?.getCaseAttachments?.map(function (
            attachmentData: any
          ) {
            return {
              fileName: attachmentData.fileName,
              documentType: attachmentData.documentType,
              documentNumber: attachmentData.documentNumber,
              attachmentId: attachmentData.attachmentId,
              createdBy: attachmentData.createdBy,
              createdTimestamp: moment(attachmentData?.createdTimestamp).format(
                'MM/DD/YYYY'
              ),
            }
          }),
        },
        {
          id: 'loadAttachmentList',
          value: attachmentLoading,
        },
      ],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataattachments, dispatch])

  const formik = useFormik<UpdateCaseRequest>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema<UpdateCaseRequest>(
          values,
          validationSchema,
          true,
          values
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      const postDispute = {
        reasonCode: caseData?.reasonCode,
        reasonCodeText: caseData?.reasonCodeText,
        caseDescription: values?.caseDescription
          ? values?.caseDescription
          : caseData?.caseDescription,
        status: values?.status ? values?.status : caseData?.status,
        documentTypeId: Number(values?.documentTypeId)
          ? Number(values?.documentTypeId)
          : Number(caseData?.documentTypeId),
        documentTypeText: values?.documentTypeText
          ? values?.documentTypeText
          : caseData?.documentTypeText,
        documentSubTypeId: Number(values?.documentSubTypeId)
          ? Number(values?.documentSubTypeId)
          : Number(caseData?.documentSubTypeId),
        documentSubTypeText: values?.documentSubTypeText
          ? values?.documentSubTypeText
          : caseData?.documentSubTypeText,
        workflow: values?.workflow ? values?.workflow : caseData?.workflow,
        lanId:
          values?.lanId !== undefined
            ? values?.lanId
            : caseData?.userProfile?.userId,
        departmentId: caseData?.departmentId,
        purchaseOrderNumber: caseData?.purchaseOrderNumber,
        locationId: caseData?.locationId,
        receiptId: caseData?.receiptId,
        receipts: caseData?.receipts,
        classId: caseData?.classId,
        resolutionCode:
          values?.resolutionCode !== undefined
            ? Number(values?.resolutionCode)
            : Number(caseData?.resolutionCode),
        resolutionCodeText:
          values?.resolutionCodeText !== undefined
            ? values?.resolutionCodeText
            : caseData?.resolutionCodeText,
        resolutionAmount:
          values?.resolutionAmount !== undefined
            ? values?.resolutionAmount
            : caseData?.resolutionAmount,
        resolutionText:
          values?.resolutionText !== undefined
            ? values?.resolutionText
            : caseData?.resolutionText,
        resolutionDate:
          values?.resolutionDate !== undefined
            ? values?.resolutionDate
            : caseData?.resolutionDate,
        disputedDocumentAmount: caseData?.disputedDocumentAmount,
        isReopen: values?.isReopen ? values?.isReopen : caseData?.isReopen,
        disputeDetails: {
          newDocumentNumber:
            values?.disputeDetails?.newDocumentNumber !== undefined
              ? values?.disputeDetails?.newDocumentNumber
              : caseData?.disputeDetails?.newDocumentNumber,
          newDocumentAmount: values?.disputeDetails?.newDocumentAmount
            ? values?.disputeDetails?.newDocumentAmount
            : caseData?.disputeDetails?.newDocumentAmount,
          newCheckNumber: values?.disputeDetails?.newCheckNumber
            ? values?.disputeDetails?.newCheckNumber
            : caseData?.disputeDetails?.newCheckNumber,
        },
        claimDetails: {
          carrierScacCode: caseData?.claimDetails?.carrierScacCode,
          carrierVendorContact: caseData?.claimDetails?.carrierVendorContact,
          carrierVendorContactEmail:
            caseData?.claimDetails?.carrierVendorContactEmail,
          carrierVendorId: caseData?.claimDetails?.carrierVendorId,
          carrierVendorName: caseData?.claimDetails?.carrierVendorName,
          isMappedVendor: caseData?.claimDetails?.isMappedVendor,
          referenceDocumentCreateDate:
            caseData?.claimDetails?.referenceDocumentCreateDate,
          referenceDocumentNumber:
            caseData?.claimDetails?.referenceDocumentNumber,
          shipDate: caseData?.claimDetails?.shipDate,
          importClaimReversal: {
            approvedPendingReversalAmount:
              values?.claimDetails?.importClaimReversal
                ?.approvedPendingReversalAmount ??
              caseData?.claimDetails?.importClaimReversal
                ?.approvedPendingReversalAmount,
            approvedReversalAmount:
              values?.claimDetails?.importClaimReversal
                ?.approvedReversalAmount ??
              caseData?.claimDetails?.importClaimReversal
                ?.approvedReversalAmount,
            domesticAccount: {
              domesticVendorId:
                values?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticVendorId ??
                caseData?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticVendorId,
              domesticAccountAmount:
                values?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticAccountAmount ??
                caseData?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticAccountAmount,
            },
            wireRefundAmount:
              values?.claimDetails?.importClaimReversal?.wireRefundAmount ??
              caseData?.claimDetails?.importClaimReversal?.wireRefundAmount,
            writeOffAmount:
              values?.claimDetails?.importClaimReversal?.writeOffAmount ??
              caseData?.claimDetails?.importClaimReversal?.writeOffAmount,
            openClaimAmountDetails: values?.claimDetails?.importClaimReversal
              ?.openClaimAmountDetails
              ? values?.claimDetails?.importClaimReversal?.openClaimAmountDetails?.map(
                  (claim) => {
                    return {
                      claimDeductionAmount: claim.claimDeductionAmount,
                      claimId: claim.claimId,
                    }
                  }
                )
              : caseData?.claimDetails?.importClaimReversal?.openClaimAmountDetails?.map(
                  (claim) => {
                    return {
                      claimDeductionAmount: claim.claimDeductionAmount,
                      claimId: claim.claimId,
                    }
                  }
                ),
          },
        },
        reopenDetails: {
          reopenReasonId: values?.reopenDetails?.reopenReasonId
            ? values?.reopenDetails?.reopenReasonId
            : caseData?.reopenDetails?.reopenReasonId,
          reopenReason: values?.reopenDetails?.reopenReason
            ? values?.reopenDetails?.reopenReason
            : caseData?.reopenDetails?.reopenReason,
          reopenDate: values?.reopenDetails?.reopenDate
            ? values?.reopenDetails?.reopenDate
            : caseData?.reopenDetails?.reopenDate,
          reopenUser: values?.reopenDetails?.reopenUser
            ? values?.reopenDetails?.reopenUser
            : caseData?.reopenDetails?.reopenUser,
          previousResolutionDate: values?.reopenDetails?.previousResolutionDate
            ? values?.reopenDetails?.previousResolutionDate
            : caseData?.reopenDetails?.previousResolutionDate,
          previousResolutionUser: values?.reopenDetails?.previousResolutionUser
            ? values?.reopenDetails?.previousResolutionUser
            : caseData?.reopenDetails?.previousResolutionUser,
          previousResolutionCode: values?.reopenDetails?.previousResolutionCode
            ? values?.reopenDetails?.previousResolutionCode
            : caseData?.reopenDetails?.previousResolutionCode,
          previousResolutionCodeText: values?.reopenDetails
            ?.previousResolutionCodeText
            ? values?.reopenDetails?.previousResolutionCodeText
            : caseData?.reopenDetails?.previousResolutionCodeText,
          previousResolutionText: values?.reopenDetails?.previousResolutionText
            ? values?.reopenDetails?.previousResolutionText
            : caseData?.reopenDetails?.previousResolutionText,
          previousResolutionAmount: values?.reopenDetails
            ?.previousResolutionAmount
            ? values?.reopenDetails?.previousResolutionAmount
            : caseData?.reopenDetails?.previousResolutionAmount,
          previousResolutionDocumentNumber: values?.reopenDetails
            ?.previousResolutionDocumentNumber
            ? values?.reopenDetails?.previousResolutionDocumentNumber
            : caseData?.reopenDetails?.previousResolutionDocumentNumber,
        },
        vendorContactName: caseData?.vendorContactName,
        vendorEmailAddress: caseData?.vendorEmailAddress,
        auditEntries:
          values?.auditEntries?.length !== 0
            ? values?.auditEntries
            : caseData?.auditEntries?.map(function (
                auditEntries: AuditEntryInput
              ) {
                return {
                  activityDescription: auditEntries.activityDescription,
                  userId: auditEntries.userId,
                  workflow: auditEntries.workflow,
                  status: auditEntries.status,
                  assignUserId: auditEntries.assignUserId,
                  activityTimestamp: auditEntries.activityTimestamp,
                }
              }),
        notes:
          values?.notes && values?.notes?.length !== 0
            ? values?.notes
            : caseData?.notes?.map(function (notes: NoteInput) {
                return {
                  userId: notes.userId,
                  userName: notes.userName,
                  commentBody: notes.commentBody,
                  isInternal: notes.isInternal,
                  commentDate: notes.commentDate,
                }
              }),
        agingDate: caseData?.agingDate,
      }
      await updateCaseDispute({
        variables: {
          id: Number(props.id),
          input: postDispute,
        },
      }).then(() => {
        caseRefetch()
      })
    },
  })

  const onAttachmentFocus = () => {
    if (myDivToFocus.current) {
      myDivToFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      myDivToFocus.current.focus()
    }
  }

  const showActivityCard = () => {
    if (showActivity.current) {
      showActivity.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      showActivity.current.focus()
    }
  }

  const onAssignUser = () => {
    setCaseUpdate(WORKFLOW)
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'userFlag', value: true },
        { id: 'assignLanId', value: caseData?.userProfile?.userId },
        { id: 'assignWorkflow', value: caseData?.workflow },
      ],
    })
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
    formik.setFieldValue('status', caseData?.status)
  }

  const onStatusProgressChange = (e: any) => {
    setCaseUpdate(STATUS)
    formik.setFieldValue('status', CaseStatus.INPROGRESS)
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
    const activityDescription = `${'Changed the Case Status to '}${
      CaseStatus.INPROGRESS
    }`
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    formik.handleSubmit(e)
  }

  const onStatusAwaitChange = () => {
    setCaseUpdate(STATUS)
    formik.setFieldValue('status', CaseStatus.AWAITINGINFO)
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
    const activityDescription = `${'Changed the Case Status to '}${
      CaseStatus.AWAITINGINFO
    }`
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'changeAwaitingInfo', value: false }],
    })
    formik.handleSubmit()
  }
  const onStatusResolveChange = (e: any) => {
    setCaseUpdate(RESOLVE)
    setresolutionMappingValues(
      findResolutionCodeValues(mappingValues?.getResolutionCodesMap, caseData)
    )
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'resolutionFlag', value: true },
        { id: 'resCodeCheck', value: true },
      ],
    })
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
  }

  const onReopenChange = (e: any) => {
    setCaseUpdate(REOPEN)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'reOpenModalFlag', value: true }],
    })
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
  }

  const onRefundDetailsUpdate = (e: any) => {
    setCaseUpdate(REFUNDUPDATE)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'refundDetailsUpdateFlag', value: true }],
    })
  }

  const onDescriptionChange = (e: any) => {
    setCaseUpdate(DESCR)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'caseDescriptionFlag', value: true }],
    })
    formik.setFieldValue('caseDescription', caseData?.caseDescription)

    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
    formik.setFieldValue('status', caseData?.status)
  }

  const { uploadAttachment, attachmentDetails } = useAttachment()
  const prepareToUploadAttachments = async () => {
    setCaseUpdate(ATTACHMENTS)
    const activityDescription = 'Uploaded Attachment'
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    await uploadAttachment(props.id, caseData?.vendorNumber).then(() => {
      formik.handleSubmit()
    })
  }
  const onDocumentCategoryChange = (e: any) => {
    setCaseUpdate(DOCCATEGORY)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'documentCategoryFlag', value: true }],
    })
    if (caseData?.documentTypeId) {
      formik.setFieldValue('documentTypeId', Number(caseData?.documentTypeId))
    }
    if (caseData?.documentSubTypeId) {
      formik.setFieldValue(
        'documentSubTypeId',
        Number(caseData?.documentSubTypeId)
      )
    }
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
    formik.setFieldValue('status', caseData?.status)
  }
  useEffect(() => {
    if (caseData?.caseSubType && mappingValues?.getDocumentTypeMaps) {
      setFilteredDocTypeValues(
        findDocumentTypeIdValues(mappingValues?.getDocumentTypeMaps, caseData)
      )
      if (caseData?.caseSubType && formik.values.documentTypeId) {
        setFilteredDocSubTypeValues(
          findDocumentSubTypeValues(
            mappingValues?.getDocumentTypeMaps,
            caseData,
            formik.values.documentTypeId
          )
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseData?.caseSubType,
    formik.values.documentTypeId,
    mappingValues?.getDocumentTypeMaps,
  ])

  useEffect(() => {
    if (state?.comments) {
      setCaseUpdate(COMMENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.comments])

  useEffect(() => {
    if (state?.changeAwaitingInfo) {
      onStatusAwaitChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.changeAwaitingInfo])

  return (
    <Layout.Body includeRail className="hc-pa-md">
      <Grid.Container>
        <BreadCrumb caseId={props.id} />
        <Grid.Item xs={12}>
          <Button
            type="primary"
            css={editButtons}
            onClick={onAttachmentFocus}
            disabled={caseData?.status === CaseStatus.CLOSED}
          >
            Attach Files
          </Button>
          <Button
            type="primary"
            css={editButtonsGroup}
            onClick={showActivityCard}
          >
            Activity
          </Button>
          {caseData?.status !== CaseStatus.RESOLVED &&
            caseData?.status !== CaseStatus.CLOSED && (
              <Dropdown size="dense" location="bottom-left">
                <Button type="primary" css={editButtons}>
                  More
                  <EnterpriseIcon icon={CaretDownIcon}></EnterpriseIcon>
                </Button>
                <Dropdown.Menu>
                  <Dropdown.MenuItem
                    onClick={(e: any) => {
                      onDescriptionChange(e)
                    }}
                  >
                    Case Brief Explanation
                  </Dropdown.MenuItem>
                  <Dropdown.MenuItem
                    onClick={(e: any) => {
                      onDocumentCategoryChange(e)
                    }}
                  >
                    Document Categories
                  </Dropdown.MenuItem>
                  {caseData?.caseSubType === 'Import Claims' && (
                    <Dropdown.MenuItem
                      onClick={(e: any) => {
                        onRefundDetailsUpdate(e)
                      }}
                    >
                      Update Claim Reversal Details
                    </Dropdown.MenuItem>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          {caseData?.status !== CaseStatus.RESOLVED &&
            caseData?.status !== CaseStatus.CLOSED && (
              <Button
                type="primary"
                css={editButtonsGroup}
                onClick={onAssignUser}
              >
                Assign
              </Button>
            )}
          {(caseData?.status === CaseStatus.AWAITINGINFO ||
            (caseData?.lanId &&
              (caseData?.status === CaseStatus.NEW ||
                caseData?.status === CaseStatus.REOPEN))) && (
            <Button
              type="primary"
              css={editButtons}
              onClick={(e: any) => {
                onStatusProgressChange(e)
              }}
            >
              {'set to InProgress'}
            </Button>
          )}
          {caseData?.status === CaseStatus.INPROGRESS && (
            <Button
              type="primary"
              css={editButtons}
              onClick={() => {
                dispatch({
                  type: SET_FIELD,
                  payload: [
                    { id: 'statusAlert', value: true },
                    { id: 'alertComponent', value: CaseStatus.AWAITINGINFO },
                  ],
                })
              }}
            >
              {'set to AwaitingInfo'}
            </Button>
          )}
          {(caseData?.status === CaseStatus.AWAITINGINFO ||
            caseData?.status === CaseStatus.INPROGRESS) && (
            <Button
              type="primary"
              css={editButtonsGroup}
              onClick={(e: any) => {
                formik.setFieldValue(
                  'resolutionAmount',
                  state.resAmount ? state.resAmount : undefined
                )

                onStatusResolveChange(e)
              }}
            >
              {'set to Resolved'}
            </Button>
          )}
          {hasAnyAdminRole &&
            (caseData?.status === CaseStatus.RESOLVED ||
              caseData?.status === CaseStatus.CLOSED) && (
              <Button
                type="primary"
                css={editButtons}
                onClick={(e: any) => {
                  onReopenChange(e)
                }}
              >
                {'set to Reopen'}
              </Button>
            )}
          {updateLoading && <SpinnerLoad case={false} createCase={false} />}
        </Grid.Item>
      </Grid.Container>

      <Grid.Container justify="space-between" className="hc-pa-none">
        <Grid.Item md={8} xs={12}>
          <Form>
            <EditVendorDetails vendorDetails={caseData} />
            <EditDisputeDetails
              heading={EDIT_DISPUTE_HEADING}
              disputeDetails={caseData}
            />
            <EditDocumentDetails documentDetails={caseData} formik={formik} />
            <EditPaymentDetails paymentDetails={caseData} />
            <EditRecieptDetails receiptDetails={caseData} />
            <div id="myDivToFocus" ref={myDivToFocus}>
              <DownloadAttachments />
              {caseData?.status !== CaseStatus.CLOSED && (
                <BottomBorderDiv>
                  <AttachmentDetails
                    isVendor={userProfile?.isVendor}
                    reasonCode={caseData?.reasonCode}
                  />
                  <div className="hc-pa-normal hc-pb-dense hc-pt-none">
                    <Grid.Container direction="row-reverse">
                      <Grid.Item className="hc-pt-none">
                        <Button
                          size="dense"
                          type="primary"
                          onClick={prepareToUploadAttachments}
                          disabled={
                            attachmentDetails.uploadingAttachments ||
                            attachmentDetails.attachments?.length === 0 ||
                            attachmentDetails.attachmentTypeNotSet
                          }
                        >
                          {attachmentDetails.uploadingAttachments && (
                            <Spinner size="dense" />
                          )}
                          Upload New Attachments
                        </Button>
                        {attachmentDetails.uploadingAttachments && (
                          <Input.Label className="hc-fs-overline hc-blh-dense">
                            Uploading Files ...
                          </Input.Label>
                        )}
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                </BottomBorderDiv>
              )}
            </div>
            <EditStatusModal
              headingText="Assign Case?"
              formik={formik}
              assignedUserValues={assignedUserValues}
              workflowValues={mappingValues?.getWorkflows}
              saveStatus={formik.handleSubmit}
            />
            <EditResolutionModal
              headingText="Resolution Outcome/Settlement"
              editResolutionDetails={caseData}
              formik={formik}
              resolutionCodeValues={resolutionMappingValues}
              saveStatus={formik.handleSubmit}
              assignedUserValues={assignedUserValues}
            />
            <EditDescriptionModal
              headingText="Do you wish to update case brief explanation?"
              formik={formik}
              saveStatus={formik.handleSubmit}
            />
            <EditDocumentCategoryModal
              formik={formik}
              docTypeValues={filteredDocTypeValues}
              docSubTypeValues={filteredDocSubTypeValues}
              saveStatus={formik.handleSubmit}
            />
            <UpdateRefundDetailsModal
              headingText="Do you wish to update the Importo refund details?"
              formik={formik}
              saveStatus={formik.handleSubmit}
              caseData={caseData}
            />
            <ReopenModal
              headingText="Reopen the case"
              formik={formik}
              reopenDetails={caseData}
              saveStatus={formik.handleSubmit}
            />
          </Form>
        </Grid.Item>
        <Grid.Item md={4} xs={12} className="hc-pa-lg">
          <EditWorkflowDetails
            heading={WORKFLOW_SUMMARY_HEADING}
            workflowDetails={caseData}
            attachments={dataattachments?.getCaseAttachments?.length}
          />
          <CaseDateSummary caseDateDetails={caseData} />
          {(caseData?.status === CaseStatus.RESOLVED ||
            caseData?.status === CaseStatus.CLOSED) && (
            <EditResolutionDetails resolutionDetails={caseData} />
          )}
          {caseData?.isReopen !== null && caseData?.isReopen && (
            <ReopenDetails reopenDetails={caseData} />
          )}
          {duplicateCases && (
            <DuplicateCaseSummary
              heading="Duplicate Cases"
              duplicatesCases={duplicateCases}
            />
          )}
        </Grid.Item>
        <SupportingAlert />
      </Grid.Container>
      <div id="showActivity" ref={showActivity}>
        <Grid.Item className="hc-bg-white">
          <NotesTab
            formik={formik}
            caseDetails={caseData}
            userProfile={userProfile}
            saveComment={formik.handleSubmit}
          />
        </Grid.Item>
      </div>
    </Layout.Body>
  )
}
export default TMDisputeView
