import React from 'react'
import { Dialog } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import {
  SET_FIELD,
  CLOSED_DUPLICATE_ALERT,
  ACTIVE_DUPLICATE_ALERT,
} from './Constants'
import { CaseType } from '../Services/ConstantData'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const headingSize = css`
  .C-Heading.\--size-h4 {
    font-size: 15px;
  }
`

interface MainProp {
  CaseType?: String
  importVendor?: boolean
}

const DuplicateCheck: React.FC<MainProp> = (props: MainProp) => {
  const [state, dispatch] = useGlobalForm()
  return !state.activeDuplicate ? (
    <Dialog
      css={headingSize}
      headingText={CLOSED_DUPLICATE_ALERT}
      approveButtonText="Yes"
      onApprove={() => {
        if (props.CaseType !== CaseType.REQUESTFORINFO) {
          if (props.importVendor) {
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'showDialog', value: false },
                { id: 'isDocNextFlag', value: true },
                { id: 'documentFound', value: true },
                { id: 'isDocShowable', value: true },
              ],
            })
          } else {
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'showDialog', value: false },
                { id: 'isDocNextFlag', value: true },
                { id: 'documentFound', value: true },
              ],
            })
          }
        } else {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'showDocuments', value: true },
              { id: 'showDialog', value: false },
            ],
          })
        }
      }}
      onRefuse={() => {
        if (props.CaseType !== CaseType.REQUESTFORINFO) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'showDialog', value: false },
              { id: 'isDocNextFlag', value: false },
              { id: 'documentFound', value: false },
            ],
          })
        } else {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'showDocuments', value: false },
              { id: 'showDialog', value: false },
            ],
          })
        }
      }}
      refuseButtonText="No"
      isVisible={state.showDialog}
    />
  ) : (
    <Dialog
      css={headingSize}
      headingText={ACTIVE_DUPLICATE_ALERT}
      approveButtonText="OK"
      onApprove={() => {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'activeDuplicate', value: false },
            { id: 'showDialog', value: false },
            { id: 'isDocNextFlag', value: false },
          ],
        })
      }}
      onRefuse={() => {}}
      isVisible={state.showDialog}
    />
  )
}

export default DuplicateCheck
