import { Alert, Grid } from '@enterprise-ui/canvas-ui-react'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'

export const Notification = () => {
  const env = useEnv() as EnvConfig
  const alertMessage = env.alertMessage
  const isBetweenDates = (): boolean => {
    const givenDateTimestamp = Date.now()
    const startDateString = env.alertStartDate
    const endDateString = env.alertEndDate

    const startDateTimestamp = Date.parse(`${startDateString} 00:00:00`)
    const endDateTimestamp = Date.parse(`${endDateString} 23:59:59`)

    return (
      givenDateTimestamp >= startDateTimestamp &&
      givenDateTimestamp <= endDateTimestamp
    )
  }
  return (
    <>
      {isBetweenDates() && (
        <Grid.Container justify="center">
          <Grid.Item xs={10}>
            <Alert type="alert" heading="Info:">
              {alertMessage}
            </Alert>
          </Grid.Item>
        </Grid.Container>
      )}
    </>
  )
}
