import React from 'react'
import { Grid, Breadcrumbs } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'

interface MainProp {
  caseId: string
}

const BreadCrumb: React.FC<MainProp> = ({ caseId }) => {
  const navigate = useNavigate()

  return (
    <Grid.Item justify-content="center" className="hc-pb-none">
      <Breadcrumbs className="hc-ml-none hc-pb-none">
        <Breadcrumbs.Item onClick={() => navigate('/cases')}>
          Cases
        </Breadcrumbs.Item>
        <Breadcrumbs.Item> {caseId} </Breadcrumbs.Item>
      </Breadcrumbs>
    </Grid.Item>
  )
}

export default BreadCrumb
