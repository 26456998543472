import React from 'react'
import { DATES_HEADING } from './Constants'
import { CaseResponse } from './Queries'
import { Section, SectionItem } from './Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'

interface MainProp {
  caseDateDetails: CaseResponse | undefined
}

const CaseDateSummary: React.FC<MainProp> = ({ caseDateDetails }) => {
  return (
    <Section labelGridSize={3} heading={DATES_HEADING} expandable detail>
      <SectionItem label="Created">
        <DateFormatter
          //@ts-ignore
          date={caseDateDetails?.createTimestamp}
          format="L"
        />
      </SectionItem>
      <SectionItem label="Updated">
        <DateFormatter
          //@ts-ignore
          date={caseDateDetails?.updateTimestamp}
          format="L"
        />
      </SectionItem>
    </Section>
  )
}

export default CaseDateSummary
