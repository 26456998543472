import {
  Button,
  ExpandableSection,
  Form,
  Grid,
  Heading,
  Input,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import React, { useState, useEffect } from 'react'
import { FormikValues } from 'formik'
import { EnterpriseIcon, MinusIcon, PlusIcon } from '@enterprise-ui/icons'
import _ from 'lodash'
import { twoDecimals } from '../Services/TwoDecimalPlaces'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import {
  Autocomplete,
  AutocompleteOptions,
} from '@enterprise-ui/canvas-ui-react-autocomplete'
import { SET_FIELD } from '../Common/Constants'

interface MainProp {
  formik: FormikValues
  invoiceItems: any
  displayCols: any
  onShortageNextClick: any
  completionStatus: any
}

const emptyShortageDetails = () => [
  {
    id: _.uniqueId(),
    department: '',
    classId: '',
    item: '',
    invoiceQuantity: '',
    quantityShortage: '',
    unitCost: '',
    dpci: '',
    casePack: '',
    cartonCount: '',
    shortageCarton: '',
    shortageCost: '',
  },
]

const ShortageDetails: React.FC<MainProp> = ({
  formik,
  invoiceItems,
  displayCols,
  onShortageNextClick,
  completionStatus,
}) => {
  const [state, dispatch] = useGlobalForm()
  const makeToast = useToaster()
  const formData = formik?.values
  let selectedInvoiceItems: Function | AutocompleteOptions[] | undefined = []
  const [selectedDpci, setSelectedDpci] = useState([])
  const [shortageDetails, setShortageDetails] = useState(emptyShortageDetails())

  const [receiptsDetail, setReceiptsDetail] = useState([
    {
      id: _.uniqueId(),
      location: '',
      poId: '',
      receiptId: '',
      cartonsCount: '',
      cartonCost: '',
    },
  ])

  function formatDPCI(dpciDetails: any): any {
    return _.padStart(dpciDetails.deptId, 4, '0')
      .concat('-')
      .concat(_.padStart(dpciDetails.classId, 4, '0'))
      .concat('-')
      .concat(_.padStart(dpciDetails.itemId, 4, '0'))
  }

  if (invoiceItems.length > 0) {
    selectedInvoiceItems = (invoiceItems || [])?.map((items: any) => {
      return {
        id: items.id,
        value: items.dpci,
        label: items.dpci,
      }
    })
  }

  function handleInitialShortageDetails() {
    if (formik?.values?.claimDetails?.itemLevelShortage?.length !== 0) {
      let initialShortageDetails = state.dpciShortage?.map((shortage: any) => {
        return {
          id: shortage.id,
          department: shortage.department,
          classId: shortage.classId,
          item: shortage.item,
          invoiceQuantity: shortage.invoiceQuantity,
          quantityShortage: shortage.quantityShortage,
          unitCost: shortage.unitCost,
          dpci: shortage.dpci ?? formatDPCI(shortage),
          casePack: shortage.casePack,
          cartonCount: shortage.cartonCount,
          shortageCarton: shortage.shortageCarton,
          shortageCost: shortage.shortageCost,
        }
      })
      let dpcis = state.dpciShortage?.map((shortage: any) => {
        return {
          id: shortage.id,
          value: shortage.dpci ?? formatDPCI(shortage),
          label: shortage.dpci ?? formatDPCI(shortage),
        }
      })
      setSelectedDpci(dpcis)
      setShortageDetails(initialShortageDetails)
    } else {
      setShortageDetails(emptyShortageDetails())
    }
  }

  function handleInitialReceiptsDetail() {
    if (formik?.values?.claimDetails?.claimReceipts?.length !== 0) {
      let initialReceiptsDetail =
        formik?.values?.claimDetails?.claimReceipts?.map((receipt: any) => {
          return {
            id: _.uniqueId(),
            location: receipt.location,
            poId: receipt.poId,
            receiptId: receipt.receiptId,
            cartonsCount: receipt.cartonsCount,
            cartonCost: receipt.cartonCost,
          }
        })
      setReceiptsDetail(initialReceiptsDetail)
    } else {
      setReceiptsDetail([
        {
          id: _.uniqueId(),
          location: '',
          poId: '',
          receiptId: '',
          cartonsCount: '',
          cartonCost: '',
        },
      ])
    }
  }

  function checkReceiptsDetails(receipt: any) {
    if (
      receipt.location === '' ||
      receipt.poId === '' ||
      receipt.receiptId === '' ||
      receipt.cartonsCount === '' ||
      receipt.cartonCost === ''
    ) {
      return true
    } else return false
  }
  function checkValidItemDetails(shortage: any) {
    if (
      shortage.department === '' ||
      shortage.classId === '' ||
      shortage.item === '' ||
      shortage.quantityShortage === '' ||
      shortage.unitCost === '' ||
      shortage.casePack === '' ||
      shortage.cartonCount === '' ||
      shortage.shortageCarton === '' ||
      shortage.shortageCost === '' ||
      shortage.invoiceQuantity === ''
    ) {
      return true
    } else return false
  }

  useEffect(() => {
    if (!state.isShortageNext) {
      handleInitialShortageDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.claimDetails?.itemLevelShortage])

  useEffect(() => {
    handleInitialReceiptsDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.claimDetails?.claimReceipts])

  useEffect(() => {
    if (state.isShortageNext) {
      onShortageNextClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isShortageNext])

  const handleAddShortageDetails = () => {
    setShortageDetails([...shortageDetails, ...emptyShortageDetails()])
  }

  const handleAddReceiptsDetail = () => {
    setReceiptsDetail([
      ...receiptsDetail,
      {
        id: _.uniqueId(),
        location: '',
        poId: '',
        receiptId: '',
        cartonsCount: '',
        cartonCost: '',
      },
    ])
  }

  const handleRemoveShortageDetails = (id: any) => {
    const updatedShortageDetails = shortageDetails.filter(
      (item) => item.id !== id
    )
    setShortageDetails(
      updatedShortageDetails.length === 0
        ? emptyShortageDetails()
        : updatedShortageDetails
    )
    if (state.invoiceItemDetails) {
      setSelectedDpci(selectedDpci.filter((item: any) => item.id !== id))
    }
  }

  const handleRemoveReceiptsDetail = (id: any) => {
    const values = [...receiptsDetail]
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    )
    setReceiptsDetail(values)
  }

  function assignToFormik() {
    let validItems = false
    _.forEach(shortageDetails, function (shortage) {
      validItems = checkValidItemDetails(shortage)
    })

    if (shortageDetails?.length !== 0) {
      if (!validItems) {
        const formikShortageDetails = shortageDetails?.map((shortage: any) => {
          return {
            department: shortage.department,
            classId: shortage.classId,
            item: shortage.item,
            quantityShortage: shortage.quantityShortage,
            unitCost: shortage.unitCost,
            invoiceQuantity: shortage.invoiceQuantity,
            casePack: shortage.casePack,
            cartonCount: shortage.cartonCount,
            shortageCarton: shortage.shortageCarton,
            shortageCost: shortage.shortageCost,
          }
        })
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'isShortageNext', value: true },
            { id: 'dpciShortage', value: shortageDetails },
          ],
        })
        formik.setFieldValue(
          'claimDetails.itemLevelShortage',
          formikShortageDetails
        )
      } else {
        makeToast({
          type: 'error',
          heading: 'Please provide all the Item level details.',
          message: 'Alert',
          autoHideDuration: 5000,
        })
      }
    } else {
      formik.setFieldValue('claimDetails.itemLevelShortage', [])
    }

    if (receiptsDetail?.length !== 0) {
      if (receiptsDetail[0].receiptId !== '') {
        let formikReceiptsDetail = receiptsDetail?.map((receipt: any) => {
          return {
            location: receipt.location,
            poId: receipt.poId,
            receiptId: receipt.receiptId,
            cartonsCount: receipt.cartonsCount,
            cartonCost: receipt.cartonCost,
          }
        })
        formik.setFieldValue('claimDetails.claimReceipts', formikReceiptsDetail)
      }
    } else {
      formik.setFieldValue('claimDetails.claimReceipts', [])
    }
  }

  const handleUpdate = (id: any, value: any) => {
    setSelectedDpci(value)
    const docNotification = value.map(
      (docNotification: any) => docNotification.id
    )
    const result = invoiceItems.filter((item: any) =>
      docNotification.includes(item.id)
    )
    if (result.length > 0) {
      setShortageDetails(result)
    } else {
      setShortageDetails(emptyShortageDetails())
    }
  }

  useEffect(() => {
    let totalAmount = shortageDetails?.reduce(
      (accumulator, current) => accumulator + Number(current.shortageCost),
      0
    )
    let totalCartons = shortageDetails?.reduce(
      (accumulator, current) => accumulator + Number(current.shortageCarton),
      0
    )

    formik.setFieldValue(
      'claimDetails.shortageAmount',
      parseFloat(totalAmount.toFixed(2))
    )
    formik.setFieldValue('claimDetails.shortageCartons', totalCartons)
    formik.setFieldValue(
      'disputedDocumentAmount',
      parseFloat(totalAmount.toFixed(2))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortageDetails])

  return (
    <>
      {state.invoiceItemDetails && (
        <div className="hc-pt-xl">
          <Grid.Item xs={10} md={6} className="hc-pb-none">
            <Autocomplete
              id="selectedItems"
              options={selectedInvoiceItems}
              value={selectedDpci}
              label="Choose the DPCIs"
              onUpdate={handleUpdate}
              multiselect
              className="hc-ta-left"
              disabled={completionStatus.shortage}
              allowSelectAll
            />
          </Grid.Item>
        </div>
      )}
      <div className="hc-pt-xl">
        <ExpandableSection startExpanded toggleLocation="left" padding="dense">
          <Heading size={6}>
            {'Enter the item level shortage details if any?'}
          </Heading>
          <ExpandableSection.Content>
            <Grid.Container className="hc-pt-normal">
              <Grid.Item xs={1}>
                <Input.Label>Dept #</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Class#</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Item #</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Invoice Qty</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Unit Cost</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Case Pack</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Carton Count</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Shortage Qty</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Shortage Ctn</Input.Label>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Input.Label>Shortage Cost</Input.Label>
              </Grid.Item>
            </Grid.Container>
            {shortageDetails?.map((shortageDetail: any, index: any) => (
              <Grid.Container className="hc-pt-normal hc-mr-xl">
                <Grid.Item xs={1} key={index}>
                  <Form.Field
                    id={`shortageDetail-${index}-department`}
                    type="number"
                    value={shortageDetail?.department ?? ''}
                    className="inputtype"
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.department = event.target.value
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 4)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>

                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-classId`}
                    errorText="Please enter the class id"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.classId ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.classId = event.target.value
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 2)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-item`}
                    errorText="Please enter the item"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.item ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.item = event.target.value
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 4)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-invoiceQuantity`}
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.invoiceQuantity ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.invoiceQuantity = event.target.value
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-unitCost`}
                    errorText="Please enter the unit cost"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.unitCost ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.unitCost = twoDecimals(
                              event.target.value
                            )
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-casePack`}
                    errorText="Please enter the casepack"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.casePack ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.casePack = event.target.value
                            shortageDtl.cartonCount =
                              shortageDetail?.invoiceQuantity /
                              shortageDtl.casePack
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-cartonCount`}
                    errorText="Please enter the carton count"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.cartonCount ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.cartonCount = event.target.value
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-quantityShortage`}
                    errorText="Please enter the quantity shortage"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.quantityShortage ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.quantityShortage = event.target.value
                            shortageDtl.shortageCarton =
                              event.target.value / shortageDtl.casePack
                            shortageDtl.shortageCost = parseFloat(
                              (
                                event.target.value * shortageDtl.unitCost
                              ).toFixed(2)
                            )
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-shortageCarton`}
                    errorText="Please enter the shortage carton"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.shortageCarton ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.shortageCarton = event.target.value
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={1}>
                  <Form.Field
                    id={`shortageDetail-${index}-shortageCost`}
                    errorText="Please enter the shortage cost"
                    type="number"
                    className="inputtype"
                    value={shortageDetail?.shortageCost ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = shortageDetails.map(
                        (shortageDtl: any) => {
                          if (shortageDtl.id === shortageDetail.id) {
                            shortageDtl.shortageCost = twoDecimals(
                              event.target.value
                            )
                          }
                          return shortageDtl
                        }
                      )
                      setShortageDetails(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs>
                  <Grid.Container className="hc-pt-min">
                    <Grid.Item>
                      <Button
                        iconOnly
                        aria-label="Remove Shortage"
                        size="dense"
                        disabled={completionStatus.shortage}
                        onClick={async () => {
                          handleRemoveShortageDetails(shortageDetail.id)
                        }}
                      >
                        <EnterpriseIcon icon={MinusIcon} color="black" />
                      </Button>
                    </Grid.Item>
                    <Grid.Item className="hc-pl-none">
                      <Button
                        iconOnly
                        aria-label="Add Shortage Details"
                        size="dense"
                        disabled={
                          checkValidItemDetails(shortageDetail) ||
                          state.invoiceItemDetails ||
                          completionStatus.shortage
                        }
                        onClick={async () => handleAddShortageDetails()}
                      >
                        <EnterpriseIcon icon={PlusIcon} color="blue" />
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            ))}
            <Grid.Container>
              {displayCols.shortageCartons && (
                <Grid.Item xs={2} className="hc-pb-none">
                  <Form.Field
                    id="claimDetails.shortageCartons"
                    label="Total Cartons"
                    type="number"
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        'claimDetails.shortageCartons',
                        event.target.value ? event.target.value : undefined
                      )
                    }}
                    value={formData?.claimDetails?.shortageCartons ?? ''}
                    className="inputtype hc-ta-left"
                    error={
                      formik.errors.claimDetails?.shortageCartons !== undefined
                    }
                    errorText={formik.errors.claimDetails?.shortageCartons}
                    disabled={state.isDocShowable}
                  />
                </Grid.Item>
              )}
              {displayCols.shortageAmount && (
                <Grid.Item xs={2} className="hc-pb-none">
                  <Form.Field
                    id="claimDetails.shortageAmount"
                    label="Total Amount"
                    type="number"
                    onChange={(event: any) => {
                      formik.setFieldValue(
                        'claimDetails.shortageAmount',
                        twoDecimals(event.target.value) ?? undefined
                      )
                    }}
                    value={formData?.claimDetails?.shortageAmount ?? ''}
                    className="inputtype hc-ta-left"
                    error={
                      formik.errors.claimDetails?.shortageAmount !== undefined
                    }
                    errorText={formik.errors.claimDetails?.shortageAmount}
                    disabled={state.isDocShowable}
                  />
                </Grid.Item>
              )}
            </Grid.Container>
          </ExpandableSection.Content>
        </ExpandableSection>
      </div>
      <div className="hc-pt-xl">
        <ExpandableSection startExpanded toggleLocation="left" padding="dense">
          <Heading size={6}>{'Different Receipts Used if any?'}</Heading>
          <ExpandableSection.Content>
            <Grid.Container className="hc-pt-normal">
              <Grid.Item xs={2}>
                <Input.Label>Location</Input.Label>
              </Grid.Item>
              <Grid.Item xs={2}>
                <Input.Label>PO #</Input.Label>
              </Grid.Item>
              <Grid.Item xs={2}>
                <Input.Label>Receipt #</Input.Label>
              </Grid.Item>
              <Grid.Item xs={2}>
                <Input.Label>Number of Cartons</Input.Label>
              </Grid.Item>
              <Grid.Item xs={2}>
                <Input.Label>Dollar Amount</Input.Label>
              </Grid.Item>
            </Grid.Container>
            {receiptsDetail?.map((receiptDetail: any, index: any) => (
              <Grid.Container className="hc-pt-normal hc-mr-xl">
                <Grid.Item xs={2} key={index}>
                  <Form.Field
                    id={`receiptDetail-${index}-location`}
                    type="number"
                    value={receiptDetail?.location ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = receiptsDetail.map(
                        (receipt: any) => {
                          if (receipt.id === receiptDetail.id) {
                            receipt.location = event.target.value
                          }
                          return receipt
                        }
                      )
                      setReceiptsDetail(newInputFields)
                    }}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 4)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    id={`receiptDetail-${index}-poId`}
                    errorText="Please enter the PO Id"
                    type="number"
                    value={receiptDetail?.poId ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = receiptsDetail.map(
                        (receipt: any) => {
                          if (receipt.id === receiptDetail.id) {
                            receipt.poId = event.target.value
                          }
                          return receipt
                        }
                      )
                      setReceiptsDetail(newInputFields)
                    }}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseFloat(e.target.value))
                        .toString()
                        .slice(0, 11)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    id={`receiptDetail-${index}-receiptId`}
                    errorText="Please enter the Receipt"
                    type="text"
                    value={receiptDetail?.receiptId ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = receiptsDetail.map(
                        (receipt: any) => {
                          if (receipt.id === receiptDetail.id) {
                            receipt.receiptId = event.target.value
                          }
                          return receipt
                        }
                      )
                      setReceiptsDetail(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    id={`receiptDetail-${index}-cartonsCount`}
                    errorText="Please enter the cartons count"
                    type="number"
                    value={receiptDetail?.cartonsCount ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = receiptsDetail.map(
                        (receipt: any) => {
                          if (receipt.id === receiptDetail.id) {
                            receipt.cartonsCount = event.target.value
                          }
                          return receipt
                        }
                      )
                      setReceiptsDetail(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Form.Field
                    id={`receiptDetail-${index}-cartonCost`}
                    errorText="Please enter the carton cost"
                    type="number"
                    value={receiptDetail?.cartonCost ?? ''}
                    onChange={(event: any) => {
                      const newInputFields = receiptsDetail.map(
                        (receipt: any) => {
                          if (receipt.id === receiptDetail.id) {
                            receipt.cartonCost = twoDecimals(event.target.value)
                          }
                          return receipt
                        }
                      )
                      setReceiptsDetail(newInputFields)
                    }}
                    disabled={completionStatus.shortage}
                  />
                </Grid.Item>

                <Grid.Item xs>
                  <Grid.Container className="hc-pt-min">
                    <Grid.Item>
                      <Button
                        iconOnly
                        aria-label="Remove Receipts Detail"
                        size="dense"
                        disabled={
                          receiptsDetail?.length === 1 ||
                          completionStatus.shortage
                        }
                        onClick={async () => {
                          handleRemoveReceiptsDetail(receiptDetail.id)
                        }}
                      >
                        <EnterpriseIcon icon={MinusIcon} color="black" />
                      </Button>
                    </Grid.Item>
                    <Grid.Item className="hc-pl-none">
                      <Button
                        iconOnly
                        aria-label="Add Receipts Detail"
                        size="dense"
                        disabled={
                          checkReceiptsDetails(receiptDetail) ||
                          completionStatus.shortage
                        }
                        onClick={async () => handleAddReceiptsDetail()}
                      >
                        <EnterpriseIcon icon={PlusIcon} color="blue" />
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            ))}
          </ExpandableSection.Content>
        </ExpandableSection>
      </div>
      <Grid.Container className="hc-mr-lg hc-mt-normal">
        <Grid.Item xs></Grid.Item>
        {!completionStatus.shortage && (
          <Grid.Item className="hc-mr-min">
            <Button
              type="primary"
              onClick={async () => {
                assignToFormik()
              }}
            >
              Next
            </Button>
          </Grid.Item>
        )}
      </Grid.Container>
    </>
  )
}

export default ShortageDetails
