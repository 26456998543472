import React, { useEffect } from 'react'
import {
  Grid,
  Heading,
  Form,
  ExpandableSection,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { FormikValues } from 'formik'
import { CommonSearch } from './CommonSearch'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import { findRequired } from '../Services/CheckRequired'
import { CaseType } from '../Services/ConstantData'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import _ from 'lodash'
import { CheckCircleIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface MainProp {
  heading: String
  caseSubTypeValues: any
  docTypeValues?: any
  docSubTypeValues?: any
  reasonCodeValues: any
  formik: FormikValues
  handleSearch: () => void
  importVendor?: boolean
}

const DisputeDetails: React.FC<MainProp> = ({
  heading,
  caseSubTypeValues,
  docTypeValues,
  docSubTypeValues,
  reasonCodeValues,
  formik,
  handleSearch,
  importVendor,
}) => {
  const [state, dispatch] = useGlobalForm()
  const { userProfile } = useProfile()
  const isVendor = userProfile?.isVendor
  const formData = formik.values
  const gridSize = importVendor ? 10 : 5
  const documentLabel =
    formData?.caseType === CaseType.INTERNALPROCESSING && importVendor
      ? 'Reference #'
      : importVendor
      ? 'Claim #'
      : 'Document'
  const caseTypeLabel =
    formData?.caseType !== CaseType.REQUESTFORINFO && importVendor
      ? 'Claim Type'
      : formData?.caseType === CaseType.REQUESTFORINFO
      ? 'Request Type'
      : formData?.caseType === CaseType.DISPUTE
      ? 'Deduction/Invoice Type'
      : 'Case Sub Type'
  const documentTypeLabel =
    formData?.caseType === CaseType.DISPUTE
      ? 'Claim / Doc Type'
      : 'Choose Document Type'
  const documentSubTypeLabel =
    formData?.caseType === CaseType.DISPUTE
      ? 'Claim / Doc Sub Type'
      : 'Choose Document Sub Type'
  const reasonLabel =
    formData?.caseType === CaseType.REQUESTFORINFO
      ? 'Reason for Request?'
      : formData?.caseType === CaseType.DISPUTE
      ? 'Reason for Disputing?'
      : 'Reason for Internal Processing'
  const searchType =
    importVendor && formData?.caseType !== CaseType.INTERNALPROCESSING
      ? 'number'
      : 'text'
  const currentDocType = docTypeValues?.find(
    (docTypeValues: any) =>
      Number(docTypeValues?.id) === Number(formik.values.documentTypeId)
  )
  const currentDocSubType = docSubTypeValues?.find(
    (docSubTypeValues: any) =>
      Number(docSubTypeValues?.id) === Number(formik.values?.documentSubTypeId)
  )
  const currentReasonCode = reasonCodeValues?.find(
    (reasonCodeList: any) => reasonCodeList?.id === formik?.values?.reasonCode
  )

  const docTypeRequired = findRequired(formik.values)?.DocTypeReqFlag
  const reasonCodeRequired = findRequired(formik.values)?.reasonCode
  function clearValues(event: any) {
    formik.setFieldValue('originalDocumentNumber', event.target.value)
    if (formData?.caseType !== CaseType.REQUESTFORINFO) {
      dispatch({
        type: SET_FIELD,
        payload: [
          { id: 'documentHintText', value: '' },
          { id: 'documentFound', value: false },
          { id: 'isDocNextFlag', value: false },
        ],
      })
    } else {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'documentHintText', value: '' }],
      })
    }
    formik.setErrors({})
  }

  useEffect(() => {
    if (reasonCodeValues?.length === 1) {
      formik.setFieldValue('reasonCode', reasonCodeValues[0].value)
      formik.setFieldValue('reasonCodeText', reasonCodeValues[0].label)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonCodeValues])
  return (
    <div className="hc-pa-normal hc-pa-none hc-ma-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="dense">
        <Heading size={6}>
          {heading}{' '}
          {state.showDocuments && (
            <EnterpriseIcon
              icon={CheckCircleIcon}
              color="green"
              className="hc-va-sub"
            />
          )}
        </Heading>
        <ExpandableSection.Content className="hc-pt-none hc-mt-none">
          <Grid.Container className="hc-pt-none hc-ml-expanded">
            {formData?.caseType !== CaseType.REQUESTFORINFO ? (
              <CommonSearch
                buttonId="documentIdSearch"
                inputId="originalDocumentNumber"
                label={documentLabel}
                onChange={(event: any) => {
                  clearValues(event)
                }}
                onBlur={(e: any) => {
                  e.preventDefault()
                  handleSearch()
                }}
                value={_.toUpper(formik.values.originalDocumentNumber)}
                size={5}
                hintText={state.documentHintText}
                type={searchType}
                maxlength="22"
                validSearchIconCheck={state.showDocuments}
                inValidSearchIconCheck={state.isDocNextFlag}
                checkIconCheck={state.disputeSectionFlag}
                infoIconCheck={!state.disputeSectionFlag}
                errorFlag={formik.errors.originalDocumentNumber !== undefined}
                fieldErrorText={formik.errors.originalDocumentNumber}
                docDisable={state.documentFound}
                isRequired={!state.isCarrierVendor}
                isVendorSearch={false}
                focus={!state.isCarrierVendor}
              />
            ) : (
              <Grid.Item xs={10} md={5}>
                <Form.Field
                  id="originalDocumentNumber"
                  label={documentLabel}
                  className="inputtype hc-ta-left"
                  hintText={state.documentHintText}
                  type={searchType}
                  maxLength="22"
                  onChange={(event: any) => {
                    clearValues(event)
                  }}
                  onBlur={(e: any) => {
                    e.preventDefault()
                    handleSearch()
                  }}
                  value={_.toUpper(formData.originalDocumentNumber)}
                  error={formik.errors.originalDocumentNumber !== undefined}
                  errorText={formik.errors.originalDocumentNumber}
                  disabled={state.showDocuments}
                  autoFocus
                  required
                />
              </Grid.Item>
            )}
            <Grid.Item xs={10} md={5}>
              <Form.Field
                id="caseSubType"
                label={caseTypeLabel}
                type="select"
                className="hc-ta-left"
                value={formik.values.caseSubType}
                onUpdate={formik.setFieldValue}
                options={caseSubTypeValues}
                error={formik.errors.caseSubType !== undefined}
                errorText={formik.errors.caseSubType}
                disabled={state.showDocuments}
                required
              />
            </Grid.Item>
          </Grid.Container>
          <Grid.Container className="hc-ml-expanded">
            {(docTypeRequired === 'Y' || !isVendor) && (
              <Grid.Item xs={10} md={gridSize} className="hc-pt-md hc-pb-none">
                <Autocomplete
                  id="documentTypeId"
                  label={documentTypeLabel}
                  onUpdate={(id, value) => {
                    formik.setFieldValue('documentTypeId', value?.value)
                    formik.setFieldValue('documentTypeText', value?.label)
                  }}
                  value={currentDocType}
                  options={docTypeValues}
                  error={formik.errors.documentTypeId !== undefined}
                  errorText={formik.errors.documentTypeId}
                  disabled={state.showDocuments}
                  required={importVendor}
                />
              </Grid.Item>
            )}
            {!isVendor && !importVendor && (
              <Grid.Item xs={10} md={5} className="hc-pt-md">
                <Autocomplete
                  id="documentSubTypeId"
                  label={documentSubTypeLabel}
                  onUpdate={(id, value) => {
                    formik.setFieldValue('documentSubTypeId', value?.value)
                    formik.setFieldValue('documentSubTypeText', value?.label)
                  }}
                  value={currentDocSubType}
                  options={docSubTypeValues}
                  error={formik.errors.documentSubTypeId !== undefined}
                  errorText={formik.errors.documentSubTypeId}
                  disabled={state.showDocuments}
                  required={importVendor}
                />
              </Grid.Item>
            )}
          </Grid.Container>
          <Grid.Container className="hc-ml-expanded">
            <Grid.Item xs={10} md={10} className="hc-pt-md">
              <Autocomplete
                id="reasonCode"
                options={reasonCodeValues}
                label={reasonLabel}
                onUpdate={(id, value) => {
                  formik.setFieldValue(id, value?.value)
                  formik.setFieldValue('reasonCodeText', value?.label)
                }}
                value={currentReasonCode}
                disabled={state.showDocuments}
                required={reasonCodeRequired === 'Y'}
                error={formik.errors.reasonCode !== undefined}
                errorText={formik.errors.reasonCode}
              />
            </Grid.Item>
          </Grid.Container>
          <Grid.Container className="hc-pt-dense hc-ml-expanded">
            <Grid.Item xs={10} md={10} className="hc-pb-none hc-pt-none">
              <Form.Field
                id="caseDescription"
                label="Brief explanation for raising the case:"
                type="textarea"
                onChange={formik.handleChange}
                maxLength="500"
                value={formik.values.caseDescription}
                error={formik.errors.caseDescription !== undefined}
                errorText={formik.errors.caseDescription}
                disabled={state.showDocuments}
                size="dense"
              />
            </Grid.Item>
          </Grid.Container>
          {formData?.caseType === CaseType.DISPUTE && (
            <Grid.Container className="hc-pt-none hc-ml-expanded">
              <Grid.Item xs={10} md={10} className="hc-pb-none hc-pt-none">
                <Input.Info
                  error={formik.errors.originalDocumentCreateDate !== undefined}
                >
                  {formik.errors.originalDocumentCreateDate}
                </Input.Info>
              </Grid.Item>
            </Grid.Container>
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default DisputeDetails
