import React from 'react'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import TMRequestCreate from './TMRequestCreate'
import BPRequestCreate from './BPRequestCreate'
import { AttachmentProvider } from '../Context/AttachmentContext'
import { GlobalFormProvider } from '../Context/GlobalFormStateContext'

const CreateRequest: React.FC = () => {
  const { userProfile } = useProfile()

  return (
    <React.Fragment>
      {userProfile?.isVendor ? (
        <AttachmentProvider>
          <GlobalFormProvider>
            <BPRequestCreate />
          </GlobalFormProvider>
        </AttachmentProvider>
      ) : (
        <AttachmentProvider>
          <GlobalFormProvider>
            <TMRequestCreate />
          </GlobalFormProvider>
        </AttachmentProvider>
      )}
    </React.Fragment>
  )
}
export default CreateRequest
