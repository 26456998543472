import React from 'react'
import { useProfile } from '../Layout/UserProfile/UserProfileProvider'
import BPDashboard from './BPDashboard'
import TMDashboard from './TMDashboard'
import { Layout } from '@enterprise-ui/canvas-ui-react'

const Dashboard = () => {
  const { userProfile } = useProfile()

  return (
    <Layout.Body includeRail>
      <div className="hc-pa-normal">
        {userProfile?.isVendor ? <BPDashboard /> : <TMDashboard />}
      </div>
    </Layout.Body>
  )
}

export default Dashboard
