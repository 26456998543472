import React from 'react'

export type AdminAction =
  | { type: 'SET_ACTIVE_TAB'; tab: string }
  | { type: 'SHOW_EDIT_FORM'; tabId: string }
  | { type: 'HIDE_EDIT_FORM'; tabId: string }
  | { type: 'SHOW_CREATE_FORM'; tabId: string }
  | { type: 'HIDE_CREATE_FORM'; tabId: string }
  | { type: 'SET_EDITABLE_ROW'; tabId: string; row: any }
  | { type: 'RESET'; initialState: AdminState }

export interface Tab {
  id: string
  tabName: string
  editFormVisible: false
  createFormVisible: false
  editableRow: any
}

export interface AdminState {
  activeTab: string
  tabs: Tab[]
}

const AdminStateContext = React.createContext({} as AdminState)

AdminStateContext.displayName = 'AdminStateContext'

const AdminDispatchContext = React.createContext(
  (() => 0) as React.Dispatch<AdminAction>
)

AdminStateContext.displayName = 'AdminDispatchContext'

export { AdminDispatchContext, AdminStateContext }
