import React from 'react'
import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { NewCaseRequest } from '../Common/Queries'
import { CASE_SUMMARY_HEADING, NO_PAYMENT_DETAILS } from '../Common/Constants'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import _ from 'lodash'
import { Section, SectionItem, SummaryHeading } from '../Common/Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'

import { useGlobalForm } from '../Context/GlobalFormStateContext'

const summary_container = css`
  height: 30px;
  overflow-x: hidden;
  overflow-y: auto;
`

interface MainProp {
  caseSummaryDetails: NewCaseRequest
  importVendor?: boolean
  displayCols?: any
}

const ClaimCaseSummary: React.FC<MainProp> = ({
  caseSummaryDetails,
  importVendor,
  displayCols,
}) => {
  const [state] = useGlobalForm()
  const documentAmount = caseSummaryDetails?.originalDocumentAmount
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(caseSummaryDetails?.originalDocumentAmount))
    : undefined
  const claimedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(caseSummaryDetails?.disputedDocumentAmount))
  const departmentId =
    caseSummaryDetails?.departmentId === 0
      ? null
      : _.padStart(caseSummaryDetails.departmentId?.toString(), 3, '0')
  const locationId = caseSummaryDetails.locationId
    ? _.padStart(caseSummaryDetails.locationId?.toString(), 4, '0')
    : null
  return (
    <React.Fragment>
      {state.isVendShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={CASE_SUMMARY_HEADING} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Merch Vendor ID" size={5}>
              {caseSummaryDetails.vendorNumber}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails.vendorName}
            </SectionItem>
            <SectionItem label="Type">
              {caseSummaryDetails.vendorType}
            </SectionItem>
            {state.isCarrierVendor && (
              <SectionItem label="SCAC">
                {caseSummaryDetails.claimDetails?.carrierScacCode}
              </SectionItem>
            )}
          </Section>
        </div>
      )}
      {state.showDocuments && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Claim Case Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Document #">
              {caseSummaryDetails.originalDocumentNumber}
            </SectionItem>
            <SectionItem label="Case Sub Type">
              {caseSummaryDetails.caseSubType}
            </SectionItem>
            <SectionItem label="Document Type">
              {caseSummaryDetails.documentTypeText}
            </SectionItem>
            {!importVendor && (
              <SectionItem label="Document Sub Type">
                {caseSummaryDetails.documentSubTypeText}
              </SectionItem>
            )}
            <SectionItem label="Reason">
              {caseSummaryDetails.reasonCodeText}
            </SectionItem>
            <Grid.Container>
              <Grid.Item xs={12} className="hc-pt-dense hc-pb-dense">
                <Input.Label className="hc-clr-grey02 hc-fs-xs">
                  Claim Description :
                </Input.Label>
                <Input.Label
                  css={summary_container}
                  className="hc-clr-black hc-fs-xs"
                >
                  {caseSummaryDetails.caseDescription}
                </Input.Label>
              </Grid.Item>
            </Grid.Container>
          </Section>
        </div>
      )}
      {state.isDocShowable && state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Payment document amount">
              {caseSummaryDetails?.originalCheckAmount}
            </SectionItem>
            <SectionItem label="Payment invoice">
              {caseSummaryDetails.originalCheckNumber}
            </SectionItem>
            <SectionItem label="Payment date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.originalCheckDate}
                format="L"
              />
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && !state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Details'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem size={3} label="Payment Details">
              {NO_PAYMENT_DETAILS}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Document Details'} />
          <Section labelGridSize={4} detail={false}>
            <SectionItem label="Document amount">{documentAmount}</SectionItem>
            <SectionItem label="Claimed amount">{claimedAmount}</SectionItem>
            <SectionItem label="Purchased order #">
              {caseSummaryDetails.purchaseOrderNumber}
            </SectionItem>
            <SectionItem label="Department">{departmentId}</SectionItem>
            <SectionItem label="Class">
              {caseSummaryDetails.classId}
            </SectionItem>
            <SectionItem label="Location">{locationId}</SectionItem>
            {displayCols.cbdcDocumentDate && (
              <SectionItem label="CBDC Document Date">
                {caseSummaryDetails.claimDetails?.cbdcDocumentDate}
              </SectionItem>
            )}
            {displayCols.retailAmount && (
              <SectionItem label="Retail Amount">
                {caseSummaryDetails.claimDetails?.retailAmount}
              </SectionItem>
            )}
            {displayCols.inventoryRemovalId && (
              <SectionItem label="Inventory Removal Id">
                {caseSummaryDetails.claimDetails?.inventoryRemovalId}
              </SectionItem>
            )}
          </Section>
        </div>
      )}
      {state.isDocShowable && state.isCrVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Carrier Vendor Details'} />
          <Section labelGridSize={2} detail={false}>
            <SectionItem label="Vendor ID">
              {caseSummaryDetails.claimDetails?.carrierVendorId}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails.claimDetails?.carrierVendorName}
            </SectionItem>
            <SectionItem label="SCAC">
              {caseSummaryDetails.claimDetails?.carrierScacCode}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Receipt Details'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem label="Receipt #">
              {caseSummaryDetails.receipts?.join(',')}
            </SectionItem>
            <SectionItem label="Ship Date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.claimDetails?.shipDate}
                format="L"
              />
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Workflow Details'} />
          <Section labelGridSize={3} detail={false}>
            <SectionItem label="Workflow">
              {caseSummaryDetails.workflow}
            </SectionItem>
            <SectionItem label="Assignee">{state.name}</SectionItem>
          </Section>
        </div>
      )}
    </React.Fragment>
  )
}

export default ClaimCaseSummary
