import React from 'react'
import { CaseResponse } from './Queries'
import _ from 'lodash'
import { EDIT_DOCUMENT_HEADING } from './Constants'
import { CaseType } from '../Services/ConstantData'
import { Section, SectionItem } from './Section'
import { BottomBorderDiv } from './SynergyDivComponent'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { getCurrency } from '../Services/TwoDecimalPlaces'
import { FieldsMapping } from '../Services/ConstantData'
import { findClaimFields, formatAmount } from '../Services/ClaimDisplayFields'
import { Grid, Form, Input } from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'

interface MainProp {
  documentDetails: CaseResponse | undefined
  formik?: FormikValues
}

interface OpenClaimProp {
  documentDetails: CaseResponse | undefined
}

const OpenClaimsDetails: React.FC<OpenClaimProp> = ({ documentDetails }) => {
  return (
    <>
      {documentDetails?.claimDetails?.importClaimReversal
        ?.openClaimAmountDetails ? (
        <Grid.Container xs={12} className="hc-pl-none">
          <Grid.Item xs={6}>
            <Input.Label>Claim #</Input.Label>
          </Grid.Item>
          <Grid.Item xs={6}>
            <Input.Label>Claim Amount $</Input.Label>
          </Grid.Item>
        </Grid.Container>
      ) : (
        'None'
      )}
      {documentDetails?.claimDetails?.importClaimReversal?.openClaimAmountDetails?.map(
        (openClaim: any, index: any) => (
          <Grid.Container xs={12} className="hc-pl-none" key={index}>
            <Grid.Item xs={6} className="hc-pb-none">
              <Form.Field
                id={`openClaim-${index}-id`}
                name="claimId"
                type="number"
                value={openClaim?.claimId ?? ''}
                className="inputtype hc-ta-left"
                disabled={true}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-md">
              <Input.Currency
                id={`openClaim-${index}-amount`}
                name="claimDeductionAmount"
                value={
                  openClaim.claimDeductionAmount != null
                    ? openClaim.claimDeductionAmount
                    : ''
                }
                allowDecimals
                className="inputtype hc-ta-left"
                disabled={true}
              />
            </Grid.Item>
          </Grid.Container>
        )
      )}
    </>
  )
}

const EditDocumentDetails: React.FC<MainProp> = ({
  documentDetails,
  formik,
}) => {
  const departmentId =
    documentDetails?.departmentId === 0
      ? 0
      : _.padStart(documentDetails?.departmentId?.toString(), 3, '0')
  const locationId = documentDetails?.locationId
    ? _.padStart(documentDetails?.locationId?.toString(), 4, '0')
    : null
  const claimFiling = documentDetails?.caseType === CaseType.INTERNALPROCESSING
  const disputeCase = documentDetails?.caseType === CaseType.DISPUTE

  let displayCols = claimFiling
    ? findClaimFields(FieldsMapping, 'D', documentDetails)
    : undefined

  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={4}
        detail
        heading={EDIT_DOCUMENT_HEADING}
        expandable
      >
        <SectionItem
          label={
            claimFiling ? 'Source document ( $ )' : 'Original document ( $ )'
          }
        >
          {getCurrency(documentDetails?.originalDocumentAmount)}
        </SectionItem>
        <SectionItem label={claimFiling ? 'Claim filed for' : 'Disputed ( $ )'}>
          {getCurrency(documentDetails?.disputedDocumentAmount)}
        </SectionItem>
        <SectionItem label="Purchase Order #">
          {documentDetails?.purchaseOrderNumber}
        </SectionItem>
        <SectionItem label="Department">{departmentId}</SectionItem>
        <SectionItem label="Class">{documentDetails?.classId}</SectionItem>
        <SectionItem label="Location">{locationId}</SectionItem>
        {disputeCase && (
          <SectionItem label="Document Create Date">
            <DateFormatter
              //@ts-ignore
              date={documentDetails?.originalDocumentCreateDate}
              format="L"
            />
          </SectionItem>
        )}
        {documentDetails?.caseSubType === 'Import Claims' && (
          <SectionItem label="Approved Reversal Amount">
            <Grid.Item xs>
              {documentDetails.claimDetails?.importClaimReversal
                ?.approvedReversalAmount != null
                ? getCurrency(
                    documentDetails.claimDetails?.importClaimReversal
                      ?.approvedReversalAmount
                  )
                : 'None'}
            </Grid.Item>
          </SectionItem>
        )}
        {documentDetails?.caseSubType === 'Import Claims' && (
          <SectionItem label="Write-Off Amount">
            <Grid.Item xs>
              {documentDetails.claimDetails?.importClaimReversal
                ?.writeOffAmount != null
                ? getCurrency(
                    documentDetails.claimDetails?.importClaimReversal
                      ?.writeOffAmount
                  )
                : 'None'}
            </Grid.Item>
          </SectionItem>
        )}
        {documentDetails?.caseSubType === 'Import Claims' && (
          <SectionItem label="Amount applied to open claims">
            <OpenClaimsDetails documentDetails={documentDetails} />
          </SectionItem>
        )}
        {documentDetails?.caseSubType === 'Import Claims' && (
          <SectionItem label="Domestic Vendor #">
            <Grid.Item xs>
              {documentDetails.claimDetails?.importClaimReversal
                ?.domesticAccount?.domesticVendorId != null
                ? documentDetails.claimDetails?.importClaimReversal
                    ?.domesticAccount?.domesticVendorId
                : 'None'}
            </Grid.Item>
          </SectionItem>
        )}
        {documentDetails?.caseSubType === 'Import Claims' && (
          <SectionItem label="Amount applied to Domestic Account">
            <Grid.Item xs>
              {documentDetails.claimDetails?.importClaimReversal
                ?.domesticAccount?.domesticAccountAmount != null
                ? getCurrency(
                    documentDetails.claimDetails?.importClaimReversal
                      ?.domesticAccount?.domesticAccountAmount
                  )
                : 'None'}
            </Grid.Item>
          </SectionItem>
        )}
        {documentDetails?.caseSubType === 'Import Claims' && (
          <SectionItem label="Amount refunded via Wire">
            <Grid.Item xs className="hc-pb-none">
              {documentDetails.claimDetails?.importClaimReversal
                ?.wireRefundAmount != null
                ? getCurrency(
                    documentDetails.claimDetails?.importClaimReversal
                      ?.wireRefundAmount
                  )
                : 'None'}
            </Grid.Item>
          </SectionItem>
        )}
        {documentDetails?.caseSubType === 'Import Claims' && (
          <SectionItem label="Approved Reversal Amount Pending Processing">
            {documentDetails.claimDetails?.importClaimReversal
              ?.approvedPendingReversalAmount != null
              ? getCurrency(
                  documentDetails.claimDetails?.importClaimReversal
                    ?.approvedPendingReversalAmount
                )
              : 'None'}
          </SectionItem>
        )}
        {displayCols?.cbdcDocumentDate !== undefined &&
          displayCols?.cbdcDocumentDate && (
            <SectionItem label="CBDC Document Date">
              <DateFormatter
                //@ts-ignore
                date={documentDetails?.claimDetails?.cbdcDocumentDate}
                format="L"
              />
            </SectionItem>
          )}
        {displayCols?.retailAmount !== undefined &&
          displayCols?.retailAmount && (
            <SectionItem label="Retail Amount">
              {getCurrency(documentDetails?.claimDetails?.retailAmount)}
            </SectionItem>
          )}
        {displayCols?.inventoryRemovalId !== undefined &&
          displayCols?.inventoryRemovalId && (
            <SectionItem label="Inventory Removal Id">
              {documentDetails?.claimDetails?.inventoryRemovalId}
            </SectionItem>
          )}
        {displayCols?.invoiceDate !== undefined && displayCols?.invoiceDate && (
          <SectionItem label="Invoice Date">
            <DateFormatter
              //@ts-ignore
              date={documentDetails?.claimDetails?.invoiceDate}
              format="L"
            />
          </SectionItem>
        )}
        {displayCols?.receiptDate !== undefined && displayCols?.receiptDate && (
          <SectionItem label="Receipt Date">
            <DateFormatter
              //@ts-ignore
              date={documentDetails?.claimDetails?.receiptDate}
              format="L"
            />
          </SectionItem>
        )}
        {displayCols?.cartonsBilled !== undefined &&
          displayCols?.cartonsBilled && (
            <SectionItem label="Cartons Billed">
              {documentDetails?.claimDetails?.cartonsBilled}
            </SectionItem>
          )}
        {displayCols?.cartonsReceived !== undefined &&
          displayCols?.cartonsReceived && (
            <SectionItem label="Cartons Received">
              {documentDetails?.claimDetails?.cartonsReceived}
            </SectionItem>
          )}
        {displayCols?.shortageCartons !== undefined &&
          displayCols?.shortageCartons && (
            <SectionItem label="Shortage Cartons">
              {documentDetails?.claimDetails?.shortageCartons}
            </SectionItem>
          )}
        {displayCols?.shortageAmount !== undefined &&
          displayCols?.shortageAmount && (
            <SectionItem label="Shortage Amount">
              {formatAmount(documentDetails?.claimDetails?.shortageAmount)}
            </SectionItem>
          )}
        {displayCols?.trailerNumber !== undefined &&
          displayCols?.trailerNumber && (
            <SectionItem label="Trailer Number">
              {documentDetails?.claimDetails?.trailerNumber}
            </SectionItem>
          )}
        {displayCols?.trackingNumber !== undefined &&
          displayCols?.trackingNumber && (
            <SectionItem label="Tracking Number">
              {documentDetails?.claimDetails?.trackingNumber}
            </SectionItem>
          )}
        {displayCols?.trailerType !== undefined && displayCols?.trailerType && (
          <SectionItem label="Trailer Type">
            {documentDetails?.claimDetails?.trailerType}
          </SectionItem>
        )}
        {displayCols?.claimType !== undefined && displayCols?.claimType && (
          <SectionItem label="Claim Type">
            {documentDetails?.claimDetails?.claimType}
          </SectionItem>
        )}
        {displayCols?.documentNotification !== undefined &&
          displayCols?.documentNotification && (
            <SectionItem label="Document Notification">
              {documentDetails?.claimDetails?.documentNotification?.join(',')}
            </SectionItem>
          )}
      </Section>
    </BottomBorderDiv>
  )
}

export default EditDocumentDetails
