export const ReportFilters = {
  sideFilters: [
    {
      id: 'fiscalMonth',
      displayName: 'Fiscal Month',
      values: [
        {
          id: '1',
          value: '012',
          label: 'January',
          isChecked: false,
        },
        {
          id: '2',
          value: '001',
          label: 'February',
          isChecked: false,
        },
        {
          id: '3',
          value: '002',
          label: 'March',
          isChecked: false,
        },
        {
          id: '4',
          value: '003',
          label: 'April',
          isChecked: false,
        },
        {
          id: '5',
          value: '004',
          label: 'May',
          isChecked: false,
        },
        {
          id: '6',
          value: '005',
          label: 'June',
          isChecked: false,
        },
        {
          id: '7',
          value: '006',
          label: 'July',
          isChecked: false,
        },
        {
          id: '8',
          value: '007',
          label: 'August',
          isChecked: false,
        },
        {
          id: '9',
          value: '008',
          label: 'September',
          isChecked: false,
        },
        {
          id: '10',
          value: '009',
          label: 'October',
          isChecked: false,
        },
        {
          id: '11',
          value: '010',
          label: 'November',
          isChecked: false,
        },
        {
          id: '12',
          value: '011',
          label: 'December',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'caseType',
      displayName: 'Case Type',
      values: [
        {
          id: '1',
          value: 'Internal Processing',
          label: 'Internal Processing',
          isChecked: false,
        },
        {
          id: '2',
          value: 'Dispute',
          label: 'Dispute',
          isChecked: false,
        },
        {
          id: '3',
          value: 'Request a Copy\\/Info',
          label: 'Request a Copy/Info',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
  ],
}

export const FISCAL_MONTH_YEAR = 'fiscal_create_date.fiscal_month_year.keyword'
export const ASSIGNED_TO = 'lan_id.keyword'
export const RESOLUTION_FISCAL_MONTH_YEAR =
  'fiscal_resolved_date.fiscal_month_year.keyword'
