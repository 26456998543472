/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import {
  Grid,
  Heading,
  Button,
  Form,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import DocumentDetails from '../Common/RequestDocumentDetails'
import RequestDisputeDetails from '../Common/DisputeDetails'
import CaseSummary from '../Common/RequestSummary'
import { useAuth } from '@praxis/component-auth'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import {
  GET_INVOICE,
  CREATE_CASE,
  NewCaseRequest,
  CreateCase,
  GET_VENDOR,
  GET_TAP_WORKFLOW,
  VendorResponse,
  VendorRequest,
  GetInvoicePayments,
  InvoiceRequest,
  TapWorkflowResponse,
  TapWorkflowRequest,
  GET_DOCPREFIXMAPS_VENDOR,
  VendorDocPrefixMapsResponse,
  WorkFlowResponse,
  GET_WORKFLOWS,
  ReceiptRequest,
  GetReceipts,
  ValidateReceipts,
  VALIDATE_RECEIPTS,
  GetImportClaim,
  ImportClaimRequest,
  GET_IMPORTCLAIM,
} from '../Common/Queries'
import {
  findCaseSubTypes,
  findCaseSubTypeValues,
  findDocumentPrefixMapping,
  findDocumentTypeValues,
} from '../Services/DocumentPrefixMapping'
import { CaseType, CaseStatus, CaseSubTypes } from '../Services/ConstantData'
import { findReasonCodeValues } from '../Services/ReasonMapping'
import {
  findWorkflowValue,
  findTapWorkflowMatch,
} from '../Services/WorkflowMapping'
import CancelModal from '../Common/CancelModal'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import * as Yup from 'yup'
import _ from 'lodash'
import moment from 'moment'
import AttachmentDetails from '../Common/AttachmentDetails'
import useAttachment from '../Hooks/useAttachment'
import SaveTimelineModal from '../Common/SaveTimelineModal'
import { BottomBorderDiv, BorderDiv } from '../Common/SynergyDivComponent'
import {
  DATE_FORMAT,
  RESET,
  SET_FIELD,
  REQUEST_HEADING,
} from '../Common/Constants'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import SpinnerLoad from '../Common/SpinnerLoad'
import DuplicateCheck from '../Common/DuplicateCheck'
import {
  GetSearchResults,
  SearchVariable,
  GET_SEARCH_RESULTS,
} from '../../Dashboard/Queries'
import { findRequired } from '../Services/CheckRequired'
import { findAttachmentsource } from '../Services/SearchAttachmetTypeMapping'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'
import { GET_RECEIPTS, ReceiptValidityRequest } from '../Common/Queries'
import AutoClosableModal from '../Common/AutoClosableModal'
import InvalidReceiptsModal from '../Common/InvalidReceiptsModal'

const BPRequestCreate: React.FC = () => {
  const { session } = useAuth()
  const { userProfile } = useProfile()
  const cTS = new Date().toISOString()
  const userName = session?.userInfo?.fullName ?? ''
  const cDesc = `${'Created a New '}${CaseType.REQUESTFORINFO}${' Case '}`
  const [reasonMappingValues, setReasonMappingValues] = useState<any>([])
  const [filteredCaseSubTypeValues, setFilteredCaseSubTypeValues] =
    useState<any>([])
  const [filteredDocTypeValues, setFilteredDocTypeValues] = useState<any>([])
  const [state, dispatch] = useGlobalForm()
  const client = useApolloClient()
  const [searchState, setSearchState] = useState<string>('')
  const [importVendor, setImportVendor] = useState<boolean>(false)
  const env = useEnv() as EnvConfig
  const [invalidReceipts, setInvalidReceipts] = useState<any>([])

  const schema = Yup.object().shape<NewCaseRequest>({
    originalDocumentNumber: Yup.string()
      .min(1, 'Document # Minimum 1 char')
      .required('Enter Document Id')
      .test(
        'Fine',
        'Document # should be 11 digits for Import Claims',
        function (value) {
          const context = this.options.context as any
          var DocumentValueLength = value?.toString().length
          if (
            context.caseSubType === 'Import Claims' &&
            DocumentValueLength !== 11
          ) {
            return false
          }
          return true
        }
      )
      .test(
        'Fine',
        'Please enter a valid DVS document Number',
        function (value) {
          if (value) {
            var documentIdLength = value?.length
            if (
              state.dvsVendor &&
              (documentIdLength < 10 || documentIdLength > 16)
            ) {
              return false
            }
          }
          return true
        }
      ),
    caseSubType: Yup.string().required('Choose Case Type Code'),
    documentTypeId: Yup.number().test(
      'Fine',
      'Choose Doc Type',
      function (value) {
        if (validationFields?.DocTypeReqFlag === 'Y' && value === undefined) {
          return false
        }
        return true
      }
    ),
    reasonCode: Yup.string().test('Fine', 'Choose Reason', function (value) {
      if (validationFields?.reasonCode === 'Y' && value === undefined) {
        return false
      }
      return true
    }),
    departmentId: Yup.number().test(
      'Fine',
      'Not a valid Department number, please try again',
      function (value) {
        if (value === null || value === undefined) {
          return true
        }
        if (state.departmentCheck === false) {
          return false
        }
        return true
      }
    ),
    locationId: Yup.number().test(
      'Fine',
      'Enter Valid Location',
      function (value) {
        const context = this.options.context as any
        if (
          state.disputeAmountCheck &&
          context.caseSubType === 'Delivery Receipt' &&
          value === undefined &&
          !state.dvsVendor
        ) {
          return false
        }
        return true
      }
    ),
    purchaseOrderNumber: Yup.number()
      .test('Fine', 'Enter Valid Purchase Order #', function (value) {
        const context = this.options.context as any
        if (
          state.disputeAmountCheck &&
          context.caseSubType === 'Delivery Receipt' &&
          value === undefined &&
          !state.dvsVendor
        ) {
          return false
        }
        return true
      })
      .test(
        'Fine',
        'PO length should be either from 1-7 or 11',
        function (value) {
          if (value) {
            var POValueLength = value?.toString().length
            if (
              (POValueLength >= 1 && POValueLength <= 7) ||
              POValueLength === 11
            ) {
              return true
            } else {
              return false
            }
          }
          return true
        }
      ),
    requestDetails: Yup.object().shape({
      shipmentDate: Yup.string().test(
        'Fine',
        'Enter only current or past date',
        function (value) {
          let sd = moment(value).format('YYYY-MM-DD')
          let today = moment().startOf('day')
          let duration = moment.duration(today.diff(sd)).asDays()
          if (value && duration < 0) {
            return false
          }
          return true
        }
      ),
    }),
  })
  const formik = useFormik<NewCaseRequest>({
    initialValues: {
      caseType: CaseType.REQUESTFORINFO,
      vendorNumber: userProfile?.defaultVendorId,
      vendorContactName: session?.userInfo?.fullName ?? '',
      vendorEmailAddress: session?.userInfo?.email ?? '',
      vendorName: userProfile?.defaultVendorName,
      status: CaseStatus.NEW,
      caseDescription: '',
      originalDocumentNumber: '',
      originalDocumentCreateDate: '',
      receiptId: '',
      requestDetails: {
        shipmentDate: '',
      },
      auditEntries: {
        activityDescription: cDesc,
        userId: userName,
        status: CaseStatus.NEW,
        activityTimestamp: cTS,
      },
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema<NewCaseRequest>(
          values,
          validationSchema,
          true,
          values
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      await createRequest({
        variables: {
          input: values,
        },
      })
    },
  })

  const formValues = formik.values

  const { data: docprefixvalues } = useQuery<VendorDocPrefixMapsResponse>(
    GET_DOCPREFIXMAPS_VENDOR,
    { fetchPolicy: 'network-only' }
  )
  const { data: datawf } = useQuery<WorkFlowResponse>(GET_WORKFLOWS, {
    fetchPolicy: 'network-only',
  })
  const onReferTapWorkflow = async () => {
    setSearchState('TapLoading')
    try {
      const { data } = await client.query<
        TapWorkflowResponse,
        TapWorkflowRequest
      >({
        query: GET_TAP_WORKFLOW,
        variables: {
          vendorId: formValues.vendorNumber,
        },
        fetchPolicy: 'network-only',
      })
      const getWorkflow = data?.getTapWorkflow
      if (getWorkflow !== null) {
        formik.setFieldValue(
          'workflow',
          findTapWorkflowMatch(
            datawf?.getWorkflows,
            getWorkflow?.vendorTapWorkflow
          )
        )
        formik.setFieldValue(
          'auditEntries.workflow',
          findTapWorkflowMatch(
            datawf?.getWorkflows,
            getWorkflow?.vendorTapWorkflow
          )
        )
      } else {
        formik.setFieldValue('workflow', 'DEFAULT')
        formik.setFieldValue('auditEntries.workflow', 'DEFAULT')
        console.log('Tap Data Found')
      }
    } catch (errors: any) {
      formik.setFieldValue('workflow', 'DEFAULT')
      formik.setFieldValue('auditEntries.workflow', 'DEFAULT')
      console.log('Error', errors?.message)
    }
  }

  const fetchTapDetails = async () => {
    setSearchState('loading')
    try {
      const { data } = await client.query<GetInvoicePayments, InvoiceRequest>({
        query: GET_INVOICE,
        variables: {
          vendorId: formValues.vendorNumber,
          documentId: formValues.originalDocumentNumber,
        },
        fetchPolicy: 'network-only',
      })
      const getInvoiceDetails = data?.getInvoiceDetails
      const getPaymentDetails = data?.getInvoiceDetails?.payment
      const getReceiptDetails = data?.getInvoiceDetails?.receipts

      if (getInvoiceDetails !== null) {
        setSearchState('success')
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'documentHintText', value: 'Document Number Found' },
            { id: 'disputeSectionFlag', value: true },
            { id: 'departmentCheck', value: true },
          ],
        })
        formik.setFieldValue('departmentId', getInvoiceDetails?.deptId)
        formik.setFieldValue('purchaseOrderNumber', getInvoiceDetails?.poId)
        formik.setFieldValue('locationId', getInvoiceDetails?.locId)
        formik.setFieldValue(
          'originalDocumentAmount',
          getInvoiceDetails?.originalDocAmount
        )
        formik.setFieldValue(
          'disputedDocumentAmount',
          getInvoiceDetails?.originalDocAmount
        )
        formik.setFieldValue(
          'originalDocumentCreateDate',
          getInvoiceDetails?.createDate.concat(DATE_FORMAT)
        )
        if (getPaymentDetails !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'documentHintText', value: 'Document Number Found' },
              { id: 'paymentSectionFlag', value: true },
              { id: 'disputeSectionFlag', value: true },
            ],
          })
          formik.setFieldValue(
            'originalCheckAmount',
            getPaymentDetails?.checkAmount
          )
          formik.setFieldValue(
            'originalCheckNumber',
            getPaymentDetails?.checkNumber
          )
          formik.setFieldValue(
            'originalCheckDate',
            getPaymentDetails?.checkDate
          )
        } else {
          formik.setFieldValue('originalCheckAmount', undefined)
          formik.setFieldValue('originalCheckNumber', undefined)
          formik.setFieldValue('originalCheckDate', undefined)
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'paymentSectionFlag', value: false }],
          })
        }
        const receipts = getReceiptDetails.map(
          (receipt) => receipt.receiptNumber
        )

        if (receipts?.length !== 0) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'documentHintText', value: 'Document Number Found' },
              { id: 'disputeSectionFlag', value: true },
              { id: 'receiptsSectionFlag', value: true },
              { id: 'receipts', value: receipts },
            ],
          })
          formik.setFieldValue('receipts', [...receipts])
        } else {
          formik.setFieldValue('receipts', undefined)
        }
      } else {
        setSearchState('notfound')
      }
    } catch (errors) {
      setSearchState('error')
      formik.setFieldValue('departmentId', undefined)
      formik.setFieldValue('purchaseOrderNumber', undefined)
      formik.setFieldValue('locationId', undefined)
      formik.setFieldValue('originalDocumentAmount', undefined)
      formik.setFieldValue('originalDocumentCreateDate', undefined)
      formik.setFieldValue('originalCheckAmount', undefined)
      formik.setFieldValue('originalCheckNumber', undefined)
      formik.setFieldValue('originalCheckDate', undefined)
      dispatch({
        type: SET_FIELD,
        payload: [
          { id: 'documentHintText', value: 'Document Number Not Found' },
          { id: 'disputeSectionFlag', value: false },
          { id: 'paymentSectionFlag', value: false },
        ],
      })
    }
  }
  let caseType = formValues?.caseType
    ? formValues?.caseType.replace(/\//g, '\\/')
    : ''
  const duplicateCaseCheck = async () => {
    try {
      const { data } = await client.query<GetSearchResults, SearchVariable>({
        query: GET_SEARCH_RESULTS,
        variables: {
          query: `vendor_number: (${formValues?.vendorNumber}) AND original_document_number.keyword: (${formValues?.originalDocumentNumber}) AND case_type: (${caseType}) AND status: (${CaseStatus.NEW} OR ${CaseStatus.INPROGRESS} OR ${CaseStatus.REOPEN} OR ${CaseStatus.AWAITINGINFO})`,
          sort: 'create_timestamp',
          page: 1,
          perPage: 10,
          vendorId: `${formValues?.vendorNumber}`,
        },
        fetchPolicy: 'network-only',
      })
      const getSearchResults = data?.getSearchResults?.cases
      if (getSearchResults !== null) {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'showDialog', value: true },
            { id: 'activeDuplicate', value: true },
          ],
        })
      } else {
        duplicateDocumentCheck()
      }
    } catch (errors: any) {
      console.log('duplicateCaseCheck', errors?.message)
    }
  }

  const duplicateDocumentCheck = async () => {
    try {
      const { data } = await client.query<GetSearchResults, SearchVariable>({
        query: GET_SEARCH_RESULTS,
        variables: {
          query: `vendor_number: (${formValues?.vendorNumber}) AND original_document_number.keyword: (${formValues?.originalDocumentNumber}) AND case_type: (${caseType})`,
          sort: 'create_timestamp',
          page: 1,
          perPage: 10,
          vendorId: `${formValues?.vendorNumber}`,
        },
        fetchPolicy: 'network-only',
      })
      const getSearchResults = data?.getSearchResults?.cases
      if (getSearchResults !== null) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'showDialog', value: true }],
        })
      } else {
        if (importVendor) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'showDocuments', value: true },
              { id: 'isDocShowable', value: true },
            ],
          })
        } else {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'showDocuments', value: true }],
          })
        }
      }
    } catch (errors: any) {
      console.log('duplicateDocumentCheck', errors?.message)
    }
  }

  const getVendor = async () => {
    try {
      const { data } = await client.query<VendorResponse, VendorRequest>({
        query: GET_VENDOR,
        variables: {
          id: userProfile?.defaultVendorId,
        },
        fetchPolicy: 'network-only',
      })
      const getVendorDetails = data?.getVendor
      if (getVendorDetails !== null) {
        formik.setFieldValue('caseSubType', '')
        formik.setFieldValue('vendorType', getVendorDetails?.vendorTypeName)
        dispatch({
          type: SET_FIELD,
          payload: [
            {
              id: 'dvsVendor',
              value: getVendorDetails?.dvsFlag === 'Y' ? true : false,
            },
          ],
        })
        const caseSubTypeValues = findCaseSubTypes(
          _.filter(CaseSubTypes, function (caseSubTypeValues) {
            return (
              caseSubTypeValues.caseType === formValues.caseType &&
              caseSubTypeValues.ImportFlag ===
                getVendorDetails?.importVendorFlag
            )
          })
        )
        setFilteredCaseSubTypeValues(caseSubTypeValues)
        if (getVendorDetails?.importVendorFlag === 'Y') {
          if (caseSubTypeValues !== undefined) {
            formik.setFieldValue('caseSubType', caseSubTypeValues[0].value)
          }
          setImportVendor(true)
        } else {
          setImportVendor(false)
        }
      } else {
        console.log('No Data Found')
      }
    } catch (errors: any) {
      console.log('Error', errors?.message)
    }
  }

  const [createCaseDispute, request] = useMutation<
    CreateCase,
    { input: NewCaseRequest }
  >(CREATE_CASE, {
    onCompleted: () => {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'isSubmit', value: true }],
      })
    },
  })

  const {
    uploadAttachment,
    updateStatus,
    attachmentDetails,
    updateAttachmentsToDefaults,
  } = useAttachment()
  const createRequest = async (postDispute: any) => {
    const submitStatuses = attachmentDetails.submitStatuses
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'isSubmit', value: true },
        { id: 'creatingCase', value: true },
        { id: 'caseCreatedFlag', value: true },
      ],
    })
    await createCaseDispute(postDispute)
      .then(async (res) => {
        submitStatuses?.push({
          message: `Case Created with Case Number:${res?.data?.createCase.caseId}`,
          status: true,
        })
        updateStatus(submitStatuses)
        await uploadAttachment(
          String(res?.data?.createCase?.caseId),
          formValues.vendorNumber
        ).then(() => {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isSubmit', value: false },
              { id: 'creatingCase', value: true },
              { id: 'caseCreatedFlag', value: true },
            ],
          })
        })
      })
      .catch(() => {
        submitStatuses?.push({
          message: 'Unable to create case, try again',
          status: false,
        })
        updateStatus(submitStatuses)
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'isSubmit', value: false },
            { id: 'creatingCase', value: true },
            { id: 'caseCreatedFlag', value: false },
          ],
        })
      })
  }

  const validationFields = findRequired(formik.values)
  const onDisputeNextClick = () => {
    formik
      .validateForm()
      .then((res) => {
        console.log(res)
        let fields = [
          'originalDocumentNumber',
          'caseSubType',
          'documentTypeId',
          'reasonCode',
        ]
        if (
          formValues.caseSubType &&
          formValues.caseSubType === 'Delivery Receipt'
        ) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'disputeAmountCheck', value: true }],
          })
        }
        if (validateFields(res, fields)) {
          fetchTapDetails()
          duplicateCaseCheck()
          const documentPrefix = findDocumentPrefixMapping(
            docprefixvalues?.getDocumentTypeMaps,
            formik?.values?.originalDocumentNumber,
            formik?.values?.caseType
          )
          dispatch({
            type: SET_FIELD,
            payload: [
              {
                id: 'documentPrefix',
                value: documentPrefix,
              },
            ],
          })
          if (importVendor) {
            formik.setFieldValue(
              'workflow',
              findWorkflowValue(
                docprefixvalues?.getDocumentTypeMaps,
                formik?.values,
                documentPrefix,
                state.dvsVendor
              )
            )
            formik.setFieldValue(
              'auditEntries.workflow',
              findWorkflowValue(
                docprefixvalues?.getDocumentTypeMaps,
                formik?.values,
                documentPrefix,
                state.dvsVendor
              )
            )
          }
          formik.setErrors({})
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onDocumentNextClick = async () => {
    if (
      formik.values?.originalDocumentCreateDate &&
      formik.values?.originalDocumentCreateDate !== undefined
    ) {
      formik.setFieldValue(
        'originalDocumentCreateDate',
        moment(formik.values.originalDocumentCreateDate)
          .format('YYYY-MM-DD')
          .concat(DATE_FORMAT)
      )
    }
    if (
      formik.values?.requestDetails?.shipmentDate &&
      formik.values?.requestDetails?.shipmentDate !== undefined
    ) {
      formik.setFieldValue(
        'requestDetails.shipmentDate',
        moment(formik.values.requestDetails.shipmentDate)
          .format('YYYY-MM-DD')
          .concat(DATE_FORMAT)
      )
    }
    if (formValues.caseSubType === 'Delivery Receipt') {
      let receipts: any[] = []
      let validReceipts: any[] = []
      let invalidFlag: boolean = false
      let invalidReceipts: any[]
      if (
        formik.values?.purchaseOrderNumber !== undefined &&
        formik.values?.locationId !== undefined
      ) {
        //fetch receipts for po-loc combination
        const { data } = await client.query<GetReceipts, ReceiptRequest>({
          query: GET_RECEIPTS,
          variables: {
            vendorId: formik.values?.vendorNumber,
            poId: formik.values?.purchaseOrderNumber,
            locationId: formik.values?.locationId,
          },
          fetchPolicy: 'network-only',
        })
        const getReceipts = data?.getReceipts
        receipts = getReceipts.map((receipt) => receipt?.receiptNumber)
      }
      //validate receipts that are entered by users
      if (
        formik.values?.receipts !== null &&
        formik.values?.receipts !== undefined &&
        formik.values?.locationId !== undefined
      ) {
        const { data: validityData } = await client.query<
          ValidateReceipts,
          ReceiptValidityRequest
        >({
          query: VALIDATE_RECEIPTS,
          variables: {
            receipts: formik.values?.receipts,
            locationId: formik.values?.locationId,
            vendorId: formik.values?.vendorNumber,
          },
          fetchPolicy: 'network-only',
        })
        validReceipts = validityData.validateReceipts
          .filter((receipt) => receipt.valid === true)
          .map((receipt) => receipt.receiptNumber)

        invalidReceipts = validityData.validateReceipts
          .filter((receipt) => receipt.valid === false)
          .map((receipt) => receipt.receiptNumber)

        setInvalidReceipts(invalidReceipts)
        invalidFlag = invalidReceipts.length !== 0 ? true : false
      }

      if (!invalidFlag) {
        formik.validateForm().then((res) => {
          const fields = [
            'purchaseOrderNumber',
            'locationId',
            'departmentId',
            'requestDetails',
            'shipmentDate',
          ]
          if (validateFields(res, fields)) {
            const allReceipts = [...new Set([...receipts, ...validReceipts])]
            formik.setFieldValue('receipts', allReceipts)
            const [searchAttachmet, searchAttachmetType] = findAttachmentsource(
              env,
              formValues.caseType,
              formValues.caseSubType,
              formValues.documentTypeId ?? 999,
              formValues.reasonCode
            )
            formik.setErrors({})
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'isDocShowable', value: true },
                { id: 'receipts', value: allReceipts },
                { id: 'location', value: formValues.locationId },
                { id: 'searchAttachments', value: searchAttachmet },
                { id: 'searchAttachmentType', value: searchAttachmetType },
              ],
            })
          }
        })
      } else {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'invalidReceiptsFlag', value: true }],
        })
      }
    } else {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'isDocShowable', value: true }],
      })
    }
    formik.setFieldValue(
      'workflow',
      findWorkflowValue(
        docprefixvalues?.getDocumentTypeMaps,
        formik?.values,
        state.documentPrefix,
        state.dvsVendor
      )
    )
    formik.setFieldValue(
      'auditEntries.workflow',
      findWorkflowValue(
        docprefixvalues?.getDocumentTypeMaps,
        formik?.values,
        state.documentPrefix,
        state.dvsVendor
      )
    )
    updateAttachmentsToDefaults()
  }
  const handleReset = () => {
    formik.resetForm()
    dispatch({
      type: RESET,
    })
    getVendor()
  }

  const assignPrefixValues = (values: any) => {
    formik.setFieldValue('caseSubType', values?.caseSubType)
    formik.setFieldValue(
      'workflow',
      values?.workflow !== null ? values?.workflow : 'DEFAULT'
    )
    formik.setFieldValue(
      'auditEntries.workflow',
      values?.workflow !== null ? values?.workflow : 'DEFAULT'
    )
  }

  function validateFields(errors: any, fields: string[]): boolean {
    const keys = _.keysIn(errors)
    return !fields.some((el) => keys.includes(el))
  }

  const handleSearch = () => {
    formik.validateForm().then(async (res) => {
      const fields = ['originalDocumentNumber']
      if (validateFields(res, fields)) {
        if (!importVendor) {
          const prefixValues = findDocumentPrefixMapping(
            docprefixvalues?.getDocumentTypeMaps,
            formik?.values?.originalDocumentNumber,
            formik?.values?.caseType
          )
          dispatch({
            type: SET_FIELD,
            payload: [
              {
                id: 'documentPrefix',
                value: prefixValues,
              },
            ],
          })
          if (prefixValues !== undefined) {
            setFilteredCaseSubTypeValues(
              findCaseSubTypeValues(prefixValues, formik?.values)
            )
            assignPrefixValues(prefixValues[0])
          } else {
            setFilteredCaseSubTypeValues(
              findCaseSubTypes(
                _.filter(CaseSubTypes, function (caseSubTypeValues) {
                  return (
                    caseSubTypeValues.caseType === formValues.caseType &&
                    caseSubTypeValues.caseSubType !== 'Vendor Income'
                  )
                })
              )
            )
          }
          formik.setErrors({})
        } else {
          formik.setErrors({})
          try {
            const { data } = await client.query<
              GetImportClaim,
              ImportClaimRequest
            >({
              query: GET_IMPORTCLAIM,
              variables: {
                vendorId: formik.values?.vendorNumber,
                claimId: formik.values?.originalDocumentNumber,
              },
              fetchPolicy: 'network-only',
            })
            const claimDetails = data?.getImportClaim
            const documentTypeValue = _.find(
              filteredDocTypeValues,
              function (o) {
                return o?.label.startsWith(claimDetails.claimTypeCode)
              }
            )
            formik.setFieldValue('documentTypeId', documentTypeValue?.id)
            formik.setFieldValue('documentTypeText', documentTypeValue?.label)
          } catch (err) {
            console.log('err while fetching import claim details')
          }
        }
      } else {
        formik.setErrors({ originalDocumentNumber: res.originalDocumentNumber })
      }
    })
  }

  useEffect(() => {
    setReasonMappingValues([])
    setFilteredDocTypeValues([])
    formik.setFieldValue('reasonCode', '')
    formik.setFieldValue('reasonCodeText', '')
    if (formik.values?.caseSubType) {
      if (docprefixvalues?.getDocumentTypeMaps) {
        setFilteredDocTypeValues(
          findDocumentTypeValues(
            docprefixvalues?.getDocumentTypeMaps,
            formik?.values
          )
        )
      }
      setReasonMappingValues(
        findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.caseSubType, docprefixvalues?.getDocumentTypeMaps])

  useEffect(() => {
    setReasonMappingValues(
      findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
    )
    formik.setFieldValue('reasonCode', '')
    formik.setFieldValue('reasonCodeText', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.documentTypeId])

  useEffect(() => {
    if (userProfile) {
      getVendor()
    }
    formik.setFieldValue('vendorNumber', userProfile?.defaultVendorId)
    formik.setFieldValue('vendorName', userProfile?.defaultVendorName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  useEffect(() => {
    if (formValues.vendorNumber) {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'documentHintText', value: '' }],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.vendorNumber])

  useEffect(() => {
    if (formValues.workflow === 'USEVEN') {
      onReferTapWorkflow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.workflow])

  useEffect(() => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'isDocNextFlag', value: true }],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout.Body includeRail>
      <Grid.Container justify="space-between" className="hc-pa-none">
        <Grid.Item sm={12} md={7}>
          <Grid.Container justify="space-between" className="hc-pa-none">
            <Grid.Item justify-content="center">
              <Heading
                size={6}
                className="hc-mt-normal hc-mb-normal hc-ml-none"
              >
                CREATE NEW REQUEST
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <Button className="headerButton" onClick={handleReset}>
                RESET FORM
              </Button>
            </Grid.Item>
          </Grid.Container>
          <Form onSubmit={formik.handleSubmit}>
            <BottomBorderDiv className="hc-pb-dense hc-pt-none">
              <RequestDisputeDetails
                heading={REQUEST_HEADING}
                caseSubTypeValues={filteredCaseSubTypeValues}
                docTypeValues={filteredDocTypeValues}
                handleSearch={handleSearch}
                reasonCodeValues={reasonMappingValues}
                formik={formik}
                importVendor={importVendor}
              />
              {!state.showDocuments && (
                <div className="hc-pa-normal hc-pb-none">
                  <Grid.Container direction="row-reverse">
                    <Grid.Item className="hc-pt-none">
                      <Button
                        onClick={() => {
                          onDisputeNextClick()
                        }}
                        type="primary"
                      >
                        Next
                      </Button>
                    </Grid.Item>
                    {searchState === 'loading' && (
                      <SpinnerLoad case={false} createCase />
                    )}
                  </Grid.Container>
                </div>
              )}
            </BottomBorderDiv>
            {state.showDocuments && !importVendor && (
              <BottomBorderDiv className="hc-pb-dense hc-pt-none">
                <DocumentDetails formik={formik} />
                {!state.isDocShowable && (
                  <div className="hc-pa-normal hc-pb-none">
                    <Grid.Container direction="row-reverse">
                      <Grid.Item className="hc-pt-none">
                        <Button
                          onClick={() => {
                            onDocumentNextClick()
                          }}
                          type="primary"
                        >
                          Next
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                )}
              </BottomBorderDiv>
            )}
            {state.isDocShowable && (
              <BottomBorderDiv className="hc-pb-dense hc-pt-none">
                <AttachmentDetails
                  isVendor={userProfile?.isVendor}
                  reasonCode={formValues?.reasonCode}
                />
              </BottomBorderDiv>
            )}
            {state.isDocShowable && (
              <div className="hc-pa-normal hc-pb-none">
                <Grid.Container direction="row-reverse">
                  <Grid.Item hidden={state.isSubmit}>
                    <Button
                      type="secondary"
                      onClick={() => {
                        dispatch({
                          type: SET_FIELD,
                          payload: [{ id: 'isCancel', value: true }],
                        })
                      }}
                      disabled={state.caseCreatedFlag}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="primary"
                      disabled={
                        state.caseCreatedFlag ||
                        attachmentDetails.attachmentTypeNotSet
                      }
                      onClick={() => {
                        if (formValues.caseSubType === 'Delivery Receipt') {
                          dispatch({
                            type: SET_FIELD,
                            payload: [{ id: 'autoClosableModal', value: true }],
                          })
                        } else {
                          formik.handleSubmit()
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            )}
          </Form>
          {(state.creatingCase || request.loading) && (
            <SaveTimelineModal
              caseId={request?.data?.createCase.caseId}
              vendorNumber={formValues.vendorNumber}
              redirectUrl={`/cases/${request?.data?.createCase?.caseId}`}
              showAlert={env.alertWorkflows?.includes(formValues.workflow!!)}
            />
          )}
        </Grid.Item>
        <Grid.Item sm={12} md={5}>
          <Grid.Container>
            <Grid.Item>
              <Heading
                size={6}
                className=" hc-mt-normal hc-mb-normal hc-ml-none"
              >
                SUMMARY OF YOUR CASE
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <BorderDiv scrollable>
            <CaseSummary
              isVendor={userProfile?.isVendor}
              caseSummaryDetails={formValues}
              docTypeReqFlag={validationFields?.DocTypeReqFlag}
              importVendor={importVendor}
            />
          </BorderDiv>
        </Grid.Item>
        <CancelModal
          headingText="Cancel Request Creation!"
          heading="Do You Want to Cancel the Request Creation?"
        />
        <DuplicateCheck
          CaseType={formValues.caseType}
          importVendor={importVendor}
        />
        <AutoClosableModal
          headingText="Request a Copy"
          heading=" Did you find the DR/exception form you were looking for?"
          formik={formik}
        />
        <InvalidReceiptsModal
          heading={
            'The following Receipt IDs are invalid. Please re-enter them correctly.'
          }
          headingText={'Invalid Receipts'}
          invalidReceipts={invalidReceipts}
        />
      </Grid.Container>
    </Layout.Body>
  )
}
export default BPRequestCreate
