import React, { useState, useEffect } from 'react'
import { Grid, Modal, Button, Form } from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { useAuth } from '@praxis/component-auth'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../Common/Constants'
import { activityData } from '../../Services/saveActivity'
import { twoDecimals } from '../../Services/TwoDecimalPlaces'

interface MainProp {
  headingText: String
  formik: FormikValues
  saveStatus: any
}

const EditReferenceModal: React.FC<MainProp> = ({
  headingText,
  formik,
  saveStatus,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const [state, dispatch] = useGlobalForm()
  const [refDocNumber, setRefDocNumber] = useState<string | null>('')
  const [refDocDate, setRefDocDate] = useState<string | null>()
  const [resolutionAmount, setResolutionAmt] = useState<number | undefined>()
  const formData = formik?.values
  const claimData = formik?.values?.claimDetails
  const activityDescription = 'Modified Claim Document Details'

  function saveActivty() {
    formik.setFieldValue('claimDetails.referenceDocumentNumber', refDocNumber)
    formik.setFieldValue(
      'claimDetails.referenceDocumentCreateDate',
      refDocDate ? refDocDate : null
    )
    formik.setFieldValue(
      'resolutionAmount',
      resolutionAmount ? resolutionAmount : null
    )
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    saveStatus()
    clearValues()
  }

  function clearValues() {
    setRefDocNumber(
      claimData?.referenceDocumentNumber
        ? claimData?.referenceDocumentNumber
        : ''
    )
    setRefDocDate(
      claimData?.referenceDocumentCreateDate
        ? claimData?.referenceDocumentCreateDate
        : ''
    )
    setResolutionAmt(formData.resolutionAmount ? formData.resolutionAmount : 0)
  }

  useEffect(() => {
    if (state.docReferenceFlag) {
      setResolutionAmt(state.resAmount)
      setRefDocNumber(
        claimData?.referenceDocumentNumber === null ||
          claimData?.referenceDocumentNumber === undefined
          ? ''
          : claimData?.referenceDocumentNumber
      )
      setRefDocDate(
        claimData?.referenceDocumentCreateDate === null ||
          claimData?.referenceDocumentCreateDate === undefined
          ? ''
          : claimData?.referenceDocumentCreateDate
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.docReferenceFlag])

  useEffect(() => {
    if (claimData?.referenceDocumentNumber) {
      setRefDocNumber(claimData?.referenceDocumentNumber)
    }
    if (claimData?.referenceDocumentCreateDate) {
      setRefDocDate(claimData?.referenceDocumentCreateDate)
    }
    if (formData?.resolutionAmount) {
      setResolutionAmt(formData?.resolutionAmount)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    claimData?.referenceDocumentNumber,
    claimData?.referenceDocumentCreateDate,
    formData?.resolutionAmount,
  ])
  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.docReferenceFlag}
        onRefuse={() => {
          clearValues()
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'docReferenceFlag', value: false }],
          })
        }}
      >
        <div className="hc-pa-normal hc-pa-lg">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={4} className="hc-pb-none">
                  <Form.Field
                    id="claimDetails.referenceDocumentNumber"
                    label="Claim / Deduction #"
                    type="text"
                    maxLength="22"
                    onChange={(event: any) => {
                      setRefDocNumber(event.target.value)
                    }}
                    value={refDocNumber}
                    className="inputtype hc-ta-left"
                  />
                </Grid.Item>
                <Grid.Item xs={4} className="hc-pb-none">
                  <Form.Field
                    id="claimDetails.referenceDocumentCreateDate"
                    label="Claim / Deduction Filed date"
                    type="date"
                    onChange={(event: any) => {
                      setRefDocDate(event.target.value)
                    }}
                    className="inputtype hc-ta-left"
                    value={refDocDate}
                  />
                </Grid.Item>
                <Grid.Item xs={4} className="hc-pb-none">
                  <Form.Field
                    id="resolutionAmount"
                    label="Claim / Deduction ( $ )"
                    type="number"
                    onChange={(event: any) => {
                      setResolutionAmt(Number(twoDecimals(event.target.value)))
                    }}
                    className="inputtype hc-ta-left"
                    value={resolutionAmount || ''}
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item xs></Grid.Item>
                <Grid.Item justify="flex-end">
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          saveActivty()
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={(e: any) => {
                          clearValues()
                          dispatch({
                            type: SET_FIELD,
                            payload: [{ id: 'docReferenceFlag', value: false }],
                          })
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}

export default EditReferenceModal
