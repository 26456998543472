import _ from 'lodash'

export function findClaimFields(
  fieldMappingValues: Array<any> | undefined,
  isDisplayFields: string,
  formikValues: any
): any {
  let mappingValues = undefined
  let fieldMappingValuesList = _.find(
    fieldMappingValues,
    function (fieldNames) {
      return (
        fieldNames.caseType === formikValues?.caseType &&
        fieldNames.caseSubType === formikValues?.caseSubType &&
        Number(fieldNames.documentTypeId) ===
          Number(formikValues?.documentTypeId) &&
        Number(fieldNames.documentSubTypeId) ===
          Number(formikValues?.documentSubTypeId)
      )
    }
  )
  if (fieldMappingValuesList !== undefined) {
    if (isDisplayFields === 'D') {
      mappingValues = fieldMappingValuesList?.displayFields
    } else if (isDisplayFields === 'R') {
      mappingValues = fieldMappingValuesList?.mandatoryFields
    } else {
      mappingValues = fieldMappingValuesList?.claimType
    }
    return mappingValues
  }
  fieldMappingValuesList = _.find(fieldMappingValues, function (fieldNames) {
    return (
      fieldNames.caseType === formikValues?.caseType &&
      fieldNames.caseSubType === formikValues?.caseSubType &&
      Number(fieldNames.documentTypeId) ===
        Number(formikValues?.documentTypeId) &&
      fieldNames.documentSubTypeId === null
    )
  })
  if (fieldMappingValuesList !== undefined) {
    if (isDisplayFields === 'D') {
      mappingValues = fieldMappingValuesList?.displayFields
    } else if (isDisplayFields === 'R') {
      mappingValues = fieldMappingValuesList?.mandatoryFields
    } else {
      mappingValues = fieldMappingValuesList?.claimType
    }
    return mappingValues
  }
  fieldMappingValuesList = _.find(fieldMappingValues, function (fieldNames) {
    return (
      fieldNames.caseType === formikValues?.caseType &&
      fieldNames.caseSubType === formikValues?.caseSubType &&
      fieldNames.documentTypeId === null &&
      fieldNames.documentSubTypeId === null
    )
  })
  if (fieldMappingValuesList !== undefined) {
    if (isDisplayFields === 'D') {
      mappingValues = fieldMappingValuesList?.displayFields
    } else if (isDisplayFields === 'R') {
      mappingValues = fieldMappingValuesList?.mandatoryFields
    } else {
      mappingValues = fieldMappingValuesList?.claimType
    }
    return mappingValues
  } else return undefined
}

export function formatAmount(inputAmount: any): any {
  if (inputAmount) {
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Number(inputAmount))
    return formattedAmount
  } else return undefined
}
