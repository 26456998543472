import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReportBaseLayout, VisualizationTypes } from './ReportBaseLayout'
import { useReport } from './ReportContext/ReportProvider'
import { UPDATE_FILTER } from '../Cases/Search/SearchContext/ActionTypes'
import * as qs from 'qs'
import { useLazyQuery } from '@apollo/client'
import {
  AggregationVariables,
  GetAggregation,
  GET_AGGREGATION,
} from '../Dashboard/Queries'
import { ColDef } from 'ag-grid-community'
import uniq from 'lodash/fp/uniq'
import flow from 'lodash/fp/flow'
import sortBy from 'lodash/fp/sortBy'
import map from 'lodash/fp/map'
import { addEscapeChar, buildReportQuery } from './ReportContext/ReportUtils'

interface ReportData {
  gridRows: any[]
  gridColumns: ColDef[]
  chartData?: any[]
  uniqueSubTypes: string[]
}

export const DupicateReport = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [state, dispatch] = useReport()
  const [reportData, setReportData] = useState<ReportData | undefined>()
  const [loadAggregation, { loading }] = useLazyQuery<
    GetAggregation,
    AggregationVariables
  >(GET_AGGREGATION, {
    fetchPolicy: 'network-only',
    onCompleted: (aggregationDetails) => {
      const aggregation = [...(aggregationDetails.getAggregation ?? [])]
      const gridData = aggregation
        .map((value) => {
          return value?.subType?.map((type) => {
            return {
              vendor_text: value.rootType,
              original_document_number: type.rootType,
              count: type.value,
            }
          })
        })
        .flat()
        .filter((row) => row !== undefined && row.count !== 1)

      const uniqueRows: string[] = flow(
        map((aggs: any) => aggs?.vendor_text),
        uniq,
        sortBy((row) => row)
      )(gridData)

      // set grid data
      setReportData({
        gridRows: gridData,
        gridColumns: [
          {
            field: 'vendor_text',
            headerName: 'Vendor',
            onCellClicked: (props) => {
              navigate(`/cases?&search%5B0%5D=${buildDrillDownUrl(props.data)}`)
            },
          },
          {
            field: 'original_document_number',
            headerName: 'Document Number',
            onCellClicked: (props) => {
              navigate(`/cases?&search%5B0%5D=${buildDrillDownUrl(props.data)}`)
            },
          },
          {
            field: 'count',
            headerName: 'Duplicate Case Count',
            onCellClicked: (props) => {
              navigate(`/cases?&search%5B0%5D=${buildDrillDownUrl(props.data)}`)
            },
          },
        ],
        chartData: [],
        uniqueSubTypes: uniqueRows,
      })
    },
  })

  useEffect(() => {
    dispatch({
      type: UPDATE_FILTER,
      payload: qs.parse(location.search.replace('?', '')),
    })
  }, [dispatch, location.search])

  useEffect(() => {
    handleQueryChange(buildReportQuery(state) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleQueryChange = (query: string) => {
    loadAggregation({
      variables: {
        aggregateBy: [`vendor_text.keyword,${state.report?.id}`],
        consolidateType: state.report?.consolidateType,
        query: query,
        numberOfPartitions: 200,
      },
    })
  }

  const buildDrillDownUrl = (row: any, isGraph: boolean = false): string => {
    const query: any = qs.parse(location.search.replace('?', ''))
    const dateFilter = !isGraph
      ? `(${state.report?.dateFilter.id}: [${state.report?.dateFilter.active?.from} TO ${state.report?.dateFilter.active?.to}])`
      : ''

    const sideFilters = Object.keys(query)
      .filter((key) => key !== 'consolidateType')
      .map((key) => {
        if (query[key] instanceof Array) {
          const filters = query[key]
            .map((filter: string) => `(${addEscapeChar(filter)})`)
            .join(' OR ')
          return `(${key}: ${filters})`
        } else {
          return ''
        }
      })
      .filter((value) => value !== '')
      .join(' AND ')

    const idFilter = `(${state.report?.id}: (${addEscapeChar(
      row.original_document_number
    )}))`

    const vendorText = `(vendor_text: ${row.vendor_text})`

    var arr = [sideFilters, dateFilter, idFilter, vendorText]
      .filter((value: string) => !(value === '') && !value.includes('()'))
      .join(' AND ')

    return arr
  }

  if (state.report == null) {
    return <div>Not Found</div>
  }

  return (
    <ReportBaseLayout
      reportName={state.report.reportName}
      sideFilters={state.report.sideFilters}
      data={reportData?.gridRows ?? []}
      columns={reportData?.gridColumns ?? []}
      chartData={[]}
      uniqueSubTypes={reportData?.uniqueSubTypes ?? []}
      reportType={'aggregation'}
      dynamicFilter={state.report.dynamicFilter}
      handleQueryChange={handleQueryChange}
      loading={loading}
      dateFilter={state.report.dateFilter}
      visualizationTypes={[VisualizationTypes.TABLE]}
    />
  )
}
