import React, { useRef, useEffect, useState } from 'react'
/** @jsxImportSource @emotion/react */
import {
  Grid,
  Button,
  Form,
  Spinner,
  Layout,
  Input,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import {
  UpdateCaseRequest,
  GET_CASE,
  CaseResponse,
  UpdateCaseVar,
  UPDATE_CASE,
  GET_CASE_ATTACHMENTS,
  CaseAttachments,
  CaseAttachmentVariable,
  Case,
  CaseVariable,
  AuditEntryInput,
  NoteInput,
} from '../Common/Queries'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import EditDisputeDetails from '../Common/EditDisputeDetails'
import EditVendorDetails from '../Common/EditVendorDetails'
import EditDocumentDetails from '../Common/EditRequestDocumentDetails'
import EditPaymentDetails from '../Common/EditPaymentDetails'
import EditWorkflowDetails from '../Common/EditWorkflowDetails'
import EditRequestReceiptDetails from '../Common/EditRequestReceiptDetails'
import NotesTab from '../Common/NotesTab'
import AttachmentDetails from '../Common/AttachmentDetails'
import DownloadAttachments from '../Common/DownloadAttachments'
import useAttachment from '../Hooks/useAttachment'
import { CaseStatus } from '../Services/ConstantData'
import {
  SET_FIELD,
  WORKFLOW_SUMMARY_HEADING,
  ATTACHMENTS,
  COMMENTS,
  EDIT_REQUESTINFO_HEADING,
} from '../Common/Constants'
import {
  BottomBorderDiv,
  editButtons,
  editButtonsGroup,
} from '../Common/SynergyDivComponent'
import EditResolutionDetails from '../Common/EditResolutionDetails'
import { useAuth } from '@praxis/component-auth'
import CaseDateSummary from '../Common/CaseDateSummary'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import BreadCrumb from '../Common/BreadCrumb'
import { activityData } from '../Services/saveActivity'
import SpinnerLoad from '../Common/SpinnerLoad'
import DuplicateCaseSummary from '../Common/DuplicateCaseSummary'
import { findDuplicates } from '../Services/DuplicateCases'
import moment from 'moment'
import { REOPEN } from '../Common/Constants'
import ReopenModal from '../Common/ReopenModal'
import ReopenDetails from '../Common/ReopenDetails'

interface Props {
  id: string
  duplicatesCases?: any
}

const BPRequestView: React.FC<Props> = (props) => {
  const { userProfile, hasAnyAdminRole } = useProfile()
  const { session } = useAuth()
  const myDivToFocus = useRef<any>(null)
  const showActivity = useRef<any>(null)
  const [state, dispatch] = useGlobalForm()
  const [caseUpdate, setCaseUpdate] = useState<string>('')
  const userName = session?.userInfo?.fullName ?? ''
  const makeToast = useToaster()
  const { data: casedetails, refetch: caseRefetch } = useQuery<
    Case,
    CaseVariable
  >(GET_CASE, {
    variables: {
      id: parseInt(props.id),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (requestdetails: Case) => {
      const caseData = requestdetails?.getCase
      if (
        caseData?.originalDocumentAmount ||
        caseData?.purchaseOrderNumber ||
        caseData?.departmentId ||
        caseData?.locationId ||
        caseData?.originalCheckDate
      ) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'docReferenceFlag', value: true }],
        })
      }
      if (caseData?.auditEntries !== null) {
        formik.setFieldValue('auditEntries', caseData?.auditEntries)
      }
      dispatch({
        type: SET_FIELD,
        payload: [
          {
            id: 'savedAttachments',
            value: dataattachments?.getCaseAttachments?.map(function (
              attachmentData: any
            ) {
              return {
                fileName: attachmentData.fileName,
                documentType: attachmentData.documentType,
                documentNumber: attachmentData.documentNumber,
                attachmentId: attachmentData.attachmentId,
                createdBy: attachmentData.createdBy,
                createdTimestamp: moment(
                  attachmentData?.createdTimestamp
                ).format('MM/DD/YYYY'),
              }
            }),
          },
          {
            id: 'caseStatus',
            value: caseData?.status,
          },
        ],
      })
    },
    fetchPolicy: 'network-only',
  })
  const duplicateCases = findDuplicates(props.duplicatesCases, props.id)
  const caseData = casedetails?.getCase
  const { data: dataattachments, refetch: refetchAttachments } = useQuery<
    CaseAttachments,
    CaseAttachmentVariable
  >(GET_CASE_ATTACHMENTS, {
    variables: {
      caseId: props.id,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'savedAttachments',
          value: dataattachments?.getCaseAttachments?.map(function (
            attachmentData: any
          ) {
            return {
              fileName: attachmentData.fileName,
              documentType: attachmentData.documentType,
              documentNumber: attachmentData.documentNumber,
              attachmentId: attachmentData.attachmentId,
              createdBy: attachmentData.createdBy,
              createdTimestamp: moment(attachmentData?.createdTimestamp).format(
                'MM/DD/YYYY'
              ),
            }
          }),
        },
      ],
    })
  }, [dataattachments, dispatch])

  const { uploadAttachment, attachmentDetails } = useAttachment()
  const prepareToUploadAttachments = async () => {
    setCaseUpdate(ATTACHMENTS)
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
    formik.setFieldValue('status', caseData?.status)
    const activityDescription = 'Uploaded Attachment'
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    await uploadAttachment(props.id, caseData?.vendorNumber).then(() => {
      formik.handleSubmit()
      refetchAttachments()
    })
  }

  const [updateRequest, { loading: updateLoading }] = useMutation<
    CaseResponse,
    UpdateCaseVar
  >(UPDATE_CASE, {
    onCompleted: () => {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'reOpenModalFlag', value: false }],
      })
      makeToast({
        type: 'success',
        heading: caseUpdate,
        message: 'Success',
      })
    },
  })

  const formik = useFormik<UpdateCaseRequest>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const postRequest = {
        caseDescription: caseData?.caseDescription,
        reasonCode: caseData?.reasonCode,
        reasonCodeText: caseData?.reasonCodeText,
        documentTypeId: caseData?.documentTypeId,
        documentTypeText: caseData?.documentTypeText,
        documentSubTypeId: caseData?.documentSubTypeId,
        documentSubTypeText: caseData?.documentSubTypeText,
        status:
          values?.status && values?.status !== CaseStatus.AWAITINGINFO
            ? values?.status
            : caseData?.status === CaseStatus.AWAITINGINFO
            ? CaseStatus.INPROGRESS
            : caseData?.status,
        lanId:
          values?.lanId !== undefined
            ? values?.lanId
            : caseData?.userProfile?.userId,
        workflow: caseData?.workflow,
        resolutionDate: caseData?.resolutionDate,
        resolutionCode: Number(caseData?.resolutionCode),
        resolutionCodeText: caseData?.resolutionCodeText,
        resolutionText: caseData?.resolutionText,
        departmentId: caseData?.departmentId,
        purchaseOrderNumber: caseData?.purchaseOrderNumber,
        locationId: caseData?.locationId,
        receiptId: caseData?.receiptId,
        receipts: caseData?.receipts,
        requestDetails: {
          receiptId: caseData?.receiptId,
          shipmentDate: caseData?.requestDetails?.shipmentDate,
          carrierVendorName: caseData?.requestDetails?.carrierVendorName,
          carrierVendorContactEmail:
            caseData?.requestDetails?.carrierVendorContactEmail,
          faxNumber: caseData?.requestDetails?.faxNumber,
          proofOfDelivery: caseData?.requestDetails?.proofOfDelivery,
          proofOfShipment: caseData?.requestDetails?.proofOfShipment,
        },
        isReopen: values?.isReopen ? values?.isReopen : caseData?.isReopen,
        reopenDetails: {
          reopenReasonId: values?.reopenDetails?.reopenReasonId
            ? values?.reopenDetails?.reopenReasonId
            : caseData?.reopenDetails?.reopenReasonId,
          reopenReason: values?.reopenDetails?.reopenReason
            ? values?.reopenDetails?.reopenReason
            : caseData?.reopenDetails?.reopenReason,
          reopenDate: values?.reopenDetails?.reopenDate
            ? values?.reopenDetails?.reopenDate
            : caseData?.reopenDetails?.reopenDate,
          reopenUser: values?.reopenDetails?.reopenUser
            ? values?.reopenDetails?.reopenUser
            : caseData?.reopenDetails?.reopenUser,
          previousResolutionDate: values?.reopenDetails?.previousResolutionDate
            ? values?.reopenDetails?.previousResolutionDate
            : caseData?.reopenDetails?.previousResolutionDate,
          previousResolutionUser: values?.reopenDetails?.previousResolutionUser
            ? values?.reopenDetails?.previousResolutionUser
            : caseData?.reopenDetails?.previousResolutionUser,
          previousResolutionCode: values?.reopenDetails?.previousResolutionCode
            ? values?.reopenDetails?.previousResolutionCode
            : caseData?.reopenDetails?.previousResolutionCode,
          previousResolutionCodeText: values?.reopenDetails
            ?.previousResolutionCodeText
            ? values?.reopenDetails?.previousResolutionCodeText
            : caseData?.reopenDetails?.previousResolutionCodeText,
          previousResolutionText: values?.reopenDetails?.previousResolutionText
            ? values?.reopenDetails?.previousResolutionText
            : caseData?.reopenDetails?.previousResolutionText,
          previousResolutionAmount: values?.reopenDetails
            ?.previousResolutionAmount
            ? values?.reopenDetails?.previousResolutionAmount
            : caseData?.reopenDetails?.previousResolutionAmount,
          previousResolutionDocumentNumber: values?.reopenDetails
            ?.previousResolutionDocumentNumber
            ? values?.reopenDetails?.previousResolutionDocumentNumber
            : caseData?.reopenDetails?.previousResolutionDocumentNumber,
        },
        originalDocumentCreateDate: caseData?.originalDocumentCreateDate,
        vendorContactName: caseData?.vendorContactName,
        vendorEmailAddress: caseData?.vendorEmailAddress,
        auditEntries:
          values?.auditEntries?.length !== 0
            ? values?.auditEntries
            : caseData?.auditEntries?.map(function (
                auditEntries: AuditEntryInput
              ) {
                return {
                  activityDescription: auditEntries.activityDescription,
                  userId: auditEntries.userId,
                  workflow: auditEntries.workflow,
                  status: auditEntries.status,
                  assignUserId: auditEntries.assignUserId,
                  activityTimestamp: auditEntries.activityTimestamp,
                }
              }),
        notes:
          values?.notes && values?.notes?.length !== 0
            ? values?.notes
            : caseData?.notes?.map(function (notes: NoteInput) {
                return {
                  userId: notes.userId,
                  userName: notes.userName,
                  commentBody: notes.commentBody,
                  isInternal: notes.isInternal,
                  commentDate: notes.commentDate,
                }
              }),
        agingDate: caseData?.agingDate,
      }
      await updateRequest({
        variables: {
          id: Number(props.id),
          input: postRequest,
        },
      }).then(() => {
        caseRefetch()
      })
    },
  })

  const onAttachmentFocus = () => {
    if (myDivToFocus.current) {
      myDivToFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
      myDivToFocus.current.focus()
    }
  }

  const showActivityCard = () => {
    if (showActivity.current) {
      showActivity.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      showActivity.current.focus()
    }
  }

  const onReopenChange = (e: any) => {
    setCaseUpdate(REOPEN)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'reOpenModalFlag', value: true }],
    })
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
  }

  useEffect(() => {
    if (state?.comments) {
      setCaseUpdate(COMMENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.comments])

  return (
    <Layout.Body includeRail className="hc-pa-md">
      <Grid.Container>
        <BreadCrumb caseId={props.id} />
        <Grid.Item xs={12}>
          <Button
            type="primary"
            css={editButtons}
            onClick={onAttachmentFocus}
            disabled={caseData?.status === CaseStatus.CLOSED}
          >
            Attach Files
          </Button>
          <Button
            type="primary"
            css={editButtonsGroup}
            onClick={showActivityCard}
          >
            Activity
          </Button>
          {hasAnyAdminRole &&
            (caseData?.status === CaseStatus.RESOLVED ||
              caseData?.status === CaseStatus.CLOSED) && (
              <Button
                type="primary"
                css={editButtons}
                onClick={(e: any) => {
                  onReopenChange(e)
                }}
              >
                {'set to Reopen'}
              </Button>
            )}
          {updateLoading && <SpinnerLoad case={false} createCase={false} />}
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify="space-between" className="hc-pa-none">
        <Grid.Item md={7} xs={12}>
          <Form>
            <EditVendorDetails vendorDetails={caseData} />
            <EditDisputeDetails
              heading={EDIT_REQUESTINFO_HEADING}
              disputeDetails={caseData}
            />
            {state.docReferenceFlag && (
              <EditDocumentDetails caseSummaryDetails={caseData} />
            )}
            {caseData?.requestDetails && (
              <EditRequestReceiptDetails receiptDetails={caseData} />
            )}
            {caseData?.originalCheckAmount && (
              <EditPaymentDetails paymentDetails={caseData} />
            )}
            <div id="myDivToFocus" ref={myDivToFocus}>
              <DownloadAttachments />
              {caseData?.status !== CaseStatus.CLOSED && (
                <BottomBorderDiv>
                  <AttachmentDetails
                    isVendor={userProfile?.isVendor}
                    reasonCode={caseData?.reasonCode}
                  />
                  <div className="hc-pa-normal hc-pb-dense hc-pt-none">
                    <Grid.Container direction="row-reverse">
                      <Grid.Item className="hc-pt-none">
                        <Button
                          size="dense"
                          type="primary"
                          onClick={prepareToUploadAttachments}
                          disabled={
                            attachmentDetails.uploadingAttachments ||
                            attachmentDetails.attachments?.length === 0 ||
                            attachmentDetails.attachmentTypeNotSet
                          }
                        >
                          {attachmentDetails.uploadingAttachments && (
                            <Spinner size="dense" />
                          )}
                          Upload New Attachments
                        </Button>
                        {attachmentDetails.uploadingAttachments && (
                          <Input.Label className="hc-fs-overline hc-blh-dense">
                            Uploading Files ...
                          </Input.Label>
                        )}
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                </BottomBorderDiv>
              )}
            </div>
            <ReopenModal
              headingText="Reopen the case"
              formik={formik}
              reopenDetails={caseData}
              saveStatus={formik.handleSubmit}
            />
          </Form>
          <div id="showActivity" ref={showActivity}>
            <Grid.Item className="hc-bg-white">
              <NotesTab
                formik={formik}
                caseDetails={caseData}
                userProfile={userProfile}
                saveComment={formik.handleSubmit}
              />
            </Grid.Item>
          </div>
        </Grid.Item>
        <Grid.Item md={5} xs={12} className="hc-pa-lg">
          <EditWorkflowDetails
            heading={WORKFLOW_SUMMARY_HEADING}
            workflowDetails={caseData}
            attachments={dataattachments?.getCaseAttachments?.length}
          />
          <CaseDateSummary caseDateDetails={caseData} />
          {(caseData?.status === CaseStatus.RESOLVED ||
            caseData?.status === CaseStatus.CLOSED) && (
            <EditResolutionDetails resolutionDetails={caseData} />
          )}
          {caseData?.isReopen !== null && caseData?.isReopen && (
            <ReopenDetails reopenDetails={caseData} />
          )}
          {duplicateCases && (
            <DuplicateCaseSummary
              heading="Duplicate Cases"
              duplicatesCases={duplicateCases}
            />
          )}
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
export default BPRequestView
