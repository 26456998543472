import { Value } from '../../Cases/Search/SearchContext/SearchInitialValues'
import { Report, SideFilter } from './ReportContext'

const sideFilters: SideFilter[] = [
  {
    id: 'case_type',
    displayName: 'Case Type',
    source: 'master',
    values: [
      {
        id: 'Internal Processing',
        value: 'Internal Processing',
        label: 'Internal Processing',
        isChecked: false,
      },
      {
        id: 'Dispute',
        value: 'Dispute',
        label: 'Dispute',
        isChecked: false,
      },
      {
        id: 'Request a Copy/Info',
        value: 'Request a Copy/Info',
        label: 'Request a Copy/Info',
        isChecked: false,
      },
    ],
    type: 'checkbox',
  },
  {
    id: 'workflow',
    displayName: 'Workflows',
    source: 'master',
    values: [],
    type: 'checkbox',
  },
]

const dateFilters: Value[] = [
  {
    id: '7d',
    label: 'Last 7 days',
    value: {
      from: 'now-7d',
      to: 'now',
    },
  },
  {
    id: '30d',
    label: 'Last 30 Days',
    value: {
      from: 'now-30d',
      to: 'now',
    },
  },
  {
    id: '360d',
    label: 'Last 1 Year',
    value: {
      from: 'now-365d',
      to: 'now',
    },
  },
]

export const aggregatedReports: Report[] = [
  {
    id: 'workflow',
    reportName: 'Aggregation by workflow',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
  {
    id: 'case_type',
    reportName: 'Aggregation by Case Type',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
  {
    id: 'case_sub_type',
    reportName: 'Aggregation by Case Sub Type',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dynamicFilter: {
      enabled: true,
      filterName: 'Case Sub Type',
      id: 'aggregation',
    },
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
  {
    id: 'department_id',
    reportName: 'Aggregation by Department',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dynamicFilter: {
      enabled: true,
      filterName: 'Departments',
      id: 'aggregation',
    },
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
  {
    id: 'reason_code_text.keyword',
    reportName: 'Aggregation by Reason Code',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dynamicFilter: {
      enabled: true,
      filterName: 'Reason Codes',
      id: 'aggregation',
    },
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
  {
    id: 'vendor_text.keyword',
    reportName: 'Aggregation by Vendor',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dynamicFilter: {
      enabled: true,
      filterName: 'Vendor Number',
      id: 'aggregation',
    },
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
  {
    id: 'location_id',
    reportName: 'Aggregation by Location',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dynamicFilter: {
      enabled: true,
      filterName: 'Location',
      id: 'aggregation',
    },
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
  {
    id: 'resolution_code_text.keyword',
    reportName: 'Aggregation by Resolution',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dynamicFilter: {
      enabled: true,
      filterName: 'Resolution',
      id: 'aggregation',
    },
    dateFilter: {
      id: 'resolution_date',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
]
