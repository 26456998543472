import React from 'react'
import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const loadingPosition = css`
  height: 80vh;
`
const spinnerHeight = css`
  height: 20px;
  width: 20px;
`

interface MainProp {
  case?: boolean
  createCase?: boolean
}

const SpinnerLoad: React.FC<MainProp> = (props) => {
  return props.case ? (
    <Grid.Container justify="center" align="center" css={loadingPosition}>
      <Grid.Item>
        <Spinner />
      </Grid.Item>
    </Grid.Container>
  ) : props.createCase ? (
    <Grid.Item className="hc-pb-none">
      <Spinner size="dense" />
    </Grid.Item>
  ) : (
    <Spinner size="dense" css={spinnerHeight} />
  )
}

export default SpinnerLoad
