import React from 'react'
import { Input, Grid } from '@enterprise-ui/canvas-ui-react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useNavigate } from 'react-router-dom'

interface MainProp {
  chartData: any
  uniqueSubType: any
  reportType: string
  buildDrillDownUrl?: any
}

const GraphView: React.FC<MainProp> = ({
  chartData,
  uniqueSubType,
  reportType,
  buildDrillDownUrl,
}) => {
  const navigate = useNavigate()
  return (
    <Grid.Item xs={12} sm={12} md={12} xl={12} lg={12}>
      {uniqueSubType?.length && uniqueSubType?.length > 100 && (
        <Input.Label className="hc-fs-overline hc-clr-error">
          *Warning: Not all the data is shown.
        </Input.Label>
      )}
      <ResponsiveContainer
        width="99%"
        height={
          uniqueSubType?.length && uniqueSubType?.length > 100 ? 900 : 600
        }
      >
        <BarChart
          width={650}
          height={600}
          data={chartData?.slice(0, 101)}
          compact={false}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="x_axis_data" />
          <YAxis width={90} />
          <Tooltip />
          <Legend
            wrapperStyle={{ position: 'relative' }}
            iconType="plainline"
            iconSize={7}
            verticalAlign="bottom"
            align="left"
            layout="horizontal"
          />
          {!(
            reportType === 'Comparison' || reportType === 'workflow_management'
          ) &&
            uniqueSubType?.slice(0, 101).map((type: any) => {
              return (
                <Bar
                  onClick={(event) => {
                    buildDrillDownUrl !== undefined &&
                      navigate(
                        `/cases?search%5B0%5D=${buildDrillDownUrl(
                          '',
                          event.x_axis_data,
                          true
                        )}`
                      )
                  }}
                  isAnimationActive={false}
                  barSize={30}
                  key={type?.toString()}
                  dataKey={type?.toString()}
                  stackId={'a'}
                  fill={
                    '#' + Math.floor(Math.random() * 16777015)?.toString(16)
                  }
                />
              )
            })}
          {reportType === 'workflow_management' &&
            uniqueSubType?.slice(0, 101).map((type: any) => {
              return (
                <Bar
                  onClick={(event) => {
                    buildDrillDownUrl !== undefined &&
                      navigate(
                        `/cases?search%5B0%5D=${buildDrillDownUrl(
                          '',
                          event.x_axis_data,
                          true
                        )}`
                      )
                  }}
                  isAnimationActive={false}
                  barSize={30}
                  key={type?.toString()}
                  dataKey={type?.toString()}
                  stackId={'a'}
                  fill={
                    '#' + Math.floor(Math.random() * 16777015)?.toString(16)
                  }
                />
              )
            })}
          {reportType === 'Comparison' &&
            uniqueSubType?.map((type: any) => {
              return (
                <Bar
                  onClick={(event) =>
                    buildDrillDownUrl !== undefined &&
                    navigate(
                      `/cases?search%5B0%5D=${buildDrillDownUrl(
                        '',
                        event.x_axis_data,
                        true
                      )}`
                    )
                  }
                  isAnimationActive={false}
                  barSize={30}
                  key={type.toString()}
                  dataKey={type.toString()}
                  fill={'#' + Math.floor(Math.random() * 16777015).toString(16)}
                />
              )
            })}
        </BarChart>
      </ResponsiveContainer>
    </Grid.Item>
  )
}

export default GraphView
