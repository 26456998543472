import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Input,
  GridContainer,
  GridItem,
  Popover,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { useNavigate, useLocation } from 'react-router'
import { buildReportUrl } from './ReportContext/ReportUtils'
import { DateFilter } from './ReportContext/ReportContext'
import {
  convertToISO,
  convertToUS,
} from './../Cases/Search/SearchContext/SearchUtils'
import _ from 'lodash'

export interface RangeFilterProps {
  dateFilter: DateFilter
}

export const RangeFilter: React.FC<RangeFilterProps> = ({ dateFilter }) => {
  const [activeTab, setActiveTab] = useState('tab_01')
  const [activeFilterLabel, setActiveFilterLabel] = useState<string>('All Time')
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const navigate = useNavigate()
  const location = useLocation()

  const filters = useMemo(() => {
    return dateFilter.options.map((value: any) => ({
      ...value,
      value: JSON.stringify(value.value),
    }))
  }, [dateFilter.options])

  useEffect(() => {
    const activeFilter = dateFilter.active
    const option = filters.find(
      (obj) => obj.value === JSON.stringify(activeFilter)
    )

    if (option !== undefined) {
      setActiveFilterLabel(option.label)
    } else {
      setActiveFilterLabel(`${activeFilter?.from} TO ${activeFilter?.to}`)
      setStartDate(convertToUS(activeFilter?.from))
      setEndDate(convertToUS(activeFilter?.to))
    }
  }, [dateFilter.active, filters])

  const handleChange = (
    id: string,
    type: string,
    event: any,
    searchField?: string
  ) => {
    navigate(
      `${location.pathname}?` +
        buildReportUrl(location, {
          filter: id,
          event: event,
          type: type,
          searchField: searchField,
        })
    )
  }

  return (
    <Popover
      content={
        <div>
          <Tabs
            activeTab={activeTab}
            onTabSelect={(event: any, tab: any) => {
              setActiveTab(tab.name)
            }}
          >
            <Tabs.Item name="tab_01">Quick Filters</Tabs.Item>
            <Tabs.Item name="tab_02">Custom Range</Tabs.Item>
            <Tabs.Content name="tab_02">
              <GridContainer justify="flex-end">
                <GridItem xs={6} className="hc-pb-none">
                  <DatePicker
                    id={`${dateFilter.id}_start_date`}
                    label="Start Date"
                    placeholder="MM/DD/YYYY"
                    onUpdate={(id, value) => {
                      setStartDate(value)
                    }}
                    value={startDate ?? ''}
                    type="gregorian"
                    location="bottom-right"
                  />
                </GridItem>
                <GridItem xs={6} className="hc-pb-none">
                  <DatePicker
                    id={`${dateFilter.id}_end_date`}
                    label="Stop Date"
                    placeholder="MM/DD/YYYY"
                    onUpdate={(id, value) => {
                      setEndDate(value)
                    }}
                    value={endDate ?? ''}
                    type="gregorian"
                    location="bottom-right"
                  />
                </GridItem>
                <GridItem className="hc-pt-none">
                  <Button
                    onClick={() => {
                      handleChange(dateFilter.id, 'date', {
                        from: convertToISO(startDate!!),
                        to: convertToISO(endDate!!),
                      })
                    }}
                    disabled={_.isEmpty(startDate) || _.isEmpty(endDate)}
                  >
                    Apply
                  </Button>
                </GridItem>
              </GridContainer>
            </Tabs.Content>
            <Tabs.Content name="tab_01">
              <Input.Radio
                id={dateFilter.id}
                options={filters}
                onUpdate={(id: any, value: any) => {
                  handleChange(id, 'date', JSON.parse(value))
                }}
                value={JSON.stringify(dateFilter?.active)}
              ></Input.Radio>
            </Tabs.Content>
          </Tabs>
        </div>
      }
      type="clickOnly"
      location="bottom-right"
      size="expanded"
    >
      <Button type="secondary">From: {activeFilterLabel}</Button>
    </Popover>
  )
}
