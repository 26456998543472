import React, { useRef, useState, useEffect } from 'react'
/** @jsxImportSource @emotion/react */
import {
  Grid,
  Button,
  Form,
  Spinner,
  Layout,
  Input,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import {
  UpdateCaseRequest,
  GET_CASE,
  GET_CASE_ATTACHMENTS,
  CaseAttachments,
  CaseAttachmentVariable,
  CaseResponse,
  UpdateCaseVar,
  UPDATE_CASE,
  AuditEntryInput,
  NoteInput,
  Case,
  CaseVariable,
} from '../Common/Queries'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import EditDisputeDetails from '../Common/EditDisputeDetails'
import EditVendorDetails from '../Common/EditVendorDetails'
import EditDocumentDetails from '../Common/EditDocumentDetails'

import EditPaymentDetails from '../Common/EditPaymentDetails'
import EditWorkflowDetails from '../Common/EditWorkflowDetails'
import AttachmentDetails from '../Common/AttachmentDetails'
import DownloadAttachments from '../Common/DownloadAttachments'
import useAttachment from '../Hooks/useAttachment'
import NotesTab from '../Common/NotesTab'
import { CaseStatus } from '../Services/ConstantData'
import ReopenModal from '../Common/ReopenModal'
import {
  EDIT_DISPUTE_HEADING,
  WORKFLOW_SUMMARY_HEADING,
  RESET,
  SET_FIELD,
  ATTACHMENTS,
  COMMENTS,
} from '../Common/Constants'
import {
  BottomBorderDiv,
  editButtons,
  editButtonsGroup,
} from '../Common/SynergyDivComponent'
import EditResolutionDetails from '../Common/EditResolutionDetails'
import EditRecieptDetails from '../Common/EditRecieptDetails'
import { useAuth } from '@praxis/component-auth'
import CaseDateSummary from '../Common/CaseDateSummary'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import BreadCrumb from '../Common/BreadCrumb'
import { activityData } from '../Services/saveActivity'
import SpinnerLoad from '../Common/SpinnerLoad'
import DuplicateCaseSummary from '../Common/DuplicateCaseSummary'
import { findDuplicates } from '../Services/DuplicateCases'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'
import _ from 'lodash'
import moment from 'moment'

interface Props {
  id: string
  duplicatesCases?: any
}

const BPDisputeView: React.FC<Props> = (props) => {
  const { session } = useAuth()
  const env = useEnv() as EnvConfig
  const { userProfile } = useProfile()
  const showActivity = useRef<any>(null)
  const myDivToFocus = useRef<any>(null)
  const [caseUpdate, setCaseUpdate] = useState<string>('')
  const [state, dispatch] = useGlobalForm()
  const userName = session?.userInfo?.fullName ?? ''
  const { data: casedetails, refetch: caseRefetch } = useQuery<
    Case,
    CaseVariable
  >(GET_CASE, {
    variables: {
      id: parseInt(props.id),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (disputeDetails: Case) => {
      const caseData = disputeDetails?.getCase
      formik.setFieldValue('claimDetails', caseData?.claimDetails)
      if (caseData?.auditEntries !== null) {
        formik.setFieldValue('auditEntries', caseData?.auditEntries)
      }
      dispatch({
        type: SET_FIELD,
        payload: [
          {
            id: 'savedAttachments',
            value: dataattachments?.getCaseAttachments?.map(function (
              attachmentData: any
            ) {
              return {
                fileName: attachmentData.fileName,
                documentType: attachmentData.documentType,
                documentNumber: attachmentData.documentNumber,
                attachmentId: attachmentData.attachmentId,
                createdBy: attachmentData.createdBy,
                createdTimestamp: moment(
                  attachmentData?.createdTimestamp
                ).format('MM/DD/YYYY'),
              }
            }),
          },
          {
            id: 'caseStatus',
            value: caseData?.status,
          },
          {
            id: 'maxRangeVendors',
            value: _.find(
              env.attachments.maxRangeVendors,
              function (maxRangeVendors) {
                return (
                  Number(maxRangeVendors) === Number(caseData?.vendorNumber)
                )
              }
            ),
          },
        ],
      })
    },
    fetchPolicy: 'network-only',
  })
  const duplicateCases = findDuplicates(props.duplicatesCases, props.id)
  const caseData = casedetails?.getCase
  const { data: dataattachments, refetch: refetchAttachments } = useQuery<
    CaseAttachments,
    CaseAttachmentVariable
  >(GET_CASE_ATTACHMENTS, {
    variables: {
      caseId: props.id,
    },
    fetchPolicy: 'network-only',
  })
  const makeToast = useToaster()

  const [updateDispute, { loading: updateLoading }] = useMutation<
    CaseResponse,
    UpdateCaseVar
  >(UPDATE_CASE, {
    onCompleted: () => {
      dispatch({
        type: RESET,
      })
      refetchAttachments()
      makeToast({
        type: 'success',
        heading: caseUpdate,
        message: 'Success',
      })
    },
  })

  useEffect(() => {
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'savedAttachments',
          value: dataattachments?.getCaseAttachments?.map(function (
            attachmentData: any
          ) {
            return {
              fileName: attachmentData.fileName,
              documentType: attachmentData.documentType,
              documentNumber: attachmentData.documentNumber,
              attachmentId: attachmentData.attachmentId,
              createdBy: attachmentData.createdBy,
              createdTimestamp: moment(attachmentData?.createdTimestamp).format(
                'MM/DD/YYYY'
              ),
            }
          }),
        },
      ],
    })
  }, [dataattachments, dispatch])

  const formik = useFormik<UpdateCaseRequest>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const updateRequest = {
        reasonCode: caseData?.reasonCode,
        reasonCodeText: caseData?.reasonCodeText,
        caseDescription: caseData?.caseDescription,
        status:
          values?.status && values?.status !== CaseStatus.AWAITINGINFO
            ? values?.status
            : caseData?.status === CaseStatus.AWAITINGINFO
            ? CaseStatus.INPROGRESS
            : caseData?.status,
        workflow: caseData?.workflow,
        lanId: caseData?.userProfile?.userId,
        departmentId: caseData?.departmentId,
        purchaseOrderNumber: caseData?.purchaseOrderNumber,
        locationId: caseData?.locationId,
        receiptId: caseData?.receiptId,
        receipts: caseData?.receipts,
        classId: caseData?.classId,
        resolutionCode: Number(caseData?.resolutionCode),
        resolutionCodeText: caseData?.resolutionCodeText,
        resolutionAmount: caseData?.resolutionAmount,
        resolutionText: caseData?.resolutionText,
        resolutionDate: caseData?.resolutionDate,
        documentTypeId: caseData?.documentTypeId,
        documentTypeText: caseData?.documentTypeText,
        documentSubTypeId: caseData?.documentSubTypeId,
        documentSubTypeText: caseData?.documentSubTypeText,
        disputedDocumentAmount: caseData?.disputedDocumentAmount,
        isReopen: caseData?.isReopen,
        disputeDetails: {
          newDocumentNumber: caseData?.disputeDetails?.newDocumentNumber,
          newDocumentAmount: caseData?.disputeDetails?.newDocumentAmount,
          newCheckNumber: caseData?.disputeDetails?.newCheckNumber,
        },
        claimDetails: {
          carrierScacCode: caseData?.claimDetails?.carrierScacCode,
          carrierVendorContact: caseData?.claimDetails?.carrierVendorContact,
          carrierVendorContactEmail:
            caseData?.claimDetails?.carrierVendorContactEmail,
          carrierVendorId: caseData?.claimDetails?.carrierVendorId,
          carrierVendorName: caseData?.claimDetails?.carrierVendorName,
          isMappedVendor: caseData?.claimDetails?.isMappedVendor,
          referenceDocumentCreateDate:
            caseData?.claimDetails?.referenceDocumentCreateDate,
          referenceDocumentNumber:
            caseData?.claimDetails?.referenceDocumentNumber,
          shipDate: caseData?.claimDetails?.shipDate,
          importClaimReversal: {
            approvedPendingReversalAmount:
              values?.claimDetails?.importClaimReversal
                ?.approvedPendingReversalAmount ??
              caseData?.claimDetails?.importClaimReversal
                ?.approvedPendingReversalAmount,
            approvedReversalAmount:
              values?.claimDetails?.importClaimReversal
                ?.approvedReversalAmount ??
              caseData?.claimDetails?.importClaimReversal
                ?.approvedReversalAmount,
            wireRefundAmount:
              values?.claimDetails?.importClaimReversal?.wireRefundAmount ??
              caseData?.claimDetails?.importClaimReversal?.wireRefundAmount,
            writeOffAmount:
              values?.claimDetails?.importClaimReversal?.writeOffAmount ??
              caseData?.claimDetails?.importClaimReversal?.writeOffAmount,
            domesticAccount: {
              domesticVendorId:
                values?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticVendorId ??
                caseData?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticVendorId,
              domesticAccountAmount:
                values?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticAccountAmount ??
                caseData?.claimDetails?.importClaimReversal?.domesticAccount
                  ?.domesticAccountAmount,
            },
            openClaimAmountDetails: values?.claimDetails?.importClaimReversal
              ?.openClaimAmountDetails
              ? values?.claimDetails?.importClaimReversal?.openClaimAmountDetails.map(
                  (claim) => {
                    return {
                      claimDeductionAmount: claim.claimDeductionAmount,
                      claimId: claim.claimId,
                    }
                  }
                )
              : caseData?.claimDetails?.importClaimReversal?.openClaimAmountDetails?.map(
                  (claim) => {
                    return {
                      claimDeductionAmount: claim.claimDeductionAmount,
                      claimId: claim.claimId,
                    }
                  }
                ),
          },
        },
        reopenDetails: {
          reopenReasonId: caseData?.reopenDetails?.reopenReasonId,
          reopenReason: caseData?.reopenDetails?.reopenReason,
          reopenDate: caseData?.reopenDetails?.reopenDate,
          reopenUser: caseData?.reopenDetails?.reopenUser,
          previousResolutionDate:
            caseData?.reopenDetails?.previousResolutionDate,
          previousResolutionUser:
            caseData?.reopenDetails?.previousResolutionUser,
          previousResolutionCode:
            caseData?.reopenDetails?.previousResolutionCode,
          previousResolutionCodeText:
            caseData?.reopenDetails?.previousResolutionCodeText,
          previousResolutionText:
            caseData?.reopenDetails?.previousResolutionText,
          previousResolutionAmount:
            caseData?.reopenDetails?.previousResolutionAmount,
          previousResolutionDocumentNumber:
            caseData?.reopenDetails?.previousResolutionDocumentNumber,
        },
        vendorContactName: caseData?.vendorContactName,
        vendorEmailAddress: caseData?.vendorEmailAddress,
        auditEntries:
          values?.auditEntries?.length !== 0
            ? values?.auditEntries
            : caseData?.auditEntries?.map(function (
                auditEntries: AuditEntryInput
              ) {
                return {
                  activityDescription: auditEntries.activityDescription,
                  userId: auditEntries.userId,
                  workflow: auditEntries.workflow,
                  status: auditEntries.status,
                  assignUserId: auditEntries.assignUserId,
                  activityTimestamp: auditEntries.activityTimestamp,
                }
              }),
        notes:
          values?.notes && values?.notes?.length !== 0
            ? values?.notes
            : caseData?.notes?.map(function (notes: NoteInput) {
                return {
                  userId: notes.userId,
                  userName: notes.userName,
                  commentBody: notes.commentBody,
                  isInternal: notes.isInternal,
                  commentDate: notes.commentDate,
                }
              }),
        agingDate: caseData?.agingDate,
      }
      await updateDispute({
        variables: {
          id: Number(props.id),
          input: updateRequest,
        },
      }).then(() => {
        caseRefetch()
      })
    },
  })
  const { uploadAttachment, attachmentDetails } = useAttachment()
  const prepareToUploadAttachments = async () => {
    setCaseUpdate(ATTACHMENTS)
    formik.setFieldValue('workflow', caseData?.workflow)
    formik.setFieldValue('lanId', caseData?.userProfile?.userId)
    formik.setFieldValue('status', caseData?.status)
    const activityDescription = 'Uploaded Attachment'
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    await uploadAttachment(props.id, caseData?.vendorNumber).then(() =>
      formik.handleSubmit()
    )
  }

  const onAttachmentFocus = () => {
    if (myDivToFocus.current) {
      myDivToFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
      myDivToFocus.current.focus()
    }
  }

  const showActivityCard = () => {
    if (showActivity.current) {
      showActivity.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      showActivity.current.focus()
    }
  }

  useEffect(() => {
    if (state?.comments) {
      setCaseUpdate(COMMENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.comments])

  return (
    <Layout.Body includeRail className="hc-pa-md">
      <Grid.Container>
        <BreadCrumb caseId={props.id} />
        <Grid.Item xs={12}>
          <Button
            type="primary"
            css={editButtons}
            onClick={onAttachmentFocus}
            disabled={caseData?.status === CaseStatus.CLOSED}
          >
            Attach Files
          </Button>
          <Button
            type="primary"
            css={editButtonsGroup}
            onClick={showActivityCard}
          >
            Activity
          </Button>
          {updateLoading && <SpinnerLoad case={false} createCase={false} />}
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify="space-between" className="hc-pa-none">
        <Grid.Item md={7} xs={12}>
          <Form>
            <EditVendorDetails vendorDetails={caseData} />
            <EditDisputeDetails
              heading={EDIT_DISPUTE_HEADING}
              disputeDetails={caseData}
            />
            <EditDocumentDetails documentDetails={caseData} formik={formik} />
            <EditPaymentDetails paymentDetails={caseData} />
            <EditRecieptDetails receiptDetails={caseData} />
            <div id="myDivToFocus" ref={myDivToFocus}>
              <DownloadAttachments />
              {caseData?.status !== CaseStatus.CLOSED && (
                <BottomBorderDiv>
                  <AttachmentDetails
                    isVendor={userProfile?.isVendor}
                    reasonCode={caseData?.reasonCode}
                  />
                  <div className="hc-pa-normal">
                    <Grid.Container direction="row-reverse">
                      <Grid.Item className="hc-ma-lg">
                        <Button
                          size="dense"
                          type="primary"
                          onClick={prepareToUploadAttachments}
                          disabled={
                            attachmentDetails.uploadingAttachments ||
                            attachmentDetails.attachments?.length === 0 ||
                            attachmentDetails.attachmentTypeNotSet
                          }
                        >
                          {attachmentDetails.uploadingAttachments && (
                            <Spinner size="dense" className="hc-mt-xd" />
                          )}
                          Upload New Attachments
                        </Button>
                        {attachmentDetails.uploadingAttachments && (
                          <Input.Label className="hc-fs-overline hc-mt-xd">
                            Uploading Files ...
                          </Input.Label>
                        )}
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                </BottomBorderDiv>
              )}
            </div>
            <ReopenModal
              headingText="Reopen the case"
              formik={formik}
              reopenDetails={caseData}
              saveStatus={formik.handleSubmit}
            />
          </Form>
          <div id="showActivity" ref={showActivity}>
            <Grid.Item className="hc-bg-white">
              <NotesTab
                formik={formik}
                caseDetails={caseData}
                userProfile={userProfile}
                saveComment={formik.handleSubmit}
              />
            </Grid.Item>
          </div>
        </Grid.Item>
        <Grid.Item md={5} xs={12} className="hc-pa-lg">
          <EditWorkflowDetails
            heading={WORKFLOW_SUMMARY_HEADING}
            workflowDetails={caseData}
            attachments={dataattachments?.getCaseAttachments?.length}
          />
          <CaseDateSummary caseDateDetails={caseData} />
          {(caseData?.status === CaseStatus.RESOLVED ||
            caseData?.status === CaseStatus.CLOSED) && (
            <EditResolutionDetails resolutionDetails={caseData} />
          )}

          {duplicateCases && (
            <DuplicateCaseSummary
              heading="Duplicate Cases"
              duplicatesCases={duplicateCases}
            />
          )}
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
export default BPDisputeView
