import { useEffect, useState } from 'react'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import { EnvProvider } from '@praxis/component-runtime-env'
import { Environment } from './config/appConfig'
import GraphQLProvider from './GraphqlProvider'
import { AuthProvider, ProtectedElement } from '@praxis/component-auth'
import Root from './components/Layout'
import Dashboard from './components/Dashboard'
import CaseList from './components/Cases/index'
import {
  Modal,
  Grid,
  ToastProvider,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import UserProfileProvider from './components/Layout/UserProfile/UserProfileProvider'
import { Reports } from './components/Reports'
import Admin from './components/Admin'
import { ReportProvider } from './components/Reports/ReportContext/ReportProvider'
import { workflowManagement } from './components/Reports/ReportContext/WorkflowManagementInitialValues'
import { WorkflowManagementReport } from './components/Reports/WorkflowManagementReport'
import { HelveticaForTarget } from '@enterprise-ui/component-font'

const App: React.FC = () => {
  const [detectedIe, setDetectedIe] = useState(false)
  const browserDetection = () => {
    // @ts-ignore
    if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode) {
      setDetectedIe(true)
    }
  }

  useEffect(() => {
    browserDetection()
  }, [])
  return (
    <EnvProvider
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {(env: Environment) => {
        return (
          <Layout className="hc-pa-none" theme="target" darkMode={'false'}>
            <AuthProvider {...env.env.auth}>
              <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
              {detectedIe && (
                <Modal
                  headingText="Browser Incompatible"
                  isVisible
                  closeButton={false}
                >
                  <div className="hc-pa-normal">
                    <Grid.Container>
                      <Grid.Item xs>
                        <p className="hc-clr-error">
                          Please use a different Browser to view this
                          Application. Application incompatible with Internet
                          Explorer.
                        </p>
                        <p className="hc-clr-success">
                          Supported Browsers: Google Chrome, Firefox, Safari,
                          Microsoft Edge
                        </p>
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                </Modal>
              )}
              {!detectedIe && (
                <ToastProvider location="top">
                  <GraphQLProvider>
                    <UserProfileProvider>
                      <Router>
                        <Root>
                          <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/cases/*" element={<CaseList />} />
                            <Route
                              path="/reports/*"
                              element={
                                <ProtectedElement
                                  component={Reports}
                                  allowed={[
                                    env.env.auth.adminRole,
                                    env.env.auth.superAdminRole,
                                    env.env.auth.userRole,
                                  ].flat()}
                                />
                              }
                            />
                            <Route
                              path="/workflow_management/:id/*"
                              element={
                                <ProtectedElement
                                  allowed={[env.env.auth.adminRole].flat()}
                                >
                                  <ReportProvider
                                    reportInitialValues={workflowManagement}
                                  >
                                    <WorkflowManagementReport />
                                  </ReportProvider>
                                </ProtectedElement>
                              }
                            />
                            <Route
                              path="/admin/*"
                              element={
                                <ProtectedElement
                                  component={Admin}
                                  allowed={[env.env.auth.superAdminRole].flat()}
                                />
                              }
                            />
                          </Routes>
                        </Root>
                      </Router>
                    </UserProfileProvider>
                  </GraphQLProvider>
                </ToastProvider>
              )}
            </AuthProvider>
          </Layout>
        )
      }}
    </EnvProvider>
  )
}

export default App
