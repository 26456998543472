import React from 'react'
import { Dialog } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  AWAITINGINFO_STATUS_CHANGE_ALERT,
  CREATE_CASE_DOCUMENT_ALERT,
  RESOLVE_CASE_DOCUMENT_ALERT,
  SET_FIELD,
} from './Constants'
import { CaseStatus } from '../Services/ConstantData'

const headingSize = css`
  .C-Heading.\--size-h4 {
    font-size: 15px;
  }
`

interface MainProp {
  modelHeading?: string
  approveLabel?: string
  refuseLabel?: string
}

const SupportingAlert: React.FC<MainProp> = (props) => {
  const [state, dispatch] = useGlobalForm()
  const headingLabel =
    state.alertComponent === CaseStatus.AWAITINGINFO
      ? AWAITINGINFO_STATUS_CHANGE_ALERT
      : state.alertComponent === CaseStatus.RESOLVED
      ? RESOLVE_CASE_DOCUMENT_ALERT
      : CREATE_CASE_DOCUMENT_ALERT
  const approveLabel =
    state.alertComponent === CaseStatus.NEW ? 'I am good' : 'Yes'
  const refuseLabel = 'Go Back'
  return (
    <Dialog
      css={headingSize}
      headingText={headingLabel}
      approveButtonText={approveLabel}
      onApprove={() => {
        state.alertComponent === CaseStatus.AWAITINGINFO
          ? dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'statusAlert', value: false },
                { id: 'changeAwaitingInfo', value: true },
              ],
            })
          : dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'documentAlert', value: false },
                { id: 'createCase', value: true },
              ],
            })
      }}
      onRefuse={() => {
        state.alertComponent === CaseStatus.AWAITINGINFO
          ? dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'statusAlert', value: false },
                { id: 'changeAwaitingInfo', value: false },
              ],
            })
          : dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'documentAlert', value: false },
                { id: 'createCase', value: false },
              ],
            })
      }}
      refuseButtonText={refuseLabel}
      isVisible={state.documentAlert || state.statusAlert}
    />
  )
}

export default SupportingAlert
