import React from 'react'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { useMutation } from '@apollo/client'
import { GET_REOPENCODES, ReopenCodes } from '../../Cases/Common/Queries'
import { CREATE_REOPENCODE, CreateReopenCodeVar } from '../common/AdminQueries'
import {
  Button,
  Form,
  Grid,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'

export interface ReopenCodeForm {
  reopenCodeText?: string
  reopenCodeDescription?: string
  active?: boolean
}

const schema = Yup.object().shape<ReopenCodeForm>({
  reopenCodeText: Yup.string()
    .required('Enter Reopen Code Text')
    .min(5, 'Length shoud be minimum 5 chars')
    .max(100, 'Length should be max 100 chars')
    .test(
      'Fine',
      'Reopen Code Text Already Exist. Please Enter a Unique Reopen Code',
      function (value) {
        const context = this.options.context as any[]

        return !context.some((reason: any) => reason.reopenCodeText === value)
      }
    ),
  reopenCodeDescription: Yup.string()
    .required('Enter Reopen Code Description')
    .min(5, 'Length shoud be minimum 5 chars')
    .max(200, 'Length should be max 200 chars'),
})

const initialValues: ReopenCodeForm = {
  reopenCodeText: undefined,
  reopenCodeDescription: undefined,
  active: true,
}

export const AddReopenCode = (props: any) => {
  const formik = useFormik<ReopenCodeForm>({
    initialValues: { ...initialValues },
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema<ReopenCodeForm>(
          values,
          validationSchema,
          true,
          props.reopenCodes
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      console.log(values)
      await updateReopenCode({
        variables: {
          input: {
            reopenCodeText: values.reopenCodeText!!,
            reopenCodeDescription: values.reopenCodeDescription!!,
            active: values.active,
          },
        },
        refetchQueries: [
          {
            query: GET_REOPENCODES,
          },
        ],
        awaitRefetchQueries: true,
      }).then(() => props.handleClose())
    },
  })

  const [updateReopenCode, { loading }] = useMutation<
    ReopenCodes,
    CreateReopenCodeVar
  >(CREATE_REOPENCODE, {
    onCompleted: (data) => {
      console.log(data)
    },
  })
  return (
    <Modal
      headingText="Create Reopen Code"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible
    >
      <Form onSubmit={formik.handleSubmit}>
        <Grid.Container justify={'center'} className="hc-pa-normal">
          <Grid.Item xs={12} className="hc-ph-lg hc-pt-md">
            <Grid.Container>
              <Grid.Item xs={12}>
                <Form.Field
                  id={'reopenCodeText'}
                  label={'Reopen Code Text'}
                  type="text"
                  required
                  onChange={(e: any) =>
                    formik.setFieldValue('reopenCodeText', e.target.value)
                  }
                  value={formik.values.reopenCodeText}
                  error={
                    formik.touched.reopenCodeText &&
                    formik.errors.reopenCodeText !== undefined
                  }
                  errorText={formik.errors.reopenCodeText}
                />
              </Grid.Item>
              <Grid.Item xs={12} className="hc-pt-none">
                <Form.Field
                  id={'reopenCodeDescription'}
                  label={'Reopen Code Description'}
                  type="textarea"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.reopenCodeDescription}
                  error={
                    formik.touched.reopenCodeDescription &&
                    formik.errors.reopenCodeDescription !== undefined
                  }
                  errorText={formik.errors.reopenCodeDescription}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item xs={12} className="hc-ph-lg hc-pt-none">
            <Grid.Container align="center">
              <Grid.Item xs={3}>
                <Form.Field
                  id="status"
                  type="toggle"
                  label={'Active'}
                  value={formik.values.active}
                  onChange={() => {
                    formik.setFieldValue('active', !formik.values.active)
                  }}
                ></Form.Field>
              </Grid.Item>
              <Grid.Item justify="flex-end" xs={9}>
                <Grid.Container direction="row-reverse" spacing="dense">
                  <Grid.Item>
                    <Button type="submit" disabled={loading}>
                      Save
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      onClick={() => {
                        props.handleClose()
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid.Item>

                  {loading && (
                    <Grid.Item>
                      <Spinner />
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Form>
    </Modal>
  )
}
