import React, { useState, useEffect } from 'react'
import {
  Grid,
  Heading,
  Form,
  Button,
  ExpandableSection,
} from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { FormikValues } from 'formik'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { CaseType } from '../Services/ConstantData'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import { SET_FIELD, WORKFLOW_HEADING } from './Constants'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { assignButtonStyle } from './SynergyDivComponent'

export const formFieldMargin = css`
  margin-top: 13px;
`

interface MainProp {
  formik: FormikValues
  workflowValues: any
  assignedUserValues: any
}

const WorkflowDetails: React.FC<MainProp> = ({
  formik,
  workflowValues,
  assignedUserValues,
}) => {
  const { userProfile } = useProfile()
  const [currentAssignedUser, setCurrentAssignedUser] = useState<any>()
  const [, dispatch] = useGlobalForm()
  const formData = formik?.values

  useEffect(() => {
    if (formData.lanId) {
      setCurrentAssignedUser(
        assignedUserValues.find(
          (assignedUserValues: any) =>
            assignedUserValues?.id === formData?.lanId
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.lanId])
  const onUserAssignment = () => {
    formik.setFieldValue('lanId', userProfile?.userId)
    setCurrentAssignedUser(
      assignedUserValues.find(
        (assignedUserValues: any) =>
          assignedUserValues?.id === userProfile?.userId
      )
    )
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'name',
          value: assignedUserValues.find(
            (assignedUserValues: any) =>
              assignedUserValues?.id === userProfile?.userId
          ).label,
        },
      ],
    })
  }

  return (
    <div className="hc-pa-normal hc-pa-none hc-ma-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="dense">
        <Heading size={6}>{WORKFLOW_HEADING}</Heading>
        <ExpandableSection.Content>
          {formData?.caseType !== CaseType.INTERNALPROCESSING ? (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10} md={5}>
                <Form.Field
                  id="workflow"
                  label="Workflow"
                  type="select"
                  className="hc-ta-left"
                  value={
                    workflowValues.find(
                      (workflow: { value: string }) =>
                        workflow.value.toUpperCase() ===
                        formData.workflow?.toUpperCase()
                    )?.value
                  }
                  onUpdate={formik.setFieldValue}
                  options={workflowValues}
                />
              </Grid.Item>
              <Grid.Item xs={10} md={5}>
                <Autocomplete
                  id="lanId"
                  options={assignedUserValues}
                  value={currentAssignedUser}
                  label="Assignee"
                  onUpdate={(id, value) => {
                    formik.setFieldValue(id, value?.value)
                    dispatch({
                      type: SET_FIELD,
                      payload: [{ id: 'name', value: value?.label }],
                    })
                  }}
                />
              </Grid.Item>
            </Grid.Container>
          ) : (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10} md={5}>
                <Form.Field
                  css={formFieldMargin}
                  id="workflow"
                  label="Workflow"
                  type="select"
                  className="hc-ta-left"
                  value={
                    workflowValues.find(
                      (workflow: { value: string }) =>
                        workflow.value.toUpperCase() ===
                        formData.workflow?.toUpperCase()
                    )?.value
                  }
                  onUpdate={formik.setFieldValue}
                  options={workflowValues}
                />
              </Grid.Item>
              <Grid.Item xs={10} md={5}>
                <Autocomplete
                  id="lanId"
                  options={assignedUserValues}
                  value={currentAssignedUser}
                  label="Assignee"
                  // @ts-ignore
                  rightContent={
                    <Button
                      size="dense"
                      onClick={() => {
                        onUserAssignment()
                      }}
                      css={assignButtonStyle}
                      className="hc-pa-none"
                    >
                      Assign to me
                    </Button>
                  }
                  onUpdate={(id, value) => {
                    formik.setFieldValue(id, value?.value)
                    dispatch({
                      type: SET_FIELD,
                      payload: [{ id: 'name', value: value?.label }],
                    })
                    setCurrentAssignedUser(
                      assignedUserValues.find(
                        (assignedUserValues: any) =>
                          assignedUserValues?.id === value?.value
                      )
                    )
                  }}
                />
              </Grid.Item>
            </Grid.Container>
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default WorkflowDetails
