import React, { useState } from 'react'
import {
  Grid,
  Heading,
  Form,
  ExpandableSection,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { FormikValues } from 'formik'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { DOCUMENT_HEADING, SET_FIELD, RECEIPT_HEADING } from './Constants'

import { useGlobalForm } from '../Context/GlobalFormStateContext'
import _ from 'lodash'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { useApolloClient } from '@apollo/client'
import {
  GetValidDepartment,
  DepartmentRequest,
  GET_DEPARTMENT,
} from './Queries'
import { CheckCircleIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface MainProp {
  formik: FormikValues
}

const DocumentDetails: React.FC<MainProp> = ({ formik }) => {
  const [state, dispatch] = useGlobalForm()
  const formData = formik?.values
  const [userReceipts, setUserReceipts] = useState('')
  const isDeliveryReceipt =
    formData?.caseSubType === 'Delivery Receipt' ? true : false

  function validateFields(errors: any, fields: string[]): boolean {
    const keys = _.keysIn(errors)
    return !fields.some((el) => keys.includes(el))
  }

  const client = useApolloClient()
  const onDepartmentChange = async (departmentId: number) => {
    if (departmentId !== 0 && departmentId < 1000) {
      try {
        const { data } = await client.query<
          GetValidDepartment,
          DepartmentRequest
        >({
          query: GET_DEPARTMENT,
          variables: { departmentId: departmentId },
          fetchPolicy: 'network-only',
        })
        const getDepartmentDetails = data?.getValidDepartment
        if (getDepartmentDetails !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'departmentCheck', value: true }],
          })
          formik.validateForm().then((res: any) => {
            const fields = ['departmentId']
            if (validateFields(res, fields)) {
              formik.setErrors({ res })
            } else {
              formik.setErrors({ departmentId: res.departmentId })
            }
          })
        }
      } catch (errors) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'departmentCheck', value: false }],
        })
        formik.validateForm().then((res: any) => {
          const fields = ['departmentId']
          if (validateFields(res, fields)) {
            formik.setErrors({ res })
          } else {
            formik.setErrors({ departmentId: res.departmentId })
          }
        })
      }
    } else if (departmentId >= 1000) {
      await dispatch({
        type: SET_FIELD,
        payload: [{ id: 'departmentCheck', value: true }],
      })
      formik.validateForm().then((res: any) => {
        const fields = ['departmentId']
        if (validateFields(res, fields)) {
          formik.setErrors({ res })
        } else {
          formik.setErrors({ departmentId: res.departmentId })
        }
      })
    }
  }

  const handleReceiptChange = (e: any) => {
    setUserReceipts(e.target.value)
    let receipts = e.target.value?.split(',')?.filter((id: any) => id !== '')
    formik.setFieldValue(
      'receipts',
      e.target.value !== '' ? receipts : undefined
    )
  }

  return (
    <div className="hc-pl-none hc-ma-none">
      <Grid.Container className="hc-pt-normal hc-pb-normal hc-mv-none hc-ml-expanded">
        <Form.Field
          id="disputeAmountCheck"
          label="Display Document/Receipt input details?"
          type="checkbox"
          checked={state.disputeAmountCheck}
          onChange={(event: any) => {
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'disputeAmountCheck', value: !state.disputeAmountCheck },
              ],
            })
          }}
          value={state.disputeAmountCheck}
          disabled={state.isDocShowable}
        />
      </Grid.Container>

      {state.disputeAmountCheck && (
        <ExpandableSection startExpanded toggleLocation="left" padding="dense">
          <Heading size={6}>
            {DOCUMENT_HEADING + ' '}
            {state.isDocShowable && (
              <EnterpriseIcon
                icon={CheckCircleIcon}
                color="green"
                className="hc-va-sub"
              />
            )}
          </Heading>
          <ExpandableSection.Content>
            {state.disputeSectionFlag && state.disputeAmountCheck && (
              <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
                <Grid.Item xs={10}>
                  <Grid.Container>
                    <Grid.Item xs={10} md={5}>
                      <Input.Label>Original document amount $</Input.Label>
                    </Grid.Item>
                    <Grid.Item>
                      <Input.Label>
                        : {formData?.originalDocumentAmount}
                      </Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Container>
                    <Grid.Item xs={10} md={5}>
                      <Input.Label>Purchased order #</Input.Label>
                    </Grid.Item>
                    <Grid.Item>
                      <Input.Label>
                        : {formData?.purchaseOrderNumber}
                      </Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Container>
                    <Grid.Item xs={10} md={5}>
                      <Input.Label>Department</Input.Label>
                    </Grid.Item>
                    <Grid.Item>
                      <Input.Label>: {formData?.departmentId}</Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Container>
                    <Grid.Item xs={10} md={5}>
                      <Input.Label>Location</Input.Label>
                    </Grid.Item>
                    <Grid.Item>
                      <Input.Label>: {formData?.locationId}</Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Container>
                    <Grid.Item xs={10} md={5}>
                      <Input.Label>Document Date</Input.Label>
                    </Grid.Item>
                    <Grid.Item>
                      <Input.Label>
                        :{' '}
                        <DateFormatter
                          date={formData?.originalDocumentCreateDate}
                          format="L"
                        />
                      </Input.Label>
                    </Grid.Item>
                  </Grid.Container>

                  {state.receiptsSectionFlag && (
                    <Grid.Container>
                      <Grid.Item xs={10} md={5}>
                        <Input.Label>
                          Receipt ID / Appointment Number
                        </Input.Label>
                      </Grid.Item>
                      <Grid.Item>
                        <Input.Label>
                          : {state?.receipts?.join(',')}
                        </Input.Label>
                      </Grid.Item>
                    </Grid.Container>
                  )}
                </Grid.Item>
              </Grid.Container>
            )}
            {!state.disputeSectionFlag && state.disputeAmountCheck && (
              <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
                <Grid.Item xs={10}>
                  <Grid.Container>
                    {state.dvsVendor ? (
                      <Grid.Item xs={10} md={6} className="hc-pb-none">
                        <Form.Field
                          id="dvsPurchaseOrderId"
                          label="Purchase Order #"
                          value={formData?.originalDocumentNumber}
                          disabled
                          className="inputtype hc-ta-left"
                        />
                      </Grid.Item>
                    ) : (
                      <Grid.Item xs={10} md={6} className="hc-pb-none">
                        <Form.Field
                          id="purchaseOrderNumber"
                          label="Purchase Order#"
                          type="number"
                          onChange={(event: any) => {
                            formik.setFieldValue(
                              'purchaseOrderNumber',
                              event.target.value
                                ? event.target.value
                                : undefined
                            )
                          }}
                          onInput={(e: any) => {
                            e.target.value = Math.max(
                              0,
                              parseFloat(e.target.value)
                            )
                              .toString()
                              .slice(0, 11)
                          }}
                          className="inputtype hc-ta-left"
                          error={
                            formik.errors.purchaseOrderNumber !== undefined
                          }
                          errorText={formik.errors.purchaseOrderNumber}
                          disabled={state.isDocShowable}
                          required={isDeliveryReceipt}
                        />
                      </Grid.Item>
                    )}
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="departmentId"
                        label="Department"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'departmentId',
                            event.target.value
                          )
                        }}
                        onInput={(e: any) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 4)
                        }}
                        onBlur={(e: any) => {
                          onDepartmentChange(e.target.value)
                        }}
                        className="inputtype hc-ta-left"
                        error={formik.errors.departmentId !== undefined}
                        errorText={formik.errors.departmentId}
                        disabled={state.isDocShowable}
                      />
                    </Grid.Item>
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="locationId"
                        label="Location"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'locationId',
                            event.target.value ? event.target.value : undefined
                          )
                        }}
                        onInput={(e: any) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 4)
                        }}
                        className="inputtype hc-ta-left"
                        error={formik.errors.locationId !== undefined}
                        errorText={formik.errors.locationId}
                        disabled={state.isDocShowable}
                        required={isDeliveryReceipt && !state.dvsVendor}
                      />
                    </Grid.Item>
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <DatePicker
                        id="originalDocumentCreateDate"
                        label="Document Date"
                        placeholder="MM/DD/YYYY"
                        onUpdate={(id, value) => {
                          formik.setFieldValue(id, value)
                        }}
                        disabled={state.isDocShowable}
                        location="bottom-right"
                      />
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            )}
            {state.disputeAmountCheck && (
              <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
                <Grid.Item xs={10}>
                  <Heading size={6} className="hc-pb-dense">
                    {RECEIPT_HEADING + ' '}
                    {state.isDocShowable && (
                      <EnterpriseIcon
                        icon={CheckCircleIcon}
                        color="green"
                        className="hc-va-sub"
                      />
                    )}
                  </Heading>
                  <Grid.Container>
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="receipts"
                        label={
                          isDeliveryReceipt
                            ? 'Receipt ID / Appointment Number'
                            : 'Receipt ID'
                        }
                        type="text"
                        maxLength="100"
                        onChange={(e: any) => handleReceiptChange(e)}
                        value={userReceipts}
                        className="inputtype hc-ta-left"
                        disabled={state.isDocShowable}
                      />
                    </Grid.Item>
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <DatePicker
                        id="requestDetails.shipmentDate"
                        label="Receipt /Shipment Date"
                        placeholder="MM/DD/YYYY"
                        onUpdate={(id, value) => {
                          formik.setFieldValue(id, value)
                        }}
                        error={
                          formik.errors.requestDetails?.shipmentDate !==
                          undefined
                        }
                        errorText={formik.errors.requestDetails?.shipmentDate}
                        disabled={state.isDocShowable}
                        location="bottom-right"
                      />
                    </Grid.Item>
                  </Grid.Container>
                  {!isDeliveryReceipt && (
                    <React.Fragment>
                      <Grid.Container>
                        <Grid.Item xs={10} md={6} className="hc-pb-none">
                          <Form.Field
                            id="requestDetails.carrierVendorName"
                            label="Carrier Name"
                            type="text"
                            maxLength="35"
                            onChange={formik.handleChange}
                            className="inputtype hc-ta-left"
                            disabled={state.isDocShowable}
                          />
                        </Grid.Item>
                        <Grid.Item xs={10} md={6} className="hc-pb-none">
                          <Form.Field
                            id="requestDetails.carrierVendorContactEmail"
                            label="Email"
                            type="text"
                            maxLength="64"
                            onChange={formik.handleChange}
                            className="inputtype hc-ta-left"
                            disabled={state.isDocShowable}
                          />
                        </Grid.Item>
                      </Grid.Container>
                      <Grid.Container>
                        <Grid.Item xs={10} md={4} className="hc-pb-none">
                          <Form.Field
                            id="requestDetails.faxNumber"
                            label="Fax #"
                            type="text"
                            onChange={formik.handleChange}
                            maxLength="12"
                            className="inputtype hc-ta-left"
                            disabled={state.isDocShowable}
                          />
                        </Grid.Item>
                        <Grid.Item xs={10} md={4} className="hc-pb-none">
                          <Form.Field
                            id="requestDetails.proofOfDelivery"
                            label="Proof Of Delivery"
                            type="text"
                            onChange={formik.handleChange}
                            maxLength="12"
                            className="inputtype hc-ta-left"
                            disabled={state.isDocShowable}
                          />
                        </Grid.Item>
                        <Grid.Item xs={10} md={4} className="hc-pb-none">
                          <Form.Field
                            id="requestDetails.proofOfShipment"
                            label="Proof Of Shipment"
                            type="text"
                            onChange={formik.handleChange}
                            maxLength="12"
                            className="inputtype hc-ta-left"
                            disabled={state.isDocShowable}
                          />
                        </Grid.Item>
                      </Grid.Container>
                    </React.Fragment>
                  )}
                </Grid.Item>
              </Grid.Container>
            )}
          </ExpandableSection.Content>
        </ExpandableSection>
      )}
    </div>
  )
}

export default DocumentDetails
