import { useEffect, useReducer, useCallback } from 'react'
import {
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Input,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { ReasonCode } from './ReasonCodesAdmin'
import {
  GET_REASONCODEMAPS,
  ReasonCodeMapResponse,
  ReasonCodeMaps,
} from '../../Cases/Common/Queries'
import {
  UpdateUpdateReasonCodeMapVar,
  UPDATE_REASONCODE_MAP,
} from '../common/AdminQueries'
import { useMutation } from '@apollo/client'
import DragAndDropList from '../common/DragAndDropList'

export interface InitialReasoncodes {
  all_reasons: {
    title: string
    items: ReasonCode[]
  }
  selected_reasons: {
    title: string
    items: ReasonCode[]
  }
}

export interface ReasonCodeMapState {
  initialReasoncodes: InitialReasoncodes
  updatedReasonCodes: InitialReasoncodes
  active: boolean
}

const initialState: ReasonCodeMapState = {
  initialReasoncodes: {
    all_reasons: {
      title: 'All Reason Codes',
      items: [],
    },
    selected_reasons: {
      title: 'Selected Reason Codes',
      items: [],
    },
  },
  updatedReasonCodes: {
    all_reasons: {
      title: 'All Reason Codes',
      items: [],
    },
    selected_reasons: {
      title: 'Selected Reason Codes',
      items: [],
    },
  },
  active: false,
}

type Action =
  | {
      type: 'SET_INITIAL_REASON_CODES'
      payload: {
        initialReasonCodes: {
          all_reasons: {
            title: string
            items: ReasonCode[]
          }
          selected_reasons: {
            title: string
            items: ReasonCode[]
          }
        }
        active: boolean
      }
    }
  | { type: 'SET_UPDATED_REASON_CODES'; payload: InitialReasoncodes }
  | { type: 'SET_ACTIVE_STATUS'; payload: boolean }

const reasonCodeMapReducer = (
  state: ReasonCodeMapState,
  action: Action
): ReasonCodeMapState => {
  switch (action.type) {
    case 'SET_INITIAL_REASON_CODES':
      return {
        ...state,
        initialReasoncodes: { ...action.payload.initialReasonCodes },
        updatedReasonCodes: { ...action.payload.initialReasonCodes },
        active: action.payload.active,
      }
    case 'SET_UPDATED_REASON_CODES':
      return { ...state, updatedReasonCodes: action.payload }
    case 'SET_ACTIVE_STATUS':
      return { ...state, active: action.payload }
    default:
      return state
  }
}

export const EditForm = (props: any) => {
  const [state, dispatch] = useReducer(reasonCodeMapReducer, initialState)

  useEffect(() => {
    const selectedReasonCodes = (props.editableRow?.reasonCodeList || []).map(
      (reasonCode: any) =>
        ({
          reasonCodeId: reasonCode.reasonCodeId,
          reasonCodeText: reasonCode.reasonCodeText,
        } as ReasonCode)
    )

    const reasonCodes = (props.reasonCodes || [])
      .filter((reasonCode: any) => reasonCode.active)
      .filter(
        (reasonCode: any) =>
          !selectedReasonCodes.some(
            (selected: any) => selected.reasonCodeId === reasonCode.reasonCodeId
          )
      )
      .map(
        (reasonCode: any) =>
          ({
            reasonCodeId: reasonCode.reasonCodeId,
            reasonCodeText: reasonCode.reasonCodeText,
          } as ReasonCode)
      )

    dispatch({
      type: 'SET_INITIAL_REASON_CODES',
      payload: {
        initialReasonCodes: {
          all_reasons: {
            title: 'All Reason Codes',
            items: [...reasonCodes],
          },
          selected_reasons: {
            title: 'Selected Reason Codes',
            items: [...selectedReasonCodes],
          },
        },
        active: props.editableRow?.active,
      },
    })
  }, [props.reasonCodes, props.editableRow, props.formVisible])

  const [updateReasonCodeMap, { loading }] = useMutation<
    ReasonCodeMapResponse,
    UpdateUpdateReasonCodeMapVar
  >(UPDATE_REASONCODE_MAP)

  const handleSave = async (
    row: ReasonCodeMaps,
    selectedReasonCodes: any[],
    active: any
  ) => {
    await updateReasonCodeMap({
      variables: {
        id: row.reasonMapId,
        input: {
          caseType: row.caseType,
          caseSubType: row.caseSubType,
          documentTypeId: row.documentTypeId,
          documentTypeText: row.documentTypeText,
          documentSubTypeId: row.documentSubTypeId,
          documentSubTypeText: row.documentSubTypeText,
          active: active,
          reasonCodeList: [...selectedReasonCodes],
        },
      },
      refetchQueries: [
        {
          query: GET_REASONCODEMAPS,
        },
      ],
      awaitRefetchQueries: true,
    }).then(() => props.handleClose())
  }

  const handleListChange = useCallback((updatedColumns: any) => {
    dispatch({ type: 'SET_UPDATED_REASON_CODES', payload: updatedColumns })
  }, [])

  return (
    <Modal
      headingText="Edit Reason Code Mapping"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible={props.formVisible}
    >
      <Grid.Container justify={'center'} className="hc-pa-normal">
        <Grid.Item xs={11}>
          <Grid.Container>
            <Grid.Item xs={6}>
              <Input.Label>Case Type</Input.Label>
              <Input.Text
                id="caseType"
                value={props?.editableRow?.caseType}
                type="select"
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={6}>
              <Input.Label>Case Sub Type</Input.Label>
              <Input.Text
                id="caseSubType"
                value={props?.editableRow?.caseSubType}
                type="select"
                disabled
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Input.Label>Document Type</Input.Label>
              <Input.Text
                id="documentType"
                value={props?.editableRow?.documentTypeText ?? ''}
                type="select"
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={6}>
              <Input.Label>Document Sub Type</Input.Label>
              <Input.Text
                id="documentSubType"
                value={props?.editableRow?.documentSubTypeText ?? ''}
                type="select"
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={12}>
              <div>
                <DragAndDropList
                  initialColumns={state.initialReasoncodes}
                  onChange={handleListChange}
                />
              </div>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <GridItem xs={11}>
          <GridContainer align="center">
            <GridItem xs={3}>
              <Form.Field
                id="status"
                type="toggle"
                label={'Active'}
                value={state.active}
                onChange={(_: any) => {
                  dispatch({
                    type: 'SET_ACTIVE_STATUS',
                    payload: !state.active,
                  })
                }}
              ></Form.Field>
            </GridItem>
            <GridItem justify="flex-end" xs={9}>
              <GridContainer direction="row-reverse" spacing="dense">
                <GridItem>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSave(
                        props.editableRow,
                        state.updatedReasonCodes.selected_reasons.items,
                        state.active
                      )
                    }}
                    disabled={loading}
                  >
                    Save
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    onClick={() => {
                      props.handleClose()
                    }}
                  >
                    Cancel
                  </Button>
                </GridItem>

                {loading && (
                  <GridItem>
                    <Spinner></Spinner>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </Grid.Container>
    </Modal>
  )
}
