import { CaseStatus, CaseType } from '../Services/ConstantData'

export function getChipClassNames(caseType: string | undefined): string {
  if (caseType === CaseType.DISPUTE) {
    return 'hc-bg-teal'
  }
  if (caseType === CaseType.INTERNALPROCESSING) {
    return 'hc-bg-pink'
  }
  return 'hc-bg-indigo'
}

export function getStatusColor(
  status: string | undefined
): 'default' | 'alert' | 'success' {
  if (
    status === CaseStatus.INPROGRESS ||
    status === CaseStatus.AWAITINGINFO ||
    status === CaseStatus.REOPEN
  ) {
    return 'alert'
  } else if (status === CaseStatus.RESOLVED || status === CaseStatus.CLOSED) {
    return 'success'
  }

  return 'default'
}
