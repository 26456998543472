import React from 'react'
import { CaseResponse } from '../../Common/Queries'
import { CARRIER_VENDOR_HEADING } from '../../Common/Constants'
import { Section, SectionItem } from '../../Common/Section'
import { BottomBorderDiv } from '../../Common/SynergyDivComponent'

interface MainProp {
  carrierVendorDetails: CaseResponse | undefined
}

const EditCarrierVendorDetails: React.FC<MainProp> = ({
  carrierVendorDetails,
}) => {
  const carrier = carrierVendorDetails?.claimDetails
  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={3}
        detail
        heading={CARRIER_VENDOR_HEADING}
        expandable
      >
        <SectionItem label="Carrier Vendor Id">
          {carrier?.carrierVendorId}
        </SectionItem>
        <SectionItem label="Carrier Vendor Name">
          {carrier?.carrierVendorName}
        </SectionItem>
        <SectionItem label="SCAC Code">{carrier?.carrierScacCode}</SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}

export default EditCarrierVendorDetails
