import React, { useEffect, useState, useRef } from 'react'
import {
  Layout,
  GridContainer,
  GridItem,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { CaseSearchAutoComplete } from './CaseSearchAutoComplete'
import { CaseSearchProvider } from './SearchContext/CaseSearchProvider'
import { CaseSearch } from './CaseSearch'
import { CaseSideFilter } from './CaseSideFilter'
import { QuickLinks } from './QuickLinks'
import { useApolloClient } from '@apollo/client'
import {
  DOWNLOAD_SEARCH_QUERY_TM,
  DOWNLOAD_SEARCH_QUERY_VENDOR,
  excelDownloadHeadersTm,
  excelDownloadHeadersVendors,
} from './SearchQueries'
import { CSVLink } from 'react-csv'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'
import { getDownloadResults } from '../Services/DownloadData'
import { Overlay } from '../Common/Overlay'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface Props {}

const Cases: React.FC<Props> = (props: any) => {
  const env = useEnv() as EnvConfig
  const { userProfile } = useProfile()
  const [queryResults, setQueryResults] = useState<any>()
  const [searchQuery, setSearchQuery] = useState<any>()
  const [downloadData, setDownloadData] = useState<any>([])
  const [searchState, setSearchState] = useState<string>('')
  const csvLink = useRef<any | null>(null)
  const client = useApolloClient()

  useEffect(() => {
    if (downloadData.length > 0) {
      setTimeout(() => {
        csvLink?.current?.link.click()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData])

  const downloadCases = async () => {
    setDownloadData([])
    setSearchState('caseDownload')
    try {
      const { data } = await client.query<any>({
        query: userProfile?.isVendor
          ? DOWNLOAD_SEARCH_QUERY_VENDOR
          : DOWNLOAD_SEARCH_QUERY_TM,
        variables: {
          query: searchQuery?.query,
          sort: searchQuery?.sort,
          vendorId: searchQuery.vendorId,
        },
        fetchPolicy: 'network-only',
      })
      const searchDownloadData = data?.getSearchDownloadResults
      if (searchDownloadData !== null) {
        setSearchState('downloadSuccess')
        setDownloadData(getDownloadResults(data?.getSearchDownloadResults))
      } else {
        setSearchState('downloadNoData')
      }
    } catch (errors) {
      setSearchState('downloadError')
    }
  }

  return (
    <CaseSearchProvider>
      <Layout.Body>
        <GridContainer padding="dense" align="center">
          <GridItem xs={10} className="hc-pb-none">
            <CaseSearchAutoComplete />
          </GridItem>
          <GridItem xs={2} className="hc-mt-sm">
            {!userProfile?.isVendor && <QuickLinks />}
            <Button
              iconOnly
              aria-label="Download Cases"
              onClick={async () => {
                if (queryResults.totalResults < env.download.downloadSize) {
                  downloadCases()
                }
              }}
              disabled={
                searchState === 'caseDownload' ||
                queryResults?.totalResults > env.download?.downloadSize
              }
            >
              <EnterpriseIcon icon={DownloadIcon} color="black" />
            </Button>
            {downloadData && !userProfile?.isVendor ? (
              <CSVLink
                data={downloadData}
                headers={excelDownloadHeadersTm}
                filename="cases.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            ) : undefined}
            {downloadData && userProfile?.isVendor ? (
              <CSVLink
                data={downloadData}
                headers={excelDownloadHeadersVendors}
                filename="cases.csv"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            ) : undefined}
          </GridItem>
          <GridItem xs={12}>
            <Overlay active={searchState === 'caseDownload'}>
              <CaseSearch
                setQueryResults={setQueryResults}
                setSearchQuery={setSearchQuery}
              />
            </Overlay>
          </GridItem>
        </GridContainer>
      </Layout.Body>
      <Layout.Rail>
        <CaseSideFilter />
      </Layout.Rail>
    </CaseSearchProvider>
  )
}

export default Cases
