import React, { useCallback } from 'react'

import { useState, useEffect } from 'react'
import {
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import {
  DocPrefixMapsResponse,
  GET_DOCPREFIXMAPS,
  GET_REASONCODEMAPS,
  ReasonCodeMaps,
} from '../../Cases/Common/Queries'
import {
  CreateReasonCodeMapVar,
  CREATE_REASONCODE_MAP,
} from '../common/AdminQueries'
import { useMutation, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import {
  getUniqueCaseSubTypes,
  getUniqueCaseTypes,
  getUniqueDocumentSubTypes,
  getUniqueDocumentTypes,
} from '../common/DoctypeHelper'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import DragAndDropList from '../common/DragAndDropList'
import { ReasonCode } from './ReasonCodesAdmin'
import { InitialReasoncodes } from './EditReasonCodeMap'
import * as Yup from 'yup'

export interface ReasonCodeMapForm {
  caseType?: string
  caseSubType?: string
  documentTypeId?: number
  documentTypeText?: string
  documentSubTypeId?: number
  documentSubTypeText?: string
  active?: boolean
  reasonCodes?: ReasonCode[]
  initalReasonCodes?: InitialReasoncodes
}

const schema = Yup.object().shape<ReasonCodeMapForm>({
  caseType: Yup.string().required('Select a Case Type'),
  caseSubType: Yup.string().required('Select a Case Sub Type'),
})

const initialValues: ReasonCodeMapForm = {
  caseSubType: undefined,
  caseType: undefined,
  documentTypeId: undefined,
  documentTypeText: undefined,
  documentSubTypeId: undefined,
  documentSubTypeText: undefined,
  active: true,
  reasonCodes: [],
  initalReasonCodes: {
    all_reasons: {
      title: 'All Reason Codes',
      items: [],
    },
    selected_reasons: {
      title: 'Selected Reason Codes',
      items: [],
    },
  },
}

export const AddReasonCodeMap = (props: any) => {
  const [reasonCodes, setReasonCodes] = useState<ReasonCode[]>()
  const formik = useFormik<ReasonCodeMapForm>({
    initialValues: { ...initialValues },
    onSubmit: async (values) => {
      console.log(values)

      await updateReasonCode({
        variables: {
          input: {
            caseType: values.caseType!!,
            caseSubType: values.caseSubType!!,
            active: values.active!!,
            documentTypeId: values.documentTypeId,
            documentTypeText: values.documentTypeText,
            documentSubTypeId: values.documentSubTypeId,
            documentSubTypeText: values.documentSubTypeText,
            reasonCodeList: values.reasonCodes,
          },
        },
        refetchQueries: [{ query: GET_REASONCODEMAPS }],
        awaitRefetchQueries: true,
      }).then(() => props.handleClose())
    },
    validateOnBlur: true,
    validationSchema: schema,
  })

  useEffect(() => {
    formik.setFieldValue('reasonCodes', reasonCodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonCodes])

  const { data: mappingValues } = useQuery<DocPrefixMapsResponse>(
    GET_DOCPREFIXMAPS,
    {
      onCompleted: (data) => {
        console.log(data.getDocumentTypeMaps)
      },
    }
  )

  const [updateReasonCode, { loading }] = useMutation<
    ReasonCodeMaps,
    CreateReasonCodeMapVar
  >(CREATE_REASONCODE_MAP, {
    onCompleted: (data) => {
      console.log(data)
    },
  })

  useEffect(() => {
    const reasonCodes = (props.reasonCodes || [])
      .filter((reasonCode: any) => reasonCode.active)
      .map(
        (reasonCode: any) =>
          ({
            reasonCodeId: reasonCode.reasonCodeId,
            reasonCodeText: reasonCode.reasonCodeText,
          } as ReasonCode)
      )

    formik.setFieldValue('initalReasonCodes', {
      all_reasons: {
        title: 'All Reason Codes',
        items: [...reasonCodes],
      },
      selected_reasons: {
        title: 'Selected Reason Codes',
        items: [],
      },
    })

    formik.setFieldValue('caseType', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reasonCodes])

  const handleListChange = useCallback((updatedColumns: InitialReasoncodes) => {
    setReasonCodes(updatedColumns.selected_reasons.items)
  }, [])

  return (
    <Modal
      headingText="Create Reason Code"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible
    >
      <Form onSubmit={formik.handleSubmit}>
        <Grid.Container justify={'center'} className="hc-pa-normal">
          <Grid.Item xs={11}>
            <Grid.Container>
              <Grid.Item xs={6}>
                <Form.Field
                  id="caseType"
                  label={'Case Type'}
                  type="select"
                  required
                  options={getUniqueCaseTypes(
                    mappingValues?.getDocumentTypeMaps
                  )}
                  value={formik.values.caseType}
                  onUpdate={(_: any, value: any) => {
                    formik.setFieldValue('caseType', value)
                    formik.setFieldValue('caseSubType', '')
                    formik.setFieldValue('documentTypeId', undefined)
                    formik.setFieldValue('documentSubTypeId', undefined)
                  }}
                  error={
                    formik.touched.caseType &&
                    formik.errors.caseType !== undefined
                  }
                  errorText={formik.errors.caseType}
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Form.Field
                  id="caseSubType"
                  label={'Case Sub Type'}
                  type="select"
                  required
                  options={getUniqueCaseSubTypes(
                    mappingValues?.getDocumentTypeMaps,
                    formik.values.caseType
                  )}
                  value={formik.values.caseSubType}
                  onUpdate={(_: any, value: any) => {
                    formik.setFieldValue('caseSubType', value)
                    formik.setFieldValue('documentTypeId', undefined)
                    formik.setFieldValue('documentSubTypeId', undefined)
                  }}
                  error={
                    formik.touched.caseType &&
                    formik.errors.caseSubType !== undefined
                  }
                  errorText={formik.errors.caseSubType}
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Autocomplete
                  id="documentTypeId"
                  label={'Document Type'}
                  onUpdate={(id, value) => {
                    formik.setFieldValue('documentTypeId', value?.value)
                    formik.setFieldValue('documentTypeText', value?.label)
                    formik.setFieldValue('documentSubTypeId', undefined)
                  }}
                  value={
                    formik.values.documentTypeId && {
                      id: formik.values.documentTypeId,
                      value: formik.values.documentTypeId,
                      label: formik.values.documentTypeText,
                    }
                  }
                  options={getUniqueDocumentTypes(
                    mappingValues?.getDocumentTypeMaps,
                    formik.values.caseType,
                    formik.values.caseSubType
                  )}
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Autocomplete
                  id="documentSubTypeId"
                  label={'Document Sub Type'}
                  onUpdate={(id, value) => {
                    formik.setFieldValue('documentSubTypeId', value?.value)
                    formik.setFieldValue('documentSubTypeText', value?.label)
                    console.log(formik.values)
                  }}
                  value={
                    formik.values.documentSubTypeId && {
                      id: formik.values.documentSubTypeId,
                      value: formik.values.documentSubTypeId,
                      label: formik.values.documentSubTypeText,
                    }
                  }
                  options={getUniqueDocumentSubTypes(
                    mappingValues?.getDocumentTypeMaps,
                    formik.values.caseType,
                    formik.values.caseSubType,
                    formik.values.documentTypeId
                  )}
                />
              </Grid.Item>
              <Grid.Item xs={12}>
                <DragAndDropList
                  initialColumns={formik.values.initalReasonCodes}
                  onChange={handleListChange}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <GridItem xs={11}>
            <GridContainer align="center">
              <GridItem xs={3}>
                <Form.Field
                  id="active"
                  type="toggle"
                  label={'Active'}
                  value={formik.values.active}
                  onChange={() => {
                    formik.setFieldValue('active', !formik.values.active)
                  }}
                ></Form.Field>
              </GridItem>
              <GridItem justify="flex-end" xs={9}>
                <GridContainer direction="row-reverse" spacing="dense">
                  <GridItem>
                    <Button type="submit" disabled={loading}>
                      Save
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => {
                        props.handleClose()
                      }}
                    >
                      Cancel
                    </Button>
                  </GridItem>

                  {loading && (
                    <GridItem>
                      <Spinner></Spinner>
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </Grid.Container>
      </Form>
    </Modal>
  )
}
