import { gql } from '@apollo/client'

export interface UpdateReasonCodeMapRequest {
  caseType: string
  caseSubType: string
  documentTypeId?: number
  documentTypeText?: string
  documentSubTypeId?: number
  documentSubTypeText?: string
  reasonCodeList?: ReasonCodeSnapshotInput[]
  active: boolean
}

export interface UpdateResolutionCodeMapRequest {
  caseType: string
  caseSubType: string
  documentTypeId: number
  documentTypeText: string
  documentSubTypeId: number
  documentSubTypeText: string
  resolutionCodeList?: ResolutionCodeSnapshotInput[]
  active: boolean
}

export interface ResolutionCodeSnapshotInput {
  resolutionCodeId?: number
  resolutionCodeText?: string
}

export interface ReasonCodeSnapshotInput {
  reasonCodeId?: string
  reasonCodeText?: string
}

export interface UpdateUpdateReasonCodeMapVar {
  id: number
  input: UpdateReasonCodeMapRequest
}

export interface UpdateUpdateResolutionCodeMapVar {
  id: number
  input: UpdateResolutionCodeMapRequest
}

export const UPDATE_REASONCODE_MAP = gql`
  mutation UpdateReasonCodeMap(
    $id: Long!
    $input: UpdateReasonCodeMapRequest!
  ) {
    updateReasonCodeMap(id: $id, updateReasonCodeMapRequest: $input) {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      reasonCodeList {
        reasonCodeId
        reasonCodeText
      }
      active
    }
  }
`

export const UPDATE_RESOLUTIONCODE_MAP = gql`
  mutation UpdateResolutionCodeMap(
    $id: Long!
    $input: UpdateResolutionCodeMapRequest!
  ) {
    updateResolutionCodeMap(id: $id, updateResolutionCodeMapRequest: $input) {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      resolutionCodeList {
        resolutionCodeId
        resolutionCodeText
      }
      active
    }
  }
`

export interface UpdateReasonCodeRequest {
  reasonCodeText: string
  supportDocList?: string
  active?: boolean
}
export interface UpdateResoultionCodeRequest {
  resolutionCodeText: string
  active: boolean
}
export interface UpdateUpdateReasonCodeVar {
  id: string
  input: UpdateReasonCodeRequest
}

export interface UpdateUpdateResolutionCodeVar {
  id: number
  input: UpdateResoultionCodeRequest
}
export const UPDATE_REASONCODE = gql`
  mutation UpdateReasonCode($id: String!, $input: UpdateReasonCodeRequest!) {
    updateReasonCode(id: $id, updateReasonCodeRequest: $input) {
      reasonCodeText
      supportDocList
      active
    }
  }
`
export const UPDATE_RESOLUTIONCODE = gql`
  mutation UpdateResolutionCode(
    $id: Long!
    $input: UpdateResolutionCodeRequest!
  ) {
    updateResolutionCode(id: $id, updateResolutionCodeRequest: $input) {
      resolutionCodeId
      resolutionCodeText
      active
    }
  }
`
export interface UpdateReopenCodeRequest {
  reopenCodeText?: string
  reopenCodeDescription?: string
  active?: boolean
}

export interface UpdateReopenCodeVar {
  id: number
  input: UpdateReopenCodeRequest
}

export const UPDATE_REOPENCODE = gql`
  mutation UpdateReopenCode($id: Long!, $input: UpdateReopenCodeRequest!) {
    updateReopenCode(id: $id, updateReopenCodeRequest: $input) {
      reopenCodeText
      reopenCodeDescription
      active
    }
  }
`
export interface NewReasonCodeMapRequest {
  caseType: string
  caseSubType: string
  documentTypeId?: number
  documentTypeText?: string
  documentSubTypeId?: number
  documentSubTypeText?: string
  reasonCodeList?: ReasonCodeSnapshotInput[]
  active: boolean
}

export interface NewResolutionCodeMapRequest {
  caseType: string
  caseSubType: string
  documentTypeId?: number
  documentTypeText?: string
  documentSubTypeId?: number
  documentSubTypeText?: string
  resolutionCodeList?: ResolutionCodeSnapshotInput[]
  active: boolean
}

export interface CreateReasonCodeMapVar {
  input: NewReasonCodeMapRequest
}

export interface CreateResolutionCodeMapVar {
  input: NewResolutionCodeMapRequest
}

export const CREATE_REASONCODE_MAP = gql`
  mutation CreateReasonCodeMap($input: NewReasonCodeMapRequest!) {
    createReasonCodeMap(newReasonCodeMapRequest: $input) {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      reasonCodeList {
        reasonCodeId
        reasonCodeText
      }
      active
    }
  }
`

export const CREATE_RESOLUTIONCODE_MAP = gql`
  mutation CreateResolutionCodeMap($input: NewResolutionCodeMapRequest!) {
    createResolutionCodeMap(newResolutionCodeMapRequest: $input) {
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      resolutionCodeList {
        resolutionCodeId
        resolutionCodeText
      }
      active
    }
  }
`

export interface CreateReasonCodeVar {
  input: NewReasonCodeRequest
}

export interface NewReasonCodeRequest {
  reasonCodeId: string
  reasonCodeText: string
  supportDocList?: string
  active?: boolean
}

export const CREATE_REASONCODE = gql`
  mutation CreateReasonCode($input: NewReasonCodeRequest!) {
    createReasonCode(newReasonCodeRequest: $input) {
      reasonCodeId
      reasonCodeText
      supportDocList
      active
    }
  }
`
export interface CreateResolutionCodeVar {
  input: NewResolutionCodeRequest
}

export interface NewResolutionCodeRequest {
  resolutionCodeText: string
  active?: boolean
}

export const CREATE_RESOLUTIONCODE = gql`
  mutation CreateResolutionCode($input: NewResolutionCodeRequest!) {
    createResolutionCode(newResolutionCodeRequest: $input) {
      resolutionCodeId
      resolutionCodeText
      active
    }
  }
`

export interface CreateReopenCodeVar {
  input: NewReopenCodeRequest
}

export interface NewReopenCodeRequest {
  reopenCodeText: string
  reopenCodeDescription: string
  active?: boolean
}

export const CREATE_REOPENCODE = gql`
  mutation CreateReopenCode($input: NewReopenCodeRequest!) {
    createReopenCode(newReopenCodeRequest: $input) {
      reopenCodeText
      reopenCodeDescription
      active
    }
  }
`
