import {
  SET_DEFAULT_SIDE_FILTERS,
  UPDATE_FILTER,
} from '../../Cases/Search/SearchContext/ActionTypes'
import { ReportContextValue } from './ReportContext'
import _ from 'lodash'

export const SWITCH_REPORT = 'SWITCH_REPROT'

export function reportReducer(draft: ReportContextValue, action: any) {
  switch (action.type) {
    case SWITCH_REPORT: {
      draft.id = action.payload.id
      draft.report = action.payload.report
      return
    }
    case SET_DEFAULT_SIDE_FILTERS: {
      draft.report?.sideFilters.forEach((filter) => {
        if (action.payload[filter.id] !== undefined) {
          filter.values = action.payload[filter.id]
        }
      })
      return
    }
    case UPDATE_FILTER: {
      // checkbox
      draft.report?.sideFilters
        .filter((filter) => filter.type === 'checkbox')
        .forEach((filter) => {
          const selectedValues = action.payload[filter.id]
          filter.values?.forEach((value) => {
            value.isChecked = _.includes(selectedValues, value.id)
          })
        })

      if (draft.report !== undefined) {
        // ageing Window
        if (action.payload['ageingWindow'] !== undefined) {
          draft.report.ageingWindow = action.payload['ageingWindow']
        }

        // consolidateWindow
        if (action.payload['consolidateType'] !== undefined) {
          draft.report.consolidateType = action.payload['consolidateType']
        }

        //date filters
        if (action.payload[draft.report.dateFilter.id] !== undefined) {
          draft.report.dateFilter.active =
            action.payload[draft.report.dateFilter.id]
        }
      }
      return
    }
    case SET_BASE_FILTERS: {
      return
    }
    default: {
      return
    }
  }
}

export const SET_BASE_FILTERS = 'SET_BASE_FILTERS'
