import _ from 'lodash'
import { ReasonCodeMapResponse } from '../Common/Queries'

function deriveReasonCodes(reasonMappingValues: Array<any>): Array<any> {
  const values = reasonMappingValues?.map((reasonCodes: any) => ({
    id: reasonCodes.reasonCodeId,
    value: reasonCodes.reasonCodeId,
    label: `${reasonCodes.reasonCodeText}`,
  }))
  return values
}

export function findReasonCodeValues(
  ReasonMappingValues: Array<ReasonCodeMapResponse> | undefined,
  formikValues: any
): any {
  let reasonMappingValuesList = _.find(
    ReasonMappingValues,
    function (ReasonCodes) {
      return (
        ReasonCodes.caseType === formikValues.caseType &&
        ReasonCodes.caseSubType === formikValues.caseSubType &&
        Number(ReasonCodes.documentTypeId) ===
          Number(formikValues.documentTypeId) &&
        Number(ReasonCodes.documentSubTypeId) ===
          Number(formikValues.documentSubTypeId)
      )
    }
  )
  if (reasonMappingValuesList !== undefined) {
    const mappingValues = deriveReasonCodes(
      reasonMappingValuesList?.reasonCodeList
    )
    return mappingValues
  }
  reasonMappingValuesList = _.find(ReasonMappingValues, function (ReasonCodes) {
    return (
      ReasonCodes.caseType === formikValues.caseType &&
      ReasonCodes.caseSubType === formikValues.caseSubType &&
      Number(ReasonCodes.documentTypeId) ===
        Number(formikValues.documentTypeId) &&
      ReasonCodes.documentSubTypeId === null
    )
  })
  if (reasonMappingValuesList !== undefined) {
    const mappingValues = deriveReasonCodes(
      reasonMappingValuesList?.reasonCodeList
    )
    return mappingValues
  }
  reasonMappingValuesList = _.find(ReasonMappingValues, function (ReasonCodes) {
    return (
      ReasonCodes.caseType === formikValues.caseType &&
      ReasonCodes.caseSubType === formikValues.caseSubType &&
      ReasonCodes.documentTypeId === null &&
      ReasonCodes.documentSubTypeId === null
    )
  })
  if (reasonMappingValuesList !== undefined) {
    const mappingValues = deriveReasonCodes(
      reasonMappingValuesList?.reasonCodeList
    )
    return mappingValues
  } else return []
}
