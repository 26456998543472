import _ from 'lodash'
import { defaultValues } from './ConstantData'

export function findDocumentPrefixMapping(
  mappingValues: Array<any> | undefined,
  docId: any,
  caseType: any
): Array<any> | undefined {
  let MappingValues: any = mappingValues
    ?.filter(
      (value: any) =>
        value.documentPrefix !== null && value.caseType === caseType
    )
    .map((value: any) => {
      return { ...value, len: value.documentPrefix.length }
    })
    .sort((a: any, b: any) => (a.len > b.len ? -1 : b.len > a.len ? 1 : 0))

  const mappingValuesList = []

  for (var Prefix of MappingValues) {
    if (
      _.toLower(Prefix.documentPrefix) ===
      _.toLower(docId.substr(0, Prefix.len))
    ) {
      mappingValuesList.push({ ...Prefix })
      break
    }
  }
  if (mappingValuesList.length >= 1) {
    return mappingValuesList
  }
  return undefined
}

export function findCaseSubTypeValues(
  CaseSubTypeMappingValues: Array<any> | undefined,
  formikValues: any
): Array<any> | undefined {
  CaseSubTypeMappingValues = CaseSubTypeMappingValues?.map(
    (caseSubType: any) => ({
      caseType: caseSubType.caseType,
      caseSubType: caseSubType.caseSubType,
    })
  )
  CaseSubTypeMappingValues = _.uniqWith(
    CaseSubTypeMappingValues,
    _.isEqual
  )?.filter(
    (caseSubType: any) => caseSubType.caseType === formikValues.caseType
  )

  const mappingValues = deriveValues(
    CaseSubTypeMappingValues,
    'caseSubType',
    'caseSubType'
  )
  return mappingValues
}

export function findDocumentTypeIdValues(
  DocTypeMappingValues: Array<any> | undefined,
  formikValues: any
): Array<any> {
  DocTypeMappingValues = DocTypeMappingValues?.map((documentTypes: any) => ({
    caseType: documentTypes.caseType,
    caseSubType: documentTypes.caseSubType,
    documentTypeId: documentTypes.documentTypeId,
    documentTypeText: documentTypes.documentTypeText,
  }))

  DocTypeMappingValues = _.uniqWith(DocTypeMappingValues, _.isEqual)?.filter(
    (DocType: any) =>
      DocType?.caseType === formikValues.caseType &&
      DocType.caseSubType === formikValues.caseSubType &&
      DocType.documentTypeId !== null &&
      formikValues.documentTypeId !== undefined
  )

  if (DocTypeMappingValues.length > 0) {
    const mappingValues = deriveValues(
      DocTypeMappingValues,
      'documentTypeId',
      'documentTypeText'
    )
    return mappingValues
  } else return defaultValues
}

export function findDocumentSubTypeValues(
  DocSubTypeMappingValues: Array<any> | undefined,
  formikValues: any,
  documentTypeId: number
): Array<any> | undefined {
  DocSubTypeMappingValues = DocSubTypeMappingValues?.map(
    (documentSubTypes: any) => ({
      caseType: documentSubTypes.caseType,
      caseSubType: documentSubTypes.caseSubType,
      documentTypeId: documentSubTypes.documentTypeId,
      documentTypeText: documentSubTypes.documentTypeText,
      documentSubTypeId: documentSubTypes.documentSubTypeId,
      documentSubTypeText: documentSubTypes.documentSubTypeText,
    })
  )

  DocSubTypeMappingValues = _.uniqWith(
    DocSubTypeMappingValues,
    _.isEqual
  )?.filter(
    (DocSubType: any) =>
      DocSubType.caseType === formikValues.caseType &&
      DocSubType.caseSubType === formikValues.caseSubType &&
      Number(DocSubType.documentTypeId) === Number(documentTypeId) &&
      documentTypeId !== null &&
      documentTypeId !== undefined &&
      DocSubType.documentSubTypeId !== null
  )

  if (DocSubTypeMappingValues.length > 0) {
    const mappingValues = deriveValues(
      DocSubTypeMappingValues,
      'documentSubTypeId',
      'documentSubTypeText'
    )
    return mappingValues
  } else return defaultValues
}

export function findCaseSubTypes(
  CaseSubTypeMappingValues: Array<any> | undefined
): Array<any> | undefined {
  const values = CaseSubTypeMappingValues?.map((caseSubTypes: any) => ({
    id: caseSubTypes.caseSubType,
    value: caseSubTypes.caseSubType,
    label: caseSubTypes.caseSubType,
  }))
  return values
}

export function findDocumentTypeValues(
  DocTypeMappingValues: Array<any> | undefined,
  formikValues: any
): Array<any> {
  DocTypeMappingValues = DocTypeMappingValues?.map((documentTypes: any) => ({
    caseType: documentTypes.caseType,
    caseSubType: documentTypes.caseSubType,
    documentTypeId: documentTypes.documentTypeId,
    documentTypeText: documentTypes.documentTypeText,
  }))
  let DocumentTypeUniqueValues = _.uniqWith(DocTypeMappingValues, _.isEqual)
  let DocTypeMappingList: any = DocumentTypeUniqueValues?.filter(
    (value: any) =>
      value?.caseType === formikValues.caseType &&
      value.caseSubType === formikValues.caseSubType &&
      Number(value.documentTypeId) === Number(formikValues.documentTypeId) &&
      formikValues.documentTypeId !== null &&
      formikValues.documentTypeId !== undefined
  )

  if (DocTypeMappingList.length > 0) {
    const mappingValues = deriveValues(
      DocTypeMappingList,
      'documentTypeId',
      'documentTypeText'
    )
    return mappingValues
  }

  DocTypeMappingList = DocumentTypeUniqueValues?.filter(
    (value: any) =>
      value?.caseType === formikValues.caseType &&
      value.caseSubType === formikValues.caseSubType &&
      value.documentTypeId !== null
  )

  if (DocTypeMappingList.length > 0) {
    const mappingValues = deriveValues(
      DocTypeMappingList,
      'documentTypeId',
      'documentTypeText'
    )
    return mappingValues
  } else return defaultValues
}

export function findDocumentSubTypeIdValues(
  DocSubTypeMappingValues: Array<any> | undefined,
  formikValues: any
): Array<any> {
  DocSubTypeMappingValues = DocSubTypeMappingValues?.map(
    (documentSubTypes: any) => ({
      caseType: documentSubTypes.caseType,
      caseSubType: documentSubTypes.caseSubType,
      documentTypeId: documentSubTypes.documentTypeId,
      documentTypeText: documentSubTypes.documentTypeText,
      documentSubTypeId: documentSubTypes.documentSubTypeId,
      documentSubTypeText: documentSubTypes.documentSubTypeText,
    })
  )
  let DocumentSubTypeUniqueValues = _.uniqWith(
    DocSubTypeMappingValues,
    _.isEqual
  )
  let DocSubTypeMappingList: any = DocumentSubTypeUniqueValues?.filter(
    (DocSubType: any) =>
      DocSubType.caseType === formikValues.caseType &&
      DocSubType.caseSubType === formikValues.caseSubType &&
      Number(DocSubType.documentTypeId) ===
        Number(formikValues.documentTypeId) &&
      Number(DocSubType.documentSubTypeId) ===
        Number(formikValues.documentSubTypeId) &&
      formikValues.documentSubTypeId !== null
  )
  if (DocSubTypeMappingList.length > 0) {
    const mappingValues = deriveValues(
      DocSubTypeMappingList,
      'documentSubTypeId',
      'documentSubTypeText'
    )
    return mappingValues
  }
  DocSubTypeMappingList = DocumentSubTypeUniqueValues?.filter(
    (DocSubType: any) =>
      DocSubType.caseType === formikValues.caseType &&
      DocSubType.caseSubType === formikValues.caseSubType &&
      Number(DocSubType.documentTypeId) ===
        Number(formikValues.documentTypeId) &&
      DocSubType.documentSubTypeId !== null
  )
  if (DocSubTypeMappingList.length > 0) {
    const mappingValues = deriveValues(
      DocSubTypeMappingList,
      'documentSubTypeId',
      'documentSubTypeText'
    )
    return mappingValues
  } else return defaultValues
}

function deriveValues(
  MappingValues: Array<any>,
  id: any,
  value: any
): Array<any> {
  const values = MappingValues?.map((MappingObject: any) => ({
    id: MappingObject[id],
    value: MappingObject[id],
    label: MappingObject[value],
  }))
  return values
}

export function findDocumentSubType(
  documentSubTypeValue: Array<any> | undefined,
  documentSubType: string
): any {
  documentSubTypeValue = documentSubTypeValue?.map((documentSubTypes: any) => ({
    documentSubTypeId: documentSubTypes.documentSubTypeId,
    documentSubTypeText: documentSubTypes.documentSubTypeText,
  }))
  documentSubTypeValue = _.uniqWith(documentSubTypeValue, _.isEqual)
  let documentSubTypeObject = {}
  for (var docSubType of documentSubTypeValue) {
    var searchString = _.trim(docSubType.documentSubTypeText)
    if (_.includes(searchString, _.trim(documentSubType))) {
      documentSubTypeObject = docSubType
      break
    }
  }
  return documentSubTypeObject
}

export function findTapDocumentSubType(
  documentSubTypeValue: Array<any> | undefined,
  documentSubType: any
): Array<any> {
  documentSubTypeValue = documentSubTypeValue?.map((documentSubTypes: any) => ({
    id: documentSubTypes.documentSubTypeId,
    value: documentSubTypes.documentSubTypeId,
    label: documentSubTypes.documentSubTypeText,
  }))
  documentSubTypeValue = _.uniqWith(documentSubTypeValue, _.isEqual)?.filter(
    (value: any) => Number(value.id) === Number(documentSubType)
  )
  if (documentSubTypeValue.length >= 1) {
    return documentSubTypeValue
  }
  return defaultValues
}
