import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  Button,
  Form,
  GridContainer,
  GridItem,
  Heading,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import {
  GetUserProfiles,
  GET_USERPROFILES,
  GET_WORKFLOWS,
  UpdateCaseRequest,
  WorkFlowResponse,
  CaseResponse,
} from '../../Common/Queries'
import { assignButtonStyle } from '../../Common/SynergyDivComponent'
import { CaseStatus } from '../../Services/ConstantData'
import { UserProfile } from '../../../Layout/UserProfile/userProfle'
import { useAuth } from '@praxis/component-auth'
import { getAuditforFieldUpdate } from '../../Services/AuditChanges'
import { convertToUpdateRequests } from '../../Common/PrepareUpdateRequest'

export const formFieldMargin = css`
  margin-top: 13px;
`
export const assignFilter = (status: string | undefined) => {
  const filter = status === CaseStatus.RESOLVED || status === CaseStatus.CLOSED
  return !filter
}

export interface AssignCaseProps {
  userProfile: UserProfile | undefined
  validSelectedCases: CaseResponse[] | undefined
  onSave: (UpdateCaseRequest: UpdateCaseRequest[]) => void
  loading: boolean
  setVisible: (visible: boolean) => void
  visible: boolean
}

const initialUpdateState = {
  workflow: undefined,
  assignedUser: undefined,
}

export const AssignCase: React.FC<AssignCaseProps> = ({
  userProfile: currentUserProfile,
  validSelectedCases,
  onSave,
  loading,
  setVisible,
  visible,
}) => {
  const [updates, setUpdates] = useState<any>(initialUpdateState)
  const [assignedUserValues, setAssignedUserValues] = useState<any>(undefined)
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''

  const { data: workflows } = useQuery<WorkFlowResponse>(GET_WORKFLOWS, {})
  useQuery<GetUserProfiles>(GET_USERPROFILES, {
    onCompleted: (datauser: GetUserProfiles) => {
      const values = datauser?.getUserProfiles?.map((userProfile: any) => ({
        id: userProfile.userId,
        value: userProfile.userId,
        label: userProfile.fullName,
        vendor: userProfile.isVendor,
      }))
      const userValues = values.filter(function (userValue: any) {
        return !userValue.vendor
      })
      setAssignedUserValues(userValues)
    },
    fetchPolicy: 'network-only',
  })

  const onUserAssignment = () => {
    setUpdates((prevUpdates: any) => {
      return {
        ...prevUpdates,
        assignedUser: assignedUserValues.find(
          (assignedUserValues: any) =>
            assignedUserValues?.id === currentUserProfile?.userId
        ),
      }
    })
  }

  function handleAuditForAssign(casee: UpdateCaseRequest) {
    const adAuditEntry = casee.auditEntries
      ? casee.auditEntries.map(function (auditEntries: any) {
          return {
            activityDescription: auditEntries.activityDescription,
            userId: auditEntries.userId,
            workflow: auditEntries.workflow,
            status: auditEntries.status,
            assignUserId: auditEntries.assignUserId,
            activityTimestamp: auditEntries.activityTimestamp,
          }
        })
      : []
    let workflowAudit = getAuditforFieldUpdate(
      casee,
      updates?.workflow ?? casee?.workflow,
      'workflow',
      'Workflow'
    )
    let assigneeAudit = getAuditforFieldUpdate(
      casee,
      updates?.assignedUser?.id,
      'lanId',
      'Case assignee'
    )
    let activityDescription = [workflowAudit, assigneeAudit]
      .filter(Boolean)
      .join(' and ')

    adAuditEntry.unshift({
      activityDescription: `${activityDescription} ${' '}`,
      userId: userName,
      workflow: updates?.workflow ?? casee?.workflow,
      status: casee?.status,
      assignUserId: updates?.assignedUser?.id ?? null,
      activityTimestamp: new Date().toISOString(),
    })
    return adAuditEntry
  }

  function handleSave() {
    const updateRequest = convertToUpdateRequests(
      validSelectedCases ?? []
    )?.map((casee: UpdateCaseRequest) => ({
      ...casee,
      auditEntries: handleAuditForAssign(casee),
      workflow: updates?.workflow ?? casee.workflow,
      lanId: updates?.assignedUser?.id ?? '',
    }))
    onSave(updateRequest ?? [])
  }
  return (
    <React.Fragment>
      <Modal
        headingText={'Assign Cases'}
        isVisible={visible}
        onRefuse={() => {
          setVisible(false)
          setUpdates(initialUpdateState)
        }}
      >
        <GridContainer justify="center" className="hc-pa-normal">
          <GridItem xs={4} className="">
            <Form.Field
              css={formFieldMargin}
              id="workflow"
              label="Workflow"
              type="select"
              className="hc-ta-left"
              value={updates.workflow}
              onUpdate={(_: any, value: any) => {
                setUpdates((prevUpdates: any) => {
                  return { ...prevUpdates, workflow: value }
                })
              }}
              options={workflows?.getWorkflows ?? []}
            />
          </GridItem>
          <GridItem xs={4}>
            <Autocomplete
              id="lanID"
              label="Assignee"
              // @ts-ignore
              rightContent={
                <Button
                  size="dense"
                  onClick={() => {
                    onUserAssignment()
                  }}
                  css={assignButtonStyle}
                >
                  Assign to me
                </Button>
              }
              onUpdate={(id, value) => {
                setUpdates((prevUpdates: any) => {
                  return {
                    ...prevUpdates,
                    assignedUser: assignedUserValues.find(
                      (assignedUserValues: any) =>
                        assignedUserValues?.id === value?.value
                    ),
                  }
                })
              }}
              value={updates.assignedUser}
              options={assignedUserValues ?? []}
            />
          </GridItem>
          <GridItem xs={12}>
            <GridContainer align="center" className="hc-ph-md">
              <GridItem xs>
                <Heading size={6}>
                  {validSelectedCases?.length} Selected
                </Heading>
              </GridItem>
              <GridItem justify="flex-end">
                <GridContainer direction="row-reverse" spacing="dense">
                  <GridItem>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSave()
                      }}
                      disabled={loading}
                    >
                      Save
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      onClick={() => {
                        setVisible(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </GridItem>
                  {loading && (
                    <GridItem>
                      <Spinner></Spinner>
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Modal>
    </React.Fragment>
  )
}
