import React from 'react'
import { CaseResponse } from '../../Common/Queries'
import { TCLAIM_OTHER_RECEIPTS_HEADING } from '../../Common/Constants'
import { BottomBorderDiv } from '../../Common/SynergyDivComponent'
import {
  ExpandableSection,
  Grid,
  Heading,
  Table,
} from '@enterprise-ui/canvas-ui-react'

interface MainProp {
  tclaim: CaseResponse | undefined
}

const EditClaimReceipts: React.FC<MainProp> = ({ tclaim }) => {
  const tclaimReceipts = tclaim?.claimDetails
  return tclaimReceipts?.claimReceipts !== null ? (
    <BottomBorderDiv>
      <div className="hc-pa-normal hc-pl-none hc-pr-none hc-pt-none">
        <ExpandableSection startExpanded toggleLocation="left" padding="none">
          <Heading size={6}>{TCLAIM_OTHER_RECEIPTS_HEADING}</Heading>
          <ExpandableSection.Content className="hc-pl-lg hc-pv-normal">
            <Grid.Container className="hc-pt-none hc-ml-xs">
              <Grid.Item sm={12} md={12}>
                <Table name="Art for sale">
                  <Table.Head>
                    <Table.Row>
                      <Table.Header xs={2}>Location</Table.Header>
                      <Table.Header xs={3}>PO</Table.Header>
                      <Table.Header xs={3}>Receipt</Table.Header>
                      <Table.Header xs={2}>Number of Cartons</Table.Header>
                      <Table.Header xs={2}>Dollar Amount</Table.Header>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {tclaimReceipts?.claimReceipts?.map(
                      (receipt: any, index: number) => (
                        <Table.Row>
                          <Table.Data xs={2}>{receipt?.location}</Table.Data>
                          <Table.Data xs={3}>{receipt?.poId}</Table.Data>
                          <Table.Data xs={3}>{receipt?.receiptId}</Table.Data>
                          <Table.Data xs={2}>
                            {receipt?.cartonsCount}
                          </Table.Data>
                          <Table.Data xs={2}>{receipt?.cartonCost}</Table.Data>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              </Grid.Item>
            </Grid.Container>
          </ExpandableSection.Content>
        </ExpandableSection>
      </div>
    </BottomBorderDiv>
  ) : (
    <></>
  )
}

export default EditClaimReceipts
