import React from 'react'
import '../../Cases/Custom.css'
import { PREV_RESOLUTION_HEADING } from './Constants'
import { Section, SectionItem } from './Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Popover } from '@enterprise-ui/canvas-ui-react'
import { GET_REOPENCODE, GetReopenCode, ReopenCodeVariable } from './Queries'
import { CaseResponse } from './Queries'
import { useQuery } from '@apollo/client'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'

interface MainProp {
  reopenDetails: CaseResponse
}

const ReopenDetails: React.FC<MainProp> = ({ reopenDetails }) => {
  const { userProfile } = useProfile()
  const reopenData = reopenDetails?.reopenDetails
  const { data: reopenCodeDetail } = useQuery<
    GetReopenCode,
    ReopenCodeVariable
  >(GET_REOPENCODE, {
    variables: {
      id: reopenDetails?.reopenDetails?.reopenReasonId,
    },
  })

  return (
    <Section
      labelGridSize={6}
      detail
      heading={PREV_RESOLUTION_HEADING}
      expandable
    >
      <SectionItem label="Reopen Reason">
        {reopenData?.reopenReason ?? 'None'}{' '}
        {reopenData?.reopenReason && !userProfile?.isVendor && (
          <Popover
            content={
              <p>
                {reopenCodeDetail?.getReopenCode?.reopenCodeDescription ?? 'NA'}
              </p>
            }
            location="bottom"
            type="clickOnly"
          >
            <p style={{ color: '#3d70d6' }}> +Description</p>
          </Popover>
        )}
      </SectionItem>
      <SectionItem label="Previous Document Number">
        {reopenData?.previousResolutionDocumentNumber}
      </SectionItem>
      <SectionItem label="Previous Settlement / Resolution Explanation">
        {reopenData?.previousResolutionCodeText}
      </SectionItem>
      <SectionItem label="Previous Resolution Amount">
        {reopenData?.previousResolutionAmount}
      </SectionItem>
      <SectionItem label="Previous Resolution Text">
        {reopenData?.previousResolutionText}
      </SectionItem>
      <SectionItem label="Previous Resolution Date">
        <DateFormatter
          //@ts-ignore
          date={reopenData?.previousResolutionDate}
          format="L"
        />
      </SectionItem>
    </Section>
  )
}

export default ReopenDetails
