import React from 'react'
import { SideFilter } from './SearchInitialValues'

export interface SearchContextValue {
  sideFilters: SideFilter[]
  searchFilters: any
  loading: boolean
}

export interface Filter {
  filterName: string
  filterValues: string[]
}

const CaseSearchStateContext = React.createContext<any>(undefined)

CaseSearchStateContext.displayName = 'CaseSearchStateContext'

const CaseSearchDispatchContext = React.createContext<any>(undefined)

CaseSearchStateContext.displayName = 'CaseSearchDispatchContext'

export { CaseSearchDispatchContext, CaseSearchStateContext }
