import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Dropdown,
  FormField,
  GridContainer,
  GridItem,
  Heading,
  Input,
  InputCheckbox,
  Pagination,
  Spinner,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { useTable, usePagination, useRowSelect } from 'react-table'
import { CaseSearchItem } from './CaseSearchItem'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { BulkUpdateCases } from './BulkUpdate'
import _ from 'lodash'
import { Security } from './SearchContext/SearchInitialValues'
import { filterUser } from './SearchContext/SearchUtils'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  EnterpriseIcon,
} from '@enterprise-ui/icons'
import { useCaseSearch } from './SearchContext/CaseSearchProvider'

const baseLayout = css`
  height: 80vh;
  overflow: hidden;
`

const rightContent = css`
  display: flex;
  justify-content: flex-end;
`

const totalAlign = css`
  display: flex;
  align-items: center;
`

const labelBottom = css`
  margin-bottom: 0px;
`
interface CaseTableProps {
  data: any
  loading: boolean
  totalPages: number
  totalCases: number
  loadCases: any
  sortColumn: any
  setSortColumn: any
  sortAscending: boolean
  setSortAscending: any
  updateCases: any
}

const sortFields = [
  { id: 'create_timestamp', value: 'Create Date', security: Security.BOTH },
  { id: 'case_id', value: 'Case Id', security: Security.BOTH },
  {
    id: 'claim_object.ship_date',
    value: 'Ship Date',
    security: Security.INTERNAL,
  },
]

const columns = [
  {
    Header: 'Case Id',
    accessor: 'caseId',
  },
]

export const CaseTable: React.FC<CaseTableProps> = ({
  data = [],
  totalPages,
  loadCases,
  loading,
  sortColumn,
  setSortColumn,
  sortAscending,
  setSortAscending,
  updateCases,
  totalCases,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [actionType, setActionType] = useState<string>('')
  const { userProfile } = useProfile()
  const [state] = useCaseSearch()
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    selectedFlatRows,
    toggleAllRowsSelected,
    isAllRowsSelected,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 100 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      manualSortBy: true,
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalPages,
    },
    usePagination,
    useRowSelect
  )

  useEffect(() => {
    loadCases({ pageSize: pageSize, pageIndex: pageIndex })
  }, [loadCases, pageIndex, pageSize])

  useEffect(() => {
    gotoPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchFilters || state.sideFilters])

  const actualSelectedCases = _.filter(
    selectedFlatRows.map((row: any) => row.original),
    (_: any) => {
      const filter = false
      return !filter
    }
  )

  if (loading) {
    return (
      <GridContainer justify="center" align="center" css={baseLayout}>
        <GridItem>
          <Spinner />
        </GridItem>
      </GridContainer>
    )
  }

  return (
    <div>
      <GridContainer justify="space-between" align="center">
        <GridItem xs={3} className="hc-pb-none hc-pt-none">
          {!userProfile?.isVendor && (
            <InputCheckbox
              id={'selectAll'}
              label={
                <Heading size={6}>
                  {actualSelectedCases.length > 0
                    ? `${actualSelectedCases.length}  SELECTED`
                    : 'SELECT ALL'}
                </Heading>
              }
              onChange={() => toggleAllRowsSelected()}
              checked={
                !isAllRowsSelected
                  ? selectedFlatRows.length > 0
                  : isAllRowsSelected
              }
              indeterminate={!isAllRowsSelected && selectedFlatRows.length > 0}
            />
          )}
        </GridItem>
        {selectedFlatRows.length > 0 && (
          <GridItem xs={3} className="hc-pb-none hc-pt-none">
            <div css={rightContent}>
              <Dropdown location="bottom-right">
                <Button type="primary" size="dense">
                  Actions
                </Button>
                <Dropdown.Menu>
                  <Dropdown.MenuItem
                    onClick={() => {
                      setVisible(true)
                      setActionType('assign')
                    }}
                  >
                    Assign
                  </Dropdown.MenuItem>
                  <Dropdown.MenuItem
                    onClick={() => {
                      setVisible(true)
                      setActionType('reopen')
                    }}
                  >
                    Reopen
                  </Dropdown.MenuItem>
                  <Dropdown.MenuItem
                    onClick={() => {
                      setVisible(true)
                      setActionType('resolve')
                    }}
                  >
                    Resolve
                  </Dropdown.MenuItem>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </GridItem>
        )}
        {selectedFlatRows.length === 0 && (
          <GridItem xs={9} className="hc-pb-none hc-pt-none">
            <div css={rightContent}>
              <div css={totalAlign} className="hc-mr-expanded">
                <Input.Label
                  className="hc-clr-grey02 hc-fs-md"
                  css={labelBottom}
                >
                  Total Cases: &nbsp;
                </Input.Label>
                <Heading size={5}>{totalCases}</Heading>
              </div>
              <Dropdown>
                <Button type="secondary" className="hc-mr-xs">
                  <span className="hc-clr-grey01 hc-fs-caption">Sort by: </span>
                  &nbsp;
                  {sortColumn.value}
                </Button>
                <Dropdown.Menu>
                  {sortFields
                    .filter((key) => filterUser(key, userProfile?.isVendor))
                    .map((sort) => (
                      <Dropdown.MenuItem
                        key={sort.id}
                        onClick={() => setSortColumn(sort)}
                      >
                        {sort.value}
                      </Dropdown.MenuItem>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                onClick={() => {
                  setSortAscending((sort: any) => !sort)
                }}
                type="secondary"
                aria-label="Sort"
              >
                <span className="hc-fs-caption">Sort by: </span>
                &nbsp;
                <EnterpriseIcon
                  icon={sortAscending ? ArrowDownIcon : ArrowUpIcon}
                />
              </Button>
            </div>
          </GridItem>
        )}
        <GridItem xs={12}>
          <Card elevation={3}>
            <Table spacing="none" {...getTableProps()}>
              <Table.Body {...getTableBodyProps()}>
                {page.map((row: any, i: any) => {
                  prepareRow(row)
                  return (
                    <CaseSearchItem
                      casee={row.original}
                      toggleRow={row.toggleRowSelected}
                      isSelected={row.isSelected}
                      key={row.id}
                    />
                  )
                })}
              </Table.Body>
            </Table>
            <GridContainer justify="space-between" className="hc-pa-md">
              <GridItem xs={6}>
                <Pagination
                  currentPage={pageIndex + 1}
                  totalPages={pageCount}
                  onRequestNext={() => {
                    nextPage()
                  }}
                  onRequestPrev={() => {
                    previousPage()
                  }}
                />
              </GridItem>
              <GridItem xs={2}>
                <FormField
                  id={'pageSize'}
                  type="select"
                  onUpdate={(e: any, a: any) => {
                    setPageSize(Number(a))
                  }}
                  options={[
                    { value: 50, label: 'Show 50' },
                    { value: 100, label: 'Show 100' },
                    { value: 250, label: 'Show 250' },
                  ]}
                  value={pageSize}
                ></FormField>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
      <BulkUpdateCases
        visible={visible}
        setVisible={setVisible}
        selectedCases={actualSelectedCases}
        updateCases={updateCases}
        actionType={actionType}
      />
    </div>
  )
}
