import {
  Button,
  Grid,
  GridItem,
  List,
  Modal,
  Spinner,
  Timeline,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import { useNavigate } from 'react-router'
import useAttachment from '../Hooks/useAttachment'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import { EnterpriseIcon, RedoIcon } from '@enterprise-ui/icons'
import { Notification } from './Notification'

interface MainProp {
  redirectUrl: string
  caseId: any
  vendorNumber: number | undefined
  showAlert?: boolean
}

const SaveTimelineModal: React.FC<MainProp> = ({
  redirectUrl,
  caseId,
  vendorNumber,
  showAlert = false,
}) => {
  const navigate = useNavigate()
  const [state, dispatch] = useGlobalForm()
  const { retryUpload, attachmentDetails, updateAttachmentsToDefaults } =
    useAttachment()

  function getTimelineType(
    status: boolean | undefined
  ): 'success' | 'error' | 'default' {
    if (status === true) return 'success'

    if (status === false) return 'success'

    return 'default'
  }

  return (
    <div className="hc-pa-normal hc-pa-normal">
      <Grid.Container className="hc-pa-normal">
        <Grid.Item className="hc-pa-normal">
          <Modal
            headingText={'Case Creation Status'}
            isVisible={state.creatingCase}
            onRefuse={() => {
              dispatch({
                type: SET_FIELD,
                payload: [{ id: 'creatingCase', value: false }],
              })
            }}
          >
            {showAlert && <Notification />}
            <Timeline className="hc-pa-normal hc-ma-normal">
              {attachmentDetails.submitStatuses?.map(
                (statusDetails: any, index: number) => (
                  <Timeline.Item
                    key={index}
                    type={getTimelineType(statusDetails.status)}
                  >
                    {statusDetails.message.search(
                      'Case Created with Case Number'
                    ) !== -1 ? (
                      <React.Fragment>
                        <span>Case Created with Case Number: &nbsp;</span>
                        <span className="hc-clr-success">
                          {statusDetails.message.split(':')[1]}
                        </span>
                      </React.Fragment>
                    ) : (
                      <span>{statusDetails.message}</span>
                    )}
                  </Timeline.Item>
                )
              )}
            </Timeline>
            {!state.isSubmit && attachmentDetails.attachments?.length !== 0 && (
              <GridItem className="hc-pa-normal hc-ma-normal">
                <Input.Label className="hc-clr-grey02">
                  Failed Uploads:
                </Input.Label>
                <List>
                  {attachmentDetails.attachments.map(
                    (attachment: any, index: number) => (
                      <List.Item
                        className="hc-clr-error"
                        divider
                        clickable={!attachmentDetails.retryFlag}
                        onClick={() => {
                          retryUpload(caseId, vendorNumber, index)
                        }}
                      >
                        {attachment?.file.name}
                        <EnterpriseIcon
                          icon={RedoIcon}
                          className="hc-ma-dense"
                        />
                        {attachmentDetails.retryFlag && (
                          <Spinner size="dense" />
                        )}
                      </List.Item>
                    )
                  )}
                </List>
              </GridItem>
            )}
            <Grid.Container className="hc-pa-normal" spacing="dense">
              <Grid.Item>
                <Button
                  type="primary"
                  className="hc-pa-normal hc-ma-normal"
                  disabled={state.isSubmit || attachmentDetails.retryFlag}
                  onClick={async () => {
                    if (attachmentDetails.submitStatuses[0].status === true) {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'creatingCase', value: false }],
                      })
                      await updateAttachmentsToDefaults()
                      navigate(redirectUrl)
                    }
                  }}
                >
                  OK
                </Button>
                {state.isSubmit && <Spinner size="dense" />}
              </Grid.Item>
            </Grid.Container>
          </Modal>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}
export default SaveTimelineModal
