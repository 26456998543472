import React from 'react'
import { Grid, Modal, Button, Form } from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { useAuth } from '@praxis/component-auth'
import { CommonSearch } from '../../Common/CommonSearch'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../Common/Constants'
import { activityData } from '../../Services/saveActivity'

interface MainProp {
  headingText: String
  formik: FormikValues
  handleSave: () => void
  handleCrVendorSearch: () => void
}

const EditCarrierVendorModal: React.FC<MainProp> = ({
  headingText,
  formik,
  handleSave,
  handleCrVendorSearch,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const [state, dispatch] = useGlobalForm()
  const formValues = formik?.values?.claimDetails
  const activityDescription = 'Modified Carrier Vendor Details'

  function saveActivty() {
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'cvendorIdSearch', value: true }],
    })
    handleSave()
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.carrVendorDetailsFlag}
        onRefuse={() =>
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'carrVendorDetailsFlag', value: false }],
          })
        }
      >
        <div className="hc-pa-normal hc-pa-lg">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Grid.Container>
                <CommonSearch
                  buttonId="documentIdSearch"
                  inputId="claimDetails.carrierVendorId"
                  label="Vendor ID"
                  onClick={(e: any) => {
                    handleCrVendorSearch()
                  }}
                  onBlur={(e: any) => {
                    handleCrVendorSearch()
                  }}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseFloat(e.target.value))
                      .toString()
                      .slice(0, 10)
                  }}
                  onChange={formik.handleChange}
                  value={formValues?.carrierVendorId}
                  size={6}
                  type="number"
                  validSearchIconCheck={true}
                  errorFlag={
                    formik.errors.claimDetails?.carrierVendorId !== undefined
                  }
                  fieldErrorText={formik.errors.claimDetails?.carrierVendorId}
                  docDisable={state.isCrVendorNextFlag}
                  isRequired={false}
                  isVendorSearch
                  focus
                />
                {formValues?.carrierVendorName && (
                  <Grid.Item xs={6} className="hc-pb-none">
                    <Form.Field
                      id="Name"
                      label="Name"
                      className="inputtype hc-ta-left"
                      value={formValues?.carrierVendorName}
                      disabled={true}
                    />
                  </Grid.Item>
                )}
              </Grid.Container>
              <Grid.Container>
                <Grid.Item xs={10} md={6} className="hc-pt-none hc-pb-none">
                  <Form.Field
                    id="claimDetails.carrierScacCode"
                    label="SCAC Code"
                    className="hc-ta-left"
                    type="text"
                    value={formValues?.carrierScacCode}
                    onChange={formik.handleChange}
                    maxLength="35"
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item xs></Grid.Item>
                <Grid.Item justify="flex-end">
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          saveActivty()
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={() => {
                          dispatch({
                            type: SET_FIELD,
                            payload: [
                              { id: 'carrVendorDetailsFlag', value: false },
                            ],
                          })
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}

export default EditCarrierVendorModal
