export function activityData(
  userId: string,
  activityDescription: string,
  formikValues: any
): Array<any> {
  const adAuditEntry = formikValues.auditEntries
    ? formikValues.auditEntries.map(function (auditEntries: any) {
        return {
          activityDescription: auditEntries.activityDescription,
          userId: auditEntries.userId,
          workflow: auditEntries.workflow,
          status: auditEntries.status,
          assignUserId: auditEntries.assignUserId,
          activityTimestamp: auditEntries.activityTimestamp,
        }
      })
    : []
  adAuditEntry.unshift({
    activityDescription: `${activityDescription} ${' '}`,
    userId: userId,
    workflow: formikValues?.workflow,
    status: formikValues?.status,
    assignUserId: formikValues?.lanId,
    activityTimestamp: new Date().toISOString(),
  })
  return [...adAuditEntry]
}
