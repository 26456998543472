import React from 'react'
import { Chip } from '@enterprise-ui/canvas-ui-react'
import { CaseResponse } from './Queries'
import { Section, SectionItem } from './Section'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import { CaseStatus, CaseType } from '../Services/ConstantData'

interface MainProp {
  heading: string
  workflowDetails: CaseResponse | undefined
  attachments?: number | undefined
}

const EditWorkflowDetails: React.FC<MainProp> = ({
  heading,
  workflowDetails,
  attachments,
}) => {
  const { userProfile } = useProfile()
  const vendor = userProfile?.isVendor
  let caseStatus = ''
  let color: 'default' | 'alert' | 'success' = 'default'
  if (vendor && workflowDetails?.status === CaseStatus.NEW) {
    color = 'default'
    caseStatus = CaseStatus.SUBMITTED
  } else if (!vendor && workflowDetails?.status === CaseStatus.NEW) {
    color = 'default'
    caseStatus = workflowDetails?.status
  } else if (
    workflowDetails?.status === CaseStatus.INPROGRESS ||
    workflowDetails?.status === CaseStatus.AWAITINGINFO ||
    workflowDetails?.status === CaseStatus.REOPEN
  ) {
    color = 'alert'
    caseStatus = workflowDetails?.status
  } else if (
    workflowDetails?.status === CaseStatus.RESOLVED ||
    workflowDetails?.status === CaseStatus.CLOSED
  ) {
    color = 'success'
    caseStatus = workflowDetails?.status
  }
  return (
    <Section labelGridSize={3} detail heading={heading} expandable>
      <SectionItem label="Status">
        <Chip size="dense" color={color} className="hc-txt-uppercase">
          {caseStatus}
        </Chip>
      </SectionItem>
      <SectionItem label="Attachments">
        <Chip size="dense" className="hc-txt-uppercase hc-bg-grey04">
          {attachments !== undefined && attachments > 0
            ? 'Available'
            : 'Not Available'}
        </Chip>
      </SectionItem>
      {!vendor && (
        <SectionItem label="Workflow">{workflowDetails?.workflow}</SectionItem>
      )}
      {!vendor && (
        <SectionItem label="Assignee">
          {workflowDetails?.userProfile?.firstName}{' '}
          {workflowDetails?.userProfile?.secondName}
        </SectionItem>
      )}
      {!vendor &&
        (workflowDetails?.caseType === CaseType.DISPUTE ||
          workflowDetails?.caseType === CaseType.INTERNALPROCESSING) &&
        workflowDetails?.isReopen !== null &&
        workflowDetails?.isReopen && (
          <SectionItem label="Reopened Case">
            <Chip size="dense" color="alert">
              YES
            </Chip>
          </SectionItem>
        )}
      <SectionItem label="Reporter">{workflowDetails?.createUser}</SectionItem>
    </Section>
  )
}

export default EditWorkflowDetails
