import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GET_REOPENCODES, ReopenCodes } from '../../Cases/Common/Queries'
import { UPDATE_REOPENCODE, UpdateReopenCodeVar } from '../common/AdminQueries'
import {
  Button,
  Form,
  Grid,
  Input,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'

const reopenCodeMap = {
  reopenCodeDescription: '',
  active: false,
}

export const EditReopenCode = (props: any) => {
  const [columns, setColumns] = useState<any>(reopenCodeMap)
  useEffect(() => {
    setColumns({
      reopenCodeDescription: props.editableRow?.reopenCodeDescription,
      active: props.editableRow?.active,
    })
  }, [props.editableRow, props.formVisible])

  const [updateReopenCode, { loading }] = useMutation<
    ReopenCodes,
    UpdateReopenCodeVar
  >(UPDATE_REOPENCODE)

  const handleSave = async (row: ReopenCodes, columns: any) => {
    await updateReopenCode({
      variables: {
        id: row.reopenCodeId!,
        input: {
          reopenCodeText: row.reopenCodeText,
          reopenCodeDescription: columns.reopenCodeDescription,
          active: columns.active,
        },
      },
      refetchQueries: [
        {
          query: GET_REOPENCODES,
        },
      ],
      awaitRefetchQueries: true,
    }).then(() => props.handleClose())
  }
  return (
    <Modal
      headingText="Edit Reopen Code"
      onRefuse={() => {
        props.handleClose()
      }}
      isVisible={props.formVisible}
    >
      <Grid.Container justify={'center'} className="hc-pa-normal">
        <Grid.Item xs={12} className="hc-ph-lg hc-pt-md">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Input.Label>Reopen Code Text</Input.Label>
              <Form.Field
                id="reopenCodeText"
                value={props?.editableRow?.reopenCodeText}
                disabled
              />
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pt-none">
              <Input.Label>Reason Code Description</Input.Label>
              <Form.Field
                id="reasonCodeDescreption"
                value={columns.reopenCodeDescription}
                type={'textarea'}
                onChange={(e: any) => {
                  setColumns({
                    ...columns,
                    reopenCodeDescription: e.target.value,
                  })
                }}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-ph-lg hc-pt-none">
          <Grid.Container align="center">
            <Grid.Item xs={3}>
              <Form.Field
                id="ststus"
                type="toggle"
                label={'Active'}
                value={columns.active}
                onChange={(e: any) => {
                  setColumns({
                    ...columns,
                    active: !columns.active,
                  })
                }}
              ></Form.Field>
            </Grid.Item>
            <Grid.Item justify="flex-end" xs={9}>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSave(props.editableRow, columns)
                    }}
                  >
                    Save
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    onClick={() => {
                      props.handleClose()
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
                {loading && (
                  <Grid.Item>
                    <Spinner />
                  </Grid.Item>
                )}
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}
