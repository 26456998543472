import {
  Card,
  Divider,
  GridContainer,
  GridItem,
  Heading,
  List,
} from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const DragAndDropList = ({ initialColumns, onChange }: any) => {
  const [columns, setColumns] = useState({ ...initialColumns })

  useEffect(() => {
    setColumns({ ...initialColumns })
  }, [initialColumns])

  useEffect(() => {
    onChange(columns)
  }, [columns, onChange])

  const onDragEnd = (result: any) => {
    if (!result.destination) return

    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <GridContainer justify={'space-between'}>
        {Object.keys(columns).map((columnId: string) => {
          const column = columns[columnId]
          return (
            <GridItem xs={6} key={columnId}>
              <Card elevation={4}>
                <Heading size={4} className="hc-pa-normal">
                  {column.title}
                </Heading>
                <Divider></Divider>
                <div>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ height: '300px', overflowY: 'scroll' }}
                      >
                        <List>
                          {column?.items?.map((item: any, index: number) => (
                            <Draggable
                              key={item.reasonCodeId}
                              draggableId={item.reasonCodeId}
                              index={index}
                            >
                              {(provided: any) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div style={{ display: 'none' }}>
                                    {provided.placeholder}
                                  </div>
                                  <List.Item divider>
                                    {item.reasonCodeText}
                                  </List.Item>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      </div>
                    )}
                  </Droppable>
                </div>
              </Card>
            </GridItem>
          )
        })}
      </GridContainer>
    </DragDropContext>
  )
}

export default DragAndDropList
