import React from 'react'
import { Dialog } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { SET_FIELD } from './Constants'
import { FormikValues } from 'formik'

const headingSize = css`
  .C-Heading.\--size-h4 {
    font-size: 15px;
  }
`

interface MainProp {
  duration?: any
  formik?: FormikValues
}

const DocValidationDateCheck: React.FC<MainProp> = (props) => {
  const [state, dispatch] = useGlobalForm()
  const heading = state.invoiceDueDateFlag
    ? 'Dispute cannot be created as invoice is not yet due'
    : state.docCreateDateFlag
    ? `${'Dispute can be raised only within '}${
        props.duration
      }${' months from the Invoice/Chargeback date'}`
    : 'Document is fully Paid already. Dispute cannot be created against this document'
  return (
    <Dialog
      css={headingSize}
      headingText={heading}
      approveButtonText="OK"
      onApprove={() => {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'invoiceDueDateFlag', value: false },
            { id: 'docCreateDateFlag', value: false },
            { id: 'paymentPaid', value: false },
            { id: 'isDocNextFlag', value: false },
          ],
        })
      }}
      isVisible={
        state.invoiceDueDateFlag || state.docCreateDateFlag || state.paymentPaid
      }
    />
  )
}

export default DocValidationDateCheck
