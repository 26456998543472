import React, { useState, useEffect, FunctionComponent } from 'react'
import {
  ExpandableSection,
  Heading,
  Input,
} from '@enterprise-ui/canvas-ui-react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FilterCheckBox } from './FilterCheckBox'
import { FilterSearch } from './FilterSearch'
import { Value, SearchField } from '../SearchContext/SearchInitialValues'
import { FilterDate } from './FilterDate'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List, ListChildComponentProps } from 'react-window'

const filterHeight = css`
  max-height: 170px;
  overflow-y: auto;
`
const autoSizeHeight = css`
  height: 170px;
`

interface FilterProps {
  id: string
  label: string
  filters?: Value[]
  onUpdate: any
  searchable?: boolean
  type: 'checkbox' | 'date' | 'search_group' | 'radio'
  active?: string | boolean | number | any
  startExpanded?: boolean
  searchFields?: SearchField[]
}

export interface FilterItemProps {
  id: string | number
  label: string
  isChecked: boolean
  value: string
}

export const Filter: React.FC<FilterProps> = ({
  label,
  id,
  filters,
  onUpdate,
  searchable,
  type,
  active,
  startExpanded = true,
  searchFields,
}) => {
  const [list, setList] = useState<Value[]>(filters ? filters : [])
  const [searchActive, setSearchActive] = useState<boolean>(
    searchable || list?.length > 7
  )

  useEffect(() => {
    setList(filters ? filters : [])
    setSearchActive(filters!?.length > 7)
  }, [filters])

  const handleSearch = (e: any) => {
    const items = [...filters!]

    setList(
      items.filter((item) => {
        if (!item.label) return true
        return item.label.toLowerCase().includes(e.target.value.toLowerCase())
      })
    )
  }

  if (!filters && type !== 'search_group') return null

  const Row: FunctionComponent<ListChildComponentProps> = ({
    data,
    index,
    style,
  }) => {
    const item = list[index]
    return (
      <div style={style}>
        <FilterCheckBox
          key={item.id}
          id={`${item.id}-${id}`}
          label={item.label}
          value={item.id}
          checked={item.isChecked!}
          onChange={(e: any) => {
            onUpdate(id, 'checkbox', e)
          }}
        />
      </div>
    )
  }

  return (
    <ExpandableSection
      padding="dense"
      startExpanded={startExpanded}
      toggleLocation="left"
    >
      <Heading className="hc-clr-black" size={6}>
        {label}
      </Heading>
      <ExpandableSection.Content className="hc-ml-sm hc-mr-sm">
        {searchActive && (
          <Input.Text
            id={`search_${id}`}
            placeholder={`Search ${label}`}
            onChange={handleSearch}
          />
        )}

        {type === 'checkbox' &&
          (filters && filters?.length > 10 ? (
            <div css={autoSizeHeight}>
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                  <List
                    className="List"
                    height={height}
                    itemCount={list.length}
                    itemSize={30}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </div>
          ) : (
            <div css={filterHeight}>
              {list.map((item) => {
                return (
                  <FilterCheckBox
                    key={item.id}
                    id={`${item.id}-${id}`}
                    label={item.label}
                    value={item.id}
                    checked={item.isChecked!}
                    onChange={(e: any) => {
                      onUpdate(id, 'checkbox', e)
                    }}
                  />
                )
              })}
            </div>
          ))}
        {type === 'date' && (
          <FilterDate
            id={id}
            key={id}
            values={list}
            activeFilter={active}
            onChange={(id: string, value: string) => {
              onUpdate(id, 'date', value)
            }}
          />
        )}
        {type === 'radio' && (
          <>
            <Input.Radio
              id={id}
              options={filters}
              onUpdate={(id: any, value: any) => {
                onUpdate(id, 'radio', value)
              }}
              //@ts-ignore
              value={
                filters?.filter((option) => {
                  return option.isChecked === true
                })[0]?.value
              }
            ></Input.Radio>
          </>
        )}
        {type === 'search_group' &&
          searchFields?.map((field) => (
            <FilterSearch
              id={field.field}
              key={id}
              suggestField={field.suggestField}
              label={field.label}
              placeHolder={field.placeHolder}
              onUpdate={(searchId: string, value: any) => {
                onUpdate(id, 'search_group', value, searchId)
              }}
            />
          ))}
      </ExpandableSection.Content>
    </ExpandableSection>
  )
}
