import {
  Grid,
  Heading,
  Input,
  ExpandableSection,
  Modal,
  Card,
  Button,
  Spinner,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import { BottomBorderDiv } from './SynergyDivComponent'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import ViewAttachment from './ViewAttachment'
import DownloadDocument from './DownloadDocument'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Gallery } from '@enterprise-ui/canvas-ui-react-gallery'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'
import { useAuth } from '@praxis/component-auth'
import jsFileDownload from 'js-file-download'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'

const modalBackground = css`
  .C-Overlay.\--background-dark {
    background: #00000014;
  }
`

const agGrid = css`
  .ag-cell {
    font-size: 10px !important;
  }
  .ag-header-cell-text {
    font-size: 10px !important;
  }
`

interface MainProp {}

const DownloadAttachments: React.FC<MainProp> = () => {
  const auth = useAuth()
  const env = useEnv() as EnvConfig
  const [state, dispatch] = useGlobalForm()
  const makeToast = useToaster()

  const downloadAttachment = async (attachmentId: string, filename: string) => {
    return await axios
      .get(`${env.attachments.attachmentServiceUrl}files/${attachmentId}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': `${env?.apiKey}`,
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      })
      .then((res: any) => {
        if (res.status === 200) {
          jsFileDownload(res.data, filename)
        }
      })
      .catch((reason: any) => {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'downloadingAttachment', value: undefined },
            { id: 'attachmentsError', value: true },
          ],
        })
        makeToast({
          type: 'error',
          heading: 'Error Downloading',
          message: 'There was an error downloading the file. Please try again',
        })
      })
  }

  const handleDownload = async () => {
    await dispatch({
      type: SET_FIELD,
      payload: [{ id: 'downloadingAttachment', value: state.fileRowIndex }],
    })
    let allFiles = state.savedAttachments ? state.savedAttachments : []
    let attachment = allFiles[state.fileRowIndex]
    await downloadAttachment(
      attachment['attachmentId'],
      attachment['fileName']
    ).then(async () => {
      await dispatch({
        type: SET_FIELD,
        payload: [{ id: 'downloadingAttachment', value: undefined }],
      })
    })
  }

  return (
    <React.Fragment>
      <BottomBorderDiv>
        <div className="hc-pa-normal hc-pl-none hc-pr-none hc-pt-none">
          <ExpandableSection startExpanded padding="none" toggleLocation="left">
            <Heading size={6}>Available Backup Documentation:</Heading>

            <ExpandableSection.Content className="hc-pl-lg hc-pv-normal">
              <Grid.Container spacing="none">
                <Grid.Item xs={12} className="hc-ml-none">
                  {state.loadAttachmentList && (
                    <Spinner size="dense" className="hc-ma-normal" />
                  )}
                  {!state.loadAttachmentList &&
                    (!state.savedAttachments ||
                      state.savedAttachments.length === 0) && (
                      <Grid.Container className="hc-pt-none hc-ml-normal">
                        <Grid.Item className="hc-pt-dense hc-pb-dense">
                          <Input.Label>No Documents Attached!</Input.Label>
                        </Grid.Item>
                      </Grid.Container>
                    )}
                  {state.savedAttachments &&
                    state.savedAttachments.length > 0 && (
                      <div className="ag-theme-alpine">
                        <AgGridReact
                          frameworkComponents={{
                            viewAttachment: ViewAttachment,
                            downloadDocument: DownloadDocument,
                          }}
                          css={agGrid}
                          defaultColDef={{
                            editable: true,
                            sortable: true,
                            flex: 1,
                            filter: true,
                            resizable: true,
                          }}
                          pagination={true}
                          paginationPageSize={20}
                          rowData={state.savedAttachments}
                          domLayout={'autoHeight'}
                        >
                          <AgGridColumn field="fileName" minWidth={120} />
                          <AgGridColumn field="documentType" minWidth={140} />
                          <AgGridColumn field="documentNumber" minWidth={160} />
                          <AgGridColumn
                            headerName="Download"
                            field="attachmentId"
                            cellRenderer="downloadDocument"
                            filter={false}
                            minWidth={80}
                          />
                          <AgGridColumn
                            headerName="View"
                            field="attachmentId"
                            cellRenderer="viewAttachment"
                            filter={true}
                            minWidth={80}
                          />
                          <AgGridColumn field="createdBy" minWidth={110} />
                          <AgGridColumn
                            headerName="Create Date"
                            field="createdTimestamp"
                            minWidth={160}
                          />
                        </AgGridReact>
                      </div>
                    )}
                </Grid.Item>
              </Grid.Container>
            </ExpandableSection.Content>
          </ExpandableSection>
        </div>
      </BottomBorderDiv>
      <Modal
        css={modalBackground}
        isVisible={state.setAttachmentView}
        onRefuse={() =>
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'setAttachmentView', value: false },
              { id: 'fileRowIndex', value: undefined },
            ],
          })
        }
      >
        <div className="hc-pa-normal">
          {state.format && (
            <Gallery
              onDownload={() => {
                handleDownload()
              }}
              className="hc-bg-white hc-ma-lg"
              mainItemHeight="70vh"
              items={[
                {
                  mediaType: state.mediaType,
                  name: state.fileName,
                  url: state.fileUrl,
                },
              ]}
            />
          )}
          {state.isAttachmentNotViewable && (
            <Card
              className="hc-pa-normal"
              align="center"
              justify="center"
              elevation={5}
            >
              <div className="hc-pa-normal hc-ta-center">
                <p>
                  The file format is not viewable. Please Download
                  <Button
                    xs={1}
                    onClick={() => {
                      handleDownload()
                    }}
                    isLoading={
                      state.downloadingAttachment === state.fileRowIndex
                    }
                  >
                    <EnterpriseIcon icon={DownloadIcon} />
                  </Button>
                </p>
              </div>
            </Card>
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DownloadAttachments
