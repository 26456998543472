import React from 'react'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import { Link } from 'react-router-dom'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CaretDownIcon, EnterpriseIcon, PlusIcon } from '@enterprise-ui/icons'

const doropdown = css`
  width: 100%;
`
const button = css`
  color: white;
  background: #cc0000;
  border-radius: 1px;
  &:hover {
    background: var(--canvas-background-color--interactive);
  }
`
interface MainProp {
  userProfile: any
}

const NewCaseButton: React.FC<MainProp> = ({ userProfile }) => {
  return (
    <div className="hc-pa-dense">
      <Dropdown
        size="dense"
        location="bottom-right"
        css={doropdown}
        onClick={(e: any) => e.stopPropagation()}
      >
        <Button
          aria-label="Case Types"
          className="hc-fs-body"
          css={button}
          fullWidth
        >
          <EnterpriseIcon icon={PlusIcon} />
          &nbsp; Create New Case
          <EnterpriseIcon icon={CaretDownIcon} />
        </Button>
        <Dropdown.Menu>
          <Dropdown.MenuItem as={Link} to={'/cases/create/dispute'}>
            DISPUTE
          </Dropdown.MenuItem>
          {!userProfile.isVendor && (
            <Dropdown.MenuItem as={Link} to={'/cases/create/claim'}>
              INTERNAL PROCESSING
            </Dropdown.MenuItem>
          )}
          {!userProfile.isVendor && (
            <Dropdown.MenuItem as={Link} to={'/cases/create/tclaim'}>
              INTERNAL PROCESSING (TCLAIM)
            </Dropdown.MenuItem>
          )}
          <Dropdown.MenuItem as={Link} to={'/cases/create/request'}>
            REQUEST FOR COPY/INFO
          </Dropdown.MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default NewCaseButton
