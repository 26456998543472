import React, { useState, useCallback } from 'react'
import { Grid, Layout, Avatar, Button } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import SideNavigation from './SideNavigation/'
import { EnterpriseIcon, MenuIcon } from '@enterprise-ui/icons'

interface Props {
  children: React.ReactNode
}

const Root: React.FC<Props> = ({ children }) => {
  const { session } = useAuth()
  const [menuOpen, setMenuOpen] = useState(false)
  const hideMenu = useCallback(() => setMenuOpen(false), [])
  const toggleMenu = useCallback(() => setMenuOpen(!menuOpen), [menuOpen])
  //@ts-ignore
  const company = session.identity?.company

  return (
    <>
      <Layout.MenuButton className="hc-pb-dense hc-pt-dense">
        <Button
          aria-label="Toggle Menu"
          iconOnly
          color="light-grey"
          onClick={toggleMenu}
          size="dense"
        >
          <EnterpriseIcon icon={MenuIcon} />
        </Button>
      </Layout.MenuButton>
      <Layout.Header includeRail>
        <Grid.Container justify="flex-end">
          <Grid.Item className="hc-clr-contrast-weak">
            <Avatar color="teal" size="dense" aria-label="User">
              {session?.userInfo?.firstName.slice(0, 1).toUpperCase()}
              {session?.userInfo?.lastName.slice(0, 1).toUpperCase()}
            </Avatar>{' '}
            {session?.userInfo?.fullName}, {company}
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
      <SideNavigation isSideNavOpen={menuOpen} onRequestClose={hideMenu} />
      {children}
    </>
  )
}

export default Root
