import React, { useEffect, useState } from 'react'
import {
  Grid,
  Heading,
  Button,
  Form,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import AttachmentDetails from '../Common/AttachmentDetails'
import DisputeDetails from '../Common/DisputeDetails'
import CaseSummary from '../Common/CaseSummary'
import DocumentDetails from '../Common/DocumentDetails'
import { useAuth } from '@praxis/component-auth'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import {
  GET_INVOICE,
  CREATE_CASE,
  NewCaseRequest,
  CreateCase,
  GET_VENDOR,
  GET_DOCPREFIXMAPS_VENDOR,
  GET_TAP_WORKFLOW,
  VendorResponse,
  VendorRequest,
  GetInvoicePayments,
  InvoiceRequest,
  TapWorkflowResponse,
  TapWorkflowRequest,
  VendorDocPrefixMapsResponse,
  WorkFlowResponse,
  GET_WORKFLOWS,
  ReceiptRequest,
  GetReceipts,
  ValidateReceipts,
  VALIDATE_RECEIPTS,
  ImportClaimRequest,
  GetImportClaim,
} from '../Common/Queries'
import CancelModal from '../Common/CancelModal'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import * as Yup from 'yup'
import _ from 'lodash'
import {
  findCaseSubTypeValues,
  findCaseSubTypes,
  findDocumentPrefixMapping,
  findDocumentTypeValues,
  findDocumentSubType,
} from '../Services/DocumentPrefixMapping'
import { CaseType, CaseStatus, CaseSubTypes } from '../Services/ConstantData'
import { findReasonCodeValues } from '../Services/ReasonMapping'
import {
  findWorkflowValue,
  findTapWorkflowMatch,
} from '../Services/WorkflowMapping'
import useAttachment from '../Hooks/useAttachment'
import SaveTimelineModal from '../Common/SaveTimelineModal'
import {
  DISPUTE_HEADING,
  RESET,
  SET_FIELD,
  DATE_FORMAT,
} from '../Common/Constants'
import { BottomBorderDiv, BorderDiv } from '../Common/SynergyDivComponent'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import SpinnerLoad from '../Common/SpinnerLoad'
import {
  GetSearchResults,
  SearchVariable,
  GET_SEARCH_RESULTS,
} from '../../Dashboard/Queries'
import DuplicateCheck from '../Common/DuplicateCheck'
import {
  findExceptionVendorDetails,
  findRequired,
} from '../Services/CheckRequired'
import moment from 'moment'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'
import SupportingAlert from '../Common/SupportingAlert'
import DocValidationDateCheck from '../Common/DocValidationDateCheck'
import { findAttachmentsource } from '../Services/SearchAttachmetTypeMapping'
import {
  GET_RECEIPTS,
  ReceiptValidityRequest,
  GET_IMPORTCLAIM,
} from '../Common/Queries'
import InvalidReceiptsModal from '../Common/InvalidReceiptsModal'
import { twoDecimals } from '../Services/TwoDecimalPlaces'

const BPDisputeCreate: React.FC = () => {
  const { session } = useAuth()
  const { userProfile } = useProfile()
  const cDesc = `${'Created a New Case for '}${CaseType.DISPUTE}${' '}`
  const cTS = new Date().toISOString()
  const userName = session?.userInfo?.fullName ?? ''
  const [state, dispatch] = useGlobalForm()
  const [importVendor, setImportVendor] = useState<boolean>(false)
  const [vendorDetails, setVendorDetails] = useState<any>()
  const client = useApolloClient()
  const [filteredDocTypeValues, setFilteredDocTypeValues] = useState<any>([])
  const [reasonMappingValues, setReasonMappingValues] = useState<any>([])
  const [filteredCaseSubTypeValues, setFilteredCaseSubTypeValues] =
    useState<any>([])
  const [searchState, setSearchState] = useState<string>('')
  const todayDate = moment().startOf('day')
  const env = useEnv() as EnvConfig
  const [duration, setDuration] = useState<number>()
  const [invalidReceipts, setInvalidReceipts] = useState<any>([])
  const schema = Yup.object().shape<NewCaseRequest>({
    originalDocumentNumber: Yup.string()
      .required('Enter Document Id')
      .test(
        'Fine',
        'Document # should be 11 digits for Import Claims',
        function (value) {
          const context = this.options.context as any
          var DocumentValueLength = value?.toString().length
          if (
            context.caseSubType === 'Import Claims' &&
            DocumentValueLength !== 11
          ) {
            return false
          }
          return true
        }
      )
      .test(
        'Fine',
        'Please enter a valid DVS document Number',
        function (value) {
          if (value) {
            var documentIdLength = value?.length
            if (
              state.dvsVendor &&
              (documentIdLength < 10 || documentIdLength > 16)
            ) {
              return false
            }
          }
          return true
        }
      ),
    caseSubType: Yup.string().required('Choose Case Type Code'),
    documentTypeId: Yup.number().test(
      'Fine',
      'Choose Doc Type',
      function (value) {
        if (validationFields?.DocTypeReqFlag === 'Y' && value === undefined) {
          return false
        }
        return true
      }
    ),
    reasonCode: Yup.string().test('Fine', 'Choose Reason', function (value) {
      if (validationFields?.reasonCode === 'Y' && value === undefined) {
        return false
      }
      return true
    }),
    departmentId: Yup.number().test(
      'Fine',
      'Not a valid Department number, please try again',
      function (value) {
        if ((value === null || value === undefined) && !state.dvsVendor) {
          return false
        }
        if (state.departmentCheck === false && !state.dvsVendor) {
          return false
        }
        return true
      }
    ),
    classId: Yup.number().test(
      'Fine',
      'Not a valid Department number - Class number combination , please try again.',
      function (value) {
        if (value === null || value === undefined) {
          return true
        }
        if (state.departmentClassCheck === false) {
          return false
        }
        return true
      }
    ),
    purchaseOrderNumber: Yup.number()
      .test('Fine', 'Enter Valid Purchase Order #', function (value) {
        const context = this.options.context as any
        if (
          context?.caseSubType !== undefined &&
          state.isPORequired &&
          value === undefined &&
          !state.dvsVendor
        ) {
          return false
        }
        return true
      })
      .test(
        'Fine',
        'PO length should be either from 1-7 or 11',
        function (value) {
          if (value) {
            var POValueLength = value?.toString().length
            if (
              (POValueLength >= 1 && POValueLength <= 7) ||
              POValueLength === 11
            ) {
              return true
            } else {
              return false
            }
          }
          return true
        }
      ),
    originalDocumentAmount: Yup.number()
      .required('Please enter a valid Document Amount')
      .positive('Please enter a valid Document Amount'),
    disputedDocumentAmount: Yup.number()
      .required('Please enter valid Dispute amount')
      .positive('Please enter valid Dispute amount')
      .test(
        'Fine',
        'Dispute Amount cannot be greater than the Original Document Amount',
        function (value) {
          const context = this.options.context as any
          if (value > context?.originalDocumentAmount) {
            return false
          }
          return true
        }
      ),
  })
  const formik = useFormik<NewCaseRequest>({
    initialValues: {
      caseType: CaseType.DISPUTE,
      vendorNumber: userProfile?.defaultVendorId,
      vendorContactName: session?.userInfo?.fullName ?? '',
      vendorEmailAddress: session?.userInfo?.email ?? '',
      vendorName: userProfile?.defaultVendorName,
      originalDocumentNumber: '',
      caseDescription: '',
      status: CaseStatus.NEW,
      auditEntries: {
        activityDescription: cDesc,
        userId: userName,
        status: CaseStatus.NEW,
        activityTimestamp: cTS,
      },
      originalDocumentCreateDate: '',
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema<NewCaseRequest>(
          values,
          validationSchema,
          true,
          values
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      await createCase({
        variables: {
          input: values,
        },
      })
    },
  })

  const formValues = formik.values
  const exceptionVendor = findExceptionVendorDetails(
    env.exception.exceptionVendors,
    formValues.vendorNumber
  )
  const { data: docprefixvalues } = useQuery<VendorDocPrefixMapsResponse>(
    GET_DOCPREFIXMAPS_VENDOR,
    { fetchPolicy: 'network-only' }
  )
  const { data: datawf } = useQuery<WorkFlowResponse>(GET_WORKFLOWS, {
    fetchPolicy: 'network-only',
  })
  const onReferTapWorkflow = async () => {
    setSearchState('TapLoading')
    try {
      const { data } = await client.query<
        TapWorkflowResponse,
        TapWorkflowRequest
      >({
        query: GET_TAP_WORKFLOW,
        variables: {
          vendorId: formValues.vendorNumber,
        },
        fetchPolicy: 'network-only',
      })
      const getWorkflow = data?.getTapWorkflow
      if (getWorkflow !== null) {
        formik.setFieldValue(
          'workflow',
          findTapWorkflowMatch(
            datawf?.getWorkflows,
            getWorkflow?.vendorTapWorkflow
          )
        )
        formik.setFieldValue(
          'auditEntries.workflow',
          findTapWorkflowMatch(
            datawf?.getWorkflows,
            getWorkflow?.vendorTapWorkflow
          )
        )
      } else {
        formik.setFieldValue('workflow', 'DEFAULT')
        formik.setFieldValue('auditEntries.workflow', 'DEFAULT')
        console.log('Tap Data Found')
      }
    } catch (errors: any) {
      formik.setFieldValue('workflow', 'DEFAULT')
      formik.setFieldValue('auditEntries.workflow', 'DEFAULT')
      console.log('Error', errors?.message)
    }
  }

  const fetchTapDetails = async (prefixValues: any | undefined) => {
    setSearchState('loading')
    try {
      const { data } = await client.query<GetInvoicePayments, InvoiceRequest>({
        query: GET_INVOICE,
        variables: {
          vendorId: formValues.vendorNumber,
          documentId: formValues.originalDocumentNumber,
        },
        fetchPolicy: 'network-only',
      })
      const getInvoiceDetails = data?.getInvoiceDetails
      const getPaymentDetails = data?.getInvoiceDetails?.payment
      const getReceiptDetails = data?.getInvoiceDetails?.receipts

      if (getInvoiceDetails !== null) {
        setSearchState('success')
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'documentHintText', value: 'Document Number Found' },
            { id: 'disputeSectionFlag', value: true },
            { id: 'documentFound', value: true },
            { id: 'departmentCheck', value: true },
          ],
        })
        formik.setFieldValue('departmentId', getInvoiceDetails?.deptId)
        formik.setFieldValue('purchaseOrderNumber', getInvoiceDetails?.poId)
        formik.setFieldValue('locationId', getInvoiceDetails?.locId)
        formik.setFieldValue(
          'originalDocumentAmount',
          getInvoiceDetails?.originalDocAmount
        )
        formik.setFieldValue(
          'originalDocumentCreateDate',
          getInvoiceDetails?.createDate.concat(DATE_FORMAT)
        )
        const validationDate =
          getInvoiceDetails.caseSubType === 'Deduction'
            ? moment(getInvoiceDetails?.createDate.concat(DATE_FORMAT)).format(
                'YYYY-MM-DD'
              )
            : moment(getInvoiceDetails?.invoiceDate.concat(DATE_FORMAT)).format(
                'YYYY-MM-DD'
              )
        const duration = moment
          .duration(todayDate.diff(validationDate))
          .asMonths()
        const dueDateFlag = moment(
          getInvoiceDetails.invoiceDueDate
        ).isSameOrAfter(todayDate)
        if (duration > exceptionVendor?.duration) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'docCreateDateFlag', value: true }],
          })
        } else if (
          dueDateFlag &&
          getInvoiceDetails.caseSubType === 'Unpaid Invoice'
        ) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'invoiceDueDateFlag', value: true }],
          })
        } else {
          duplicateCaseCheck()
        }

        if (prefixValues === undefined) {
          setFilteredCaseSubTypeValues(
            findCaseSubTypes(
              _.filter(CaseSubTypes, function (caseSubTypeValues) {
                return (
                  caseSubTypeValues.caseType === formValues.caseType &&
                  caseSubTypeValues.caseSubType ===
                    getInvoiceDetails?.caseSubType
                )
              })
            )
          )
          formik.setFieldValue('caseSubType', getInvoiceDetails?.caseSubType)
          const documentTypeValue = _.find(
            docprefixvalues?.getDocumentTypes,
            function (o) {
              return (
                _.toLower(o?.documentTypeText) ===
                _.toLower(getInvoiceDetails?.docType)
              )
            }
          )
          formik.setFieldValue(
            'documentTypeId',
            documentTypeValue?.documentTypeId
          )
          formik.setFieldValue('documentTypeText', getInvoiceDetails?.docType)
          if (getInvoiceDetails?.docSubType) {
            const documentType = findDocumentSubType(
              docprefixvalues?.getDocumentSubTypes,
              getInvoiceDetails?.docSubType
            )
            if (documentType !== undefined) {
              formik.setFieldValue(
                'documentSubTypeId',
                documentType?.documentSubTypeId
              )
              formik.setFieldValue(
                'documentSubTypeText',
                documentType?.documentSubTypeText
              )
            }
          }
        }
        if (getPaymentDetails !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'documentHintText', value: 'Document Number Found' },
              { id: 'disputeSectionFlag', value: true },
              { id: 'documentFound', value: true },
              { id: 'paymentSectionFlag', value: true },
              {
                id: 'paymentPaid',
                value:
                  Number(getPaymentDetails?.checkAmount) >=
                  Number(getInvoiceDetails?.originalDocAmount)
                    ? true
                    : false,
              },
            ],
          })
          formik.setFieldValue(
            'originalCheckAmount',
            getPaymentDetails?.checkAmount
          )
          formik.setFieldValue(
            'originalCheckNumber',
            getPaymentDetails?.checkNumber
          )
          formik.setFieldValue(
            'originalCheckDate',
            getPaymentDetails?.checkDate
          )
        } else {
          formik.setFieldValue('originalCheckAmount', undefined)
          formik.setFieldValue('originalCheckNumber', undefined)
          formik.setFieldValue('originalCheckDate', undefined)
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'paymentSectionFlag', value: false }],
          })
        }

        const receipts = getReceiptDetails.map(
          (receipt) => receipt.receiptNumber
        )

        if (receipts?.length !== 0) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'documentHintText', value: 'Document Number Found' },
              { id: 'disputeSectionFlag', value: true },
              { id: 'receiptsSectionFlag', value: true },
              { id: 'receipts', value: receipts },
            ],
          })
          formik.setFieldValue('receipts', [...receipts])
        } else {
          formik.setFieldValue('receipts', undefined)
        }
      } else {
        if (prefixValues === undefined) {
          setFilteredCaseSubTypeValues(
            findCaseSubTypes(
              _.filter(CaseSubTypes, function (caseSubTypeValues) {
                return (
                  caseSubTypeValues.caseType === formValues.caseType &&
                  caseSubTypeValues.ImportFlag ===
                    vendorDetails?.importVendorFlag
                )
              })
            )
          )
        }
        setSearchState('notfound')
      }
    } catch (errors) {
      duplicateCaseCheck()
      dispatch({
        type: SET_FIELD,
        payload: [
          { id: 'documentHintText', value: 'Document Number Not Found' },
          { id: 'disputeSectionFlag', value: false },
          { id: 'documentFound', value: false },
          { id: 'paymentSectionFlag', value: false },
        ],
      })
      if (prefixValues === undefined) {
        setFilteredCaseSubTypeValues(
          findCaseSubTypes(
            _.filter(CaseSubTypes, function (caseSubTypeValues) {
              return (
                caseSubTypeValues.caseType === formValues.caseType &&
                caseSubTypeValues.ImportFlag === vendorDetails?.importVendorFlag
              )
            })
          )
        )
      }
      formik.setFieldValue('departmentId', undefined)
      formik.setFieldValue('purchaseOrderNumber', undefined)
      formik.setFieldValue('locationId', undefined)
      formik.setFieldValue('originalDocumentAmount', undefined)
      formik.setFieldValue('originalDocumentCreateDate', undefined)
      formik.setFieldValue('originalCheckAmount', undefined)
      formik.setFieldValue('originalCheckNumber', undefined)
      formik.setFieldValue('originalCheckDate', undefined)
      setSearchState('error')
    }
  }

  const duplicateCaseCheck = async () => {
    try {
      const { data } = await client.query<GetSearchResults, SearchVariable>({
        query: GET_SEARCH_RESULTS,
        variables: {
          query: `vendor_number: (${formValues?.vendorNumber}) AND original_document_number.keyword: (${formValues?.originalDocumentNumber}) AND case_type: (${formValues?.caseType}) AND status: (${CaseStatus.NEW} OR ${CaseStatus.INPROGRESS} OR ${CaseStatus.REOPEN} OR ${CaseStatus.AWAITINGINFO})`,
          sort: 'create_timestamp',
          page: 1,
          perPage: 10,
          vendorId: `${formValues?.vendorNumber}`,
        },
        fetchPolicy: 'network-only',
      })
      const getSearchResults = data?.getSearchResults?.cases
      if (getSearchResults !== null) {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'showDialog', value: true },
            { id: 'activeDuplicate', value: true },
          ],
        })
      } else {
        duplicateDocumentCheck()
      }
    } catch (errors: any) {
      console.log('duplicateCaseCheck', errors?.message)
    }
  }

  const duplicateDocumentCheck = async () => {
    try {
      const { data } = await client.query<GetSearchResults, SearchVariable>({
        query: GET_SEARCH_RESULTS,
        variables: {
          query: `vendor_number: (${formValues?.vendorNumber}) AND original_document_number.keyword: (${formValues?.originalDocumentNumber}) AND case_type: (${formValues?.caseType})`,
          sort: 'create_timestamp',
          page: 1,
          perPage: 10,
          vendorId: `${formValues?.vendorNumber}`,
        },
        fetchPolicy: 'network-only',
      })
      const getSearchResults = data?.getSearchResults?.cases
      if (!state.activeDuplicate) {
        if (getSearchResults !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'showDialog', value: true }],
          })
        } else {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isDocNextFlag', value: true },
              { id: 'documentFound', value: true },
            ],
          })
        }
      }
    } catch (errors: any) {
      console.log('duplicateDocumentCheck', errors?.message)
    }
  }

  const {
    uploadAttachment,
    updateStatus,
    attachmentDetails,
    updateAttachmentsToDefaults,
  } = useAttachment()
  const createCase = async (postDispute: any) => {
    const submitStatuses = attachmentDetails.submitStatuses
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'isSubmit', value: true },
        { id: 'creatingCase', value: true },
        { id: 'caseCreatedFlag', value: true },
      ],
    })
    await createCaseDispute(postDispute)
      .then(async (res) => {
        submitStatuses?.push({
          message: `Case Created with Case Number:${res?.data?.createCase.caseId}`,
          status: true,
        })
        updateStatus(submitStatuses)
        await uploadAttachment(
          String(res?.data?.createCase?.caseId),
          formValues.vendorNumber
        ).then(() => {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isSubmit', value: false },
              { id: 'creatingCase', value: true },
              { id: 'caseCreatedFlag', value: true },
              { id: 'createCase', value: false },
            ],
          })
        })
      })
      .catch(() => {
        submitStatuses?.push({
          message: 'Unable to create case, try again',
          status: false,
        })
        updateStatus(submitStatuses)
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'isSubmit', value: false },
            { id: 'creatingCase', value: true },
            { id: 'caseCreatedFlag', value: false },
            { id: 'createCase', value: false },
          ],
        })
      })
  }

  const [createCaseDispute, dispute] = useMutation<
    CreateCase,
    { input: NewCaseRequest }
  >(CREATE_CASE)

  const getVendor = async () => {
    try {
      const { data } = await client.query<VendorResponse, VendorRequest>({
        query: GET_VENDOR,
        variables: {
          id: userProfile?.defaultVendorId,
        },
        fetchPolicy: 'network-only',
      })
      const getVendorDetails = data?.getVendor
      if (getVendorDetails !== null) {
        setVendorDetails(data?.getVendor)
        formik.setFieldValue('vendorType', getVendorDetails?.vendorTypeName)
        formik.setFieldValue('bankName', getVendorDetails?.bankName)
        dispatch({
          type: SET_FIELD,
          payload: [
            {
              id: 'dvsVendor',
              value: getVendorDetails?.dvsFlag === 'Y' ? true : false,
            },
            {
              id: 'maxRangeVendors',
              value: _.find(
                env.attachments.maxRangeVendors,
                function (maxRangeVendors) {
                  return (
                    Number(maxRangeVendors) ===
                    Number(userProfile?.defaultVendorId)
                  )
                }
              ),
            },
          ],
        })
        if (getVendorDetails?.importVendorFlag === 'Y') {
          const caseSubTypeValues = findCaseSubTypes(
            _.filter(CaseSubTypes, function (caseSubTypeValues) {
              return (
                caseSubTypeValues.caseType === formValues.caseType &&
                caseSubTypeValues.ImportFlag ===
                  getVendorDetails?.importVendorFlag
              )
            })
          )
          if (caseSubTypeValues !== undefined) {
            setFilteredCaseSubTypeValues(caseSubTypeValues)
            formik.setFieldValue('caseSubType', caseSubTypeValues[0].value)
          }
          setImportVendor(true)
        } else {
          setImportVendor(false)
          setFilteredCaseSubTypeValues([])
          formik.setFieldValue('caseSubType', '')
        }
      } else {
        setVendorDetails(null)
        console.log('No Data Found')
      }
    } catch (errors: any) {
      console.log('Error', errors?.message)
    }
  }

  const validationFields = findRequired(formik.values)
  const onDisputeNextClick = () => {
    formik.validateForm().then((res) => {
      let fields = [
        'originalDocumentNumber',
        'caseSubType',
        'documentTypeId',
        'originalDocumentCreateDate',
        'reasonCode',
      ]
      if (validateFields(res, fields)) {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'showDocuments', value: true },
            {
              id: 'isPORequired',
              value: validationFields?.PO === 'Y' ? true : false,
            },
          ],
        })
        formik.setErrors({})
      }
    })
  }

  const onDocumentNextClick = async () => {
    let receipts: any[] = []
    let validReceipts: any[] = []
    let invalidFlag: boolean = false
    let invalidReceipts: any[]
    if (
      formik.values?.purchaseOrderNumber !== undefined &&
      formik.values?.locationId !== undefined
    ) {
      const { data } = await client.query<GetReceipts, ReceiptRequest>({
        query: GET_RECEIPTS,
        variables: {
          vendorId: formik.values?.vendorNumber,
          poId: formik.values?.purchaseOrderNumber,
          locationId: formik.values?.locationId,
        },
        fetchPolicy: 'network-only',
      })
      const getReceipts = data?.getReceipts
      receipts = getReceipts.map((receipt) => receipt?.receiptNumber)
    }

    if (
      formik.values?.receipts !== null &&
      formik.values?.receipts !== undefined &&
      formik.values?.locationId !== undefined
    ) {
      const { data: validityData } = await client.query<
        ValidateReceipts,
        ReceiptValidityRequest
      >({
        query: VALIDATE_RECEIPTS,
        variables: {
          receipts: formik.values?.receipts,
          locationId: formik.values?.locationId,
          vendorId: formik.values?.vendorNumber,
        },
        fetchPolicy: 'network-only',
      })
      validReceipts = validityData.validateReceipts
        .filter((receipt) => receipt.valid === true)
        .map((receipt) => receipt.receiptNumber)

      invalidReceipts = validityData.validateReceipts
        .filter((receipt) => receipt.valid === false)
        .map((receipt) => receipt.receiptNumber)

      setInvalidReceipts(invalidReceipts)
      invalidFlag = invalidReceipts.length !== 0 ? true : false
    }

    if (!invalidFlag) {
      formik.validateForm().then((res) => {
        const fields = [
          'purchaseOrderNumber',
          'originalDocumentAmount',
          'departmentId',
          'disputedDocumentAmount',
          'disputeDetails',
          'classId',
        ]
        if (validateFields(res, fields)) {
          const allReceipts = [...new Set([...receipts, ...validReceipts])]
          formik.setFieldValue('receipts', allReceipts)
          const [searchAttachmet, searchAttachmetType] = findAttachmentsource(
            env,
            formValues.caseType,
            formValues.caseSubType,
            formValues.documentTypeId ?? 999,
            formValues.reasonCode,
            formValues.originalDocumentNumber
          )
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isDocShowable', value: true },
              { id: 'receipts', value: allReceipts },
              { id: 'location', value: formValues.locationId },
              { id: 'vendorNumber', value: formValues.vendorNumber },
              {
                id: 'documentNumber',
                value: formValues.originalDocumentNumber,
              },
              { id: 'searchAttachments', value: searchAttachmet },
              { id: 'searchAttachmentType', value: searchAttachmetType },
            ],
          })
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isDocShowable', value: true }],
          })
          formik.setErrors({})
          if (
            formik?.values?.workflow !== 'DEFAULT' &&
            (formik?.values?.workflow === null ||
              formik?.values?.workflow === undefined)
          ) {
            formik.setFieldValue(
              'workflow',
              findWorkflowValue(
                docprefixvalues?.getDocumentTypeMaps,
                formik?.values,
                state.documentPrefix,
                state.dvsVendor
              )
            )
            formik.setFieldValue(
              'auditEntries.workflow',
              findWorkflowValue(
                docprefixvalues?.getDocumentTypeMaps,
                formik?.values,
                state.documentPrefix,
                state.dvsVendor
              )
            )
          }
        }
        updateAttachmentsToDefaults()
      })
    } else {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'invalidReceiptsFlag', value: true }],
      })
    }
  }

  const handleSearch = async () => {
    formik.validateForm().then(async (res) => {
      const fields = ['originalDocumentNumber']
      if (validateFields(res, fields)) {
        formik.setErrors({})
        if (!importVendor) {
          const prefixValues = findDocumentPrefixMapping(
            docprefixvalues?.getDocumentTypeMaps,
            formik?.values?.originalDocumentNumber,
            formik?.values?.caseType
          )
          dispatch({
            type: SET_FIELD,
            payload: [
              {
                id: 'documentPrefix',
                value: prefixValues,
              },
            ],
          })
          if (prefixValues !== undefined) {
            setFilteredCaseSubTypeValues(
              findCaseSubTypeValues(prefixValues, formik?.values)
            )
            setFormValues(prefixValues[0])
            if (prefixValues[0].documentSource === 'TAP') {
              fetchTapDetails(prefixValues)
            } else {
              duplicateCaseCheck()
            }
          } else if (prefixValues === undefined) {
            fetchTapDetails(prefixValues)
          }
        } else {
          duplicateCaseCheck()
          formik.setErrors({})
          try {
            const { data } = await client.query<
              GetImportClaim,
              ImportClaimRequest
            >({
              query: GET_IMPORTCLAIM,
              variables: {
                vendorId: formik.values?.vendorNumber,
                claimId: formik.values?.originalDocumentNumber,
              },
              fetchPolicy: 'network-only',
            })
            const claimDetails = data?.getImportClaim
            const documentTypeValue = _.find(
              filteredDocTypeValues,
              function (o) {
                return o?.label.startsWith(claimDetails.claimTypeCode)
              }
            )
            formik.setFieldValue('documentTypeId', documentTypeValue?.id)
            formik.setFieldValue('documentTypeText', documentTypeValue?.label)
            formik.setFieldValue(
              'originalDocumentAmount',
              twoDecimals(claimDetails.claimAmount.toString())
            )
            formik.setFieldValue(
              'purchaseOrderNumber',
              Math.max(0, parseFloat(claimDetails.purchaseOrderId.toString()))
                .toString()
                .slice(0, 11)
            )
            formik.setFieldValue(
              'departmentId',
              Math.max(0, parseFloat(claimDetails.departmentId.toString()))
                .toString()
                .slice(0, 4)
            )
            formik.setFieldValue(
              'classId',
              Math.max(0, parseFloat(claimDetails.classId.toString()))
                .toString()
                .slice(0, 2)
            )
            formik.setFieldValue(
              'locationId',
              Math.max(
                0,
                parseFloat(claimDetails.consolidatedLocationId.toString())
              )
                .toString()
                .slice(0, 4)
            )
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'departmentCheck', value: true },
                { id: 'departmentClassCheck', value: true },
              ],
            })
          } catch (err) {
            console.log('err while fetching import claim details')
          }
        }
      } else {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'isDocNextFlag', value: false }],
        })
        formik.setErrors({ originalDocumentNumber: res.originalDocumentNumber })
      }
    })
  }
  const handleReset = () => {
    formik.resetForm()
    dispatch({
      type: RESET,
    })
    getVendor()
  }

  function validateFields(errors: any, fields: string[]): boolean {
    const keys = _.keysIn(errors)
    return !fields.some((el) => keys.includes(el))
  }

  const setFormValues = (values: any) => {
    formik.setFieldValue('caseSubType', values?.caseSubType)
    formik.setFieldValue(
      'documentTypeId',
      values?.documentTypeId ? values?.documentTypeId : undefined
    )
    formik.setFieldValue('documentTypeText', values?.documentTypeText)
    formik.setFieldValue(
      'documentSubTypeId',
      values?.documentSubTypeId ? values?.documentSubTypeId : undefined
    )
    formik.setFieldValue('documentSubTypeText', values?.documentSubTypeText)
    formik.setFieldValue(
      'workflow',
      values?.workflow !== null ? values?.workflow : 'DEFAULT'
    )
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    await dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'documentAlert', value: true },
        { id: 'alertComponent', value: CaseStatus.NEW },
      ],
    })
  }
  useEffect(() => {
    if (state.createCase) {
      formik.handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.createCase])

  useEffect(() => {
    if (!importVendor) {
      setReasonMappingValues([])
      formik.setFieldValue('caseSubType', '')
      formik.setFieldValue('documentTypeId', undefined)
      formik.setFieldValue('documentTypeText', '')
      formik.setFieldValue('documentSubTypeId', undefined)
      formik.setFieldValue('documentSubTypeText', '')
      formik.setFieldValue('reasonCode', '')
      formik.setFieldValue('reasonCodeText', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.originalDocumentNumber])

  useEffect(() => {
    setReasonMappingValues([])
    setFilteredDocTypeValues([])
    formik.setFieldValue('reasonCode', '')
    formik.setFieldValue('reasonCodeText', '')
    if (formik.values?.caseSubType) {
      if (docprefixvalues?.getDocumentTypeMaps) {
        setFilteredDocTypeValues(
          findDocumentTypeValues(
            docprefixvalues?.getDocumentTypeMaps,
            formik?.values
          )
        )
      }
      setReasonMappingValues(
        findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.caseSubType, docprefixvalues?.getDocumentTypeMaps])

  useEffect(() => {
    setReasonMappingValues(
      findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
    )
    formik.setFieldValue('reasonCode', '')
    formik.setFieldValue('reasonCodeText', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.documentTypeId])

  useEffect(() => {
    if (
      formik.values.caseSubType &&
      formik.values?.documentTypeId &&
      formik.values?.documentSubTypeId
    ) {
      setReasonMappingValues(
        findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.caseSubType,
    formik.values?.documentTypeId,
    formik.values?.documentSubTypeId,
  ])

  useEffect(() => {
    if (userProfile) {
      getVendor()
    }
    formik.setFieldValue('vendorNumber', userProfile?.defaultVendorId)
    formik.setFieldValue('vendorName', userProfile?.defaultVendorName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  useEffect(() => {
    if (state.disputeAmountCheck) {
      formik.setFieldValue(
        'disputedDocumentAmount',
        formValues.originalDocumentAmount
      )
    } else if (!state.disputeAmountCheck) {
      formik.setFieldValue('disputedDocumentAmount', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.disputeAmountCheck])

  useEffect(() => {
    if (formValues.workflow === 'USEVEN') {
      onReferTapWorkflow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.workflow])

  useEffect(() => {
    if (exceptionVendor.duration !== undefined) {
      setDuration(exceptionVendor.duration)
    }
  }, [exceptionVendor.duration])

  return (
    <Layout.Body includeRail>
      <Grid.Container justify="space-between" className="hc-pa-none">
        <Grid.Item xs={12} md={7}>
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading
                size={6}
                className="hc-mt-normal hc-mb-normal hc-ml-none"
              >
                CREATE NEW DISPUTE
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <Button className="headerButton" onClick={handleReset}>
                RESET FORM
              </Button>
            </Grid.Item>
          </Grid.Container>
          <Form>
            <BottomBorderDiv className="hc-pb-dense hc-pt-none">
              <DisputeDetails
                heading={DISPUTE_HEADING}
                caseSubTypeValues={filteredCaseSubTypeValues}
                docTypeValues={filteredDocTypeValues}
                reasonCodeValues={reasonMappingValues}
                formik={formik}
                handleSearch={handleSearch}
                importVendor={importVendor}
              />
              {!state.showDocuments && (
                <div className="hc-pa-normal hc-pb-none">
                  <Grid.Container direction="row-reverse">
                    <Grid.Item className="hc-pt-none">
                      <Button
                        onClick={() => {
                          onDisputeNextClick()
                        }}
                        type="primary"
                        disabled={!state.isDocNextFlag}
                      >
                        Next
                      </Button>
                    </Grid.Item>
                    {searchState === 'loading' && (
                      <SpinnerLoad case={false} createCase />
                    )}
                  </Grid.Container>
                </div>
              )}
            </BottomBorderDiv>
            {state.showDocuments && (
              <BottomBorderDiv className="hc-pb-dense">
                <DocumentDetails formik={formik} importVendor={importVendor} />
                {!state.isDocShowable && (
                  <div className="hc-pa-normal hc-pb-none">
                    <Grid.Container direction="row-reverse">
                      <Grid.Item className="hc-pt-none">
                        <Button
                          onClick={() => {
                            onDocumentNextClick()
                          }}
                          type="primary"
                        >
                          Next
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                )}
              </BottomBorderDiv>
            )}
            {state.isDocShowable && (
              <BottomBorderDiv className="hc-pb-dense">
                <AttachmentDetails
                  isVendor={userProfile?.isVendor}
                  reasonCode={formValues?.reasonCode}
                />
              </BottomBorderDiv>
            )}
            {state.isDocShowable && (
              <div className="hc-pa-normal hc-pb-none">
                {(state.creatingCase || dispute.loading) && (
                  <SaveTimelineModal
                    caseId={dispute?.data?.createCase.caseId}
                    vendorNumber={formValues.vendorNumber}
                    redirectUrl={`/cases/${dispute?.data?.createCase?.caseId}`}
                    showAlert={env.alertWorkflows?.includes(
                      formValues.workflow!!
                    )}
                  />
                )}
                <Grid.Container direction="row-reverse">
                  <Grid.Item hidden={state.isSubmit}>
                    <Button
                      type="secondary"
                      onClick={() => {
                        dispatch({
                          type: SET_FIELD,
                          payload: [{ id: 'isCancel', value: true }],
                        })
                      }}
                      disabled={state.caseCreatedFlag}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      type="primary"
                      onClick={(e: any) => {
                        handleSubmit(e)
                      }}
                      disabled={
                        state.caseCreatedFlag ||
                        attachmentDetails.attachmentTypeNotSet
                      }
                    >
                      Submit
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            )}
          </Form>
        </Grid.Item>
        <Grid.Item xs={12} md={5}>
          <Grid.Container>
            <Grid.Item>
              <Heading
                size={6}
                className=" hc-mt-normal hc-mb-normal hc-ml-none"
              >
                SUMMARY OF YOUR CASE
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <BorderDiv scrollable>
            <CaseSummary
              isVendor={userProfile?.isVendor}
              caseSummaryDetails={formValues}
              docTypeReqFlag={validationFields?.DocTypeReqFlag}
            />
          </BorderDiv>
        </Grid.Item>
        <CancelModal
          headingText="Cancel Dispute Creation!"
          heading="Do You Want to Cancel the Dispute Creation?"
        />
        <DuplicateCheck />
        <DocValidationDateCheck duration={duration} />
        <InvalidReceiptsModal
          heading={
            'The following Receipt IDs are invalid. Please re-enter them correctly.'
          }
          headingText={'Invalid Receipts'}
          invalidReceipts={invalidReceipts}
        />
        <SupportingAlert />
      </Grid.Container>
    </Layout.Body>
  )
}
export default BPDisputeCreate
