import React from 'react'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import { AttachmentProvider } from '../Context/AttachmentContext'
import TMTClaimCreate from './TMTClaimCreate'
import { GlobalFormProvider } from '../Context/GlobalFormStateContext'

const CreateTclaim: React.FC = () => {
  const { userProfile } = useProfile()

  return (
    <React.Fragment>
      {!userProfile?.isVendor && (
        <AttachmentProvider>
          <GlobalFormProvider>
            <TMTClaimCreate />
          </GlobalFormProvider>
        </AttachmentProvider>
      )}
    </React.Fragment>
  )
}
export default CreateTclaim
