import {
  Anchor,
  Caption,
  Card,
  Chip,
  Form,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import React, { useState, useEffect } from 'react'
import { CaseType } from '../../../Services/ConstantData'
import { VendorInfo } from '../../CaseSearchItem'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { getDocNumField, getResolveCaseDateField } from '.'
import _ from 'lodash'
import moment from 'moment'
import { twoDecimals } from '../../../Services/TwoDecimalPlaces'
import { getChipClassNames } from '../../utils'

interface ItemProps {
  label: any
  value: any
}
const FieldItem: React.FC<ItemProps> = ({ label, value }) => {
  return (
    <Caption
      above={<p className="hc-fs-min">{label}</p>}
      className="hc-fs-sm hc-to-ellipsis"
    >
      {value}
    </Caption>
  )
}

export interface ResolveItemProps {
  index: any
  caseData: any
  handleIndividualCase: any
  globalActions: any
  resolutionCodeList: any
  bulkResolveError: boolean
  resolutionNoteList: any
}

export const ResolutionItem: React.FC<ResolveItemProps> = ({
  index,
  caseData,
  handleIndividualCase,
  globalActions,
  resolutionCodeList,
  bulkResolveError,
  resolutionNoteList,
}) => {
  const [settlementNote, setSettlementNote] = useState({
    id: '',
    value: '',
    label: '',
  })

  useEffect(() => {
    setSettlementNote({
      id: caseData?.caseId,
      value: globalActions?.settlementNote ?? '',
      label: globalActions?.settlementNote ?? '',
    })
  }, [caseData?.caseId, globalActions])

  return (
    <Grid.Item className="hc-mv-md" key={index}>
      <Card
        elevation={3}
        className="hc-ph-md hc-pt-md"
        style={{ border: 'solid #e6e6e6' }}
      >
        <Grid.Container>
          <Grid.Item
            style={{ fontWeight: 'bold' }}
            className="hc-fs-xs hc-ph-min hc-pv-md"
          >
            <Anchor href={`/cases/${caseData?.caseId}`} target="_blank">
              CASE: {caseData?.caseId}
            </Anchor>
          </Grid.Item>
          <Grid.Item className={'hc-ph-min'}>
            <Chip
              className={getChipClassNames(caseData?.caseType)}
              size="dense"
            >
              {caseData?.caseType}
            </Chip>
          </Grid.Item>
          <Grid.Item className="hc-ph-min">
            <FieldItem
              label="Document #"
              value={caseData?.originalDocumentNumber}
            />
          </Grid.Item>
          <Grid.Item>
            <FieldItem
              label="Vendor"
              value={
                <VendorInfo
                  vendorId={caseData?.vendorNumber}
                  vendorName={caseData?.vendorName}
                />
              }
            />
          </Grid.Item>
          <Grid.Item>
            <FieldItem
              label={`Reason for ${
                caseData?.caseType === CaseType.INTERNALPROCESSING
                  ? 'Claim'
                  : 'Dispute'
              }`}
              value={caseData?.reasonCodeText}
            />
          </Grid.Item>
          <Grid.Item>
            <FieldItem
              label={`${
                caseData?.caseType === CaseType.INTERNALPROCESSING
                  ? 'Filed'
                  : 'Received'
              } Date`}
              value={
                <DateFormatter date={caseData?.createTimestamp} format="L" />
              }
            />
          </Grid.Item>
          {[CaseType.DISPUTE, CaseType.INTERNALPROCESSING].includes(
            caseData?.caseType
          ) && (
            <Grid.Item>
              <FieldItem
                label={`${
                  caseData?.caseType === CaseType.INTERNALPROCESSING
                    ? 'Claim'
                    : 'Disputed'
                } Amount`}
                value={`$${caseData?.disputedDocumentAmount}`}
              />
            </Grid.Item>
          )}
          {[CaseType.DISPUTE, CaseType.INTERNALPROCESSING].includes(
            caseData?.caseType
          ) && (
            <>
              <Grid.Item>
                <Form.Field
                  id={`${index}_newDocumentNumber`}
                  label={'New Document #'}
                  type="text"
                  onChange={(event: any) => {
                    handleIndividualCase(
                      caseData,
                      getDocNumField(caseData),
                      event.target.value
                    )
                  }}
                  className="inputtype hc-ta-left"
                  value={_.get(caseData, getDocNumField(caseData) ?? '') || ''}
                />
              </Grid.Item>
              <Grid.Item>
                <Form.Field
                  id={`${index}_resolutionAmount`}
                  label={'Resolution Amount ($)'}
                  type="number"
                  onChange={(event: any) => {
                    handleIndividualCase(
                      caseData,
                      'resolutionAmount',
                      twoDecimals(event.target.value)
                    )
                  }}
                  errorText="Resolution $ can't be greater than the Disputed $"
                  error={
                    bulkResolveError &&
                    caseData?.resolutionAmount >
                      caseData?.disputedDocumentAmount
                  }
                  className="inputtype hc-ta-left"
                  value={caseData?.resolutionAmount || ''}
                />
              </Grid.Item>
            </>
          )}
          {caseData?.caseType === CaseType.INTERNALPROCESSING && (
            <Grid.Item xs>
              <Form.Field
                id={`${index}_claimFiledDate`}
                label="Claim/Deduction Filed Date"
                type="date"
                onChange={(event: any) => {
                  handleIndividualCase(
                    caseData,
                    getResolveCaseDateField(caseData) ?? '',
                    moment(event.target.value).utc().format()
                  )
                }}
                value={moment(
                  _.get(caseData, getResolveCaseDateField(caseData) ?? '') || ''
                ).format('YYYY-MM-DD')}
              />
            </Grid.Item>
          )}
          <Grid.Item xs={3}>
            <Autocomplete
              id={`${index}_resolutionExplanation`}
              showSearchIcon
              options={resolutionCodeList ?? []}
              label="Settlement/Resolution Explanation"
              required
              errorText="Please select an explanation"
              error={
                bulkResolveError &&
                (caseData?.resolutionCodeText === '' ||
                  caseData?.resolutionCodeText == null)
              }
              onUpdate={(id, value) => {
                handleIndividualCase(
                  caseData,
                  'resolutionCodeText',
                  value?.value
                )
              }}
              value={{
                id: caseData?.caseId ?? '',
                label: caseData?.resolutionCodeText ?? '',
                value: caseData?.resolutionCodeText ?? '',
              }}
            />
          </Grid.Item>
          <Grid.Item xs={3}>
            <Autocomplete
              id={`${index}_bulkSettlementNote`}
              showSearchIcon
              label="Select a Settlement Note"
              options={resolutionNoteList ?? []}
              errorText="Please select a note"
              onUpdate={(id, value) => {
                setSettlementNote(value)
                handleIndividualCase(caseData, 'resolutionText', value?.value)
              }}
              value={settlementNote}
            />
          </Grid.Item>
          <Grid.Item xs={3} className="hc-pb-none">
            <Form.Field
              id={`${index}_bulkSettlementNoteText`}
              label="Final Settlement Note"
              type="text"
              onChange={(e: any) => {
                handleIndividualCase(caseData, 'resolutionText', e.target.value)
              }}
              value={caseData?.resolutionText ?? ''}
              maxLength="500"
            />
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Grid.Item>
  )
}
