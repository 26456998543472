import React from 'react'
import {
  Grid,
  Heading,
  Form,
  ExpandableSection,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'

import { FormikValues } from 'formik'
import { RECEIPT_HEADING } from './Constants'

import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { CheckCircleIcon, EnterpriseIcon } from '@enterprise-ui/icons'
import moment from 'moment'
import _ from 'lodash'

interface MainProp {
  formik: FormikValues
  displayCols?: any
  requiredCols?: any
  onShipDateChange?: any
}

const ReceiptDetails: React.FC<MainProp> = ({
  formik,
  displayCols,
  requiredCols,
  onShipDateChange,
}) => {
  const [state] = useGlobalForm()
  const formData = formik.values

  const handleReceiptChange = (e: any) => {
    formik.setFieldValue('receipts', e.target.value?.split(','))
  }

  return (
    <div className="hc-pa-normal hc-pa-none hc-ma-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="dense">
        <Heading size={6}>
          {RECEIPT_HEADING}
          {state.isDocShowable && (
            <EnterpriseIcon
              icon={CheckCircleIcon}
              color="green"
              className="hc-va-sub"
            />
          )}
        </Heading>
        <ExpandableSection.Content>
          {state.receiptsSectionFlag && (
            <Grid.Container className="hc-mv-none hc-ml-expanded hc-pa-none">
              <Grid.Item xs={10}>
                <Grid.Container>
                  <Grid.Item xs={10} md={5}>
                    <Input.Label>Receipt ID / Appointment Number</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {formData?.receipts?.join(',')}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
          <Grid.Container className="hc-mv-none hc-ml-expanded hc-pa-none">
            <Grid.Item xs={10}>
              <Grid.Container>
                {!state.receiptsSectionFlag && (
                  <Grid.Item xs={6} className="hc-pb-none">
                    <Form.Field
                      id="receipts"
                      label="Receipt #"
                      className="inputtype hc-ta-left"
                      type="text"
                      onChange={(e: any) => handleReceiptChange(e)}
                      value={formData.receipts?.join(',') ?? ''}
                      maxLength="10"
                    />
                  </Grid.Item>
                )}
                <Grid.Item xs={6} className="hc-pb-none">
                  <DatePicker
                    id="claimDetails.shipDate"
                    label="Ship Date"
                    placeholder="MM/DD/YYYY"
                    onUpdate={(id, value) => {
                      formik.setFieldValue(
                        id,
                        moment(value).format('MM/DD/YYYY')
                      )
                      if (onShipDateChange !== undefined) {
                        _.delay(() => {
                          onShipDateChange()
                        }, 200)
                      }
                    }}
                    disableDates={{ future: true }}
                    required={requiredCols.shipDate}
                    value={formik.values.claimDetails.shipDate}
                    disabled={state.isCrVendShowable}
                    error={formik.errors.claimDetails?.shipDate !== undefined}
                    errorText={formik.errors?.claimDetails?.shipDate}
                    location="bottom-right"
                  />
                </Grid.Item>
                {displayCols.receiptDate && state.cvendorNbrflag && (
                  <Grid.Item xs={6} className="hc-pb-none">
                    <DatePicker
                      id="claimDetails.receiptDate"
                      label="Receipt Date"
                      placeholder="MM/DD/YYYY"
                      onUpdate={(id, value) => {
                        formik.setFieldValue(
                          id,
                          moment(value).format('YYYY-MM-DD')
                        )
                      }}
                      value={formik.values.claimDetails.receiptDate}
                      noValidate={
                        formik.values.claimDetails.receiptDate !== undefined
                      }
                      className="inputtype hc-ta-left"
                      error={
                        formik.errors.claimDetails?.receiptDate !== undefined
                      }
                      errorText={formik.errors.claimDetails?.receiptDate}
                      required={requiredCols.receiptDate}
                      disabled={state.isDocShowable}
                      location="bottom-right"
                    />
                  </Grid.Item>
                )}
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default ReceiptDetails
