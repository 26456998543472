import React, { useState, useEffect } from 'react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useApolloClient } from '@apollo/client'
import {
  GetCaseSuggestion,
  SuggestionRequest,
  GET_CASE_SUGGESTION,
} from './SearchQueries'
import { buildUrl } from './SearchContext/SearchUtils'
import { debounce, uniqBy } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'

export const CaseSearchAutoComplete = () => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const location = useLocation()
  const { userProfile } = useProfile()

  const [searchTerm, setSearchTerm] = useState('')
  const [options, setOptions] = useState<any[]>([])

  const setInputSearchTerm = debounce((input) => setSearchTerm(input), 250)

  useEffect(() => {
    const getValidOptions = async () => {
      const validOptions = await handleSuggestionOptions(searchTerm)
      setOptions(validOptions)
    }

    getValidOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  const handleSuggestionOptions = async (searchText: string) => {
    if (searchText === '') {
      return []
    }

    const result = []

    result.push({
      id: searchText,
      value: searchText,
      label: searchText,
    })

    if (userProfile?.isVendor) {
      return result
    }

    try {
      const { data } = await client.query<
        GetCaseSuggestion,
        { input: SuggestionRequest }
      >({
        query: GET_CASE_SUGGESTION,
        variables: {
          input: {
            limit: 10,
            query: searchText,
          },
        },
      })

      if (data.getCaseSuggestion !== null) {
        data.getCaseSuggestion.forEach((suggestion) => {
          result.push({
            id: suggestion.suggestion,
            value: suggestion.suggestion,
            label: suggestion.suggestion,
          })
        })
      }
    } catch (_: any) {
      console.warn('Suggest Failed')
    }

    return uniqBy(result, 'id')
  }

  const handleSearchSuggestion = (value: any) => {
    if (value && value.label) {
      navigate(
        '/cases/?' +
          buildUrl(location, {
            type: 'search',
            id: value.id,
          })
      )
    }
  }

  return (
    <div>
      <Autocomplete
        id="case_search"
        //@ts-ignore
        options={(searchText?: string) => {
          setInputSearchTerm(searchText)
          return options
        }}
        label="Search Cases"
        onUpdate={(_, value) => {
          handleSearchSuggestion(value)
        }}
        placeholder={
          userProfile?.isVendor
            ? 'Search by Case ID, Status'
            : 'Search by Case ID, Vendor name, Vendor Number'
        }
        showSearchIcon
      />
    </div>
  )
}
