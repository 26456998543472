import { useState, useRef } from 'react'
import Tippy from '@tippyjs/react'
import { Card, Chip } from '@enterprise-ui/canvas-ui-react'

const PopupCellRenderers = (props: any) => {
  const tippyRef = useRef()
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const dropDownContent = (
    <Card
      className="hc-pa-dense hc-ma-none hc-bg-contrast"
      elevation={1}
      style={{
        maxWidth: '300px',
        maxHeight: '300px',
        overflowY: 'auto',
      }}
    >
      {props?.value?.map((resolutionCodeList: any) => (
        <div className="hc-pa-dense" key={resolutionCodeList.resolutionCodeId}>
          <Chip size="dense" className="hc-clr-dark">
            {resolutionCodeList.resolutionCodeText}
          </Chip>
        </div>
      ))}
    </Card>
  )

  return (
    <>
      {props.value !== null && (
        <Chip size="dense"> {props.value[0].resolutionCodeText}</Chip>
      )}
      {props.value !== null && props.value.length > 1 && (
        <Tippy
          //@ts-ignore
          ref={tippyRef}
          content={dropDownContent}
          visible={visible}
          onClickOutside={hide}
          allowHTML={true}
          arrow={false}
          appendTo={document.body}
          interactive={true}
          placement="left"
        >
          <div
            onClick={visible ? hide : show}
            className="C-Chip --size-dense --color-default --clickable"
          >
            <div className=" C-Chip__children">
              {'+ ' + (props.value.length - 1).toString()}
            </div>
          </div>
        </Tippy>
      )}
    </>
  )
}

export default PopupCellRenderers
