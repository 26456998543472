import _ from 'lodash'
import { EnvConfig } from '../../../config/appConfig'

export function findAttachmentsource(
  envConfig: EnvConfig,
  caseType?: string,
  caseSubType?: string,
  documentType?: number,
  reasonCode?: string,
  documentNumber?: string
): [boolean, any[]] {
  console.log(caseType, caseSubType, documentType, reasonCode, documentNumber)

  let autoExtractAttachmentsFlag: boolean = false
  let lookupSource: any[] = []

  const chargebackAttachmentSearch = _.findLast(
    envConfig.attachments.attachmentSearch,
    function (n) {
      return n.caseType === caseType
    }
  )
  chargebackAttachmentSearch?.prefixes?.forEach((prefix) => {
    if (documentNumber?.includes(prefix)) {
      lookupSource.push(
        chargebackAttachmentSearch?.cbLookupSource?.type.toString()
      )
      autoExtractAttachmentsFlag = true
    }
  })

  const attachmentSearch = _.findLast(
    envConfig.attachments.attachmentSearch,
    function (n) {
      return (
        n.caseType === caseType &&
        n.caseSubType === caseSubType &&
        n.documentType === documentType
      )
    }
  )

  const excludedReasonCodes = attachmentSearch?.excludedReasonCodes
  const includedReasonCodes = attachmentSearch?.includedReasonCodes

  // check if there is any reason code excluded return false
  if (reasonCode && excludedReasonCodes?.length !== 0) {
    if (excludedReasonCodes?.includes(reasonCode)) {
      //note: 'DR' is not added to the array since it has to be excluded
      return [autoExtractAttachmentsFlag, lookupSource]
    }
  }

  // check if the reason code is included return true
  if (reasonCode && includedReasonCodes?.length !== 0) {
    if (includedReasonCodes?.includes(reasonCode)) {
      autoExtractAttachmentsFlag = true
      lookupSource.push(attachmentSearch?.lookupSource.type.toString() ?? '')
      return [autoExtractAttachmentsFlag, lookupSource]
    }
  }

  // if incded is empty it treats all the values are included
  if (includedReasonCodes?.length === 0) {
    autoExtractAttachmentsFlag = true
    lookupSource.push(attachmentSearch?.lookupSource.type.toString() ?? '')
    return [autoExtractAttachmentsFlag, lookupSource]
  }
  return [autoExtractAttachmentsFlag, [...new Set(lookupSource)]]
}
