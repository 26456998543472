import React, { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import AutoSizer from 'react-virtualized-auto-sizer'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { ColDef } from 'ag-grid-community'

export interface ReportTableProps {
  data: any[]
  columns?: ColDef[]
  pagination?: boolean
}

const ReportTable: React.FC<ReportTableProps> = ({
  data,
  columns,
  pagination = true,
}) => {
  const gridData = useMemo(() => {
    return [...data]
  }, [data])

  return (
    <div style={{ flex: '1 1 auto', height: '80vh', width: '100%' }}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <div
            className="ag-theme-alpine"
            style={{ height: height, width: width }}
          >
            <AgGridReact
              rowData={gridData}
              pagination={true}
              columnDefs={columns}
            />
          </div>
        )}
      </AutoSizer>
    </div>
  )
}

export default ReportTable
