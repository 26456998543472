import React from 'react'
import { Grid, Timeline } from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { AuditEntryInput } from '../Common/Queries'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import memoize from 'memoize-one'
import moment from 'moment'
import _ from 'lodash'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const activityColor = css`
  color: #009999;
`
interface Props {
  auditEntries: AuditEntryInput[]
}

const AuditLog: React.FC<Props> = ({ auditEntries }) => {
  const formatDataForRendering = memoize((auditEntriesValues) => {
    let convertedData = _.groupBy(
      _.orderBy(auditEntriesValues, ['activityTimestamp'], ['desc']),
      (auditEntriesValues) => {
        return moment(auditEntriesValues.activityTimestamp).format('YYYY-MM-DD')
      }
    )
    return convertedData
  })

  const formattedData = formatDataForRendering(auditEntries)
  return (
    <div className="hc-pa-normal hc-pa-none">
      <Timeline>
        {Object.keys(formattedData).map((event) => {
          return (
            <>
              <Timeline.Flag>
                <DateFormatter date={event} size="expanded" relative />
              </Timeline.Flag>
              {formattedData[event].map((row: any, i: any) => {
                return (
                  <Timeline.Item key={i} type="success">
                    <Grid.Container>
                      <Grid.Item>
                        <p className="hc-clr-grey02 hc-fs-xs">
                          <DateFormatter
                            format="MM/DD/YYYY hh:mm A"
                            timezone="America/Chicago"
                            date={row?.activityTimestamp.concat('Z')}
                          />
                          &nbsp;&nbsp;&mdash;
                        </p>
                      </Grid.Item>
                      <Grid.Item className="hc-pr-none">
                        <p className="hc-fs-sm" css={activityColor}>
                          {row.activityDescription}
                        </p>
                      </Grid.Item>
                      <Grid.Item className="hc-pl-none hc-pr-none">
                        <p className="hc-clr-grey01 hc-fs-sm">
                          &nbsp;{'by'}&nbsp;
                        </p>
                      </Grid.Item>
                      <Grid.Item className="hc-pl-none">
                        <p css={activityColor}>{row.userId}</p>
                      </Grid.Item>
                    </Grid.Container>
                  </Timeline.Item>
                )
              })}
            </>
          )
        })}
      </Timeline>
    </div>
  )
}
export default AuditLog
