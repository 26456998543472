import React from 'react'
import { Grid, Input, Table } from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { NewCaseRequest } from '../Common/Queries'
import { CASE_SUMMARY_HEADING, NO_PAYMENT_DETAILS } from '../Common/Constants'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import _ from 'lodash'
import { Section, SectionItem, SummaryHeading } from '../Common/Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { formatAmount } from '../Services/ClaimDisplayFields'

const summary_container = css`
  height: 30px;
  overflow-x: hidden;
  overflow-y: auto;
`

interface MainProp {
  caseSummaryDetails: NewCaseRequest
  importVendor?: boolean
  displayCols?: any
}

const TclaimCaseSummary: React.FC<MainProp> = ({
  caseSummaryDetails,
  importVendor,
  displayCols,
}) => {
  const [state] = useGlobalForm()
  const documentAmount = caseSummaryDetails?.originalDocumentAmount
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(caseSummaryDetails?.originalDocumentAmount))
    : undefined
  const claimedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(caseSummaryDetails?.disputedDocumentAmount))

  const departmentId =
    caseSummaryDetails?.departmentId === 0
      ? null
      : _.padStart(caseSummaryDetails.departmentId?.toString(), 3, '0')
  const locationId = caseSummaryDetails.locationId
    ? _.padStart(caseSummaryDetails.locationId?.toString(), 4, '0')
    : null
  return (
    <React.Fragment>
      {state.isVendShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={CASE_SUMMARY_HEADING} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Merch Vendor ID" size={5}>
              {caseSummaryDetails.vendorNumber}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails.vendorName}
            </SectionItem>
            <SectionItem label="Type">
              {caseSummaryDetails.vendorType}
            </SectionItem>
            {state.isCarrierVendor && (
              <SectionItem label="SCAC">
                {caseSummaryDetails.claimDetails?.carrierScacCode}
              </SectionItem>
            )}
          </Section>
        </div>
      )}
      {state.showDocuments && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Claim Case Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Document #">
              {caseSummaryDetails.originalDocumentNumber}
            </SectionItem>
            <SectionItem label="Case Sub Type">
              {caseSummaryDetails.caseSubType}
            </SectionItem>
            <SectionItem label="Document Type">
              {caseSummaryDetails.documentTypeText}
            </SectionItem>
            {!importVendor && (
              <SectionItem label="Document Sub Type">
                {caseSummaryDetails.documentSubTypeText}
              </SectionItem>
            )}
            <SectionItem label="Reason">
              {caseSummaryDetails.reasonCodeText}
            </SectionItem>
            <Grid.Container>
              <Grid.Item xs={12} className="hc-pt-dense hc-pb-dense">
                <Input.Label className="hc-clr-grey02 hc-fs-xs">
                  Claim Description :
                </Input.Label>
                <Input.Label
                  css={summary_container}
                  className="hc-clr-black hc-fs-xs"
                >
                  {caseSummaryDetails.caseDescription}
                </Input.Label>
              </Grid.Item>
            </Grid.Container>
          </Section>
        </div>
      )}
      {state.isDocShowable && state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Payment document amount">
              {caseSummaryDetails?.originalCheckAmount}
            </SectionItem>
            <SectionItem label="Payment invoice">
              {caseSummaryDetails.originalCheckNumber}
            </SectionItem>
            <SectionItem label="Payment date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.originalCheckDate}
                format="L"
              />
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && !state.paymentSectionFlag && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Payment Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem size={3} label="Payment Details">
              {NO_PAYMENT_DETAILS}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Document Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Document amount">{documentAmount}</SectionItem>
            <SectionItem label="Claimed amount">{claimedAmount}</SectionItem>
            <SectionItem label="Purchased order #">
              {caseSummaryDetails.purchaseOrderNumber}
            </SectionItem>
            <SectionItem label="Department">{departmentId}</SectionItem>
            <SectionItem label="Class">
              {caseSummaryDetails.classId}
            </SectionItem>
            <SectionItem label="Location">{locationId}</SectionItem>
            {displayCols.cbdcDocumentDate && (
              <SectionItem label="CBDC Document Date">
                {caseSummaryDetails.claimDetails?.cbdcDocumentDate}
              </SectionItem>
            )}
            {displayCols.retailAmount && (
              <SectionItem label="Retail Amount">
                {caseSummaryDetails.claimDetails?.retailAmount}
              </SectionItem>
            )}
            {displayCols.inventoryRemovalId && (
              <SectionItem label="Inventory Removal Id">
                {caseSummaryDetails.claimDetails?.inventoryRemovalId}
              </SectionItem>
            )}
            {displayCols.invoiceDate && (
              <SectionItem label="Invoice Date">
                <DateFormatter
                  //@ts-ignore
                  date={caseSummaryDetails.claimDetails?.invoiceDate}
                  format="L"
                />
              </SectionItem>
            )}
            {displayCols.receiptDate && (
              <SectionItem label="Receipt Date">
                <DateFormatter
                  //@ts-ignore
                  date={caseSummaryDetails.claimDetails?.receiptDate}
                  format="L"
                />
              </SectionItem>
            )}
            {displayCols.cartonsBilled && (
              <SectionItem label="Cartons Billed">
                {caseSummaryDetails.claimDetails?.cartonsBilled}
              </SectionItem>
            )}
            {displayCols.cartonsReceived && (
              <SectionItem label="Cartons Received">
                {caseSummaryDetails.claimDetails?.cartonsReceived}
              </SectionItem>
            )}
            {displayCols.shortageCartons && (
              <SectionItem label="Shortage Cartons">
                {caseSummaryDetails.claimDetails?.shortageCartons}
              </SectionItem>
            )}
            {displayCols.shortageAmount && (
              <SectionItem label="Shortage Amount">
                {formatAmount(caseSummaryDetails.claimDetails?.shortageAmount)}
              </SectionItem>
            )}
            {displayCols.trailerNumber && (
              <SectionItem label="Trailer Number">
                {caseSummaryDetails.claimDetails?.trailerNumber}
              </SectionItem>
            )}
            {displayCols.trackingNumber && (
              <SectionItem label="Tracking Number">
                {caseSummaryDetails.claimDetails?.trackingNumber}
              </SectionItem>
            )}
            {displayCols.trailerType && (
              <SectionItem label="Trailer Type">
                {caseSummaryDetails.claimDetails?.trailerType}
              </SectionItem>
            )}
            {displayCols.claimType && (
              <SectionItem label="Claim Type">
                {caseSummaryDetails.claimDetails?.claimType}
              </SectionItem>
            )}
            {displayCols.documentNotification && (
              <SectionItem label="Document Notification">
                {caseSummaryDetails.claimDetails?.documentNotification?.join(
                  ','
                )}
              </SectionItem>
            )}
          </Section>
        </div>
      )}
      {state.isDocShowable && state.isCrVendor && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Carrier Vendor Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Vendor ID">
              {caseSummaryDetails.claimDetails?.carrierVendorId}
            </SectionItem>
            <SectionItem label="Name">
              {caseSummaryDetails.claimDetails?.carrierVendorName}
            </SectionItem>
            <SectionItem label="SCAC">
              {caseSummaryDetails.claimDetails?.carrierScacCode}
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Receipt Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Receipt #">
              {caseSummaryDetails.receipts?.join(',')}
            </SectionItem>
            <SectionItem label="Ship Date">
              <DateFormatter
                //@ts-ignore
                date={caseSummaryDetails.claimDetails?.shipDate}
                format="L"
              />
            </SectionItem>
          </Section>
        </div>
      )}
      {state.isDocShowable && (
        <>
          <Grid.Container>
            <Grid.Item sm={12} md={10}>
              <SummaryHeading heading={'Item Level Details'} />
            </Grid.Item>
          </Grid.Container>
          {/* </Grid.Item>
            <Grid.Item> */}
          <Grid.Container>
            <Grid.Item className="hc-ml-normal" sm={12} md={12}>
              <Table name="Item level shortage">
                <Table.Head>
                  <Table.Row>
                    <Table.Header xs={1}>Dept</Table.Header>
                    <Table.Header xs={1}>Class</Table.Header>
                    <Table.Header xs={1}>Item</Table.Header>
                    <Table.Header xs={1}>Invoice Qty</Table.Header>
                    <Table.Header xs={1}>Unit Cost</Table.Header>
                    <Table.Header xs={1}>Case Pack</Table.Header>
                    <Table.Header xs={1}>Carton Count</Table.Header>
                    <Table.Header xs={1}>Shortage Qty</Table.Header>
                    <Table.Header xs={1}>Shortage Ctn</Table.Header>
                    <Table.Header xs={1}>Shortage Cost</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {caseSummaryDetails.claimDetails?.itemLevelShortage?.map(
                    (shortage: any, index: number) => (
                      <Table.Row>
                        <Table.Data xs={1}>{shortage?.department}</Table.Data>
                        <Table.Data xs={1}>{shortage?.classId}</Table.Data>
                        <Table.Data xs={1}>{shortage?.item}</Table.Data>
                        <Table.Data xs={1}>
                          {shortage?.invoiceQuantity}
                        </Table.Data>
                        <Table.Data xs={1}>{shortage?.unitCost}</Table.Data>
                        <Table.Data xs={1}>{shortage?.casePack}</Table.Data>
                        <Table.Data xs={1}>{shortage?.cartonCount}</Table.Data>
                        <Table.Data xs={1}>
                          {shortage?.quantityShortage}
                        </Table.Data>
                        <Table.Data xs={1}>
                          {shortage?.shortageCarton}
                        </Table.Data>
                        <Table.Data xs={1}>{shortage?.shortageCost}</Table.Data>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Grid.Item>
          </Grid.Container>
        </>
      )}
      {state.isDocShowable && (
        <>
          <Grid.Container>
            <Grid.Item sm={12} md={10}>
              <SummaryHeading heading={'Other Receipt Details'} />
            </Grid.Item>
          </Grid.Container>
          <Grid.Container>
            <Grid.Item className="hc-ml-normal" sm={12} md={10}>
              <Table name="Other Receipts">
                <Table.Head>
                  <Table.Row>
                    <Table.Header xs={2}>Location</Table.Header>
                    <Table.Header xs={2}>PO</Table.Header>
                    <Table.Header xs={2}>Receipt</Table.Header>
                    <Table.Header xs={2}>Number of Cartons</Table.Header>
                    <Table.Header xs={2}>Dollar Amount</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {caseSummaryDetails.claimDetails?.claimReceipts?.map(
                    (receipt: any, index: number) => (
                      <Table.Row>
                        <Table.Data xs={2}>{receipt?.location}</Table.Data>
                        <Table.Data xs={2}>{receipt?.poId}</Table.Data>
                        <Table.Data xs={2}>{receipt?.receiptId}</Table.Data>
                        <Table.Data xs={2}>{receipt?.cartonsCount}</Table.Data>
                        <Table.Data xs={2}>{receipt?.cartonCost}</Table.Data>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>
            </Grid.Item>
          </Grid.Container>
        </>
      )}
      {state.isDocShowable && (
        <div className="hc-pa-normal hc-pa-none">
          <SummaryHeading heading={'Workflow Details'} />
          <Section labelGridSize={5} detail={false}>
            <SectionItem label="Workflow">
              {caseSummaryDetails.workflow}
            </SectionItem>
            <SectionItem label="Assignee">{state.name}</SectionItem>
          </Section>
        </div>
      )}
    </React.Fragment>
  )
}

export default TclaimCaseSummary
