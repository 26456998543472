import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReportBaseLayout } from './ReportBaseLayout'
import { useReport } from './ReportContext/ReportProvider'
import { UPDATE_FILTER } from '../Cases/Search/SearchContext/ActionTypes'
import * as qs from 'qs'
import { useLazyQuery } from '@apollo/client'
import {
  AggregationAgeVariables,
  GetAgeAggregation,
  GET_AGE_AGGREGATION,
} from '../Dashboard/Queries'
import { FISCAL_MONTH_YEAR } from './ReportConstants'
import { convertToCurrency, sortByMonthYear } from './ReportsUtils'
import { ColDef } from 'ag-grid-community'
import flatten from 'lodash/fp/flatten'
import uniqBy from 'lodash/fp/uniqBy'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import {
  addEscapeChar,
  buildReportQuery,
  buildReportUrl,
} from './ReportContext/ReportUtils'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../config/appConfig'
import {
  ExpandableSection,
  Input,
  Heading,
  Divider,
} from '@enterprise-ui/canvas-ui-react'

interface ReportData {
  gridRows: any[]
  gridColumns: ColDef[]
  chartData?: any[]
  uniqueSubTypes: string[]
}

interface AgeingWindowProps {
  agingWindows: any
  ageingWindow?: string
}

const AgeingWindow: React.FC<AgeingWindowProps> = ({
  agingWindows,
  ageingWindow,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <ExpandableSection padding="dense" startExpanded toggleLocation="left">
        <Heading className="hc-clr-black" size={6}>
          Ageing Window
        </Heading>
        <ExpandableSection.Content className="hc-ml-sm hc-mr-sm">
          <Input.Radio
            id="Aging Interval"
            value={ageingWindow}
            options={agingWindows}
            onUpdate={(id: any, value: any) => {
              navigate(
                `${location.pathname}?` +
                  buildReportUrl(location, {
                    filter: ageingWindow,
                    event: value,
                    type: 'ageingWindow',
                  })
              )
            }}
          />
        </ExpandableSection.Content>
      </ExpandableSection>
      <Divider />
    </>
  )
}

export const AgeingReport = () => {
  const location = useLocation()
  const [state, dispatch] = useReport()
  const [reportData, setReportData] = useState<ReportData | undefined>()
  const env = useEnv() as EnvConfig

  const [loadAggregation, { loading }] = useLazyQuery<
    GetAgeAggregation,
    AggregationAgeVariables
  >(GET_AGE_AGGREGATION, {
    fetchPolicy: 'network-only',
    onCompleted: (aggregationDetails) => {
      const aggregation = [...(aggregationDetails.getAggregatedAgeNew ?? [])]
      const uniqueRows: string[] = flow(
        map((aggs: any) => aggs.subType),
        flatten,
        uniqBy('rootType'),
        map((aggs: any) => aggs?.rootType)
      )(aggregation)
      const uniqueColumns = aggregation
        .sort(sortByMonthYear)
        .map((column) => column.rootType)
      const gridData = uniqueRows.map((row) => {
        let gridRow: any = {
          aggregation: row,
        }
        uniqueColumns.forEach((column) => {
          const value =
            aggregation
              .find((aggs) => aggs.rootType === column)
              ?.subType?.find((aggs) => aggs.rootType === row)?.value ?? 0
          gridRow[column as string] = value
        })
        return gridRow
      })
      const chartData = aggregation.sort(sortByMonthYear).map((value) => {
        let subCategory: any = {}
        value.subType?.forEach((type) => {
          subCategory[type['rootType'] as string] =
            state.report?.consolidateType === 'COUNT'
              ? type['value']
              : type['value']?.toFixed(2)
        })
        subCategory.x_axis_data = value.rootType
        subCategory.total = value.value
        return subCategory
      })

      // set grid data
      setReportData({
        gridRows: gridData,
        gridColumns: [
          {
            field: 'aggregation',
            headerName: 'Aging/ Filed Month, Year',
          },
          ...uniqueColumns.map((colum) => {
            let col: ColDef = {
              field: colum,
              valueFormatter:
                state.report?.consolidateType === 'AMOUNT'
                  ? (params) => {
                      return `${convertToCurrency(params.value)}`
                    }
                  : undefined,
            }
            return col
          }),
        ],
        chartData: chartData,
        uniqueSubTypes: uniqueRows,
      })
    },
  })

  useEffect(() => {
    dispatch({
      type: UPDATE_FILTER,
      payload: qs.parse(location.search.replace('?', '')),
    })
  }, [dispatch, location.search])

  useEffect(() => {
    handleQueryChange(buildReportQuery(state) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleQueryChange = (query: string) => {
    loadAggregation({
      variables: {
        query: query,
        aggregateBy: FISCAL_MONTH_YEAR,
        field: state.id,
        agingInterval: state.report?.ageingWindow,
      },
    })
  }

  const buildDrillDownUrl = (
    row: string,
    column?: string,
    isGraph: boolean = false
  ): string => {
    const query: any = qs.parse(location.search.replace('?', ''))
    const dateFilter = !isGraph
      ? `(${state.report?.dateFilter.id}: [${state.report?.dateFilter.active?.from} TO ${state.report?.dateFilter.active?.to}])`
      : ''

    const sideFilters = Object.keys(query)
      .filter((key) => key !== 'consolidateType')
      .map((key) => {
        if (query[key] instanceof Array) {
          const filters = query[key]
            .map((filter: string) => `(${addEscapeChar(filter)})`)
            .join(' OR ')
          return `(${key}: ${filters})`
        } else {
          return ''
        }
      })
      .filter((value) => value !== '')
      .join(' AND ')

    const idFilter = `(${state.report?.id}: (${addEscapeChar(row)}))`
    const fisicalCalander =
      column !== undefined ? `(${FISCAL_MONTH_YEAR}: (${column}))` : ''
    var arr = [sideFilters, dateFilter, idFilter, fisicalCalander]
      .filter((value: string) => !(value === '') && !value.includes('()'))
      .join(' AND ')

    return arr
  }

  if (state.report == null) {
    return <div>Not Found</div>
  }

  return (
    <ReportBaseLayout
      reportName={state.report.reportName}
      sideFilters={state.report.sideFilters}
      data={reportData?.gridRows ?? []}
      columns={reportData?.gridColumns ?? []}
      chartData={reportData?.chartData ?? []}
      uniqueSubTypes={reportData?.uniqueSubTypes ?? []}
      reportType={'aggregation'}
      dynamicFilter={state.report.dynamicFilter}
      handleQueryChange={handleQueryChange}
      loading={loading}
      dateFilter={state.report.dateFilter}
      customSideFilters={
        <AgeingWindow
          agingWindows={env.reports.agingWindows}
          ageingWindow={state.report.ageingWindow}
        />
      }
      buildDrillDownUrl={buildDrillDownUrl}
    />
  )
}
