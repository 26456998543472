import React from 'react'
import { CaseResponse } from './Queries'
import { PAYMENT_HEADING, NO_PAYMENT_DETAILS } from './Constants'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Section, SectionItem } from './Section'
import { BottomBorderDiv } from './SynergyDivComponent'

interface MainProp {
  paymentDetails: CaseResponse | undefined
}

const EditPaymentDetails: React.FC<MainProp> = ({ paymentDetails }) => {
  const payment = paymentDetails?.originalCheckAmount
  return payment ? (
    <BottomBorderDiv>
      <Section labelGridSize={4} detail heading={PAYMENT_HEADING} expandable>
        <SectionItem label="Payment Document Amount">
          {paymentDetails?.originalCheckAmount}
        </SectionItem>
        <SectionItem label="Payment Invoice">
          {paymentDetails?.originalCheckNumber}
        </SectionItem>
        <SectionItem label="Payment Date">
          <DateFormatter
            //@ts-ignore
            date={paymentDetails?.originalCheckDate}
            format="L"
          />
        </SectionItem>
      </Section>
    </BottomBorderDiv>
  ) : (
    <BottomBorderDiv>
      <Section labelGridSize={2} detail heading={PAYMENT_HEADING} expandable>
        <SectionItem label="Payment">{NO_PAYMENT_DETAILS}</SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}

export default EditPaymentDetails
