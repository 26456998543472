import React from 'react'
import { Heading, Layout } from '@enterprise-ui/canvas-ui-react'
import { ReportSection, ReportSectionProps } from './ReportSection'
import { useProfile } from '../Layout/UserProfile/UserProfileProvider'

export const ReportsList = () => {
  const { userProfile } = useProfile()
  const workflow = userProfile?.team?.teamName
  const reports: ReportSectionProps[] = [
    {
      title: 'Operational Reports',
      cards: [
        {
          cardTitle: 'Filing & Dispute',
          url: `/cases?${encodeURI(
            'case_type[0]=Internal Processing&case_type[1]=Dispute'
          )}`,
        },
        {
          cardTitle: 'Cases Resolved by Me in last 7 days',
          url: `/cases?${encodeURI(
            `lan_id[0]=${userProfile?.userId.toUpperCase()}&` +
              'status[0]=Resolved&' +
              'status[1]=Closed&' +
              'resolution_date[from]=now-7d&' +
              'resolution_date[to]=now'
          )}`,
        },
        {
          cardTitle: 'Expired Transportation Claims >270 days',
          url: `/cases?${encodeURI(
            'case_type[0]=Internal Processing&' +
              'workflow[0]=TCLAIM&' +
              'status[0]=New&' +
              'status[1]=InProgress&' +
              'claim_details.ship_date[from]=*&' +
              'claim_details.ship_date[to]=now-270d'
          )}`,
        },
      ],
    },
    {
      title: 'Aggregation by Count',
      cards: [
        {
          cardTitle: 'Count By Case Type',
          url: `/reports/aggregated_reports/case_type?workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Count By Case Sub Type',
          url: `/reports/aggregated_reports/case_sub_type?workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Count By Department',
          url: `/reports/aggregated_reports/department_id?workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Count By Reason Code',
          url: `/reports/aggregated_reports/reason_code_text.keyword?workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Count By Workflow',
          url: `/reports/aggregated_reports/workflow?workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Count By Vendor',
          url: `/reports/aggregated_reports/vendor_text.keyword?workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Count By Location',
          url: `/reports/aggregated_reports/location_id?workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Count By Resolution Code',
          url: `/reports/aggregated_reports/resolution_code_text.keyword?workflow%5B0%5D=${workflow}`,
        },
      ],
    },
    {
      title: 'Aggregation by Sum',
      cards: [
        {
          cardTitle: 'Sum By Case Type',
          url: `/reports/aggregated_reports/case_type?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Sum By Case Sub Type',
          url: `/reports/aggregated_reports/case_sub_type?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Sum By Department',
          url: `/reports/aggregated_reports/department_id?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Sum By Reason Code',
          url: `/reports/aggregated_reports/reason_code_text.keyword?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Sum By Workflow',
          url: `/reports/aggregated_reports/workflow?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Sum By Vendor',
          url: `/reports/aggregated_reports/vendor_text.keyword?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Sum By Location',
          url: `/reports/aggregated_reports/location_id?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
        {
          cardTitle: 'Sum By Resolution Code',
          url: `/reports/aggregated_reports/resolution_code_text.keyword?consolidateType=AMOUNT&workflow%5B0%5D=${workflow}`,
        },
      ],
    },
    {
      title: 'Aging Reports',
      cards: [
        {
          cardTitle: 'Aging of Resolved Cases',
          url: `/reports/aging_reports/resolution_date?status%5B0%5D=Resolved&status%5B1%5D=Closed&workflow%5B0%5D=${workflow}`,
          size: 6,
        },
        {
          cardTitle: 'Aging of Open Cases',
          url: `/reports/aging_reports/current_date?status%5B0%5D=New&status%5B1%5D=ReOpen&status%5B2%5D=InProgress&status%5B3%5D=AwaitingInfo&workflow%5B0%5D=${workflow}`,
          size: 6,
        },
      ],
    },
    {
      title: 'Other Reports',
      cards: [
        {
          cardTitle: 'Disputed Amount vs Resolved Amount',
          url: `/reports/compare_reports/resolution_amount?workflow%5B0%5D=${workflow}`,
          size: 6,
        },
        {
          cardTitle: 'Duplicate Report',
          url: `/reports/duplicate_reports/original_document_number.keyword?workflow%5B0%5D=${workflow}`,
          size: 6,
        },
      ],
    },
  ]

  return (
    <>
      <Layout.SectionHeader>
        <div className="hc-pa-normal hc-pb-none">
          <Heading size={2}>Reports</Heading>
        </div>
      </Layout.SectionHeader>
      <Layout.Body includeRail>
        {reports.map((report) => (
          <ReportSection
            key={report.title}
            title={report.title}
            cards={report.cards}
          />
        ))}
      </Layout.Body>
    </>
  )
}
