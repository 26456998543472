import { gql, useLazyQuery } from '@apollo/client'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import React, { useEffect, Dispatch } from 'react'
import { useImmerReducer } from 'use-immer'
import {
  ReportContextValue,
  ReportStateContext,
  ReportDispatchContext,
  Report,
} from './ReportContext'
import _ from 'lodash'
import * as qs from 'qs'
import {
  SET_DEFAULT_SIDE_FILTERS,
  UPDATE_FILTER,
} from '../../Cases/Search/SearchContext/ActionTypes'
import { useEnv } from '@praxis/component-runtime-env'
import { useLocation, useParams } from 'react-router-dom'
import { reportReducer, SWITCH_REPORT } from './ReportReducer'
import { EnvConfig } from '../../../config/appConfig'

const QUERY = gql`
  query Filters {
    reason_code: getReasonCodes {
      id: reasonCodeId
      label: reasonCodeText
      value: reasonCodeId
    }
    workflow: getWorkflows {
      id: workflow
      label: workflow
      value: workflow
    }
    document_type_id: getDocumentTypes {
      id: documentTypeId
      label: documentTypeText
      value: documentTypeId
    }
    lan_id: getUserProfiles {
      id: userId
      label: fullName
      value: userId
    }
  }
`

interface ReportProviderProps {
  reportInitialValues: Report[]
  children: React.ReactNode
}

export const ReportProvider: React.FC<ReportProviderProps> = ({
  reportInitialValues,
  children,
}) => {
  const params = useParams<{ id: string }>()
  const location = useLocation()
  const env = useEnv() as EnvConfig

  const report = reportInitialValues.find((report) => report.id === params.id)
  const partitions = env.reports.reportTypes.find(
    (report: any) => report.value === params.id
  )?.numberOfPartitions
  const [state, dispatch] = useImmerReducer<ReportContextValue, any>(
    reportReducer,
    {
      id: params.id!!,
      loading: false,
      report: report,
      partitions: partitions ?? 1,
    }
  )

  const [loadFilters, { loading, error }] = useLazyQuery(QUERY, {
    onCompleted: (data) => {
      setDefaultFilters(dispatch, data, location.search.replace('?', ''))
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (state.report?.sideFilters === report?.sideFilters) loadFilters()
  }, [loadFilters, report?.sideFilters, state])

  useEffect(() => {
    const updatedReport = reportInitialValues.find(
      (report) => report.id === params.id
    )
    dispatch({
      type: SWITCH_REPORT,
      payload: { id: params.id, report: updatedReport },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  if (loading) {
    return (
      <Layout.Body includeRail>
        <h1>Loading Filters...</h1>
      </Layout.Body>
    )
  }

  if (error) {
    return <h1>Error</h1>
  }

  return (
    <ReportStateContext.Provider value={state}>
      <ReportDispatchContext.Provider value={dispatch}>
        {children}
      </ReportDispatchContext.Provider>
    </ReportStateContext.Provider>
  )
}

export function setDefaultFilters(
  dispatch: Dispatch<any>,
  data: any,
  location: string
) {
  const enrichedData = _.mapValues(data, function (test) {
    return test.map((obj: any) => ({ ...obj, isChecked: false }))
  })

  // set filter values from API
  dispatch({ type: SET_DEFAULT_SIDE_FILTERS, payload: enrichedData })

  // set default filter values
  dispatch({
    type: UPDATE_FILTER,
    payload: qs.parse(location),
  })
}

// hooks
function useReportState() {
  const context = React.useContext(ReportStateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CaseSearchProvider')
  }
  return context
}
function useReportDispatch() {
  const context = React.useContext(ReportDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CaseSearchProvider')
  }
  return context
}

export function useReport(): [ReportContextValue, any] {
  return [useReportState() as ReportContextValue, useReportDispatch()]
}
