import React from 'react'

import {
  Grid,
  Input,
  Heading,
  ExpandableSection,
} from '@enterprise-ui/canvas-ui-react'

interface CommonCaseSectionProps {
  labelGridSize?: number
  valueGridSize?: number
  children?: any
  detail?: boolean
  expandable?: boolean
  heading?: string
}

export const Section: React.FC<CommonCaseSectionProps> = (props) => {
  const itemContent: any = []
  if (props.children.length === undefined) {
    itemContent.push(
      React.cloneElement(props.children, {
        size: props.labelGridSize,
        detail: props.detail,
        summarysize: props.valueGridSize,
      })
    )
  }
  for (var i = 0; i < props.children?.length; i++) {
    if (props.children[i].type?.displayName === 'SectionItem') {
      itemContent.push(
        React.cloneElement(props.children[i], {
          size: props.labelGridSize,
          detail: props.detail,
          summarysize: props.valueGridSize,
        })
      )
    } else {
      if (props?.children[i]?.props?.children?.length > 0) {
        for (var j = 0; j < props?.children[i]?.props?.children?.length; j++) {
          itemContent.push(
            React.cloneElement(props?.children[i]?.props?.children[j], {
              size: props.labelGridSize,
              detail: props.detail,
              summarysize: props.valueGridSize,
            })
          )
        }
      }
    }
  }
  const finalClass = props.detail
    ? 'hc-pt-none hc-ml-md '
    : 'hc-pl-md hc-mv-min hc-pt-none'
  return props.expandable ? (
    <div className="hc-pa-normal hc-pl-none hc-pr-none hc-pt-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="none">
        <Heading size={6}>{props.heading}</Heading>
        <ExpandableSection.Content className="hc-pl-lg hc-pv-normal">
          <Grid.Container className={finalClass}>{itemContent}</Grid.Container>
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  ) : (
    <Grid.Container className={finalClass + ' hc-ml-none'}>
      {itemContent}
    </Grid.Container>
  )
}

// Section Item Properties
interface SectionItemProps {
  size?: number
  label?: string
  summarysize?: number
  detail?: boolean
  heading?: string
  children?: React.ReactNode
}

export const SectionItem: React.FC<SectionItemProps> = (props) => {
  const finalClass = props.detail ? '' : 'hc-pt-none hc-pb-none'
  return (
    <Grid.Container align="center" spacing="none">
      <Grid.Item md={props.size} sm={6} className={finalClass}>
        <div className="hc-pt-sm">
          <Input.Label className="hc-clr-grey02 hc-fs-xs">
            {props.label}:
          </Input.Label>
        </div>
      </Grid.Item>
      <Grid.Item md={props.summarysize} sm={6} className={finalClass}>
        <Input.Label className="hc-clr-black hc-fs-xs hc-mb-none">
          {props.children !== undefined &&
          props.children !== null &&
          props.children !== 'NA' &&
          props.children !== '' &&
          Object(props?.children).props?.date !== null
            ? props.children
            : 'None'}
        </Input.Label>
      </Grid.Item>
    </Grid.Container>
  )
}
SectionItem.displayName = 'SectionItem'

export const SummaryHeading: React.FC<SectionItemProps> = ({ heading }) => {
  return (
    <Grid.Item className="hc-pl-md hc-pt-dense">
      <Heading size={6} className="hc-clr-black">
        {heading}
      </Heading>
    </Grid.Item>
  )
}
