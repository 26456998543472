import { Value } from '../../Cases/Search/SearchContext/SearchInitialValues'
import { Report, SideFilter } from './ReportContext'

const sideFilters: SideFilter[] = [
  {
    id: 'case_type',
    displayName: 'Case Type',
    source: 'master',
    values: [
      {
        id: 'Internal Processing',
        value: 'Internal Processing',
        label: 'Internal Processing',
        isChecked: false,
      },
      {
        id: 'Dispute',
        value: 'Dispute',
        label: 'Dispute',
        isChecked: false,
      },
      {
        id: 'Request a Copy/Info',
        value: 'Request a Copy/Info',
        label: 'Request a Copy/Info',
        isChecked: false,
      },
    ],
    type: 'checkbox',
  },
  {
    id: 'workflow',
    displayName: 'Workflows',
    source: 'master',
    values: [],
    type: 'checkbox',
  },
  {
    id: 'status',
    displayName: 'Status',
    source: 'master',
    values: [
      {
        id: 'New',
        value: 'New',
        label: 'New',
        isChecked: false,
      },
      {
        id: 'InProgress',
        value: 'InProgress',
        label: 'In Progress',
        isChecked: false,
      },
      {
        id: 'ReOpen',
        value: 'ReOpen',
        label: 'Re Opened',
        isChecked: false,
      },
      {
        id: 'AwaitingInfo',
        value: 'AwaitingInfo',
        label: 'Awaiting Info',
        isChecked: false,
      },
      {
        id: 'Resolved',
        value: 'Resolved',
        label: 'Resolved',
        isChecked: false,
      },
      {
        id: 'Closed',
        value: 'Closed',
        label: 'Closed',
        isChecked: false,
      },
    ],
    type: 'checkbox',
  },
]

const dateFilters: Value[] = [
  {
    id: '7d',
    label: 'Last 7 days',
    value: {
      from: 'now-7d',
      to: 'now',
    },
  },
  {
    id: '30d',
    label: 'Last 30 Days',
    value: {
      from: 'now-30d',
      to: 'now',
    },
  },
  {
    id: '360d',
    label: 'Last 1 Year',
    value: {
      from: 'now-365d',
      to: 'now',
    },
  },
]

export const compareReports: Report[] = [
  {
    id: 'resolution_amount',
    reportName: 'Comparison of Disputed Amount vs Resolved Amount',
    sideFilters: [...sideFilters],
    consolidateType: 'COUNT',
    dateFilter: {
      id: 'create_timestamp',
      options: dateFilters,
      active: {
        from: 'now-365d',
        to: 'now',
      },
    },
  },
]
