import React, { useEffect, useState } from 'react'
import { Grid, Modal, Button, Form } from '@enterprise-ui/canvas-ui-react'
import { FormikValues } from 'formik'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useAuth } from '@praxis/component-auth'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import { activityData } from '../Services/saveActivity'
import { assignButtonStyle } from './SynergyDivComponent'
import _ from 'lodash'

export const formFieldMargin = css`
  margin-top: 13px;
`
interface MainProp {
  headingText: String
  formik: FormikValues
  saveStatus: any
  workflowValues: any
  assignedUserValues: any
}

const EditStatusModal: React.FC<MainProp> = ({
  headingText,
  formik,
  workflowValues,
  assignedUserValues,
  saveStatus,
}) => {
  const { session } = useAuth()
  const { userProfile } = useProfile()
  const userName = session?.userInfo?.fullName ?? ''
  const [currentAssignedUser, setCurrentAssignedUser] = useState<any>()
  const [state, dispatch] = useGlobalForm()
  let activityDescription = 'Modified Workflow Details'

  function saveActivty() {
    let assignedUserName = _.find(
      assignedUserValues,
      function (assignedUserName) {
        return assignedUserName.id === formik.values.lanId
      }
    )?.label
    if (
      state.assignWorkflow !== formik.values.workflow &&
      ((state.assignLanId === formik.values.lanId &&
        state.assignWorkflow !== undefined &&
        state.assignWorkflow !== null) ||
        (state.assignLanId !== formik.values.lanId &&
          (formik.values.lanId === undefined ||
            formik.values.lanId === null ||
            formik.values.lanId === '')))
    ) {
      activityDescription = `${'Workflow was transferred from '}${
        state.assignWorkflow
      }${' to '}${formik.values.workflow}`
    } else if (
      state.assignWorkflow !== formik.values.workflow &&
      state.assignLanId === formik.values.lanId &&
      (state.assignWorkflow === undefined || state.assignWorkflow === null)
    ) {
      activityDescription = `${'Workflow was assigned to '}
      ${formik.values.workflow}`
    } else if (
      state.assignWorkflow === formik.values.workflow &&
      state.assignLanId !== formik.values.lanId &&
      (formik.values.lanId === undefined ||
        formik.values.lanId === null ||
        formik.values.lanId === '')
    ) {
      activityDescription = `${'Case assignee was removed '}`
    } else if (
      state.assignWorkflow === formik.values.workflow &&
      state.assignLanId !== formik.values.lanId &&
      ((state.assignLanId !== undefined && state.assignLanId !== null) ||
        state.assignLanId === undefined ||
        state.assignLanId === null)
    ) {
      activityDescription = `${'Case was assigned to '}${assignedUserName}`
    } else if (
      state.assignWorkflow !== formik.values.workflow &&
      state.assignLanId !== formik.values.lanId &&
      state.assignWorkflow !== undefined &&
      state.assignWorkflow !== null &&
      state.assignLanId !== undefined &&
      state.assignLanId !== null
    ) {
      activityDescription = `${'Workflow was transferred from '}
      ${state.assignWorkflow}${' to '}
      ${formik.values.workflow}${' and was assigned to '}${assignedUserName}`
    }
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    saveStatus()
  }
  useEffect(() => {
    setCurrentAssignedUser(null)
    if (formik.values.lanId) {
      setCurrentAssignedUser(
        assignedUserValues.find(
          (assignedUserValues: any) =>
            assignedUserValues?.id === formik.values.lanId
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.lanId, assignedUserValues])

  const onUserAssignment = () => {
    formik.setFieldValue('lanId', userProfile?.userId)
    setCurrentAssignedUser(
      assignedUserValues.find(
        (assignedUserValues: any) =>
          assignedUserValues?.id === userProfile?.userId
      )
    )
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.userFlag}
        onRefuse={() =>
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'userFlag', value: false }],
          })
        }
      >
        <div className="hc-pa-normal hc-pa-lg">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={6}>
                  <Form.Field
                    css={formFieldMargin}
                    id="workflow"
                    label="Workflow"
                    type="select"
                    className="hc-ta-left"
                    value={formik?.values?.workflow}
                    onUpdate={(e: any, value: any) => {
                      formik.setFieldValue('lanId', '')
                      formik.setFieldValue('workflow', value)
                    }}
                    options={workflowValues}
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Autocomplete
                    id="lanId"
                    label="Assignee"
                    // @ts-ignore
                    rightContent={
                      <Button
                        size="dense"
                        onClick={() => {
                          onUserAssignment()
                        }}
                        css={assignButtonStyle}
                      >
                        Assign to me
                      </Button>
                    }
                    onUpdate={(id, value) => {
                      formik.setFieldValue('lanId', value?.value ?? '')
                      setCurrentAssignedUser(
                        assignedUserValues.find(
                          (assignedUserValues: any) =>
                            assignedUserValues?.id === value?.value
                        )
                      )
                    }}
                    value={currentAssignedUser}
                    options={assignedUserValues}
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container align="center">
                <Grid.Item xs></Grid.Item>
                <Grid.Item justify="flex-end">
                  <Grid.Container direction="row-reverse" spacing="dense">
                    <Grid.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          saveActivty()
                        }}
                      >
                        Save
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={() => {
                          dispatch({
                            type: SET_FIELD,
                            payload: [{ id: 'userFlag', value: false }],
                          })
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}

export default EditStatusModal
