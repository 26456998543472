import { gql } from '@apollo/client'
import { UpdateCaseRequest } from '../Common/Queries'

export const GET_CASE_SUGGESTION = gql`
  query GetCaseSuggestion($input: SuggestionRequest!) {
    getCaseSuggestion(suggestionRequest: $input) {
      suggestion
      label
    }
  }
`

export const SEARCH_QUERY = gql`
  query GetSearchResults(
    $query: String
    $page: Int
    $perPage: Int
    $sort: String!
    $vendorId: String
  ) {
    getSearchResults(
      query: $query
      page: $page
      perPage: $perPage
      sort: $sort
      vendorId: $vendorId
    ) {
      paging {
        totalResults
        totalPages
      }
      cases {
        caseId
        caseType
        caseSubType
        documentTypeId
        documentTypeText
        documentSubTypeId
        documentSubTypeText
        caseDescription
        reasonCode
        reasonCodeText
        status
        workflow
        resolutionDate
        resolutionAmount
        resolutionCode
        resolutionCodeText
        departmentId
        purchaseOrderNumber
        locationId
        classId
        originalDocumentNumber
        originalDocumentAmount
        receiptId
        receipts
        originalDocumentCreateDate
        disputedDocumentAmount
        vendorName
        vendorNumber
        vendorContactName
        vendorEmailAddress
        vendorType
        bankName
        lanId
        originalCheckNumber
        originalCheckDate
        originalCheckAmount
        resolutionText
        updateTimestamp
        createTimestamp
        isDocumentsAvailable
        agingDate
        notes {
          userId
          userName
          commentBody
          isInternal
          commentDate
        }
        disputeDetails {
          newDocumentNumber
          newDocumentAmount
          newPaidDate
          newCheckNumber
        }
        claimDetails {
          shipDate
          carrierVendorId
          carrierVendorName
          carrierVendorContact
          carrierVendorContactEmail
          carrierScacCode
          referenceDocumentNumber
          referenceDocumentCreateDate
          isMappedVendor
          cbdcDocumentDate
          retailAmount
          inventoryRemovalId
          importClaimReversal {
            approvedReversalAmount
            writeOffAmount
            openClaimAmountDetails {
              claimId
              claimDeductionAmount
            }
            domesticAccount {
              domesticVendorId
              domesticAccountAmount
            }
            wireRefundAmount
            approvedPendingReversalAmount
          }
          invoiceDate
          receiptDate
          cartonsBilled
          cartonsReceived
          shortageCartons
          shortageAmount
          trailerNumber
          trackingNumber
          trailerType
          claimType
          itemLevelShortage {
            department
            classId
            item
            quantityShortage
            unitCost
            invoiceQuantity
            casePack
            cartonCount
            shortageCarton
            shortageCost
          }
          claimReceipts {
            location
            poId
            receiptId
            cartonsCount
            cartonCost
          }
          documentNotification
        }
        requestDetails {
          receiptId
          shipmentDate
          carrierVendorName
          carrierVendorContactEmail
          faxNumber
          proofOfDelivery
          proofOfShipment
        }
        auditEntries {
          activityDescription
          userId
          workflow
          status
          assignUserId
          activityTimestamp
        }
        reopenDetails {
          reopenReason
          reopenDate
          reopenUser
          previousResolutionDate
          previousResolutionUser
          previousResolutionCode
          previousResolutionText
          previousResolutionAmount
          previousResolutionDocumentNumber
        }
      }
    }
  }
`
export interface Suggestion {
  suggestion: string
  label: string
}

export interface SuggestionRequest {
  query: string
  limit: number
  fieldName?: string
}

export interface GetCaseSuggestion {
  getCaseSuggestion: [Suggestion]
}

export const DOWNLOAD_SEARCH_QUERY_TM = gql`
  query GetSearchDownloadResults(
    $query: String
    $sort: String!
    $vendorId: String
  ) {
    getSearchDownloadResults(query: $query, sort: $sort, vendorId: $vendorId) {
      agingDate
      caseId
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      reasonCode
      reasonCodeText
      status
      workflow
      originalDocumentNumber
      originalDocumentAmount
      vendorName
      vendorNumber
      lanId
      updateTimestamp
      createTimestamp
      disputedDocumentAmount
      resolutionDate
      resolutionText
      resolutionCodeText
      resolutionAmount
      locationId
      purchaseOrderNumber
      departmentId
      classId
      createUser
      isDocumentsAvailable
      vendorEmailAddress
      disputeDetails {
        newDocumentNumber
        newDocumentAmount
        newPaidDate
        newCheckNumber
      }
      claimDetails {
        shipDate
        carrierVendorId
        carrierVendorName
        carrierScacCode
        inventoryRemovalId
      }
      userProfile {
        fullName
      }
    }
  }
`

export const DOWNLOAD_SEARCH_QUERY_VENDOR = gql`
  query GetSearchDownloadResults(
    $query: String
    $sort: String!
    $vendorId: String
  ) {
    getSearchDownloadResults(query: $query, sort: $sort, vendorId: $vendorId) {
      agingDate
      caseId
      caseType
      caseSubType
      documentTypeId
      documentTypeText
      reasonCode
      reasonCodeText
      status
      originalDocumentNumber
      originalDocumentAmount
      vendorName
      vendorNumber
      updateTimestamp
      createTimestamp
      disputedDocumentAmount
      resolutionDate
      resolutionCodeText
      resolutionText
      resolutionAmount
      isDocumentsAvailable
      vendorEmailAddress
      disputeDetails {
        newDocumentNumber
        newDocumentAmount
        newPaidDate
        newCheckNumber
      }
    }
  }
`
export const excelDownloadHeadersTm = [
  { label: 'Case ID #', key: 'caseId' },
  { label: 'Case created date', key: 'createTimestamp' },
  { label: 'Case Aging', key: 'caseAging' },
  { label: 'Case Type', key: 'caseType' },
  { label: 'Case Sub Type', key: 'caseSubType' },
  { label: 'Document Type', key: 'documentTypeText' },
  { label: 'Document Sub Type', key: 'documentSubTypeText' },
  { label: 'Doc #', key: 'originalDocumentNumber' },
  { label: 'Dispute $', key: 'disputedDocumentAmount' },
  { label: 'Dispute Reason', key: 'reasonCodeText' },
  { label: 'Status', key: 'status' },
  { label: 'Workflow', key: 'workflow' },
  { label: 'Vend #', key: 'vendorNumber' },
  { label: 'Vendor Name', key: 'vendorName' },
  { label: 'Vendor Contact', key: 'vendorEmailAddress' },
  { label: 'Current Assignee', key: 'userProfile.fullName' },
  { label: 'Case Reporter ID', key: 'createUser' },
  { label: 'Carrier Vendor Name', key: 'claimDetails.carrierVendorName' },
  { label: 'Carrier Vendor ID', key: 'claimDetails.carrierVendorId' },
  { label: 'Original Document Amount', key: 'originalDocumentAmount' },
  { label: 'PO', key: 'purchaseOrderNumber' },
  { label: 'Dept', key: 'departmentId' },
  { label: 'Location', key: 'locationId' },
  { label: 'Class', key: 'classId' },
  { label: 'SCAC Code', key: 'claimDetails.carrierScacCode' },
  { label: 'Ship Date', key: 'claimDetails.shipDate' },
  { label: 'Update Date', key: 'updateTimestamp' },
  { label: 'Resolution Comments', key: 'resolutionCodeText' },
  { label: 'Resolution Note', key: 'resolutionText' },
  { label: 'Resolved $', key: 'resolutionAmount' },
  { label: 'Resolution Date', key: 'resolutionDate' },
  {
    label: 'Resolution Document Number',
    key: 'disputeDetails.newDocumentNumber',
  },
  { label: 'Check #', key: 'disputeDetails.newCheckNumber' },
  { label: 'Payment Date', key: 'disputeDetails.newPaidDate' },
  { label: 'Attachments', key: 'isDocumentsAvailable' },
  { label: 'Inventory Removal Id', key: 'claimDetails.inventoryRemovalId' },
]

export const excelDownloadHeadersVendors = [
  { label: 'Case ID', key: 'caseId' },
  { label: 'Case Created Date', key: 'createTimestamp' },
  { label: 'Doc No', key: 'originalDocumentNumber' },
  { label: 'Disputed amount', key: 'disputedDocumentAmount' },
  { label: 'Status', key: 'status' },
  { label: 'Resolution Date', key: 'resolutionDate' },
  { label: 'Resolution Amount', key: 'resolutionAmount' },
  { label: 'Resolution Comments', key: 'resolutionCodeText' },
  { label: 'Resolution Note', key: 'resolutionText' },
  {
    label: 'Resolution Doc No',
    key: 'disputeDetails.newDocumentNumber',
  },
  { label: 'Check No', key: 'disputeDetails.newCheckNumber' },
  { label: 'Check Date', key: 'disputeDetails.newPaidDate' },
  { label: 'Vendor Contact', key: 'vendorEmailAddress' },
  { label: 'Attachments', key: 'isDocumentsAvailable' },
]

export const excelDownloadDateFields = [
  { key: 'updateTimestamp' },
  { key: 'createTimestamp' },
  { key: 'shipDate' },
  { key: 'resolutionDate' },
  { key: 'newPaidDate' },
]

export const excelDownloadObjects = [
  {
    key: 'claimDetails',
    object: {
      shipDate: '',
      carrierVendorId: '',
      carrierVendorName: '',
      carrierScacCode: '',
      inventoryRemovalId: '',
    },
  },
  {
    key: 'disputeDetails',
    object: {
      newDocumentNumber: '',
      newDocumentAmount: '',
      newPaidDate: '',
      newCheckNumber: '',
    },
  },
  {
    key: 'userProfile',
    object: {
      fullName: '',
    },
  },
]

export const BULK_UPDATE_CASE = gql`
  mutation BulkUpdateCases($input: [UpdateCaseRequest]!) {
    bulkUpdateCases(updateCaseRequests: $input) {
      caseId
      caseType
      caseSubType
      caseDescription
      reasonCode
      reasonCodeText
      status
      lanId
      workflow
      resolutionDate
      resolutionCode
      resolutionCodeText
      resolutionText
      departmentId
      purchaseOrderNumber
      locationId
      classId
      documentTypeId
      documentTypeText
      documentSubTypeId
      documentSubTypeText
      originalDocumentNumber
      originalDocumentAmount
      originalDocumentCreateDate
      disputedDocumentAmount
      resolutionAmount
      originalCheckNumber
      originalCheckDate
      originalCheckAmount
      receiptId
      vendorName
      vendorNumber
      vendorContactName
      vendorEmailAddress
      vendorType
      isReopen
      createUser
      createTimestamp
      updateUser
      updateTimestamp
      agingDate
      notes {
        userId
        userName
        commentBody
        isInternal
        commentDate
      }
      disputeDetails {
        newDocumentNumber
        newPaidDate
        newDocumentAmount
        newCheckNumber
      }
      claimDetails {
        shipDate
        carrierVendorId
        carrierVendorName
        carrierVendorContact
        carrierVendorContactEmail
        carrierScacCode
        referenceDocumentNumber
        referenceDocumentCreateDate
        isMappedVendor
        cbdcDocumentDate
        retailAmount
        inventoryRemovalId
        importClaimReversal {
          approvedReversalAmount
          writeOffAmount
          openClaimAmountDetails {
            claimId
            claimDeductionAmount
          }
          domesticAccount {
            domesticVendorId
            domesticAccountAmount
          }
          wireRefundAmount
          approvedPendingReversalAmount
        }
        invoiceDate
        receiptDate
        cartonsBilled
        cartonsReceived
        shortageCartons
        shortageAmount
        trailerNumber
        trackingNumber
        trailerType
        claimType
        itemLevelShortage {
          department
          classId
          item
          quantityShortage
          unitCost
          invoiceQuantity
          casePack
          cartonCount
          shortageCarton
          shortageCost
        }
        claimReceipts {
          location
          poId
          receiptId
          cartonsCount
          cartonCost
        }
        documentNotification
      }
      auditEntries {
        activityDescription
        userId
        workflow
        status
        assignUserId
        activityTimestamp
      }
    }
  }
`

export interface BulkUpdateCaseVar {
  input: UpdateCaseRequest[]
}
