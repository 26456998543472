import React from 'react'
import LoadingOverlay from 'react-loading-overlay-ts'
import {
  GridContainer,
  GridItem,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'

// Overlay loader Properties
interface OverlayProps {
  active?: boolean
  children: React.ReactNode
}

export const Overlay: React.FC<OverlayProps> = (props) => {
  return (
    <LoadingOverlay
      active={props.active}
      spinner={
        <GridContainer justify="center" align="center">
          <GridItem>
            <Spinner />
          </GridItem>
        </GridContainer>
      }
      styles={{
        content: (base: any) => ({
          ...base,
          marginLeft: '450px',
          marginRight: 'unset',
          marginTop: '400px',
          marginBottom: 'unset',
        }),
      }}
      text="Downloading..."
    >
      {props.children}
    </LoadingOverlay>
  )
}
