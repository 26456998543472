import { Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import useAutoExtractedDocuments from '../../Hooks/useAutoExtractDocuments'
import { AutoExtractAttachments } from './AutoExtractAttachments'
export interface AttachmentSearchProps {
  attchmentSearchVisible: boolean
  setAttachmetSearchVisible: any
}

export const AttachmentSearch: React.FC<AttachmentSearchProps> = ({
  attchmentSearchVisible,
  setAttachmetSearchVisible,
}) => {
  const allAttachments: [any] = useAutoExtractedDocuments(
    attchmentSearchVisible
  )
  const header = () => {
    return 'Search Auto-Extracted Documents '
  }

  return (
    <div>
      <Modal
        headingText={header()}
        isVisible={attchmentSearchVisible}
        onRefuse={() => setAttachmetSearchVisible(false)}
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={12}>
              <AutoExtractAttachments
                setAttachmetSearchVisible={setAttachmetSearchVisible}
                attachData={allAttachments}
              />
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}
