import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AgeingReport } from './AgeingReport'
import { AggregatedReport } from './AggregatedReport'
import { CompareReport } from './CompareReport'
import { DupicateReport } from './DuplicateReport'
import { aggregatedReports } from './ReportContext/AggregatedReportInitalValues'
import { agingReports } from './ReportContext/AgingReportInitailValues'
import { compareReports } from './ReportContext/CompareReportInitialValues'
import { duplicateReports } from './ReportContext/DuplicateReportInitialValues'
import { ReportProvider } from './ReportContext/ReportProvider'
import { ReportsList } from './ReportsList'
// import { workflowManagement } from './ReportContext/WorkflowManagementInitialValues'
// import { WorkflowManagementReport } from './WorkflowManagementReport'
import { ProtectedElement } from '@praxis/component-auth'
import { EnvConfig } from '../../config/appConfig'
import { useEnv } from '@praxis/component-runtime-env'

export const Reports = () => {
  const env = useEnv() as EnvConfig

  return (
    <>
      <Routes>
        <Route path={'/'} element={<ReportsList />} />
        <Route
          path="/aggregated_reports/:id"
          element={
            <ProtectedElement
              allowed={[
                env.auth.adminRole,
                env.auth.superAdminRole,
                env.auth.userRole,
              ].flat()}
            >
              <ReportProvider reportInitialValues={aggregatedReports}>
                <AggregatedReport />
              </ReportProvider>
            </ProtectedElement>
          }
        />
        <Route
          path="/aging_reports/:id"
          element={
            <ProtectedElement
              allowed={[
                env.auth.adminRole,
                env.auth.superAdminRole,
                env.auth.userRole,
              ].flat()}
            >
              <ReportProvider reportInitialValues={agingReports}>
                <AgeingReport />
              </ReportProvider>
            </ProtectedElement>
          }
        />

        <Route
          path="/compare_reports/:id"
          element={
            <ProtectedElement
              allowed={[
                env.auth.adminRole,
                env.auth.superAdminRole,
                env.auth.userRole,
              ].flat()}
            >
              <ReportProvider reportInitialValues={compareReports}>
                <CompareReport />
              </ReportProvider>
            </ProtectedElement>
          }
        />
        <Route
          path="/duplicate_reports/:id"
          element={
            <ProtectedElement
              allowed={[
                env.auth.adminRole,
                env.auth.superAdminRole,
                env.auth.userRole,
              ].flat()}
            >
              <ReportProvider reportInitialValues={duplicateReports}>
                <DupicateReport />
              </ReportProvider>
            </ProtectedElement>
          }
        />
      </Routes>
    </>
  )
}
