import React, { useState } from 'react'
import { useCaseSearch } from './SearchContext/CaseSearchProvider'
import { useApolloClient } from '@apollo/client'
import * as qs from 'qs'
import { GridContainer, GridItem } from '@enterprise-ui/canvas-ui-react'

import { useLocation } from 'react-router-dom'
import { UPDATE_FILTER } from './SearchContext/ActionTypes'
import { SEARCH_QUERY } from './SearchQueries'
import { CaseTable } from './CaseTable'
import { buildQuery } from './SearchContext/SearchUtils'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import _ from 'lodash'
import { useAuth } from '@praxis/component-auth'

interface Props {
  setQueryResults: any
  setSearchQuery: any
}

export const CaseSearch: React.FC<Props> = (props) => {
  const [cases, setCases] = useState([])
  const { session } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [totalCases, setTotalCases] = useState<number>(0)
  const [sortColumn, setSortColumn] = useState<any>({
    id: 'create_timestamp',
    value: 'Create TimeStamp',
  })
  const [sortAscending, setSortAscending] = useState<boolean>(false)
  const fetchIdRef = React.useRef(0)
  const [state, dispatch] = useCaseSearch()
  const { userProfile } = useProfile()
  const location = useLocation()
  const client = useApolloClient()

  const lanId = session?.userInfo?.lanId ?? ''

  const fetchCases = React.useCallback(
    async ({ pageSize, pageIndex }: any) => {
      const fetchId = ++fetchIdRef.current

      if (fetchId === fetchIdRef.current) {
        setLoading(true)
        client
          .query({
            query: SEARCH_QUERY,
            variables: {
              query: buildQuery(state, userProfile?.isVendor, lanId),
              page: pageIndex + 1,
              perPage: pageSize,
              sort: `${sortColumn.id}:${sortAscending ? 'ASC' : 'DESC'}`,
              vendorId: userProfile?.defaultVendorId,
            },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            setCases(res.data?.getSearchResults?.cases ?? [])
            setTotalPages(res.data?.getSearchResults?.paging?.totalPages || 0)
            setTotalCases(res.data?.getSearchResults?.paging?.totalResults || 0)
            props.setQueryResults(res.data?.getSearchResults?.paging)
            props.setSearchQuery({
              query: buildQuery(state, userProfile?.isVendor, lanId),
              page: pageIndex + 1,
              perPage: pageSize,
              sort: `${sortColumn.id}:${sortAscending ? 'ASC' : 'DESC'}`,
              vendorId: userProfile?.defaultVendorId,
            })
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, state, sortColumn, sortAscending, userProfile]
  )
  React.useEffect(() => {
    dispatch({
      type: UPDATE_FILTER,
      payload: qs.parse(location.search.replace('?', ''), {
        arrayLimit: 100,
      }),
    })
  }, [dispatch, location.search])

  const updateCases = (updatedCases: any) => {
    const newCases = [...cases] as any

    updatedCases.forEach((casee: any) => {
      const index = _.findIndex(newCases, (newCase: any) => {
        return newCase.caseId === casee.caseId
      })
      newCases.splice(index, 1, { ...casee })
    })
    setCases(newCases)
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <CaseTable
          data={cases}
          loading={loading}
          totalPages={totalPages}
          totalCases={totalCases}
          loadCases={fetchCases}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortAscending={sortAscending}
          setSortAscending={setSortAscending}
          updateCases={updateCases}
        ></CaseTable>
      </GridItem>
    </GridContainer>
  )
}
