import React from 'react'
import { CaseResponse } from './Queries'
import { CaseType } from '../Services/ConstantData'
import { Section, SectionItem } from './Section'
import { BottomBorderDiv } from './SynergyDivComponent'

interface MainProp {
  heading: string
  disputeDetails: CaseResponse | undefined
}

const EditDisputeDetails: React.FC<MainProp> = ({
  heading,
  disputeDetails,
}) => {
  const claimFiling = disputeDetails?.caseType === CaseType.INTERNALPROCESSING
  const request = disputeDetails?.caseType === CaseType.REQUESTFORINFO
  return (
    <BottomBorderDiv>
      <Section labelGridSize={4} detail heading={heading} expandable>
        <SectionItem label="Document #">
          {disputeDetails?.originalDocumentNumber}
        </SectionItem>
        <SectionItem
          label={claimFiling ? 'Case Sub Type' : 'Deduction or Invoice Type'}
        >
          {disputeDetails?.caseSubType}
        </SectionItem>
        <SectionItem label={claimFiling ? 'Claim Type' : 'Claim / Doc Type'}>
          {disputeDetails?.documentTypeText}
        </SectionItem>
        <SectionItem
          label={claimFiling ? 'Claim Sub Type' : 'Claim / Doc Sub Type'}
        >
          {disputeDetails?.documentSubTypeText}
        </SectionItem>
        <SectionItem
          label={
            claimFiling
              ? 'Reason for filing the Claim'
              : request
              ? 'Reason for Request'
              : 'Reason for Disputing'
          }
        >
          {disputeDetails?.reasonCodeText}
        </SectionItem>
        <SectionItem
          label={
            claimFiling
              ? 'Brief explanation for filing the case'
              : 'Brief explanation for raising the case'
          }
        >
          {disputeDetails?.caseDescription}
        </SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}

export default EditDisputeDetails
