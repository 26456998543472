import { Divider } from '@enterprise-ui/canvas-ui-react'
import React, { ReactNode, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Filter } from '../Cases/Search/Filter'
import { Value } from '../Cases/Search/SearchContext/SearchInitialValues'
import { buildReportUrl } from './ReportContext/ReportUtils'
import { DynamicFilter } from './ReportBaseLayout'
import { SideFilter } from './ReportContext/ReportContext'

interface ReportSideFiltersProps {
  sideFilters?: SideFilter[]
  dynamicFilter?: DynamicFilter
  dynamicFilters?: Value[]
  customSideFilters?: ReactNode
}

export const ReportSideFilters: React.FC<ReportSideFiltersProps> = ({
  sideFilters = [],
  dynamicFilter,
  dynamicFilters = [],
  customSideFilters,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dynamicFilterData = useMemo(() => {
    return [...dynamicFilters]
  }, [dynamicFilters])

  const handleChange = (
    id: string,
    type: string,
    event: any,
    searchField?: string
  ) => {
    navigate(
      `${location.pathname}?` +
        buildReportUrl(location, {
          filter: id,
          event: event,
          type: type,
          searchField: searchField,
        })
    )
  }

  return (
    <>
      {customSideFilters}
      {dynamicFilter?.enabled && (
        <React.Fragment>
          <Filter
            id={dynamicFilter.id}
            label={dynamicFilter.filterName}
            filters={dynamicFilterData}
            onUpdate={dynamicFilter.handleDynamicFilterChange}
            type={'checkbox'}
            startExpanded
          />
          <Divider></Divider>
        </React.Fragment>
      )}
      {sideFilters.map((key) => (
        <React.Fragment key={key.id}>
          <Filter
            id={key.id}
            label={key.displayName}
            filters={key.values}
            onUpdate={handleChange}
            type={key.type}
            startExpanded={key.startExpanded}
          />
          <Divider></Divider>
        </React.Fragment>
      ))}
    </>
  )
}
