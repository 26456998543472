import _ from 'lodash'
import { dvsWorkflow } from '../Services/ConstantData'

export function findTapWorkflowMatch(
  workflowMappingValues: any,
  workflow: string
): string {
  let workflowMappingValue = _.find(workflowMappingValues, function (wf) {
    return wf.value === workflow
  })
  if (workflowMappingValue !== undefined) {
    return workflowMappingValue.value
  } else return 'DEFAULT'
}

export function findWorkflowValue(
  workflowMappingValues: Array<any> | undefined,
  formikValues: any,
  documentPrefix: any | undefined,
  dvsFlag: boolean
): string {
  if (dvsFlag) {
    return dvsWorkflow
  }
  let mappingWorkflow: any
  let workflowMappingValue: any = workflowMappingValues?.filter(
    (wf: any) =>
      wf.caseType === formikValues.caseType &&
      wf.caseSubType === formikValues.caseSubType &&
      Number(wf.documentTypeId) === Number(formikValues.documentTypeId) &&
      Number(wf.documentSubTypeId) === Number(formikValues.documentSubTypeId)
  )
  if (workflowMappingValue.length > 0) {
    mappingWorkflow = checkPrefixMapping(workflowMappingValue, documentPrefix)
    return mappingWorkflow
  }
  workflowMappingValue = workflowMappingValues?.filter(
    (wf: any) =>
      wf.caseType === formikValues.caseType &&
      wf.caseSubType === formikValues.caseSubType &&
      Number(wf.documentTypeId) === Number(formikValues.documentTypeId) &&
      wf.documentSubTypeId === null
  )
  if (workflowMappingValue.length > 0) {
    mappingWorkflow = checkPrefixMapping(workflowMappingValue, documentPrefix)
    return mappingWorkflow
  }
  workflowMappingValue = workflowMappingValues?.filter(
    (wf: any) =>
      wf.caseType === formikValues.caseType &&
      wf.caseSubType === formikValues.caseSubType &&
      wf.documentTypeId === null &&
      wf.documentSubTypeId === null
  )
  if (workflowMappingValue.length > 0) {
    mappingWorkflow = checkPrefixMapping(workflowMappingValue, documentPrefix)
    return mappingWorkflow
  } else return 'DEFAULT'
}

function checkPrefixMapping(
  mappingValues: Array<any>,
  documentPrefix: any | undefined
): any {
  let workflowMatch: any
  if (mappingValues.length > 1) {
    if (documentPrefix?.length > 0) {
      workflowMatch = mappingValues?.filter(
        (wf: any) => wf.documentPrefix === documentPrefix[0]?.documentPrefix
      )
    }
    if (workflowMatch?.length > 0) {
      return workflowMatch[0].workflow
    }
    workflowMatch = mappingValues?.filter(
      (wf: any) => wf.documentPrefix === null
    )
    if (workflowMatch?.length > 0) {
      return workflowMatch[0].workflow
    }
    return mappingValues[0].workflow
  } else {
    return mappingValues[0]?.workflow
  }
}
