import React from 'react'
import { useAuth } from '@praxis/component-auth'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat,
} from '@apollo/client'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from './config/appConfig'
import Authentication from './components/Layout/Authentication'
import { isSSOSessionActive } from './components/Layout/AuthUtils'

interface Props {
  children: React.ReactNode
}

const GraphQLProvider: React.FC<Props> = (props) => {
  const auth = useAuth()
  const env = useEnv() as EnvConfig

  const httpLink = new HttpLink({ uri: env.baseApiUrl })

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (isSSOSessionActive()) {
      // add the authorization to the headers
      operation.setContext({
        headers: {
          Authorization: localStorage.getItem('access_token') || null,
          'x-api-key': env.apiKey,
        },
      })
    } else {
      auth.login()
    }
    return forward(operation)
  })

  const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })

  if (auth.isAuthenticated() && isSSOSessionActive()) {
    return <ApolloProvider client={client}>{props.children} </ApolloProvider>
  }

  return <Authentication />
}

export default GraphQLProvider
