export function getAuditforFieldUpdate(
  prevCase: any,
  update: any,
  field: string,
  label: string
) {
  if (prevCase[field] === update) {
    return `${label} is updated`
  } else {
    if (prevCase[field] == null && update != null) {
      return `${label} is changed to ${update}`
    } else if (prevCase[field] != null && update == null) {
      return `${label} is removed`
    } else if (
      prevCase[field] != null &&
      update != null &&
      prevCase[field] !== update
    ) {
      return `${label} was transferred from ${
        prevCase[field] ?? 'NA'
      } to ${update}`
    }
  }
  return ''
}
