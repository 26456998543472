import { CaseResponse } from '../Cases/Common/Queries'
import { gql } from '@apollo/client'

export const GET_AGING_DETAILS = gql`
  query GetAggregatedAge($query: String!, $vendorNumber: String) {
    getAggregatedAge(query: $query, vendorNumber: $vendorNumber) {
      caseSubType
      count
      aging {
        ageType
        count
      }
    }
  }
`

export interface GetAging {
  getAggregatedAge?: AgingDetails[]
}

export interface AgingDetails {
  caseSubType?: string
  count?: number
  aging?: Aging[]
}

export interface Aging {
  ageType?: string
  count?: number
}

export interface AggregateVariable {
  query?: string
  summaryDays?: number
  vendorNumber?: string
}

export const GET_STATUS_DETAILS = gql`
  query GetAggregatedStatus(
    $query: String!
    $summaryDays: Long!
    $vendorNumber: String
  ) {
    getAggregatedStatus(
      query: $query
      summaryDays: $summaryDays
      vendorNumber: $vendorNumber
    ) {
      caseSubType
      count
      statuses {
        status
        count
      }
    }
  }
`

export interface GetStatuses {
  getAggregatedStatus?: StatusDetails[]
}

export interface StatusDetails {
  caseSubType?: string
  count?: number
  statuses?: Statuses[]
}

export interface Statuses {
  status?: string
  count?: number
}

export const GET_SEARCH_RESULTS = gql`
  query GetSearchResults(
    $query: String
    $sort: String!
    $vendorId: String
    $perPage: Int!
    $page: Int!
  ) {
    getSearchResults(
      query: $query
      perPage: $perPage
      page: $page
      vendorId: $vendorId
      sort: $sort
    ) {
      paging {
        totalResults
        totalPages
      }
      cases {
        caseId
        caseType
        caseSubType
        notes {
          userId
          userName
          commentBody
          isInternal
          commentDate
        }
        lanId
        createUser
        createTimestamp
        updateTimestamp
        status
        agingDate
      }
    }
  }
`

export interface GetSearchResults {
  getSearchResults?: SearchResponse
}

export interface SearchResponse {
  paging: Paging
  cases?: [CaseResponse]
}

export interface Paging {
  totalResults: number
  totalPages: number
}

export interface SearchVariable {
  query?: string
  perPage?: number
  page?: number
  sort?: string
  vendorId?: string
}

export const GET_AGGREGATION = gql`
  query GetAggregation(
    $aggregateBy: [String]
    $consolidateType: String
    $query: String
    $numberOfPartitions: Int
  ) {
    getAggregation(
      aggregateBy: $aggregateBy
      consolidateType: $consolidateType
      query: $query
      numberOfPartitions: $numberOfPartitions
    ) {
      rootType
      type
      value
      subType {
        rootType
        type
        value
      }
    }
  }
`

export const GET_AGE_AGGREGATION = gql`
  query GetAgeAggregation(
    $query: String
    $aggregateBy: String
    $field: String
    $agingInterval: String
  ) {
    getAggregatedAgeNew(
      query: $query
      aggregateBy: $aggregateBy
      field: $field
      agingInterval: $agingInterval
    ) {
      rootType
      type
      value
      subType {
        rootType
        type
        value
      }
    }
  }
`

export const GET_COMPARE_AGGREGATION = gql`
  query GetCompareAggregation(
    $query: String
    $aggregateBy: String
    $compareFields: [String]
  ) {
    getCompareAggregation(
      query: $query
      aggregateBy: $aggregateBy
      compareFields: $compareFields
    ) {
      rootType
      value
      subType {
        rootType
        value
      }
    }
  }
`

export interface GetCompareAggregation {
  getCompareAggregation?: AggregationDetails[]
}

export interface AggregationVariables {
  aggregateBy?: string[]
  consolidateType?: string
  query?: string
  numberOfPartitions?: number
}

export interface AggregationAgeVariables {
  aggregateBy?: string
  agingInterval?: string
  query?: string
  field?: string
}

export interface CompareAggregationVariables {
  aggregateBy?: string
  query?: string
  compareFields?: string[]
}

export interface GetAggregation {
  getAggregation?: AggregationDetails[]
}

export interface GetAgeAggregation {
  getAggregatedAgeNew?: AggregationDetails[]
}

export interface AggregationDetails {
  rootType?: string
  type?: string
  value?: number
  subType?: AggregationDetails[]
}
