import React from 'react'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import BPDisputeCreate from './BPDisputeCreate'
import TMDisputeCreate from './TMDisputeCreate'
import { AttachmentProvider } from '../Context/AttachmentContext'
import { GlobalFormProvider } from '../Context/GlobalFormStateContext'

const CreateDispute: React.FC = () => {
  const { userProfile } = useProfile()

  return (
    <React.Fragment>
      {userProfile?.isVendor ? (
        <AttachmentProvider>
          <GlobalFormProvider>
            <BPDisputeCreate />
          </GlobalFormProvider>
        </AttachmentProvider>
      ) : (
        <AttachmentProvider>
          <GlobalFormProvider>
            <TMDisputeCreate />
          </GlobalFormProvider>
        </AttachmentProvider>
      )}
    </React.Fragment>
  )
}
export default CreateDispute
