// Headings
export const VENDOR_HEADING = 'Choose the correct Merch Vendor Info?'
export const INTERNAL_PROCESSING_VENDOR_HEADING =
  'Choose the correct Vendor Info?'
export const DISPUTE_HEADING = 'What do you wish to dispute and why?' // use for disputes
export const DOCUMENT_HEADING =
  'Document Info – here’s what we know. Do these look correct?'
export const ATTACHMENT_HEADING =
  'Have documentation to support / backup the case? Attach here:'
export const CASE_SUMMARY_HEADING = 'Vendor Details'
export const RECEIPT_HEADING = 'Enter the receipt/shipment details'
export const EDIT_RECEIPT_HEADING = 'Receipt/shipment details'
export const WORKFLOW_HEADING = 'Case Workflow Details'
export const PAYMENT_HEADING = 'Payment Details'
export const WORKFLOW_SUMMARY_HEADING = 'Workflow Details'
export const RESOLUTION_HEADING = 'Resolution Details'
export const DOCUMENT_CATEGORY_HEADING = 'Update Document Categories'
export const REQUEST_HEADING = 'What do you wish to request?' // use for requests
export const EDIT_VENDOR_HEADING = 'Vendor Info'
export const EDIT_DOCUMENT_HEADING = 'Document Details'
export const CR_VENDOR_HEADING = 'Choose the carrier Vendor Info if applicable?'
export const CLAIM_HEADING =
  'What do we need to recover / adjust with the Vendor?' // use for claims
export const REFERENCE_HEADING = 'Reference Document Details'
export const CARRIER_VENDOR_HEADING = 'Carrier Vendor Details'
export const TCLAIM_OTHER_RECEIPTS_HEADING = 'Different Receipts Used'
export const TCLAIM_ITEM_LEVEL_SHORTAGE_HEADING = 'Item Level Shortage Details'
export const PREV_RESOLUTION_HEADING = 'Previous Resolution Details'
export const SOURCE_DOCUMENT_HEADING = 'Source Document Details'
export const ACTIVE_DUPLICATE_ALERT =
  'Your request against this document already has an open case. Please check.'
export const CLOSED_DUPLICATE_ALERT =
  'Your request against this document already has resolved cases. Do you still want to duplicate this request?'
export const EDIT_DISPUTE_HEADING = 'Dispute Details'
export const EDIT_INTERNALPROCESS_HEADING = 'Internal Process Details'
export const EDIT_REQUESTINFO_HEADING = 'Request Details'
export const CREATE_CASE_DOCUMENT_ALERT =
  'Please ensure to upload supporting documents for speedy resolution'
export const RESOLVE_CASE_DOCUMENT_ALERT =
  'Hope you have uploaded all the supporting documents'
export const AWAITINGINFO_STATUS_CHANGE_ALERT =
  'Hope you have provided all the info for prompt vendor response'
// Other constants
export const NO_PAYMENT_DETAILS = 'NA'

// Date label
export const DATES_HEADING = 'Dates'
export const DATE_FORMAT = 'T00:00:00.000Z'

// global form types
export const SET_FIELD = 'SET_FIELD'
export const RESET = 'RESET'

// Case Update
export const WORKFLOW = 'Workflow Updated'
export const STATUS = 'Status Updated'
export const RESOLVE = 'Case is Resolved'
export const REOPEN = 'Case is Reopened'
export const DESCR = 'Brief Explanation Updated'
export const CRVENDOR = 'Carrier Vendor Details Updated'
export const DOCREF = 'Document Reference Details Updated'
export const DOCCATEGORY = 'Document Category Details Updated'
export const ATTACHMENTS = 'Documents Uploaded'
export const COMMENTS = 'Work Note Added'
export const REFUNDUPDATE = 'Refund Details Updated'

//TARGET & VENDOR Constants
export const VENDOR = 'VENDOR'
export const TARGET = 'TARGET'
