import { GlobalType, initialState } from './GlobalFormStateContext'
import { RESET, SET_FIELD } from '../Common/Constants'

export function globalReducer(draft: GlobalType, action: any) {
  switch (action.type) {
    case SET_FIELD: {
      // @ts-ignore
      action.payload.forEach((field: { id: string; value: any }) => {
        // @ts-ignore
        draft[field.id] = field.value
      })
      return
    }
    case RESET: {
      return initialState
    }
    default:
      return
  }
}
