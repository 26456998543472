/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import {
  Popover,
  Card,
  GridContainer,
  GridItem,
  Heading,
  List,
} from '@enterprise-ui/canvas-ui-react'
import {
  useProfile,
  useTeamsOrVendors,
} from '../UserProfile/UserProfileProvider'
import { useMutation } from '@apollo/client'
import { useAuth } from '@praxis/component-auth'
import {
  UpdateUserProfileVar,
  UserProfile,
  UPDATE_USER_PROFILE,
} from '../UserProfile/userProfle'
import { GET_USER_PROFILE } from '../UserProfile/userProfle'
import { CaretRightIcon, EnterpriseIcon, GroupIcon } from '@enterprise-ui/icons'

const card = css`
  width: 250px;
  cursor: pointer;
  :hover {
    background: #eeeeee;
  }
`
const listScroll = css`
  overflow: auto;
  max-height: 500px;
`

const popoverPosition = css`
  transform: none !important;
  top: 20% !important;
`

const ProfileSwitch: React.FC = (props) => {
  const { session } = useAuth()
  const { userProfile } = useProfile()
  const [activeTeam, setActiveTeam] = useState<{
    id: String
    value: String
  }>()
  const autoValues: [any] = useTeamsOrVendors(
    userProfile?.isVendor,
    userProfile?.userId!!
  )

  const [updateProfile] = useMutation<UserProfile, UpdateUserProfileVar>(
    UPDATE_USER_PROFILE
  )

  useEffect(() => {
    if (userProfile?.isVendor) {
      setActiveTeam({
        id: userProfile?.defaultVendorId!!.toString(),
        value: userProfile?.defaultVendorName!!,
      })
    } else {
      setActiveTeam({
        id: userProfile?.team?.teamId!!,
        value: userProfile?.team?.teamName!!,
      })
    }
  }, [userProfile])

  const handleChange = async (value: string, name: string) => {
    await updateProfile({
      variables: {
        id: session?.userInfo?.lanId ?? '',
        input: {
          firstName: session?.userInfo?.firstName ?? '',
          secondName: session?.userInfo?.lastName ?? '',
          teamId: !userProfile!.isVendor ? value : undefined,
          defaultVendorId: userProfile?.isVendor ? value : undefined,
          defaultVendorName: userProfile?.isVendor ? name : undefined,
        },
      },
      refetchQueries: [
        {
          query: GET_USER_PROFILE,
          variables: { userId: userProfile?.userId },
        },
      ],
      awaitRefetchQueries: true,
    })
  }

  return (
    <div className="hc-pa-dense hc-pb-none">
      <Popover
        content={
          <List css={listScroll}>
            {autoValues.map((value) => (
              <List.Item
                key={value?.id}
                divider
                clickable
                selected={activeTeam?.id === value?.value}
                onClick={() =>
                  handleChange(
                    value?.value,
                    userProfile?.isVendor
                      ? value.defaultVendorName
                      : value?.label
                  )
                }
              >
                {value?.label}
              </List.Item>
            ))}
          </List>
        }
        location="right"
        as="div"
        className="hc-ma-none hc-pa-none"
        css={popoverPosition}
      >
        <Card elevation={1} corners="none" css={card} className="hc-pa-none">
          <GridContainer
            justify="center"
            align="center"
            spacing="none"
            className="hc-pa-dense"
          >
            <GridItem xs={2}>
              <EnterpriseIcon icon={GroupIcon} size="xl" />
            </GridItem>
            <GridItem xs={9} className="hc-ta-center">
              <Heading size={6}>
                {userProfile?.isVendor
                  ? `${activeTeam?.id} - ${activeTeam?.value}`
                  : `${activeTeam?.value}`}
              </Heading>
              <p className="hc-clr-grey02 hc-fs-caption">
                {userProfile?.isVendor
                  ? 'Switch between Accounts'
                  : 'Switch between Workflows'}
              </p>
            </GridItem>
            <GridItem xs={1}>
              <EnterpriseIcon icon={CaretRightIcon} />
            </GridItem>
          </GridContainer>
        </Card>
      </Popover>
    </div>
  )
}

export default ProfileSwitch
