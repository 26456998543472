import React from 'react'
import { CaseResponse } from './Queries'
import { EDIT_VENDOR_HEADING } from './Constants'
import { BottomBorderDiv } from './SynergyDivComponent'
import { CaseType } from '../Services/ConstantData'
import { Section, SectionItem } from './Section'

interface MainProp {
  vendorDetails: CaseResponse | undefined
}

const EditVendorDetails: React.FC<MainProp> = ({ vendorDetails }) => {
  const claimFiling = vendorDetails?.caseType === CaseType.INTERNALPROCESSING
  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={claimFiling ? 3 : 3}
        detail
        heading={EDIT_VENDOR_HEADING}
        expandable
      >
        <SectionItem label={claimFiling ? 'Vendor ID' : 'Merch Vendor ID'}>
          {vendorDetails?.vendorNumber}
        </SectionItem>
        <SectionItem label="Name">{vendorDetails?.vendorName}</SectionItem>
        <SectionItem label="Type">{vendorDetails?.vendorType}</SectionItem>
        {!claimFiling && (
          <SectionItem label="Contact">
            {vendorDetails?.vendorContactName
              ? vendorDetails?.vendorContactName
              : 'None'}
          </SectionItem>
        )}
        {!claimFiling && (
          <SectionItem label="Email">
            {vendorDetails?.vendorEmailAddress
              ? vendorDetails?.vendorEmailAddress
              : 'None'}
          </SectionItem>
        )}
        {vendorDetails?.bankName !== null && (
          <SectionItem label="Bank Name">
            {vendorDetails?.bankName ?? 'None'}
          </SectionItem>
        )}
      </Section>
    </BottomBorderDiv>
  )
}

export default EditVendorDetails
