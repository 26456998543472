import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { CaseResponse, UpdateCaseRequest } from '../../Common/Queries'
import { useProfile } from '../../../Layout/UserProfile/UserProfileProvider'
import { BulkUpdateCaseVar, BULK_UPDATE_CASE } from '../SearchQueries'
import { AssignCase, assignFilter } from './AssignCase'
import { ReopenCase, reopenFilter } from './ReopenCase'
import { ResolveCase, resolveFilter } from './ResolveCase'

interface CaseAssignProps {
  visible: boolean
  setVisible: any
  selectedCases: any
  updateCases: any
  actionType: string
}

export const BulkUpdateCases: React.FC<CaseAssignProps> = ({
  visible,
  setVisible,
  selectedCases,
  updateCases,
  actionType,
}) => {
  const [validSelectedCases, setValidSelectedCases] = useState()
  const { userProfile } = useProfile()

  const [updateDispute, { loading }] = useMutation<any, BulkUpdateCaseVar>(
    BULK_UPDATE_CASE,
    {
      onCompleted: (cases) => {
        if (cases?.bulkUpdateCases.length > 0) {
          updateCases([...cases.bulkUpdateCases])
        }
        setVisible(false)
      },
      onError: () => {
        setVisible(false)
      },
    }
  )

  useEffect(() => {
    const validUpdateCases = selectedCases
      .map((casee: CaseResponse) => casee)
      .filter((casee: CaseResponse) => {
        switch (actionType) {
          case 'assign': {
            return assignFilter(casee.status)
          }
          case 'reopen': {
            return reopenFilter(casee.status)
          }
          case 'resolve': {
            return resolveFilter(casee.status, casee.lanId)
          }
          default: {
            return true
          }
        }
      })
    setValidSelectedCases(validUpdateCases)
  }, [selectedCases, actionType])

  const onSave = (updateCaseRequests: UpdateCaseRequest[]) => {
    updateDispute({
      variables: {
        input: [...updateCaseRequests],
      },
    })
  }
  const getActionTypeView = () => {
    switch (actionType) {
      case 'assign': {
        return (
          <AssignCase
            userProfile={userProfile}
            validSelectedCases={validSelectedCases}
            onSave={onSave}
            loading={loading}
            setVisible={setVisible}
            visible={visible}
          />
        )
      }
      case 'reopen': {
        return (
          <ReopenCase
            validSelectedCases={validSelectedCases}
            allSelectedCases={selectedCases}
            onSave={onSave}
            loading={loading}
            setVisible={setVisible}
            visible={visible}
          />
        )
      }
      case 'resolve': {
        return (
          <ResolveCase
            validSelectedCases={validSelectedCases}
            onSave={onSave}
            loading={loading}
            setVisible={setVisible}
            visible={visible}
          />
        )
      }
      default:
        return null
    }
  }
  return <div>{getActionTypeView()}</div>
}
