import React from 'react'
import { CaseResponse } from './Queries'
import { EDIT_RECEIPT_HEADING } from './Constants'
import { CaseType } from '../Services/ConstantData'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Section, SectionItem } from './Section'
import { BottomBorderDiv } from '../Common/SynergyDivComponent'

interface MainProp {
  receiptDetails: CaseResponse | undefined
}

const EditRecieptDetails: React.FC<MainProp> = ({ receiptDetails }) => {
  const claimFiling = receiptDetails?.caseType === CaseType.INTERNALPROCESSING
  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={4}
        detail
        heading={EDIT_RECEIPT_HEADING}
        expandable
      >
        <SectionItem label="Receipt Number">
          {receiptDetails?.receipts?.join(', ')}
        </SectionItem>
        {claimFiling && (
          <SectionItem label="Ship Date">
            <DateFormatter
              //@ts-ignore
              date={receiptDetails?.claimDetails?.shipDate}
              format="L"
            />
          </SectionItem>
        )}
      </Section>
    </BottomBorderDiv>
  )
}

export default EditRecieptDetails
