import {
  Button,
  Card,
  GridContainer,
  GridItem,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { Gallery } from '@enterprise-ui/canvas-ui-react-gallery'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../../config/appConfig'
import { useAuth } from '@praxis/component-auth'
import axios from 'axios'
import { useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface ViewSearchAttachmentProps {
  attachmentId: string
  fileName: string
  documentSource: string
  documentId: string
  vendorId: string
}

export const ViewSearchAttachment: React.FC<ViewSearchAttachmentProps> = ({
  attachmentId,
  fileName,
  documentSource,
  documentId,
  vendorId,
}) => {
  const auth = useAuth()
  const env = useEnv() as EnvConfig
  const [file, setFile] = useState<{
    mediaType: string
    fileName: string
    fileUrl: string
  }>({ mediaType: '', fileName: '', fileUrl: '' })
  const [viewableItem, SetViewableitem] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios
      .get(
        `${env.attachments.attachmentServiceUrl}files/${
          attachmentId ?? documentId
        }?document_source=${documentSource}&vendor_id=${vendorId}&document_id=${documentId}`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-api-key': `${env?.apiKey}`,
            Authorization: `Bearer ${auth?.session?.accessToken}`,
          },
        }
      )
      .then((res: any) => {
        if (res.status === 200) {
          let blob
          let fileType = fileName.slice(
            fileName.lastIndexOf('.') + 1,
            fileName.length
          )
          if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
            blob = new Blob([res.data], { type: 'image/png' })

            setFile({
              mediaType: '',
              fileName: fileName,
              fileUrl: window.URL.createObjectURL(blob),
            })
            SetViewableitem(true)
          } else if (fileType === 'pdf') {
            blob = new Blob([res.data], { type: 'application/pdf' })
            setFile({
              mediaType: 'PDF',
              fileName: fileName,
              fileUrl: window.URL.createObjectURL(blob),
            })
            SetViewableitem(true)
          } else {
            blob = new Blob([res.data], { type: 'application/text' })
            setFile({
              mediaType: 'PDF',
              fileName: fileName,
              fileUrl: window.URL.createObjectURL(blob),
            })
            SetViewableitem(false)
          }
        }
        setLoading(false)
      })
      .catch((reason: any) => {
        SetViewableitem(false)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownload = async () => {
    let blob = await fetch(file.fileUrl).then((r) => r.blob())
    fileDownload(blob, file.fileName)
  }

  return (
    <div className="hc-pa-normal">
      <GridContainer>
        <GridItem xs={12}>
          {viewableItem && (
            <Gallery
              onDownload={() => handleDownload()}
              on
              className="hc-bg-white hc-ma-lg"
              mainItemHeight="70vh"
              items={[
                {
                  mediaType: file?.mediaType,
                  name: file?.fileName,
                  url: file?.fileUrl,
                },
              ]}
            />
          )}

          {loading && (
            <BaseCard>
              <Spinner></Spinner>
            </BaseCard>
          )}

          {!loading && !viewableItem && (
            <BaseCard>
              <p>
                The file format is not viewable. Please Download &nbsp;
                <Button
                  xs={1}
                  onClick={() => {
                    handleDownload()
                  }}
                >
                  <EnterpriseIcon icon={DownloadIcon} />
                </Button>
              </p>
            </BaseCard>
          )}
        </GridItem>
      </GridContainer>
    </div>
  )
}

interface Props {
  children: React.ReactNode
}

const BaseCard: React.FC<Props> = (props) => {
  return (
    <Card
      className="hc-pa-normal"
      align="center"
      justify="center"
      elevation={5}
    >
      <div className="hc-pa-normal hc-ta-center">{props.children}</div>
    </Card>
  )
}
