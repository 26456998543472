import React from 'react'
import { DynamicFilter } from '../ReportBaseLayout'

export interface ReportContextValue {
  id: string
  report?: Report
  loading: boolean
  partitions: number
}

export interface Filter {
  filterName: string
  filterValues: string[]
}
export interface Report {
  id: string
  reportName: string
  sideFilters: SideFilter[]
  dynamicFilter?: DynamicFilter
  ageingWindow?: string
  ageingField?: string
  consolidateType?: string
  dateFilter: DateFilter
}

export interface DateFilter {
  id: string
  options: Value[]
  active?: DateRange
}
export interface SideFilter {
  id: string
  displayName: string
  type: 'checkbox' | 'radio'
  source: 'master' | 'aggregation' | 'dynamic'
  startExpanded?: boolean
  values?: Value[]
}

export interface Value {
  id: string
  label: string
  value: string | DateRange
  isChecked?: boolean
}
export interface DateRange {
  from: string
  to: string
}

const ReportStateContext = React.createContext<any>(undefined)

ReportStateContext.displayName = 'NewReportStateContext'

const ReportDispatchContext = React.createContext<any>(undefined)

ReportDispatchContext.displayName = 'NewReportDispatchContext'

export { ReportStateContext, ReportDispatchContext }
