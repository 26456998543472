import React, { useEffect, useState } from 'react'
import { Grid, Modal, Button, List } from '@enterprise-ui/canvas-ui-react'
import _ from 'lodash'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'

interface MainProp {
  headingText: String
  supportingDocument: any
}

const DocSupportingModal: React.FC<MainProp> = ({
  headingText,
  supportingDocument,
}) => {
  const [supportingDocumentList, setSupportingDocumentList] = useState<any>([])
  useEffect(() => {
    if (supportingDocument) {
      setSupportingDocumentList(_.split(supportingDocument, ','))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportingDocument])

  const [state, dispatch] = useGlobalForm()
  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.docSupportingFlag}
        onRefuse={() =>
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'docSupportingFlag', value: false }],
          })
        }
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={12}>
                  <List>
                    {supportingDocumentList?.map(
                      (supportingDocList: any, index: number) => (
                        <List.Item divider key={index.toString()}>
                          {supportingDocList}
                        </List.Item>
                      )
                    )}
                  </List>
                </Grid.Item>
              </Grid.Container>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    onClick={() => {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'docSupportingFlag', value: false }],
                      })
                    }}
                  >
                    Ok
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}

export default DocSupportingModal
