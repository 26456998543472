import React, { useState, useEffect } from 'react'
import {
  Grid,
  Modal,
  Button,
  Form,
  Heading,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { GET_RESOLUTIONNOTESMAP, CaseResponse } from './Queries'
import { findResolutionNotesValues } from '../Services/ResolutionNotesMapping'
import { useQuery } from '@apollo/client'
import { FormikValues } from 'formik'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useAuth } from '@praxis/component-auth'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CaseStatus, CaseType } from '../Services/ConstantData'
import { activityData } from '../Services/saveActivity'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from './Constants'
import { twoDecimals } from '../Services/TwoDecimalPlaces'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import _ from 'lodash'

const button = css`
  left: 10px;
`
const summaryLabel = css`
  font-weight: bold;
`
const summaryValue = css`
  font-weight: 500;
`
const settlementHeight = css`
  C-FormField__FieldInfo {
    min-height: 0px;
  }
`

interface MainProp {
  headingText: String
  editResolutionDetails: CaseResponse | undefined
  formik: FormikValues
  saveStatus: any
  resolutionCodeValues: any
  assignedUserValues: any
}

const EditResolutionModal: React.FC<MainProp> = ({
  headingText,
  editResolutionDetails,
  formik,
  resolutionCodeValues,
  saveStatus,
  assignedUserValues,
}) => {
  const { session } = useAuth()
  const userName = session?.userInfo?.fullName ?? ''
  const [currentResolutionCode, setCurrentResolutionCode] = useState<any>()
  const [resolutionMappingText, setResolutionMappingText] = useState<any>()
  const [refDocNumber, setRefDocNumber] = useState<string | null>('')
  const [newDocNumber, setNewDocNumber] = useState<string | null>('')
  const [resCode, setResCode] = useState<string | null>('')
  const [resCodeText, setResCodeText] = useState<string | null>('')
  const [resText, setResText] = useState<string | null>('')
  const [refDocDate, setRefDocDate] = useState<string | null | undefined>(
    undefined
  )
  const [resolutionAmount, setResolutionAmt] = useState<number | undefined>()
  const [resSummaryFlag, setresSummaryFlag] = useState<boolean>(false)
  const [resInputFlag, setResInputFlag] = useState<boolean>(true)
  const [resolutionNoteMapping, setResolutionNoteMapping] = useState<any>([])
  const [countNextClick, setCountNextClick] = useState(2)
  const { data: resolutionnotemapvalues } = useQuery(GET_RESOLUTIONNOTESMAP, {
    fetchPolicy: 'network-only',
  })
  const [state, dispatch] = useGlobalForm()
  const resolveData = formik?.values
  const activityDescription = `${'Changed the Case Status to '}${
    CaseStatus.RESOLVED
  }`
  const { userProfile } = useProfile()
  function validateFields(errors: any, fields: string[]): boolean {
    const keys = _.keysIn(errors)
    return !fields.some((el) => keys.includes(el))
  }
  const handleAlert = async (e: any) => {
    e.preventDefault()
    await dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'documentAlert', value: true },
        { id: 'alertComponent', value: CaseStatus.RESOLVED },
      ],
    })
  }
  useEffect(() => {
    if (state.createCase) {
      saveActivity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.createCase])
  useEffect(() => {
    if (state.resolutionFlag) {
      setResolutionAmt(state.resAmount)
      setRefDocNumber(
        resolveData.claimDetails?.referenceDocumentNumber === null ||
          resolveData.claimDetails?.referenceDocumentNumber === undefined
          ? ''
          : resolveData.claimDetails?.referenceDocumentNumber
      )
      setRefDocDate(
        resolveData.claimDetails?.referenceDocumentCreateDate === null ||
          resolveData.claimDetails?.referenceDocumentCreateDate === undefined
          ? ''
          : resolveData.claimDetails?.referenceDocumentCreateDate
      )
      setNewDocNumber('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.resolutionFlag])

  function saveActivity() {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'partialResolution', value: '' }],
    })
    let resolutionAmountValue = resolutionAmount ? resolutionAmount : 0
    formik.setFieldValue('resolutionDate', new Date().toISOString())
    formik.setFieldValue('claimDetails.referenceDocumentNumber', refDocNumber)
    formik.setFieldValue(
      'claimDetails.referenceDocumentCreateDate',
      refDocDate ? refDocDate : null
    )
    formik.setFieldValue('resolutionAmount', resolutionAmount)
    formik.setFieldValue('disputeDetails.newDocumentNumber', newDocNumber)
    formik.setFieldValue('resolutionCode', resCode)
    formik.setFieldValue('resolutionCodeText', resCodeText)
    formik.setFieldValue('resolutionText', resText)
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    if (
      editResolutionDetails?.caseType === CaseType.DISPUTE &&
      resolutionAmountValue > 0
    ) {
      formik.setFieldValue('status', CaseStatus.RESOLVED)
    } else {
      formik.setFieldValue('status', CaseStatus.CLOSED)
    }
    clearValues()
    saveStatus()
  }

  let docNumber = ''
  let docAmount = ''
  if (editResolutionDetails?.caseType === CaseType.INTERNALPROCESSING) {
    docNumber = 'Claim / Deduction #'
    docAmount = 'Claim/Deduction($)'
  } else {
    docNumber = 'New Document  #'
    docAmount = 'Resolution ( $ )'
  }
  useEffect(() => {
    if (resolveData.claimDetails?.referenceDocumentNumber) {
      setRefDocNumber(resolveData.claimDetails?.referenceDocumentNumber)
    }
    if (resolveData.claimDetails?.referenceDocumentCreateDate) {
      setRefDocDate(resolveData.claimDetails?.referenceDocumentCreateDate)
    }
    if (resolveData?.resolutionAmount) {
      setResolutionAmt(resolveData?.resolutionAmount)
    }
    if (resolveData?.disputeDetails?.newDocumentNumber) {
      setNewDocNumber(resolveData.disputeDetails?.newDocumentNumber)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    resolveData.claimDetails?.referenceDocumentNumber,
    resolveData.disputeDetails?.newDocumentNumber,
    resolveData.claimDetails?.referenceDocumentCreateDate,
    resolveData?.resolutionAmount,
  ])
  function clearValues() {
    formik.setErrors({})
    setRefDocNumber(
      resolveData.claimDetails?.referenceDocumentNumber
        ? resolveData.claimDetails?.referenceDocumentNumber
        : ''
    )
    setRefDocDate(
      resolveData.claimDetails?.referenceDocumentCreateDate
        ? resolveData.claimDetails?.referenceDocumentCreateDate
        : ''
    )
    setResolutionAmt(state.resAmount ? state.resAmount : undefined)
    setNewDocNumber(
      resolveData.disputeDetails?.newDocumentNumber
        ? resolveData.disputeDetails?.newDocumentNumber
        : ''
    )
    setResText('')
    setResCodeText('')
    setCurrentResolutionCode(undefined)
    setResolutionMappingText(undefined)
    setResInputFlag(true)
    setresSummaryFlag(false)
  }

  const onUserResolve = () => {
    formik.setFieldValue('lanId', userProfile?.userId)
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'resCodeCheck',
          value: true,
        },
        {
          id: 'name',
          value: assignedUserValues.find(
            (assignedUserValues: any) =>
              assignedUserValues?.id === userProfile?.userId
          ).label,
        },
      ],
    })
  }
  useEffect(() => {
    if (state.resolutionFlag) {
      formik.setFieldValue('resolutionAmount', resolutionAmount)
    }
  }, [resolutionAmount])

  useEffect(() => {
    formik.setFieldValue('resolutionCode', resCode)
  }, [resCode])

  useEffect(() => {
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'partialResolution',
          value: resText ? resText : '',
        },
      ],
    })
  }, [resText])

  return (
    <React.Fragment>
      <Modal
        headingText={headingText}
        isVisible={state.resolutionFlag}
        onRefuse={() => {
          clearValues()
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'resolutionFlag', value: false },
              { id: 'resCodeCheck', value: false },
              { id: 'partialResolution', value: '' },
            ],
          })
        }}
      >
        <div className="hc-pa-normal">
          {resInputFlag && !resSummaryFlag && (
            <Grid.Item xs={12} className="hc-pa-md">
              <Grid.Container>
                {editResolutionDetails?.caseType ===
                  CaseType.INTERNALPROCESSING && (
                  <Grid.Item xs={4} className="hc-pb-none">
                    <Form.Field
                      id="claimDetails.referenceDocumentNumber"
                      label={docNumber}
                      type="text"
                      maxLength="22"
                      onChange={(event: any) => {
                        setRefDocNumber(event.target.value)
                      }}
                      className="inputtype hc-ta-left"
                      value={refDocNumber}
                    />
                  </Grid.Item>
                )}
                {editResolutionDetails?.caseType === CaseType.DISPUTE && (
                  <Grid.Item xs={4} className="hc-pb-none">
                    <Form.Field
                      id="disputeDetails.newDocumentNumber"
                      label={docNumber}
                      type="text"
                      maxLength="22"
                      onChange={(event: any) => {
                        setNewDocNumber(event.target.value)
                      }}
                      className="inputtype hc-ta-left"
                      value={newDocNumber}
                    />
                  </Grid.Item>
                )}
                {editResolutionDetails?.caseType ===
                  CaseType.INTERNALPROCESSING && (
                  <Grid.Item xs={4} className="hc-pb-none">
                    <Form.Field
                      id="claimDetails.referenceDocumentCreateDate"
                      label="Claim / Deduction Filed date"
                      type="date"
                      onChange={(event: any) => {
                        setRefDocDate(event.target.value)
                      }}
                      className="inputtype hc-ta-left"
                      value={refDocDate}
                    />
                  </Grid.Item>
                )}
                {editResolutionDetails?.caseType !==
                  CaseType.REQUESTFORINFO && (
                  <Grid.Item xs={4} className="hc-pb-none">
                    <Form.Field
                      id="resolutionAmount"
                      label={docAmount}
                      type="number"
                      onChange={(event: any) => {
                        setResolutionAmt(
                          Number(twoDecimals(event.target.value))
                        )
                      }}
                      className="inputtype hc-ta-left"
                      value={resolutionAmount || ''}
                      error={formik.errors.resolutionAmount !== undefined}
                      errorText={formik.errors.resolutionAmount}
                    />
                  </Grid.Item>
                )}
              </Grid.Container>
              <Grid.Item xs={12} className="hc-pb-none">
                <Autocomplete
                  id="resolutionCode"
                  options={resolutionCodeValues}
                  label="Settlement / Resolution Explanation"
                  required={true}
                  onUpdate={(id, value) => {
                    formik.setFieldValue('resolutionCode', value?.value)
                    setResCode(value?.value)
                    setResCodeText(value?.label)
                    setCurrentResolutionCode(
                      resolutionCodeValues.find(
                        (resolutionCodeValues: any) =>
                          resolutionCodeValues?.id === value?.value
                      )
                    )
                    setResolutionNoteMapping(
                      findResolutionNotesValues(
                        resolutionnotemapvalues?.getResolutionNotesMap,
                        editResolutionDetails,
                        resCode
                      )
                    )
                  }}
                  error={formik.errors.resolutionCode !== undefined}
                  errorText={formik.errors.resolutionCode}
                  value={currentResolutionCode}
                />
              </Grid.Item>
              <Grid.Item xs={12} className="hc-pb-none">
                <Autocomplete
                  css={settlementHeight}
                  id="resoulutionNoteText"
                  options={resolutionNoteMapping}
                  label="Select a Settlement Note from the list"
                  onUpdate={(id, value) => {
                    setResolutionMappingText(
                      resolutionNoteMapping.find(
                        (resolutionNoteValues: any) =>
                          resolutionNoteValues?.id === value?.value
                      )
                    )
                    setResText(value?.label ? value?.label : '')
                  }}
                  value={resolutionMappingText}
                />
              </Grid.Item>
              <Grid.Item xs={12} className="hc-pb-none">
                <Form.Field
                  id="resolutionText"
                  label="Settlement Note"
                  type="textarea"
                  onChange={(e: any) => {
                    setResText(e.target.value)
                  }}
                  value={resText}
                  maxLength="500"
                  error={formik.errors.resolutionText !== undefined}
                  errorText={formik.errors.resolutionText}
                />
              </Grid.Item>
            </Grid.Item>
          )}
          {!resInputFlag && resSummaryFlag && (
            <Grid.Item xs={12} className="hc-pa-md">
              <Grid.Container>
                <div className="hc-pa-normal">
                  <Grid.Container>
                    <Grid.Item>
                      <Heading size={5}>
                        Please review the below resolution summary before
                        continue
                      </Heading>
                    </Grid.Item>
                  </Grid.Container>
                  {editResolutionDetails?.caseType !==
                    CaseType.REQUESTFORINFO && (
                    <Grid.Container>
                      {editResolutionDetails?.caseType ===
                      CaseType.INTERNALPROCESSING ? (
                        <Grid.Item xs={4}>
                          <Input.Label css={summaryLabel}>
                            Claim ( $ ):
                          </Input.Label>
                        </Grid.Item>
                      ) : (
                        <Grid.Item xs={4}>
                          <Input.Label css={summaryLabel}>
                            Original Document ( $ ):
                          </Input.Label>
                        </Grid.Item>
                      )}
                      <Grid.Item xs={2} css={summaryValue}>
                        <Input.Label>
                          {editResolutionDetails?.disputedDocumentAmount}
                        </Input.Label>
                      </Grid.Item>
                    </Grid.Container>
                  )}
                  {editResolutionDetails?.caseType ===
                    CaseType.INTERNALPROCESSING && (
                    <Grid.Container>
                      <Grid.Item xs={4} css={summaryLabel}>
                        <Input.Label>Claim / Deduction #:</Input.Label>
                      </Grid.Item>
                      <Grid.Item xs={2} css={summaryValue}>
                        <Input.Label>{refDocNumber}</Input.Label>
                      </Grid.Item>
                    </Grid.Container>
                  )}
                  {editResolutionDetails?.caseType === CaseType.DISPUTE && (
                    <Grid.Container>
                      <Grid.Item xs={4} css={summaryLabel}>
                        <Input.Label>New Document #:</Input.Label>
                      </Grid.Item>
                      <Grid.Item xs={2} css={summaryValue}>
                        <Input.Label>{newDocNumber}</Input.Label>
                      </Grid.Item>
                    </Grid.Container>
                  )}
                  <Grid.Container>
                    <Grid.Item xs={4} css={summaryLabel}>
                      <Input.Label>
                        Settlement / Resolution Explanation:
                      </Input.Label>
                    </Grid.Item>
                    <Grid.Item xs={5} css={summaryValue}>
                      <Input.Label>{resCodeText}</Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                  {editResolutionDetails?.caseType ===
                    CaseType.INTERNALPROCESSING && (
                    <Grid.Container>
                      <Grid.Item xs={4} css={summaryLabel}>
                        <Input.Label>Claim / Deduction Filed date:</Input.Label>
                      </Grid.Item>
                      <Grid.Item xs={5} css={summaryValue}>
                        <Input.Label>{refDocDate}</Input.Label>
                      </Grid.Item>
                    </Grid.Container>
                  )}
                  {editResolutionDetails?.caseType !==
                    CaseType.REQUESTFORINFO && (
                    <Grid.Container>
                      <Grid.Item xs={4} css={summaryLabel}>
                        {editResolutionDetails?.caseType ===
                        CaseType.INTERNALPROCESSING ? (
                          <Input.Label>Claim / Deduction ( $ )</Input.Label>
                        ) : (
                          <Input.Label>Resolution ( $ ):</Input.Label>
                        )}
                      </Grid.Item>
                      <Grid.Item xs={3} css={summaryValue}>
                        <Input.Label>{resolutionAmount}</Input.Label>
                      </Grid.Item>
                    </Grid.Container>
                  )}
                  <Grid.Container>
                    <Grid.Item xs={4} css={summaryLabel}>
                      <Input.Label>Settlement Note:</Input.Label>
                    </Grid.Item>
                    <Grid.Item xs={5} css={summaryValue}>
                      <Input.Label>{resText}</Input.Label>
                    </Grid.Item>
                  </Grid.Container>
                </div>
              </Grid.Container>
            </Grid.Item>
          )}
          <Grid.Item className="hc-pa-md">
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item>
                {!resInputFlag && resSummaryFlag && (
                  <Button
                    type="primary"
                    onClick={(e: any) => {
                      handleAlert(e)
                    }}
                  >
                    Save
                  </Button>
                )}
                {resInputFlag && !resSummaryFlag && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setCountNextClick(countNextClick + 1)
                      formik.validateForm().then((res: any) => {
                        const fields = [
                          'resolutionCode',
                          'resolutionAmount',
                          'resolutionText',
                        ]
                        if (validateFields(res, fields)) {
                          setResInputFlag(false)
                          setresSummaryFlag(true)
                          formik.setErrors({})
                        } else {
                          formik.setErrors({
                            resolutionCode: res.resolutionCode,
                            resolutionAmount: res.resolutionAmount,
                            resolutionText: res.resolutionText,
                          })
                        }
                      })
                      onUserResolve()
                    }}
                  >
                    Next
                  </Button>
                )}
                {!resInputFlag && resSummaryFlag && (
                  <Button
                    css={button}
                    type="secondary"
                    onClick={() => {
                      setResInputFlag(true)
                      setresSummaryFlag(false)
                    }}
                  >
                    Back
                  </Button>
                )}
              </Grid.Item>
              <Grid.Item>
                <Button
                  onClick={() => {
                    clearValues()
                    setResCode('')
                    dispatch({
                      type: SET_FIELD,
                      payload: [
                        { id: 'resolutionFlag', value: false },
                        { id: 'resCodeCheck', value: false },
                        { id: 'partialResolution', value: '' },
                      ],
                    })
                  }}
                >
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default EditResolutionModal
