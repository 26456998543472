import React from 'react'
import {
  Button,
  Card,
  Grid,
  Heading,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import { TargetLogo } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import '../../App.css'
import { handleLogout } from './AuthUtils'

const Authentication: React.FC = () => {
  const auth = useAuth()
  const { isAuthenticated, login } = auth

  return (
    <Layout.Body>
      {login()}
      <Grid.Container
        className="praxcss-height100vh"
        align="center"
        justify="center"
      >
        <Grid.Item xs={10} md={6} lg={4}>
          <Card className="hc-pa-md">
            <div className="hc-pa-normal hc-ta-center">
              <Heading>
                <TargetLogo size="inline" /> S Y N E R G Y
              </Heading>
            </div>
            <div className="">
              {!isAuthenticated() ? (
                <Button
                  type="primary"
                  size="expanded"
                  fullWidth
                  onClick={login}
                  // className="hc-fs-body"
                >
                  Log In
                </Button>
              ) : (
                <Button
                  type="primary"
                  size="expanded"
                  fullWidth
                  onClick={() => handleLogout(auth)}
                >
                  Logout
                </Button>
              )}
            </div>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}

export default Authentication
