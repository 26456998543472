import React from 'react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { Grid, Modal, Heading } from '@enterprise-ui/canvas-ui-react'
import { SET_FIELD } from './Constants'

interface MainProp {
  heading: String
  headingText: String
  invalidReceipts: []
}

const InvalidReceiptsModal: React.FC<MainProp> = ({
  headingText,
  heading,
  invalidReceipts,
}) => {
  const [state, dispatch] = useGlobalForm()
  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.invalidReceiptsFlag}
        onRefuse={() => {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'invalidReceiptsFlag', value: false }],
          })
        }}
      >
        <div className="hc-pa-normal">
          <Grid.Container className="hc-pl-sm hc-pb-sm">
            <Grid.Item xs={12} className="hc-pb-none">
              <Heading size={7}>{heading}</Heading>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Heading size={7}>{invalidReceipts.join(',')}</Heading>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </div>
  )
}
export default InvalidReceiptsModal
