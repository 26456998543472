import React, { useState } from 'react'
import {
  Table,
  Grid,
  Input,
  Popover,
  GridContainer,
  Pagination,
} from '@enterprise-ui/canvas-ui-react'
import { useQuery } from '@apollo/client'
import {
  AggregateVariable,
  GET_AGING_DETAILS,
  GET_SEARCH_RESULTS,
  GET_STATUS_DETAILS,
  GetAging,
  GetSearchResults,
  GetStatuses,
  SearchVariable,
} from './Queries'
import { useProfile } from '../Layout/UserProfile/UserProfileProvider'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { BorderDiv } from '../Cases/Common/SynergyDivComponent'
import { useNavigate } from 'react-router'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../config/appConfig'
import { CaseStatus, CaseType } from '../Cases/Services/ConstantData'
import moment from 'moment'

const BPDashboard = () => {
  const { userProfile } = useProfile()
  const navigate = useNavigate()
  const env = useEnv() as EnvConfig

  const { data: agingDetails } = useQuery<GetAging, AggregateVariable>(
    GET_AGING_DETAILS,
    {
      variables: {
        query: `status: ((${CaseStatus.NEW}) OR (${CaseStatus.INPROGRESS}) OR (${CaseStatus.REOPEN}) OR (${CaseStatus.AWAITINGINFO}))`,
        vendorNumber: userProfile?.defaultVendorId?.toString(),
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data: statusDetails } = useQuery<GetStatuses, AggregateVariable>(
    GET_STATUS_DETAILS,
    {
      variables: {
        summaryDays: env.dashboard.summaryDays,
        query: `case_type: ((${
          CaseType.DISPUTE
        }) OR (${CaseType.REQUESTFORINFO.replace('/', '\\/')}))`,
        vendorNumber: userProfile?.defaultVendorId?.toString(),
      },
      fetchPolicy: 'network-only',
    }
  )

  const [searchPage, setSearchPage] = useState<number>(1)

  const { data: searchResults } = useQuery<GetSearchResults, SearchVariable>(
    GET_SEARCH_RESULTS,
    {
      variables: {
        query: `status: (${CaseStatus.AWAITINGINFO} ) AND case_type: (${CaseType.DISPUTE})`,
        vendorId: userProfile?.defaultVendorId?.toString(),
        sort: 'update_timestamp',
        page: searchPage,
        perPage: 20,
      },
      fetchPolicy: 'network-only',
    }
  )

  return (
    <Grid.Container justify="space-between" className="hc-pa-none">
      {/*Summary of cases by age*/}
      <Grid.Item xs={12} sm={12} md={12} xl={6} lg={6}>
        <BorderDiv className="hc-ma-normal hc-pa-normal">
          <Input.Label className="hc-fs-section">
            Aging View Of Open Cases
          </Input.Label>
          <Table width="500px">
            <Table.Head>
              <Table.Row>
                <Table.Header xs={3}>Type</Table.Header>
                <Table.Header xs>Less than 7 days</Table.Header>
                <Table.Header xs>Less than 14 days</Table.Header>
                <Table.Header xs>Less than 21 days</Table.Header>
                <Table.Header xs>More than 21 days</Table.Header>
                <Table.Header xs>Total</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {agingDetails?.getAggregatedAge
                ?.filter(function (status: any) {
                  return status?.caseSubType !== 'Claim'
                })
                ?.map((ageDetails: any, index: number) => (
                  <Table.Row clickable key={ageDetails['caseSubType']}>
                    <Table.Data xs={3}>{ageDetails['caseSubType']}</Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let lessThanSeven = `((case_sub_type:(${ageDetails['caseSubType']})) AND aging_date: [now-7d TO now] AND status: ((${CaseStatus.NEW}) OR (${CaseStatus.INPROGRESS}) OR (${CaseStatus.AWAITINGINFO}) OR (${CaseStatus.REOPEN})))`
                        navigate(`/cases?search%5B0%5D=${lessThanSeven}`)
                      }}
                    >
                      {
                        ageDetails['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Less than 7 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let lessThanFourteen = `((case_sub_type:(${
                          ageDetails['caseSubType']
                        })) AND aging_date: [${moment(new Date())
                          .subtract(15, 'day')
                          .format('YYYY-MM-DD')} TO ${moment(new Date()).format(
                          'YYYY-MM-DD'
                        )}] AND status: ((${CaseStatus.NEW}) OR (${
                          CaseStatus.INPROGRESS
                        }) OR (${CaseStatus.AWAITINGINFO}) OR (${
                          CaseStatus.REOPEN
                        })))`
                        navigate(`/cases?search%5B0%5D=${lessThanFourteen}`)
                      }}
                    >
                      {
                        ageDetails['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Less than 14 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let lessThan21 = `((case_sub_type:(${
                          ageDetails['caseSubType']
                        })) AND aging_date: [${moment(new Date())
                          .subtract(21, 'day')
                          .format('YYYY-MM-DD')} TO ${moment(new Date()).format(
                          'YYYY-MM-DD'
                        )}] AND status:((${CaseStatus.NEW}) OR (${
                          CaseStatus.INPROGRESS
                        }) OR (${CaseStatus.AWAITINGINFO}) OR (${
                          CaseStatus.REOPEN
                        })))`
                        navigate(`/cases?search%5B0%5D=${lessThan21}`)
                      }}
                    >
                      {
                        ageDetails['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Less than 21 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let moreThan21 = `((case_sub_type:(${
                          ageDetails['caseSubType']
                        })) AND aging_date: [${moment(new Date())
                          .subtract(2, 'year')
                          .format('YYYY-MM-DD')} TO ${moment(new Date())
                          .subtract(21, 'day')
                          .format('YYYY-MM-DD')}] AND status: ((${
                          CaseStatus.NEW
                        }) OR (${CaseStatus.INPROGRESS}) OR (${
                          CaseStatus.AWAITINGINFO
                        }) OR (${CaseStatus.REOPEN})))`
                        navigate(`/cases?search%5B0%5D=${moreThan21}`)
                      }}
                    >
                      {
                        ageDetails['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Older than 21 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data xs>{ageDetails['count']}</Table.Data>
                  </Table.Row>
                ))}
              {/*Total of columns*/}
              <Table.Row key="total">
                <Table.Data xs={3}>Total</Table.Data>
                <Table.Data xs>
                  {agingDetails?.getAggregatedAge
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Less than 7 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.getAggregatedAge
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Less than 14 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.getAggregatedAge
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Less than 21 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.getAggregatedAge
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Older than 21 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.getAggregatedAge
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </BorderDiv>
      </Grid.Item>

      {/*Summary of cases by status*/}
      <Grid.Item xs={12} sm={12} md={12} xl={6} lg={6}>
        <BorderDiv className="hc-ma-normal hc-pa-normal">
          <Input.Label className="hc-fs-section">
            Summary of Cases in last {env.dashboard.summaryDays} Days
          </Input.Label>
          <Table width="650px">
            <Table.Head>
              <Table.Row className="hc-pb-normal">
                <Table.Header xs={2}>Type</Table.Header>
                <Table.Header xs={1} className="hc-pa-dense">
                  New
                </Table.Header>
                <Table.Header xs>In Progress</Table.Header>
                <Table.Header xs>Reopen</Table.Header>
                <Table.Header xs>Resolved</Table.Header>
                <Table.Header xs>Close</Table.Header>
                <Table.Header xs>Total</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {statusDetails?.getAggregatedStatus
                ?.filter(function (status: any) {
                  return status?.caseSubType !== 'Claim'
                })
                ?.map((statusDetails: any, index: number) => (
                  <Table.Row clickable key={statusDetails['caseSubType']}>
                    <Table.Data xs={2}>
                      {statusDetails['caseSubType']}
                    </Table.Data>
                    <Table.Data
                      xs={1}
                      onClick={() => {
                        let statusNew = encodeURI(
                          `search[0]=(case_sub_type:(${statusDetails['caseSubType']}))&` +
                            'workflow[0]=Default&' +
                            'status[0]=New&' +
                            `create_timestamp[from]=${moment(new Date())
                              .subtract(145, 'day')
                              .format('YYYY-MM-DD')}&` +
                            `create_timestamp[to]=${moment(new Date()).format(
                              'YYYY-MM-DD'
                            )}`
                        )
                        navigate(`cases?${statusNew}`)
                      }}
                    >
                      {statusDetails['statuses']?.filter(function (
                        status: any
                      ) {
                        return status['status'] === CaseStatus.NEW
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusInProgress = encodeURI(
                          `search[0]=(case_sub_type:(${statusDetails['caseSubType']}))&` +
                            'workflow[0]=Default&' +
                            'status[0]=InProgress&' +
                            `create_timestamp[from]=${moment(new Date())
                              .subtract(145, 'day')
                              .format('YYYY-MM-DD')}&` +
                            `create_timestamp[to]=${moment(new Date()).format(
                              'YYYY-MM-DD'
                            )}`
                        )
                        navigate(`cases?${statusInProgress}`)
                      }}
                    >
                      {statusDetails['statuses']?.filter(function (
                        status: any
                      ) {
                        return status['status'] === CaseStatus.INPROGRESS
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusReOpened = encodeURI(
                          `search[0]=(case_sub_type:(${statusDetails['caseSubType']}))&` +
                            'workflow[0]=Default&' +
                            'status[0]=ReOpen&' +
                            `create_timestamp[from]=${moment(new Date())
                              .subtract(145, 'day')
                              .format('YYYY-MM-DD')}&` +
                            `create_timestamp[to]=${moment(new Date()).format(
                              'YYYY-MM-DD'
                            )}`
                        )
                        navigate(`cases?${statusReOpened}`)
                      }}
                    >
                      {statusDetails['statuses']?.filter(function (
                        status: any
                      ) {
                        return status['status'] === CaseStatus.REOPEN
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusResolved = encodeURI(
                          `search[0]=(case_sub_type:(${statusDetails['caseSubType']}))&` +
                            'workflow[0]=Default&' +
                            'status[0]=Resolved&' +
                            `create_timestamp[from]=${moment(new Date())
                              .subtract(145, 'day')
                              .format('YYYY-MM-DD')}&` +
                            `create_timestamp[to]=${moment(new Date()).format(
                              'YYYY-MM-DD'
                            )}`
                        )
                        navigate(`cases?${statusResolved}`)
                      }}
                    >
                      {statusDetails['statuses']?.filter(function (
                        status: any
                      ) {
                        return status['status'] === CaseStatus.RESOLVED
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusClosed = encodeURI(
                          `search[0]=(case_sub_type:(${statusDetails['caseSubType']}))&` +
                            'workflow[0]=Default&' +
                            'status[0]=Closed&' +
                            `create_timestamp[from]=${moment(new Date())
                              .subtract(145, 'day')
                              .format('YYYY-MM-DD')}&` +
                            `create_timestamp[to]=${moment(new Date()).format(
                              'YYYY-MM-DD'
                            )}`
                        )
                        navigate(`cases?${statusClosed}`)
                      }}
                    >
                      {statusDetails['statuses']?.filter(function (
                        status: any
                      ) {
                        return status['status'] === CaseStatus.CLOSED
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data xs>{statusDetails['count']}</Table.Data>
                  </Table.Row>
                ))}

              {/*Total of columns*/}
              <Table.Row key="total">
                <Table.Data xs={2}>Total</Table.Data>
                <Table.Data xs={1}>
                  {statusDetails?.getAggregatedStatus
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.NEW
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.getAggregatedStatus
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.INPROGRESS
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.getAggregatedStatus
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.REOPEN
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.getAggregatedStatus
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.RESOLVED
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.getAggregatedStatus
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.CLOSED
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.getAggregatedStatus
                    ?.filter(function (status: any) {
                      return status?.caseSubType !== 'Claim'
                    })
                    ?.reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </BorderDiv>
      </Grid.Item>

      {/*Cases awaiting user information*/}
      <Grid.Item xs={12} sm={12} md={12} xl={6} lg={6}>
        <BorderDiv className="hc-ma-normal hc-pa-normal">
          <Input.Label
            className="hc-fs-section"
            rightContent={`Total: ${searchResults?.getSearchResults?.paging?.totalResults}`}
          >
            {' '}
            Actions / To Do
          </Input.Label>
          <Table width="600px">
            <Table.Head>
              <Table.Row>
                <Table.Header xs={2}>Case Number</Table.Header>
                <Table.Header xs>Created On</Table.Header>
                <Table.Header xs>Days Elapsed Since Request</Table.Header>
                <Table.Header xs>Last Comment</Table.Header>
                <Table.Header xs>Remaining Days</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchResults?.getSearchResults?.cases?.map(
                (cases: any, index: number) => (
                  <Table.Row
                    justify="space-around"
                    clickable
                    key={cases['caseId']}
                    onClick={() => navigate(`/cases/${cases['caseId']}`)}
                    className={
                      env.dashboard.autoCloseDays -
                        Math.ceil(
                          Math.abs(
                            new Date().getTime() -
                              new Date(cases['updateTimestamp']).getTime()
                          ) /
                            (1000 * 3600 * 24)
                        ) <=
                      env.dashboard.autoCloseDays - 3
                        ? 'hc-bg-error'
                        : 'hc-bg-white'
                    }
                  >
                    <Table.Data xs={2}>{cases['caseId']}</Table.Data>
                    <Table.Data xs>
                      <DateFormatter date={cases['createTimestamp']} />
                    </Table.Data>
                    <Table.Data xs>
                      {Math.ceil(
                        Math.abs(
                          new Date().getTime() -
                            new Date(cases['updateTimestamp']).getTime()
                        ) /
                          (1000 * 3600 * 24)
                      )}
                    </Table.Data>
                    <Table.Data xs>
                      <Popover
                        size="dense"
                        content={
                          cases['notes']
                            ?.filter(function (note: any) {
                              return note.isInternal === false
                            })
                            .slice(-1)[0]?.commentBody
                        }
                        location="bottom-right"
                      >
                        <Input.Label>
                          {cases['notes']
                            ?.filter(function (note: any) {
                              return note.isInternal === false
                            })
                            .slice(-1)[0]
                            ?.commentBody.substring(0, 15)}
                          ...
                        </Input.Label>
                      </Popover>
                    </Table.Data>
                    <Table.Data xs>
                      {env.dashboard.autoCloseDays -
                        Math.ceil(
                          Math.abs(
                            new Date().getTime() -
                              new Date(cases['updateTimestamp']).getTime()
                          ) /
                            (1000 * 3600 * 24)
                        )}
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
          <GridContainer direction="row-reverse">
            <Pagination
              className="hc-ma-expanded"
              onRequestNext={() => {
                if (
                  searchPage <
                  Math.ceil(
                    searchResults?.getSearchResults?.paging.totalResults
                      ? searchResults?.getSearchResults?.paging.totalPages
                      : 1
                  )
                ) {
                  setSearchPage(searchPage + 1)
                }
              }}
              onRequestPrev={() => {
                if (searchPage !== 1) {
                  setSearchPage(searchPage - 1)
                }
              }}
              currentPage={searchPage}
              totalPages={
                searchResults?.getSearchResults?.paging
                  ? Math.ceil(
                      searchResults?.getSearchResults?.paging.totalPages
                    )
                  : 1
              }
            />
          </GridContainer>
        </BorderDiv>
      </Grid.Item>
    </Grid.Container>
  )
}

export default BPDashboard
