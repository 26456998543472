import React from 'react'
import {
  Grid,
  Heading,
  Form,
  ExpandableSection,
} from '@enterprise-ui/canvas-ui-react'
import '../../Cases/Custom.css'
import { FormikValues } from 'formik'
import {
  VENDOR_HEADING,
  INTERNAL_PROCESSING_VENDOR_HEADING,
  SET_FIELD,
} from './Constants'
import { CommonSearch } from './CommonSearch'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { CaseType } from '../Services/ConstantData'
import _ from 'lodash'
import { CheckCircleIcon, EnterpriseIcon } from '@enterprise-ui/icons'

interface MainProp {
  formik: FormikValues
  handleVendorSearch: () => void
}

const VendorDetails: React.FC<MainProp> = ({ formik, handleVendorSearch }) => {
  const [state, dispatch] = useGlobalForm()
  const formData = formik?.values
  function clearValues(event: any) {
    formik.setFieldValue('vendorNumber', event.target.value)
    formik.setErrors({})
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'vendorIdSearch', value: false },
        { id: 'isVendShowable', value: false },
        { id: 'vendorIdHintText', value: '' },
        { id: 'isVendorNextFlag', value: false },
      ],
    })
  }
  const vendorHeading =
    formData?.caseType === CaseType.INTERNALPROCESSING
      ? INTERNAL_PROCESSING_VENDOR_HEADING
      : VENDOR_HEADING
  return (
    <div className="hc-pa-normal hc-pa-none hc-ma-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="dense">
        <Heading size={6}>
          {vendorHeading}{' '}
          {state.isVendShowable && (
            <EnterpriseIcon
              icon={CheckCircleIcon}
              color="green"
              className="hc-va-sub"
            />
          )}
        </Heading>
        <ExpandableSection.Content>
          <Grid.Container className="hc-pt-none hc-ml-expanded">
            <CommonSearch
              buttonId="documentIdSearch"
              inputId="vendorNumber"
              label="Vendor ID"
              onInput={(e: any) => {
                e.target.value = Math.max(0, parseFloat(e.target.value))
                  .toString()
                  .slice(0, 10)
              }}
              // onChange={formik.handleChange}
              onChange={(event: any) => {
                clearValues(event)
              }}
              onBlur={(e: any) => {
                e.preventDefault()
                handleVendorSearch()
              }}
              value={formData.vendorNumber || ''}
              size={5}
              type="number"
              validSearchIconCheck={state.isVendShowable}
              inValidSearchIconCheck={state.isVendorNextFlag}
              checkIconCheck={state.isVendorNextFlag}
              errorFlag={formik.errors.vendorNumber !== undefined}
              fieldErrorText={formik.errors.vendorNumber}
              docDisable={state.isVendorNextFlag}
              isRequired
              isVendorSearch
              focus
            />
            {state.isVendorNextFlag && (
              <Grid.Item xs={10} md={5}>
                <Form.Field
                  id="Name"
                  label="Vendor Name"
                  className="inputtype hc-ta-left"
                  value={formData.vendorName}
                  disabled={true}
                />
              </Grid.Item>
            )}
          </Grid.Container>
          {formData?.caseType !== CaseType.INTERNALPROCESSING && (
            <Grid.Container className="hc-pt-none hc-ml-expanded">
              <Grid.Item xs={10} md={5} className="hc-pt-none hc-pb-none">
                <Form.Field
                  id="vendorContactName"
                  label="Vendor Contact"
                  className="hc-ta-left"
                  type="text"
                  value={formData.vendorContactName}
                  onChange={formik.handleChange}
                  maxLength="35"
                  disabled={state.isVendShowable}
                />
              </Grid.Item>
              <Grid.Item xs={10} md={5} className="hc-pt-none hc-pb-none">
                <Form.Field
                  id="vendorEmailAddress"
                  label="Vendor Email"
                  className="hc-ta-left"
                  type="text"
                  value={formData.vendorEmailAddress}
                  onChange={formik.handleChange}
                  maxLength="64"
                  error={formik.errors.vendorEmailAddress !== undefined}
                  errorText={formik.errors.vendorEmailAddress}
                  disabled={state.isVendShowable}
                />
              </Grid.Item>
              {state.isVendorNextFlag && state.importVendor === 'Y' && (
                <Grid.Item xs={10} md={10} className="hc-pt-none hc-pb-normal">
                  <Form.Field
                    id="vendorBankName"
                    label="Bank Name"
                    className="hc-ta-left"
                    type="text"
                    value={formData?.bankName}
                    disabled={true}
                  />
                </Grid.Item>
              )}
            </Grid.Container>
          )}
          {state.isCarrierVendor && (
            <Grid.Container className="hc-pt-none hc-ml-expanded">
              <Grid.Item xs={10} md={5} className="hc-pt-none hc-pb-none">
                <Form.Field
                  id="claimDetails.carrierScacCode"
                  label="SCAC Code"
                  className="hc-ta-left"
                  type="text"
                  value={formData.claimDetails.carrierScacCode}
                  onChange={(event: any) => {
                    formik.setFieldValue(
                      'claimDetails.carrierScacCode',
                      _.toUpper(event.target.value)
                    )
                  }}
                  maxLength="35"
                  disabled={state.isVendShowable}
                  error={
                    formik.errors.claimDetails?.carrierScacCode !== undefined
                  }
                  errorText={formik.errors.claimDetails?.carrierScacCode}
                  required
                />
              </Grid.Item>
            </Grid.Container>
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default VendorDetails
