import {
  CaseResponse,
  OpenClaimAmount,
  UpdateCaseRequest,
  AuditEntryInput,
  NoteInput,
} from './Queries'

export function convertToUpdateRequest(casee: CaseResponse): UpdateCaseRequest {
  return {
    caseId: casee?.caseId,
    caseDescription: casee?.caseDescription,
    reasonCode: casee?.reasonCode,
    reasonCodeText: casee?.reasonCodeText,
    documentTypeId: casee?.documentTypeId,
    documentTypeText: casee?.documentTypeText,
    documentSubTypeId: casee?.documentSubTypeId,
    documentSubTypeText: casee?.documentSubTypeText,
    status: casee?.status,
    lanId: casee?.lanId,
    workflow: casee?.workflow,
    resolutionDate: casee?.resolutionDate,
    resolutionCode: casee?.resolutionCode,
    resolutionCodeText: casee?.resolutionCodeText,
    resolutionText: casee?.resolutionText,
    resolutionAmount: casee?.resolutionAmount,
    departmentId: casee?.departmentId,
    purchaseOrderNumber: casee?.purchaseOrderNumber,
    locationId: casee?.locationId,
    classId: casee?.classId,
    receiptId: casee?.receiptId,
    receipts: casee?.receipts,
    disputedDocumentAmount: casee?.disputedDocumentAmount,
    originalDocumentCreateDate: casee?.originalDocumentCreateDate,
    vendorContactName: casee?.vendorContactName,
    vendorEmailAddress: casee?.vendorEmailAddress,
    isReopen: casee?.isReopen,
    agingDate: casee?.agingDate,
    disputeDetails: {
      newDocumentNumber: casee?.disputeDetails?.newDocumentNumber,
      newPaidDate: casee?.disputeDetails?.newPaidDate,
      newDocumentAmount: casee?.disputeDetails?.newDocumentAmount,
      newCheckNumber: casee?.disputeDetails?.newCheckNumber,
    },
    claimDetails: {
      shipDate: casee?.claimDetails?.shipDate,
      carrierVendorId: casee?.claimDetails?.carrierVendorId,
      carrierVendorName: casee?.claimDetails?.carrierVendorName,
      carrierVendorContact: casee?.claimDetails?.carrierVendorName,
      carrierVendorContactEmail: casee?.claimDetails?.carrierVendorContactEmail,
      carrierScacCode: casee?.claimDetails?.carrierScacCode,
      referenceDocumentNumber: casee?.claimDetails?.referenceDocumentNumber,
      referenceDocumentCreateDate:
        casee?.claimDetails?.referenceDocumentCreateDate,
      isMappedVendor: casee?.claimDetails?.isMappedVendor,
      cbdcDocumentDate: casee?.claimDetails?.cbdcDocumentDate,
      inventoryRemovalId: casee?.claimDetails?.inventoryRemovalId,
      retailAmount: casee?.claimDetails?.retailAmount,
      invoiceDate: casee?.claimDetails?.invoiceDate,
      receiptDate: casee?.claimDetails?.receiptDate,
      cartonsBilled: casee?.claimDetails?.cartonsBilled,
      cartonsReceived: casee.claimDetails?.cartonsReceived,
      shortageCartons: casee.claimDetails?.shortageCartons,
      shortageAmount: casee.claimDetails?.shortageAmount,
      trailerNumber: casee.claimDetails?.trailerNumber,
      trackingNumber: casee.claimDetails?.trackingNumber,
      trailerType: casee.claimDetails?.trailerType,
      claimType: casee.claimDetails?.claimType,
      itemLevelShortage: casee.claimDetails?.itemLevelShortage,
      claimReceipts: casee.claimDetails?.claimReceipts,
      documentNotification: casee.claimDetails?.documentNotification,
      importClaimReversal: {
        approvedReversalAmount:
          casee?.claimDetails?.importClaimReversal?.approvedReversalAmount,
        writeOffAmount:
          casee?.claimDetails?.importClaimReversal?.writeOffAmount,
        openClaimAmountDetails:
          casee?.claimDetails?.importClaimReversal?.openClaimAmountDetails?.map(
            function (openClaim: OpenClaimAmount) {
              return {
                claimId: openClaim?.claimId,
                claimDeductionAmount: openClaim?.claimDeductionAmount,
              }
            }
          ),
        domesticAccount: {
          domesticVendorId:
            casee?.claimDetails?.importClaimReversal?.domesticAccount
              ?.domesticVendorId,
          domesticAccountAmount:
            casee?.claimDetails?.importClaimReversal?.domesticAccount
              ?.domesticAccountAmount,
        },
        wireRefundAmount:
          casee?.claimDetails?.importClaimReversal?.wireRefundAmount,
        approvedPendingReversalAmount:
          casee?.claimDetails?.importClaimReversal
            ?.approvedPendingReversalAmount,
      },
    },
    requestDetails: {
      receiptId: casee?.requestDetails?.receiptId,
      shipmentDate: casee?.requestDetails?.shipmentDate,
      carrierVendorName: casee?.requestDetails?.carrierVendorName,
      carrierVendorContactEmail:
        casee?.requestDetails?.carrierVendorContactEmail,
      faxNumber: casee?.requestDetails?.faxNumber,
      proofOfDelivery: casee?.requestDetails?.proofOfDelivery,
      proofOfShipment: casee?.requestDetails?.proofOfShipment,
    },
    reopenDetails: {
      reopenReasonId: casee?.reopenDetails?.reopenReasonId,
      reopenReason: casee?.reopenDetails?.reopenReason,
      reopenDate: casee?.reopenDetails?.reopenDate,
      reopenUser: casee?.reopenDetails?.reopenUser,
      previousResolutionDate: casee?.reopenDetails?.previousResolutionDate,
      previousResolutionUser: casee?.reopenDetails?.previousResolutionUser,
      previousResolutionCode: casee?.reopenDetails?.previousResolutionCode,
      previousResolutionCodeText:
        casee?.reopenDetails?.previousResolutionCodeText,
      previousResolutionText: casee?.reopenDetails?.previousResolutionText,
      previousResolutionAmount: casee?.reopenDetails?.previousResolutionAmount,
      previousResolutionDocumentNumber:
        casee?.reopenDetails?.previousResolutionDocumentNumber,
    },
    auditEntries: casee?.auditEntries?.map(function (
      auditEntries: AuditEntryInput
    ) {
      return {
        activityDescription: auditEntries.activityDescription,
        userId: auditEntries.userId,
        workflow: auditEntries.workflow,
        status: auditEntries.status,
        assignUserId: auditEntries.assignUserId,
        activityTimestamp: auditEntries.activityTimestamp,
      }
    }),
    notes: casee?.notes?.map(function (notes: NoteInput) {
      return {
        userId: notes.userId,
        userName: notes.userName,
        commentBody: notes.commentBody,
        isInternal: notes.isInternal,
        commentDate: notes.commentDate,
      }
    }),
  }
}

export function convertToUpdateRequests(cases: CaseResponse[]) {
  let updateRequests = cases.map((casee: CaseResponse) =>
    convertToUpdateRequest(casee)
  )
  return updateRequests
}
