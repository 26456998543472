import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import { AdminProvider } from './common/AdminProvider'
import {
  ReasonCodesAdmin,
  reasonCodesInitailState,
} from './ReasonCodes/ReasonCodesAdmin'
import {
  ResolutionCodesAdmin,
  resolutionCodesInitailState,
} from './ResolutionCodes/ResolutionCodesAdmin'
import {
  reopenCodesInitialState,
  ReopenCodesAdmin,
} from './ReopenCodes/ReopenCodesAdmin'

interface Props {}

const Admin: React.FC<Props> = (props) => {
  return (
    <>
      <Routes>
        <Route
          path="/manage_reason_codes"
          element={
            <AdminProvider initialState={reasonCodesInitailState}>
              <ReasonCodesAdmin />
            </AdminProvider>
          }
        />

        <Route
          path="/manage_resolution_codes"
          element={
            <AdminProvider initialState={resolutionCodesInitailState}>
              <ResolutionCodesAdmin />
            </AdminProvider>
          }
        />

        <Route
          path="/manage_reopen_codes"
          element={
            <AdminProvider initialState={reopenCodesInitialState}>
              <ReopenCodesAdmin />
            </AdminProvider>
          }
        />
      </Routes>
    </>
  )
}

export default Admin
