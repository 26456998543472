import React from 'react'
import '../../Cases/Custom.css'
import { CaseResponse } from './Queries'
import { DOCUMENT_HEADING } from './Constants'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Section, SectionItem } from './Section'
import { BottomBorderDiv } from '../Common/SynergyDivComponent'

interface MainProp {
  caseSummaryDetails: CaseResponse | undefined
}

const EditRequestDocumentDetails: React.FC<MainProp> = ({
  caseSummaryDetails,
}) => {
  const documentAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(caseSummaryDetails?.originalDocumentAmount))
  return (
    <BottomBorderDiv>
      <Section labelGridSize={4} detail heading={DOCUMENT_HEADING} expandable>
        <SectionItem label="Original document ( $ )">
          {documentAmount}
        </SectionItem>
        <SectionItem label="Purchase order #">
          {caseSummaryDetails?.purchaseOrderNumber}
        </SectionItem>
        <SectionItem label="Department">
          {caseSummaryDetails?.departmentId}
        </SectionItem>
        <SectionItem label="Location">
          {caseSummaryDetails?.locationId}
        </SectionItem>
        <SectionItem label="Document Date">
          <DateFormatter
            //@ts-ignore
            date={caseSummaryDetails?.originalDocumentCreateDate}
            format="L"
          />
        </SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}

export default EditRequestDocumentDetails
