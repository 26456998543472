import React from 'react'
import {
  Button,
  Divider,
  Heading,
  SideNav,
  TargetLogo,
} from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'
import NewCaseButton from './NewCaseButton'
import ProfileSwitch from './ProfileSwitch'

import { useProfile } from '../UserProfile/UserProfileProvider'
import { useAuth } from '@praxis/component-auth'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { handleLogout } from '../AuthUtils'
import { Role } from '../UserProfile/UserProfileContext'
import {
  ChartLineIcon,
  DashboardIcon,
  EnterpriseIcon,
  FileIcon,
  FilterIcon,
  LogoutIcon,
  NetworkIcon,
} from '@enterprise-ui/icons'
import { EnvConfig } from '../../../config/appConfig'
import { useEnv } from '@praxis/component-runtime-env'

interface MainProp {
  isSideNavOpen: boolean
  onRequestClose: () => void
}

const sideNavOverflow = css`
  overflow: inherit;
`

const SideNavigation: React.FC<MainProp> = ({
  isSideNavOpen,
  onRequestClose,
}) => {
  const env = useEnv() as EnvConfig
  const auth = useAuth()
  const { userProfile, hasAnyAdminRole, userRole } = useProfile()
  return (
    <SideNav
      isVisible={isSideNavOpen}
      onRequestClose={onRequestClose}
      css={sideNavOverflow}
      className="hc-bg-grey06"
    >
      <SideNav.Header to="/" as={NavLink} className="hc-pa-dense">
        <Heading size={4}>
          <TargetLogo size="inline" /> &nbsp;S Y N E R G Y
        </Heading>
      </SideNav.Header>
      <Divider />

      <SideNav.Navigation css={sideNavOverflow}>
        <ProfileSwitch />
        <NewCaseButton userProfile={userProfile} />
        <SideNav.NavigationItem as={NavLink} to={'/'}>
          DASHBOARD
          <EnterpriseIcon icon={DashboardIcon} />
        </SideNav.NavigationItem>
        <SideNav.NavigationItem as={NavLink} to={'/cases'}>
          CASES
          <EnterpriseIcon icon={FileIcon} />
        </SideNav.NavigationItem>
        {!userProfile?.isVendor && (
          <SideNav.NavigationItem as={NavLink} to={'/reports'}>
            REPORTS
            <EnterpriseIcon icon={ChartLineIcon} />
          </SideNav.NavigationItem>
        )}
        {hasAnyAdminRole && (
          <SideNav.NavigationItem
            as={NavLink}
            to={`/workflow_management/New?workflow%5B0%5D=${userProfile?.team?.teamName}`}
          >
            WORKFLOW MANAGEMENT
            <EnterpriseIcon icon={NetworkIcon} />
          </SideNav.NavigationItem>
        )}

        {userProfile?.isVendor && (
          // env.auth.allowedVendors.includes(
          //   userProfile?.defaultVendorId?.toString() ?? ''
          // ) &&
          <>
            <Divider />
            <SideNav.NavigationItem>
              <Button
                fullWidth={true}
                type="secondary"
                onClick={() => {
                  window.open(env.nonediVendorUrl, '_blank', 'noreferrer')
                }}
              >
                GO TO INVOICIO
              </Button>
            </SideNav.NavigationItem>
          </>
        )}

        {auth.isAuthorized(env.auth.nonediTmRole) && (
          <>
            <Divider />
            <SideNav.NavigationItem>
              <Button
                fullWidth={true}
                type="secondary"
                onClick={() => {
                  window.open(env.nonediTmUrl, '_blank', 'noreferrer')
                }}
              >
                GO TO INVOICIO
              </Button>
            </SideNav.NavigationItem>
          </>
        )}

        {userRole === Role.SADMIN && (
          <>
            <Divider />
            <SideNav.NavigationGroup startExpanded>
              <SideNav.NavigationItem>
                ADMIN <EnterpriseIcon icon={FilterIcon}></EnterpriseIcon>
              </SideNav.NavigationItem>
              <SideNav.NavigationGroupContent>
                <SideNav.NavigationItem
                  as={NavLink}
                  to={`/admin/manage_reason_codes`}
                >
                  REASON CODES
                </SideNav.NavigationItem>
                <SideNav.NavigationItem
                  as={NavLink}
                  to={`/admin/manage_resolution_codes`}
                >
                  RESOLUTION CODES
                </SideNav.NavigationItem>
                <SideNav.NavigationItem
                  as={NavLink}
                  to={`/admin/manage_reopen_codes`}
                >
                  REOPEN CODES
                </SideNav.NavigationItem>
              </SideNav.NavigationGroupContent>
            </SideNav.NavigationGroup>
            <Divider />
          </>
        )}
      </SideNav.Navigation>
      <SideNav.Footer>
        <div className="hc-pa-normal">
          <Button
            onClick={() => {
              handleLogout(auth)
            }}
            fullWidth
          >
            <EnterpriseIcon icon={LogoutIcon} /> &nbsp; Logout
          </Button>
        </div>
      </SideNav.Footer>
    </SideNav>
  )
}

export default SideNavigation
