import React, { useEffect, useRef } from 'react'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CautionIcon, CheckIcon, EnterpriseIcon } from '@enterprise-ui/icons'

const searchPosRel = css`
  position: relative;
`
const iconsuccess = css`
  color: green;
  position: absolute;
  top: 31px;
  right: 20px;
  width: 28px;
  height: 34px;
  border: none;
`

interface CommonSearchProps {
  buttonId: string
  inputId: string
  label: string
  value: string
  onChange: any
  onClick?: any
  onInput?: any
  size: number
  hintText?: string
  type: any
  validSearchIconCheck: boolean
  inValidSearchIconCheck?: boolean
  checkIconCheck?: boolean
  infoIconCheck?: boolean
  errorFlag: boolean
  fieldErrorText: string
  docDisable: boolean
  isRequired?: any
  isVendorSearch?: boolean
  maxlength?: string
  focus?: any
  onBlur?: any
}

export const CommonSearch: React.FC<CommonSearchProps> = ({
  buttonId,
  inputId,
  label,
  onClick,
  onChange,
  onInput,
  value,
  size,
  hintText,
  type,
  validSearchIconCheck,
  inValidSearchIconCheck,
  checkIconCheck,
  infoIconCheck,
  errorFlag,
  fieldErrorText,
  docDisable,
  isRequired,
  isVendorSearch,
  maxlength,
  focus,
  onBlur,
}) => {
  const textInput = useRef<any>(null)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      textInput.current?.focus()
    }, 0)

    return () => clearTimeout(timeoutId)
  }, [])
  return (
    <Grid.Item md={size} xs={10} css={searchPosRel}>
      {isVendorSearch && inValidSearchIconCheck && checkIconCheck && (
        <EnterpriseIcon icon={CheckIcon} css={iconsuccess} size="xl" />
      )}
      {!isVendorSearch && inValidSearchIconCheck && checkIconCheck && (
        <EnterpriseIcon icon={CheckIcon} css={iconsuccess} size="xl" />
      )}

      {!isVendorSearch && inValidSearchIconCheck && infoIconCheck && (
        <EnterpriseIcon
          icon={CautionIcon}
          css={iconsuccess}
          size="xl"
          className="hc-clr-alert"
        />
      )}
      {isVendorSearch && inValidSearchIconCheck && infoIconCheck && (
        <EnterpriseIcon
          icon={CautionIcon}
          css={iconsuccess}
          size="xl"
          className="hc-clr-alert"
        />
      )}
      <Form.Field
        id={inputId}
        label={label}
        className="inputtype hc-ta-left"
        hintText={hintText}
        type={type}
        onChange={onChange}
        onInput={onInput}
        value={value}
        error={errorFlag}
        errorText={fieldErrorText}
        disabled={validSearchIconCheck && docDisable}
        required={isRequired}
        maxLength={maxlength}
        ref={focus ? textInput : null}
        onBlur={onBlur}
      />
    </Grid.Item>
  )
}
