import * as qs from 'qs'
import _ from 'lodash'
import { Location } from 'react-router-dom'
import { SearchContextValue, Security } from './SearchInitialValues'
import { addEscapeChar } from '../../../Reports/ReportContext/ReportUtils'

function buildUrl(history: Location, payload: any): string {
  let queryObject: any = qs.parse(history.search.replace('?', ''))

  const { filter, searchField, event, type } = payload
  //radio
  if (type === 'radio') {
    queryObject[filter] = event
  }

  if (type === 'search_group') {
    if (queryObject[filter] === undefined) {
      queryObject[filter] = { [searchField]: [...event.id.split(',')] }
    } else {
      queryObject[filter][searchField] = _.uniq([
        ...(queryObject[filter][searchField] ?? []),
        ...event.id.split(','),
      ])
    }
  }

  // checkbox
  if (type === 'checkbox') {
    if (queryObject[filter] === undefined) {
      queryObject[filter] = [event.target.value]
    } else {
      const filters = Object.values(queryObject[filter])
      const value = event.target.value
      if (filters.length === _.pull(filters, value).length) {
        filters.push(value)
      }
      queryObject[filter] = [...filters]
    }
  }

  // date ranges
  if (type === 'date') {
    queryObject[filter] = event
  }

  // main search
  if (type === 'search') {
    if (queryObject['search'] === undefined) {
      queryObject['search'] = [payload.id]
    } else {
      queryObject['search'] = _.uniq([...queryObject['search'], payload.id])
    }
  }
  return qs.stringify(queryObject)
}

function removeFilter(history: Location, payload: any): string {
  let queryObject: any = qs.parse(history.search.replace('?', ''), {
    arrayLimit: 100,
  })
  const { type, filter, searchFilter, id } = payload

  Object.keys(queryObject)
    .filter((key) => key === filter)
    .forEach((key) => {
      if (type === 'checkbox' || filter === 'search') {
        _.remove(queryObject[key], function (n) {
          return n === id
        })
      }

      if (type === 'date' || type === 'radio') {
        queryObject[key] = undefined
      }

      if (type === 'search_group') {
        _.remove(queryObject[key][searchFilter], function (n) {
          return n === id
        })
      }
    })

  return qs.stringify(queryObject)
}

const convertToUS = (isoDate: string | undefined) => {
  const split = isoDate?.split('-')

  if (split && split.length > 1) {
    return `${split[1]}/${split[2]}/${split[0]}`
  }
  return undefined
}

const convertToISO = (usDate?: string) => {
  const split = usDate?.split('/')

  if (split && split.length > 1) {
    return `${split[2]}-${split[0]}-${split[1]}`
  } else {
    return usDate
  }
}

const buildQuery = (
  state: SearchContextValue,
  vendor?: boolean,
  lanId?: any
): String | null => {
  const checkboxFilters = state.sideFilters
    .filter((key) => filterUser(key, vendor))
    .filter((filter) => filter.type === 'checkbox')
    .map(
      (key) =>
        `${key.id}: (` +
        key.values
          ?.filter((value) => value.isChecked === true)
          .map((value) => `(${addEscapeChar(value.id)})`)
          .join(' OR ')
          .concat(')')
    )
    .filter((value) => !value.includes('()'))
    .join(' AND ')

  const dateFilters = state.sideFilters
    .filter((key) => filterUser(key, vendor))
    .filter((filter) => filter.type === 'date')
    .map(
      (key) =>
        `${key.id}: [${key.active?.from ?? ''} TO ${key.active?.to ?? ''}]`
    )
    .filter((value) => !value.includes('[ TO ]'))
    .join(' AND ')

  const searchGroupFilters = state.sideFilters
    .filter((key) => filterUser(key, vendor))
    .filter((filter) => filter.type === 'search_group')
    .map((filter) => {
      return filter.searchFields
        ?.map(
          (field) =>
            `${field.field}: (` +
            field.values
              .map((value) => `(${addEscapeChar(value.id)})`)
              .join(' OR ')
              .concat(')')
        )
        .filter((value) => !(value === '') && !value.includes('()'))
        .join(' AND ')
    })
    .filter((value) => !(value === '') && !value?.includes('()'))
    .join(' AND ')

  const searchFilter =
    '(' + state.searchFilters.map((value: any) => value.id).join(' OR ') + ')'

  const radioFilters = state.sideFilters
    .filter((key) => filterUser(key, vendor))
    .filter((filter) => filter.type === 'radio')
    .map((key) => {
      if (key.id === 'case_assignment') {
        let isChecked = key.values?.find((val) => {
          return val.id === 'Cases Assigned to Me'
        })?.isChecked
        return isChecked ? `lan_id: (${lanId})` : ''
      } else return '' //update the return stmt if other radio filters are added
    })
    .filter((value) => !(value === '') && !value?.includes('()'))
    .join(' AND ')

  var arr = [
    checkboxFilters,
    searchFilter,
    searchGroupFilters,
    dateFilters,
    radioFilters,
  ]
    .filter((value: string) => !(value === '') && !value.includes('()'))
    .join(' AND ')

  return arr ? arr : null
}

function filterUser(key: any, isVendor?: Boolean): Boolean {
  if (isVendor) {
    return key.security === Security.EXTERNAL || key.security === Security.BOTH
  }
  return key.security === Security.INTERNAL || key.security === Security.BOTH
}

export {
  removeFilter,
  buildUrl,
  convertToISO,
  convertToUS,
  buildQuery,
  filterUser,
}
