import React, { useEffect, useState } from 'react'
import {
  Grid,
  Heading,
  Input,
  Table,
  Button,
  ExpandableSection,
  Caption,
  GridItem,
  InputSelect,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { get } from 'lodash'
import '../Custom.css'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'
import useAttachment from '../Hooks/useAttachment'
import DocSupportingModal from './DocSupportingModal'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ATTACHMENT_HEADING, SET_FIELD } from './Constants'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { GET_REASONCODES, GetReasonCodes } from '../Common/Queries'
import { useLazyQuery } from '@apollo/client'
import { CaseType } from '../Services/ConstantData'
import { AttachmentSearch } from './AttchmentSearch'
import produce from 'immer'
import { computeChecksumMd5 } from '../Services/MD5Checksum'
import { EnterpriseIcon, SearchIcon, TrashIcon } from '@enterprise-ui/icons'

const docbuttonstyle = css`
  color: #cc0000;
  font-size: 10px;
  text-transform: initial;
  text-decoration: underline;
  background: white;
  border: none;
`

const buttonRight = css`
  text-align: right;
`

interface MainProp {
  isVendor: boolean | undefined
  reasonCode: any
}

const AttachmentDetails: React.FC<MainProp> = ({ isVendor, reasonCode }) => {
  const [supportingDocument, setSupportingDocument] = useState<any>()
  const [state, dispatch] = useGlobalForm()
  const env = useEnv() as EnvConfig
  const allowedFileTypes = `${'*Allowed File Types: '}${
    env.attachments.acceptFileType
  }`
  const [attachmentSearch, setAttachmentSearch] = useState<boolean>(true)
  const makeToast = useToaster()

  useEffect(() => {
    if (reasonCode) {
      fetchReasonCodes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonCode])

  useEffect(() => {
    const selectedAttachmentList = [...state.selectedSearchAttachments]

    if (attachmentDetails.attachments.length === 0) {
      updateAttachmentsList(selectedAttachmentList)
    } else {
      // loop through and filter files
      let attachments = [...attachmentDetails.attachments]
      let addedAttachments = attachments.filter(
        (attachment) => attachment.documentSource === undefined
      )
      const attachmentsList = [...addedAttachments, ...selectedAttachmentList]
      console.log(attachmentsList)
      updateAttachmentsList(attachmentsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedSearchAttachments])

  let maxRangeAttachmentlimit = 0
  if (state?.maxRangeVendors === undefined) {
    maxRangeAttachmentlimit = env.attachments.normalAttachmentlimit
  } else {
    maxRangeAttachmentlimit = env.attachments.maxRangeAttachmentlimit
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [fetchReasonCodes] = useLazyQuery<GetReasonCodes>(GET_REASONCODES, {
    onCompleted: (data: GetReasonCodes) => {
      const reasonCodes = data?.getReasonCodes

      if (reasonCode) {
        setSupportingDocument(
          reasonCodes.find(
            (reasonCodeValues: any) =>
              reasonCodeValues?.reasonCodeId === reasonCode
          )?.supportDocList
        )
      } else {
        setSupportingDocument('Attach the required documents')
      }
    },
    fetchPolicy: 'network-only',
  })

  const supportingListHeading =
    'Backup/Supporting required to review the case - Click here'
  const { attachmentDetails, updateAttachmentsList } = useAttachment()

  const handleAddMultipleAttachments = async (files: any) => {
    let attachmentList = attachmentDetails.attachments
      ? [...attachmentDetails.attachments]
      : []

    for (var i = 0; i < files.length; i++) {
      let fileHash = await computeChecksumMd5(files[i]).then((md5) => {
        console.log(`The uploaded file's MD5 hash is: ${md5}`)
        return md5
      })

      const fileExtension = files[i]?.file?.type?.split('/')[1]
      const type = files[i]?.file?.type
      const parts = [
        new Blob([''], {
          type: type,
        }),
      ]
      let refFile = new File(parts, 'reference_file' + fileExtension)
      let refFileHash = await computeChecksumMd5(refFile).then((md5) => {
        console.log(`Empty reference file's MD5 hash is: ${md5}`)
        return md5
      })
      if (env.attachments.unacceptedFileTypes.includes(files[i]?.type)) {
        makeToast({
          type: 'alert',
          heading: 'Attachment Alert',
          message:
            'Few file type(s) are unaccepted, please make sure you upload files with accepted formats.',
        })
        continue
      }
      if (fileHash === refFileHash || files[i]?.file?.size === '0') {
        makeToast({
          type: 'alert',
          heading: 'Attachment Alert',
          message:
            'Few files(s) are corrupt, please make sure you upload non-empty files.',
        })
        continue
      }
      if (
        attachmentList.length + state?.savedAttachments?.length >=
        maxRangeAttachmentlimit
      ) {
        makeToast({
          type: 'alert',
          heading: 'Attachment Alert',
          message:
            'Attachments exceeded the number of allowed attachments limit',
        })
        break
      } else if (attachmentList.length >= env.attachments.numberOfAttachments) {
        makeToast({
          type: 'alert',
          heading: 'Attachment Alert',
          message: `Only ${env.attachments.numberOfAttachments} attachments are allowed per Upload`,
        })
        break
      }

      if (files?.item(i)?.size > env.attachments.attachmentSize) {
        makeToast({
          type: 'alert',
          heading: 'Attachment Alert',
          message: `Attachment size is exceeded the allowed file size`,
        })
        continue
      }
      attachmentList.push({
        file: files?.item(i),
        documentType: 'Choose One',
        documentNumber: files
          ?.item(i)
          ?.name.substr(0, files?.item(i)?.name.lastIndexOf('.')),
        isInternal: state.requestType === CaseType.INTERNALPROCESSING,
      })
    }
    updateAttachmentsList(attachmentList)
  }

  const handleRemoveAttachment = (index: any) => {
    let allFiles = attachmentDetails.attachments
      ? [...attachmentDetails.attachments]
      : []
    allFiles.splice(index, 1)
    updateAttachmentsList(allFiles)
  }

  const handleAttachmentDetailChange = (id: string, index: any, value: any) => {
    let allFiles = [...attachmentDetails.attachments]

    if (allFiles) {
      let updatedList = produce(allFiles, (draft) => {
        draft[index][id] = value
      })
      updateAttachmentsList(updatedList)
    }
  }

  const handleApply = (value: any) => {
    let allFiles = [...attachmentDetails.attachments]
    if (allFiles) {
      let updatedList = produce(allFiles, (draft) => {
        for (var i = 0; i < draft.length; i++) {
          draft[i]['documentType'] = value
        }
      })
      updateAttachmentsList(updatedList)
    }
  }

  const handleSearchAttachment = () => {
    setAttachmentSearch(true)
  }

  return (
    <>
      <div className="hc-pa-normal hc-pt-normal hc-pl-none">
        <ExpandableSection
          startExpanded
          preserveDom
          toggleLocation="left"
          padding="none"
        >
          <Heading size={6}>
            {ATTACHMENT_HEADING}
            {state.requestType !== CaseType.INTERNALPROCESSING && (
              <Caption
                below={
                  <Button
                    css={docbuttonstyle}
                    size="dense"
                    onClick={(e: any) => {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'docSupportingFlag', value: true }],
                      })
                      e.stopPropagation()
                    }}
                  >
                    {supportingListHeading}
                  </Button>
                }
              ></Caption>
            )}
          </Heading>
          <ExpandableSection.Content>
            <Grid.Container className="hc-pt-none">
              {state.searchAttachments && (
                <GridItem xs={12} md={12}>
                  <div css={buttonRight}>
                    <Button type="secondary" onClick={handleSearchAttachment}>
                      <EnterpriseIcon icon={SearchIcon} /> Search Attachments
                    </Button>
                  </div>
                </GridItem>
              )}
              <Grid.Item xs={12} md={12}>
                <Input.DropArea
                  fullwidth
                  multiple={true}
                  id="attachments"
                  accept={
                    env.attachments.acceptFileType
                      ? env.attachments.acceptFileType.split(', ')
                      : []
                  }
                  instructionText={'Drag and Drop Files Here to Upload.'}
                  dropText="Drag and Drop files"
                  disabled={
                    (attachmentDetails.attachments &&
                      attachmentDetails.attachments.length +
                        state?.savedAttachments?.length >=
                        maxRangeAttachmentlimit) ||
                    attachmentDetails.uploadingAttachments
                  }
                  onUpdate={(event: any) => {
                    const files =
                      get(event, 'dataTransfer.files') ||
                      get(event, 'target.files')
                    handleAddMultipleAttachments(files)
                  }}
                  size="dense"
                />
                <Input.Label
                  className="hc-fs-overline hc-clr-error"
                  rightContent={allowedFileTypes}
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Container className="hc-pt-none">
              <Grid.Item xs={12} md={12}>
                {attachmentDetails.attachments &&
                  attachmentDetails.attachments.length > 0 && (
                    <Table name="uploadedFiles">
                      <Table.Head>
                        <Table.Row justify="space-between">
                          <Table.Header xs={1} />
                          <Table.Header xs={4}>File Name</Table.Header>
                          <Table.Header xs={2}>Document Number</Table.Header>
                          <Table.Header xs={3}>Attachment Type*</Table.Header>
                          {!isVendor &&
                            state.requestType !==
                              CaseType.INTERNALPROCESSING && (
                              <Table.Header xs={2}>Internal</Table.Header>
                            )}
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        <Table.Row>
                          <Table.Data
                            xs={
                              isVendor ||
                              state.requestType === CaseType.INTERNALPROCESSING
                                ? 9
                                : 7
                            }
                            className="hc-fs-xs hc-mt-sm hc-ta-center hc-clr-black"
                          >
                            Select attachment type to apply for all the
                            attachments
                          </Table.Data>
                          <Table.Data xs={3}>
                            <InputSelect
                              disabled={attachmentDetails?.uploadingAttachments}
                              id={'commonDocumentType'}
                              options={env.attachments.attachmentDocumentTypes}
                              onUpdate={(id: any, value: any) => {
                                handleApply(value)
                              }}
                            />
                          </Table.Data>
                        </Table.Row>
                        {attachmentDetails.attachments?.map(
                          (attachmentValues: any, index: number) => (
                            <Table.Row justify="space-between" key={index}>
                              <Table.Data xs={1}>
                                <Button
                                  xs={1}
                                  onClick={() => {
                                    handleRemoveAttachment(index)
                                  }}
                                  disabled={
                                    attachmentDetails.uploadingAttachments
                                  }
                                >
                                  <EnterpriseIcon icon={TrashIcon} />
                                </Button>
                              </Table.Data>
                              <Table.Data
                                xs={4}
                                className={`${
                                  attachmentValues?.isUploaded === false
                                    ? 'hc-clr-error'
                                    : ''
                                }`}
                              >
                                {attachmentValues?.file?.name}
                                {attachmentValues?.isUploaded === false && (
                                  <Input.Label className="hc-fs-overline">
                                    *Unable to upload this file. Please contact
                                    admin if the request isn't successful after
                                    multiple retries.
                                  </Input.Label>
                                )}
                              </Table.Data>
                              <Table.Data xs={2}>
                                <Input.Text
                                  id={'documentNumber' + index}
                                  disabled={
                                    attachmentDetails.uploadingAttachments
                                  }
                                  value={attachmentValues?.documentNumber}
                                  onChange={(event: any) => {
                                    handleAttachmentDetailChange(
                                      'documentNumber',
                                      index,
                                      event.target.value
                                    )
                                  }}
                                />
                              </Table.Data>
                              <Table.Data xs={3}>
                                <InputSelect
                                  disabled={
                                    attachmentDetails.uploadingAttachments
                                  }
                                  id={'documentType'}
                                  defaultValue={attachmentValues?.documentType}
                                  options={
                                    env.attachments.attachmentDocumentTypes
                                  }
                                  value={
                                    attachmentDetails.attachments[index][
                                      'documentType'
                                    ]
                                  }
                                  onUpdate={(id: any, value: any) => {
                                    handleAttachmentDetailChange(
                                      id,
                                      index,
                                      value
                                    )
                                  }}
                                />
                              </Table.Data>
                              {!isVendor &&
                                state.requestType !==
                                  CaseType.INTERNALPROCESSING && (
                                  <Table.Data xs={2}>
                                    <Input.Checkbox
                                      id={'isInternal' + index}
                                      className="hc-pl-normal"
                                      checked={attachmentValues?.isInternal}
                                      disabled={
                                        attachmentDetails.uploadingAttachments
                                      }
                                      onChange={(event: any) =>
                                        handleAttachmentDetailChange(
                                          'isInternal',
                                          index,
                                          event.target.checked
                                        )
                                      }
                                    />
                                  </Table.Data>
                                )}
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  )}
                {attachmentDetails.attachmentTypeNotSet && (
                  <Input.Label
                    className="hc-fs-overline hc-clr-error"
                    rightContent="*Some attachment types are not set."
                  />
                )}
              </Grid.Item>
            </Grid.Container>
            <DocSupportingModal
              headingText="Supporting Document List"
              supportingDocument={supportingDocument}
            />
          </ExpandableSection.Content>
        </ExpandableSection>
        {state.searchAttachments && (
          <AttachmentSearch
            attchmentSearchVisible={attachmentSearch}
            setAttachmetSearchVisible={setAttachmentSearch}
          ></AttachmentSearch>
        )}
      </div>
    </>
  )
}
export default AttachmentDetails
