import React, { useState } from 'react'
import {
  Divider,
  ExpandableSection,
  Heading,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import Comments from './Comments'
import { FormikValues } from 'formik'
import { BottomBorderDiv } from './SynergyDivComponent'
import ActivityModel from '../Activity/AuditLog'

interface MainProp {
  formik: FormikValues
  caseDetails: any
  userProfile: any
  saveComment: any
}

const NotesTab: React.FC<MainProp> = ({
  formik,
  caseDetails,
  userProfile,
  saveComment,
}) => {
  const [activeNotesTab, setActiveNotesTab] = useState<string>('comments')
  return (
    <BottomBorderDiv>
      <div className="hc-pa-normal">
        <ExpandableSection startExpanded padding="none" toggleLocation="left">
          <Heading size={6} className="hc-pa-none">
            Activities
          </Heading>
          <ExpandableSection.Content>
            <div className="hc-pa-dense hc-ma-none">
              <Tabs
                onTabSelect={(event: Event, tab: { name: string }) => {
                  setActiveNotesTab(tab.name)
                }}
                activeTab={activeNotesTab}
                className="hc-mt-normal"
              >
                {!userProfile?.isVendor && (
                  <Tabs.Item name="auditLog">Activity Stream</Tabs.Item>
                )}
                <Tabs.Item name="comments">Work Notes</Tabs.Item>
                <Divider />
                {!userProfile?.isVendor && (
                  <Tabs.Content name="auditLog">
                    <ActivityModel
                      auditEntries={caseDetails?.auditEntries}
                    ></ActivityModel>
                  </Tabs.Content>
                )}
                <Tabs.Content name="comments">
                  <Comments
                    formik={formik}
                    notes={caseDetails?.notes}
                    userProfile={userProfile}
                    saveComment={saveComment}
                  />
                </Tabs.Content>
              </Tabs>
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </div>
    </BottomBorderDiv>
  )
}

export default NotesTab
