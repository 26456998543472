import React, { useState } from 'react'
import {
  Table,
  Grid,
  Input,
  Pagination,
  GridContainer,
} from '@enterprise-ui/canvas-ui-react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { BorderDiv } from '../Cases/Common/SynergyDivComponent'
import {
  AggregateVariable,
  GET_AGING_DETAILS,
  GET_SEARCH_RESULTS,
  GET_STATUS_DETAILS,
  GetAging,
  GetSearchResults,
  GetStatuses,
  SearchVariable,
} from './Queries'
import { useNavigate, useLocation } from 'react-router'
import { useProfile } from '../Layout/UserProfile/UserProfileProvider'
import { CaseStatus } from '../Cases/Services/ConstantData'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../config/appConfig'
import moment from 'moment'
import { GET_WORKFLOWS } from '../Cases/Common/Queries'
import { buildReportUrl } from '../Reports/ReportsUtils'
import { addEscapeChar } from '../Reports/ReportContext/ReportUtils'

const TMDashboard = () => {
  const [searchTMPage, setSearchTMPage] = useState<number>(1)
  const [searchTeamPage, setSearchTeamPage] = useState<number>(1)
  const [workflow, setWorkflow] = useState<string>('')
  const { userProfile } = useProfile()
  const env = useEnv() as EnvConfig
  const navigate = useNavigate()
  const location = useLocation()

  useQuery(GET_WORKFLOWS, {
    variables: {
      teamId: userProfile?.team?.teamId,
    },
    onCompleted: (getWorkflows) => {
      setWorkflow(
        getWorkflows.getWorkflows
          ?.map((workflow: any) => `(${workflow.value})`)
          .join(' OR ')
      )
      getAgingDetails()
      getStatusDetails()
      getTmResults()
      getTeamResults()
    },
    fetchPolicy: 'network-only',
  })

  const [getAgingDetails, agingDetails] = useLazyQuery<
    GetAging,
    AggregateVariable
  >(GET_AGING_DETAILS, {
    variables: {
      query: `workflow: (${addEscapeChar(workflow)}) AND status: ((${
        CaseStatus.NEW
      }) OR (${CaseStatus.INPROGRESS}) OR (${CaseStatus.REOPEN}) OR (${
        CaseStatus.AWAITINGINFO
      }))`,
    },
    fetchPolicy: 'network-only',
  })

  const [getStatusDetails, statusDetails] = useLazyQuery<
    GetStatuses,
    AggregateVariable
  >(GET_STATUS_DETAILS, {
    variables: {
      summaryDays: env.dashboard.summaryDays,
      query: `workflow: (${addEscapeChar(workflow)})`,
    },
    fetchPolicy: 'network-only',
  })

  const [getTmResults, searchTMResults] = useLazyQuery<
    GetSearchResults,
    SearchVariable
  >(GET_SEARCH_RESULTS, {
    variables: {
      query: `lan_id: (${userProfile?.userId}) AND status: ((${
        CaseStatus.NEW
      }) OR (${CaseStatus.INPROGRESS}) OR (${CaseStatus.REOPEN}) OR (${
        CaseStatus.AWAITINGINFO
      })) AND workflow: ((${addEscapeChar(workflow)}))`,
      sort: 'create_timestamp',
      page: searchTMPage,
      perPage: 10,
    },
    fetchPolicy: 'network-only',
  })

  const [getTeamResults, searchTeamResults] = useLazyQuery<
    GetSearchResults,
    SearchVariable
  >(GET_SEARCH_RESULTS, {
    variables: {
      query: `workflow: (${addEscapeChar(workflow)}) AND status: ((${
        CaseStatus.NEW
      }) OR (${CaseStatus.INPROGRESS}) OR (${CaseStatus.REOPEN}) OR (${
        CaseStatus.AWAITINGINFO
      }))`,
      sort: 'create_timestamp',
      page: searchTeamPage,
      perPage: 10,
    },
    fetchPolicy: 'network-only',
  })

  return (
    <Grid.Container justify="space-between" className="hc-pa-none">
      {/* Summary of cases  assigned to the team  by age*/}
      <Grid.Item xs={12} sm={12} md={12} xl={6} lg={6}>
        <BorderDiv className="hc-ma-normal hc-pa-normal">
          <Input.Label className="hc-fs-section">
            Aging View Of Open Cases
          </Input.Label>
          <Table width="500px">
            <Table.Head>
              <Table.Row>
                <Table.Header xs={3}>Type</Table.Header>
                <Table.Header xs>Less than 7 days</Table.Header>
                <Table.Header xs>Less than 14 days</Table.Header>
                <Table.Header xs>Less than 21 days</Table.Header>
                <Table.Header xs>More than 21 days</Table.Header>
                <Table.Header xs>Total</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {agingDetails?.data?.getAggregatedAge?.map(
                (ageDetail: any, index: number) => (
                  <Table.Row clickable key={ageDetail['caseSubType']}>
                    <Table.Header xs={3}>
                      {ageDetail['caseSubType']}
                    </Table.Header>
                    <Table.Data
                      xs
                      onClick={() => {
                        let lessThanSeven = `((case_sub_type:(${
                          ageDetail['caseSubType']
                        })) AND aging_date: [now-7d TO now] AND workflow: (${addEscapeChar(
                          workflow
                        )}) AND status:((${CaseStatus.NEW}) OR (${
                          CaseStatus.INPROGRESS
                        }) OR (${CaseStatus.REOPEN}) OR (${
                          CaseStatus.AWAITINGINFO
                        })))`
                        navigate(`/cases?search%5B0%5D=${lessThanSeven}`)
                      }}
                    >
                      {
                        ageDetail['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Less than 7 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let lessThanFourteen = `((case_sub_type:(${
                          ageDetail['caseSubType']
                        })) AND aging_date: [${moment(new Date())
                          .subtract(15, 'day')
                          .format('YYYY-MM-DD')} TO ${moment(new Date()).format(
                          'YYYY-MM-DD'
                        )}] AND workflow: (${addEscapeChar(
                          workflow
                        )}) AND status:((${CaseStatus.NEW}) OR (${
                          CaseStatus.INPROGRESS
                        }) OR (${CaseStatus.REOPEN}) OR (${
                          CaseStatus.AWAITINGINFO
                        })))`
                        navigate(`/cases?search%5B0%5D=${lessThanFourteen}`)
                      }}
                    >
                      {
                        ageDetail['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Less than 14 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let lessThan21 = `((case_sub_type:(${
                          ageDetail['caseSubType']
                        })) AND aging_date: [${moment(new Date())
                          .subtract(21, 'day')
                          .format('YYYY-MM-DD')} TO ${moment(new Date()).format(
                          'YYYY-MM-DD'
                        )}] AND workflow: (${addEscapeChar(
                          workflow
                        )}) AND status:((${CaseStatus.NEW}) OR (${
                          CaseStatus.INPROGRESS
                        }) OR (${CaseStatus.REOPEN}) OR (${
                          CaseStatus.AWAITINGINFO
                        })))`
                        navigate(`/cases?search%5B0%5D=${lessThan21}`)
                      }}
                    >
                      {
                        ageDetail['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Less than 21 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let moreThan21 = `((case_sub_type:(${
                          ageDetail['caseSubType']
                        })) AND aging_date: [${moment(new Date())
                          .subtract(2, 'year')
                          .format('YYYY-MM-DD')} TO ${moment(new Date())
                          .subtract(21, 'day')
                          .format(
                            'YYYY-MM-DD'
                          )}] AND workflow: (${addEscapeChar(
                          workflow
                        )}) AND status:((${CaseStatus.NEW}) OR (${
                          CaseStatus.INPROGRESS
                        }) OR (${CaseStatus.REOPEN}) OR (${
                          CaseStatus.AWAITINGINFO
                        })))`
                        navigate(`/cases?search%5B0%5D=${moreThan21}`)
                      }}
                    >
                      {
                        ageDetail['aging']?.filter(function (ageGroup: any) {
                          return ageGroup['ageType'] === 'Older than 21 days'
                        })[0].count
                      }
                    </Table.Data>
                    <Table.Data xs>{ageDetail['count']}</Table.Data>
                  </Table.Row>
                )
              )}
              {/*Total of columns*/}
              <Table.Row key="total">
                <Table.Data xs={3}>Total</Table.Data>
                <Table.Data xs>
                  {agingDetails?.data?.getAggregatedAge
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Less than 7 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.data?.getAggregatedAge
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Less than 14 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.data?.getAggregatedAge
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Less than 21 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.data?.getAggregatedAge
                    ?.map(
                      (ages) =>
                        ages?.aging?.filter(
                          (obj) => obj.ageType === 'Older than 21 days'
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {agingDetails?.data?.getAggregatedAge?.reduce(
                    (a, b) => a + (b?.count || 0),
                    0
                  )}
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </BorderDiv>
      </Grid.Item>

      {/*Summary of cases assigned to the TMs team by status*/}
      <Grid.Item xs={12} sm={12} md={12} xl={6} lg={6}>
        <BorderDiv className="hc-ma-normal hc-pa-normal">
          <Input.Label className="hc-fs-section ">
            Summary of Cases in last {env.dashboard.summaryDays} Days
          </Input.Label>
          <Table width="650px">
            <Table.Head>
              <Table.Row className="hc-pb-normal">
                <Table.Header xs={2}>Type</Table.Header>
                <Table.Header xs={1} className="hc-pa-dense">
                  New
                </Table.Header>
                <Table.Header xs>In Progress</Table.Header>
                <Table.Header xs>Reopen</Table.Header>
                <Table.Header xs>Resolved</Table.Header>
                <Table.Header xs>Awaiting Info</Table.Header>
                <Table.Header xs>Total</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {statusDetails?.data?.getAggregatedStatus?.map(
                (statusDetail: any, index: number) => (
                  <Table.Row clickable key={statusDetail['caseSubType']}>
                    <Table.Data xs={2}>
                      {statusDetail['caseSubType']}
                    </Table.Data>
                    <Table.Data
                      xs={1}
                      onClick={() => {
                        let statusNew =
                          encodeURI(
                            `search[0]=(case_sub_type:(${statusDetail['caseSubType']}))&` +
                              'status[0]=New&' +
                              `create_timestamp[from]=${moment(new Date())
                                .subtract(145, 'day')
                                .format('YYYY-MM-DD')}&` +
                              `create_timestamp[to]=${moment(new Date()).format(
                                'YYYY-MM-DD'
                              )}`
                          ) +
                          `&${buildReportUrl(
                            location,
                            'workflow',
                            workflow.replace(/["'\\(\\)]/g, '').split(' OR '),
                            'checkboxes'
                          )}`
                        navigate(`cases?${statusNew}`)
                      }}
                    >
                      {statusDetail['statuses']?.filter(function (status: any) {
                        return status['status'] === CaseStatus.NEW
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusInProgress =
                          encodeURI(
                            `search[0]=(case_sub_type:(${statusDetail['caseSubType']}))&` +
                              'status[0]=InProgress&' +
                              `create_timestamp[from]=${moment(new Date())
                                .subtract(145, 'day')
                                .format('YYYY-MM-DD')}&` +
                              `create_timestamp[to]=${moment(new Date()).format(
                                'YYYY-MM-DD'
                              )}`
                          ) +
                          `&${buildReportUrl(
                            location,
                            'workflow',
                            workflow.replace(/["'\\(\\)]/g, '').split(' OR '),
                            'checkboxes'
                          )}`
                        navigate(`cases?${statusInProgress}`)
                      }}
                    >
                      {statusDetail['statuses']?.filter(function (status: any) {
                        return status['status'] === CaseStatus.INPROGRESS
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusReOpened =
                          encodeURI(
                            `search[0]=(case_sub_type:(${statusDetail['caseSubType']}))&` +
                              'status[0]=ReOpen&' +
                              `create_timestamp[from]=${moment(new Date())
                                .subtract(145, 'day')
                                .format('YYYY-MM-DD')}&` +
                              `create_timestamp[to]=${moment(new Date()).format(
                                'YYYY-MM-DD'
                              )}`
                          ) +
                          `&${buildReportUrl(
                            location,
                            'workflow',
                            workflow.replace(/["'\\(\\)]/g, '').split(' OR '),
                            'checkboxes'
                          )}`
                        navigate(`cases?${statusReOpened}`)
                      }}
                    >
                      {statusDetail['statuses']?.filter(function (status: any) {
                        return status['status'] === CaseStatus.REOPEN
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusResolved =
                          encodeURI(
                            `search[0]=(case_sub_type:(${statusDetail['caseSubType']}))&` +
                              'status[0]=Resolved&' +
                              `create_timestamp[from]=${moment(new Date())
                                .subtract(145, 'day')
                                .format('YYYY-MM-DD')}&` +
                              `create_timestamp[to]=${moment(new Date()).format(
                                'YYYY-MM-DD'
                              )}`
                          ) +
                          `&${buildReportUrl(
                            location,
                            'workflow',
                            workflow.replace(/["'\\(\\)]/g, '').split(' OR '),
                            'checkboxes'
                          )}`
                        navigate(`cases?${statusResolved}`)
                      }}
                    >
                      {statusDetail['statuses']?.filter(function (status: any) {
                        return status['status'] === CaseStatus.RESOLVED
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data
                      xs
                      onClick={() => {
                        let statusAwaitingInfo =
                          encodeURI(
                            `search[0]=(case_sub_type:(${statusDetail['caseSubType']}))&` +
                              'status[0]=AwaitingInfo&' +
                              `create_timestamp[from]=${moment(new Date())
                                .subtract(145, 'day')
                                .format('YYYY-MM-DD')}&` +
                              `create_timestamp[to]=${moment(new Date()).format(
                                'YYYY-MM-DD'
                              )}`
                          ) +
                          `&${buildReportUrl(
                            location,
                            'workflow',
                            workflow.replace(/["'\\(\\)]/g, '').split(' OR '),
                            'checkboxes'
                          )}`
                        navigate(`cases?${statusAwaitingInfo}`)
                      }}
                    >
                      {statusDetail['statuses']?.filter(function (status: any) {
                        return status['status'] === CaseStatus.AWAITINGINFO
                      })[0]?.count || 0}
                    </Table.Data>
                    <Table.Data xs>{statusDetail['count']}</Table.Data>
                  </Table.Row>
                )
              )}
              {/*Total of columns*/}
              <Table.Row key="total">
                <Table.Data xs={2}>Total</Table.Data>
                <Table.Data xs>
                  {statusDetails?.data?.getAggregatedStatus
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.NEW
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.data?.getAggregatedStatus
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.INPROGRESS
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.data?.getAggregatedStatus
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.REOPEN
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.data?.getAggregatedStatus
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.RESOLVED
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.data?.getAggregatedStatus
                    ?.map(
                      (aggStatus) =>
                        aggStatus?.statuses?.filter(
                          (obj) => obj.status === CaseStatus.AWAITINGINFO
                        )[0]
                    )
                    .reduce((a, b) => a + (b?.count || 0), 0)}
                </Table.Data>
                <Table.Data xs>
                  {statusDetails?.data?.getAggregatedStatus?.reduce(
                    (a, b) => a + (b?.count || 0),
                    0
                  )}
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </BorderDiv>
      </Grid.Item>

      {/*Open cases assigned to TM*/}
      <Grid.Item xs={12} sm={12} md={12} xl={6} lg={6}>
        <BorderDiv className="hc-ma-normal hc-pa-dense">
          <Input.Label
            className="hc-fs-section"
            rightContent={`Total: ${searchTMResults?.data?.getSearchResults?.paging?.totalResults}`}
          >
            Assigned to Me - Open Cases
          </Input.Label>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header xs>Case Number</Table.Header>
                <Table.Header xs>Case Type</Table.Header>
                <Table.Header xs>Status</Table.Header>
                <Table.Header xs>Created By</Table.Header>
                <Table.Header xs>Created On</Table.Header>
                <Table.Header xs classNmae="hc-pa-none">
                  Assigned To
                </Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchTMResults?.data?.getSearchResults?.cases?.map(
                (cases: any, index: number) => (
                  <Table.Row
                    justify="space-around"
                    clickable
                    onClick={() => navigate(`/cases/${cases['caseId']}`)}
                    key={cases['caseId']}
                  >
                    <Table.Data xs className="hc-pa-dense">
                      {cases['caseId']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      {cases['caseType']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      {cases['status']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      {cases['createUser']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      <DateFormatter date={cases['createTimestamp']} />
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      {cases['lanId']}
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
          <GridContainer direction="row-reverse">
            <Pagination
              className="hc-ma-expanded"
              onRequestNext={() => {
                if (
                  searchTMPage <
                  Math.ceil(
                    searchTMResults?.data?.getSearchResults?.paging
                      ? searchTMResults?.data?.getSearchResults?.paging
                          .totalPages
                      : 1
                  )
                ) {
                  setSearchTMPage(searchTMPage + 1)
                }
              }}
              onRequestPrev={() => {
                if (searchTMPage !== 1) {
                  setSearchTMPage(searchTMPage - 1)
                }
              }}
              currentPage={searchTMPage}
              totalPages={
                searchTMResults?.data?.getSearchResults?.paging
                  ? Math.ceil(
                      searchTMResults?.data?.getSearchResults?.paging.totalPages
                    )
                  : 1
              }
            />
          </GridContainer>
        </BorderDiv>
      </Grid.Item>

      {/* Open cases assigned to TM's team*/}
      <Grid.Item xs={12} sm={12} md={12} xl={6} lg={6}>
        <BorderDiv className="hc-ma-normal hc-pa-dense">
          <Input.Label
            className="hc-fs-section"
            rightContent={`Total: ${searchTeamResults?.data?.getSearchResults?.paging?.totalResults}`}
          >
            Assigned to My Workflow - Open Cases
          </Input.Label>
          <Table>
            <Table.Head>
              <Table.Row width="500px">
                <Table.Header xs>Case Number</Table.Header>
                <Table.Header xs>Case Type</Table.Header>
                <Table.Header xs>Status</Table.Header>
                <Table.Header xs>Created By</Table.Header>
                <Table.Header xs>Created On</Table.Header>
                <Table.Header xs>Assigned To</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {searchTeamResults?.data?.getSearchResults?.cases?.map(
                (cases: any, index: number) => (
                  <Table.Row
                    justify="space-around"
                    clickable
                    key={index}
                    onClick={() => navigate(`/cases/${cases['caseId']}`)}
                  >
                    <Table.Data xs className="hc-pa-dense">
                      {cases['caseId']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      {cases['caseType']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      {cases['status']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      {cases['createUser']}
                    </Table.Data>
                    <Table.Data xs className="hc-pa-dense">
                      <DateFormatter date={cases['createTimestamp']} />
                    </Table.Data>
                    <Table.Data xs>{cases['createUser']}</Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
          <GridContainer direction="row-reverse">
            <Pagination
              className="hc-ma-expanded"
              onRequestNext={() => {
                // @ts-ignore
                if (
                  searchTeamPage <
                  Math.ceil(
                    searchTeamResults?.data?.getSearchResults?.paging
                      ? searchTeamResults?.data?.getSearchResults?.paging
                          .totalPages
                      : 1
                  )
                ) {
                  setSearchTeamPage(searchTeamPage + 1)
                }
              }}
              onRequestPrev={() => {
                if (searchTeamPage !== 1) {
                  setSearchTeamPage(searchTeamPage - 1)
                }
              }}
              currentPage={searchTeamPage}
              totalPages={
                searchTeamResults?.data?.getSearchResults?.paging.totalPages
                  ? Math.ceil(
                      searchTeamResults?.data?.getSearchResults?.paging
                        .totalPages
                    )
                  : 1
              }
            />
          </GridContainer>
        </BorderDiv>
      </Grid.Item>
    </Grid.Container>
  )
}

export default TMDashboard
