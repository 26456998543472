import React from 'react'
import {
  Grid,
  Heading,
  Form,
  ExpandableSection,
  Caption,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { FormikValues } from 'formik'

import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { DOCUMENT_HEADING, SET_FIELD } from '../Common/Constants'

import _ from 'lodash'
import { twoDecimals } from '../Services/TwoDecimalPlaces'
import { useApolloClient } from '@apollo/client'
import {
  GetValidDepartment,
  DepartmentRequest,
  GET_DEPARTMENT,
  DepartmentClassRequest,
  GetValidDepartmentClass,
  GET_DEPARTMENT_CLASS,
} from '../Common/Queries'
import { CheckCircleIcon, EnterpriseIcon } from '@enterprise-ui/icons'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import moment from 'moment'

interface MainProp {
  formik: FormikValues
  displayCols: any
  requiredCols: any
  importVendor?: boolean
}

const DocumentDetails: React.FC<MainProp> = ({
  formik,
  displayCols,
  requiredCols,
  importVendor,
}) => {
  const formData = formik?.values
  const documentAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(formData?.originalDocumentAmount))
  const [state, dispatch] = useGlobalForm()

  const isDocuumentAmountRequired =
    importVendor ||
    state.isCarrierVendor ||
    !requiredCols.originalDocumentAmount
      ? false
      : true

  function validateFields(errors: any, fields: string[]): boolean {
    const keys = _.keysIn(errors)
    return !fields.some((el) => keys.includes(el))
  }
  const client = useApolloClient()
  const onDepartmentChange = async (departmentId: number) => {
    if (departmentId !== 0 && departmentId < 1000) {
      try {
        const { data } = await client.query<
          GetValidDepartment,
          DepartmentRequest
        >({
          query: GET_DEPARTMENT,
          variables: { departmentId: departmentId },
          fetchPolicy: 'network-only',
        })
        const getDepartmentDetails = data?.getValidDepartment
        if (getDepartmentDetails !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'departmentCheck', value: true }],
          })
          _.delay(
            () =>
              formik.validateForm().then((res: any) => {
                const fields = ['departmentId']
                if (validateFields(res, fields)) {
                  formik.setErrors({ res })
                } else {
                  formik.setErrors({ departmentId: res.departmentId })
                }
              }),
            0
          )
        }
      } catch (errors) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'departmentCheck', value: false }],
        })
        _.delay(
          () =>
            formik.validateForm().then((res: any) => {
              const fields = ['departmentId']
              if (validateFields(res, fields)) {
                formik.setErrors({ res })
              } else {
                formik.setErrors({ departmentId: res.departmentId })
              }
            }),
          0
        )
      }
    } else if (departmentId >= 1000) {
      await dispatch({
        type: SET_FIELD,
        payload: [{ id: 'departmentCheck', value: true }],
      })
      _.delay(
        () =>
          formik.validateForm().then((res: any) => {
            const fields = ['departmentId']
            if (validateFields(res, fields)) {
              formik.setErrors({ res })
            } else {
              formik.setErrors({ departmentId: res.departmentId })
            }
          }),
        0
      )
    }
  }

  const onDepartmentClassChange = async (
    departmentId: number,
    classId: number
  ) => {
    if (departmentId !== 0 && departmentId < 1000) {
      try {
        const { data } = await client.query<
          GetValidDepartmentClass,
          DepartmentClassRequest
        >({
          query: GET_DEPARTMENT_CLASS,
          variables: { departmentId: departmentId, classId: classId },
          fetchPolicy: 'network-only',
        })
        const getDepartmentClassDetails = data?.getValidDepartmentClass

        if (getDepartmentClassDetails !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'departmentClassCheck', value: true }],
          })
          _.delay(
            () =>
              formik.validateForm().then((res: any) => {
                const fields = ['classId']
                if (validateFields(res, fields)) {
                  formik.setErrors({ res })
                } else {
                  formik.setErrors({ classId: res.classId })
                }
              }),
            0
          )
        }
      } catch (errors) {
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'departmentClassCheck', value: false }],
        })
        _.delay(
          () =>
            formik.validateForm().then((res: any) => {
              const fields = ['classId']
              if (validateFields(res, fields)) {
                formik.setErrors({ res })
              } else {
                formik.setErrors({ classId: res.classId })
              }
            }),
          0
        )
      }
    } else if (departmentId >= 1000 || departmentId === 0) {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'departmentClassCheck', value: true }],
      })
    }
  }
  return (
    <div className="hc-pa-normal hc-pa-none hc-ma-none">
      <ExpandableSection startExpanded toggleLocation="left" padding="dense">
        <Heading size={6} className="hc-clr-grey02">
          {DOCUMENT_HEADING}
          {state.isDocShowable && (
            <EnterpriseIcon
              icon={CheckCircleIcon}
              color="green"
              className="hc-va-sub"
            />
          )}
          <Caption below="* Payment info (if applicable) is displayed on the summary section." />
        </Heading>
        <ExpandableSection.Content className="hc-pt-none hc-mt-none">
          {state.disputeSectionFlag && (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10} md={7}>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Source document amount</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {documentAmount}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Department</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {formData?.departmentId}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Purchased order #</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {formData?.purchaseOrderNumber}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item xs={5}>
                    <Input.Label>Location</Input.Label>
                  </Grid.Item>
                  <Grid.Item>
                    <Input.Label>: {formData?.locationId}</Input.Label>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xs={3}>
                <Grid.Container>
                  <Grid.Item className="hc-pb-none">
                    <Form.Field
                      id="disputedDocumentAmount"
                      label="Claim Filed For ($)"
                      type="number"
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          'disputedDocumentAmount',
                          twoDecimals(event.target.value) ?? undefined
                        )
                      }}
                      value={formData?.disputedDocumentAmount}
                      className="inputtype hc-ta-left"
                      error={formik.errors.disputedDocumentAmount !== undefined}
                      errorText={formik.errors.disputedDocumentAmount}
                      disabled={state.isDocShowable}
                      required={requiredCols.disputedDocumentAmount}
                    />
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item className="hc-pt-none">
                    <Form.Field
                      id="disputeAmountCheck"
                      label="Claim Full Amount?"
                      type="checkbox"
                      checked={state.disputeAmountCheck}
                      onChange={(event: any) => {
                        if (state.disputeAmountCheck) {
                          formik.setFieldValue(
                            'disputedDocumentAmount',
                            Number('')
                          )
                        } else {
                          formik.setFieldValue(
                            'disputedDocumentAmount',
                            formData?.originalDocumentAmount
                          )
                        }
                        dispatch({
                          type: SET_FIELD,
                          payload: [
                            {
                              id: 'disputeAmountCheck',
                              value: !state.disputeAmountCheck,
                            },
                          ],
                        })
                      }}
                      value={state.disputeAmountCheck}
                      disabled={state.isDocShowable}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
          {state.disputeSectionFlag && displayCols.classId && (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10}>
                <Grid.Container>
                  <Grid.Item xs={10} md={4} className="hc-pb-none">
                    <Form.Field
                      id="classId"
                      label="Class"
                      type="number"
                      onChange={(event: any) => {
                        formik.setFieldValue(
                          'classId',
                          event.target.value ? event.target.value : undefined
                        )
                      }}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseFloat(e.target.value))
                          .toString()
                          .slice(0, 2)
                      }}
                      onBlur={(e: any) => {
                        if (formik.values.departmentId !== undefined) {
                          onDepartmentClassChange(
                            formik.values.departmentId,
                            e.target.value
                          )
                        }
                      }}
                      value={formData?.classId ?? ''}
                      className="inputtype hc-ta-left"
                      error={formik.errors.classId !== undefined}
                      errorText={formik.errors.classId}
                      disabled={state.isDocShowable}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
          {!state.disputeSectionFlag && (
            <Grid.Container className="hc-pv-none hc-mv-none hc-ml-expanded">
              <Grid.Item xs={10}>
                <Grid.Container>
                  {displayCols.originalDocumentAmount && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="originalDocumentAmount"
                        label="Source Document Amount"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'originalDocumentAmount',
                            twoDecimals(event.target.value) ?? undefined
                          )
                        }}
                        value={formData?.originalDocumentAmount ?? ''}
                        className="inputtype hc-ta-left"
                        error={
                          formik.errors.originalDocumentAmount !== undefined
                        }
                        errorText={formik.errors.originalDocumentAmount}
                        disabled={state.isDocShowable}
                        required={isDocuumentAmountRequired}
                      />
                    </Grid.Item>
                  )}
                  {displayCols.disputedDocumentAmount && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="disputedDocumentAmount"
                        label="Claim Filed For ($)"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'disputedDocumentAmount',
                            twoDecimals(event.target.value) ?? undefined
                          )
                        }}
                        value={formData?.disputedDocumentAmount ?? ''}
                        className="inputtype hc-ta-left"
                        error={
                          formik.errors.disputedDocumentAmount !== undefined
                        }
                        errorText={formik.errors.disputedDocumentAmount}
                        disabled={state.isDocShowable}
                        required={requiredCols.disputedDocumentAmount}
                      />
                    </Grid.Item>
                  )}
                  {displayCols.purchaseOrderNumber && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="purchaseOrderNumber"
                        label="Purchase Order #"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'purchaseOrderNumber',
                            event.target.value ? event.target.value : undefined
                          )
                        }}
                        value={formData?.purchaseOrderNumber ?? ''}
                        onInput={(e: any) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 11)
                        }}
                        className="inputtype hc-ta-left"
                        error={formik.errors.purchaseOrderNumber !== undefined}
                        errorText={formik.errors.purchaseOrderNumber}
                        required={state.isPORequired}
                      />
                    </Grid.Item>
                  )}
                  {displayCols.departmentId && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="departmentId"
                        label="Department"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'departmentId',
                            event.target.value
                          )
                        }}
                        value={formData?.departmentId ?? ''}
                        onInput={(e: any) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 4)
                        }}
                        onBlur={(e: any) => {
                          onDepartmentChange(e.target.value)
                        }}
                        className="inputtype hc-ta-left"
                        error={formik.errors.departmentId !== undefined}
                        errorText={formik.errors.departmentId}
                        disabled={state.isDocShowable}
                        required
                      />
                    </Grid.Item>
                  )}
                  {displayCols.classId && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="classId"
                        label="Class"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'classId',
                            event.target.value ? event.target.value : undefined
                          )
                        }}
                        onInput={(e: any) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 2)
                        }}
                        onBlur={(e: any) => {
                          if (formik.values.departmentId !== undefined) {
                            onDepartmentClassChange(
                              formik.values.departmentId,
                              e.target.value
                            )
                          }
                        }}
                        value={formData?.classId ?? ''}
                        className="inputtype hc-ta-left"
                        error={formik.errors.classId !== undefined}
                        errorText={formik.errors.classId}
                        disabled={state.isDocShowable}
                        required={requiredCols.classId}
                      />
                    </Grid.Item>
                  )}
                  {displayCols.locationId && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="locationId"
                        label="Location"
                        type="number"
                        onChange={formik.handleChange}
                        value={formData?.locationId ?? ''}
                        onInput={(e: any) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          )
                            .toString()
                            .slice(0, 4)
                        }}
                        className="inputtype hc-ta-left"
                        error={formik.errors.locationId !== undefined}
                        errorText={formik.errors.locationId}
                        required={requiredCols.locationId}
                      />
                    </Grid.Item>
                  )}
                  {displayCols.cbdcDocumentDate && (
                    <Grid.Item xs={6} className="hc-pb-none">
                      <DatePicker
                        id="claimDetails.cbdcDocumentDate"
                        label="CBDC Document Date"
                        placeholder="MM/DD/YYYY"
                        onUpdate={(id, value) => {
                          formik.setFieldValue(
                            id,
                            moment(value).format('YYYY-MM-DD')
                          )
                        }}
                        className="inputtype hc-ta-left"
                        error={
                          formik.errors.claimDetails?.cbdcDocumentDate !==
                          undefined
                        }
                        errorText={formik.errors.claimDetails?.cbdcDocumentDate}
                        required={requiredCols.cbdcDocumentDate}
                        disabled={state.isDocShowable}
                        location="bottom-right"
                      />
                    </Grid.Item>
                  )}
                  {displayCols.retailAmount && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="claimDetails.retailAmount"
                        label="Retail Amount"
                        type="number"
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'claimDetails.retailAmount',
                            twoDecimals(event.target.value) ?? undefined
                          )
                        }}
                        value={formData?.claimDetails?.retailAmount ?? ''}
                        className="inputtype hc-ta-left"
                        error={
                          formik.errors.claimDetails?.retailAmount !== undefined
                        }
                        errorText={formik.errors.claimDetails?.retailAmount}
                        required={requiredCols?.retailAmount}
                        disabled={state.isDocShowable}
                      />
                    </Grid.Item>
                  )}
                  {displayCols.inventoryRemovalId && (
                    <Grid.Item xs={10} md={6} className="hc-pb-none">
                      <Form.Field
                        id="claimDetails.inventoryRemovalId"
                        label="Inventory Removal #"
                        type="text"
                        // onChange={formik.handleChange}
                        className="inputtype hc-ta-left"
                        required={requiredCols.inventoryRemovalId}
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            'claimDetails.inventoryRemovalId',
                            event.target.value
                          )
                        }}
                        disabled={state.isDocShowable}
                      />
                    </Grid.Item>
                  )}
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          )}
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}

export default DocumentDetails
