import React, { useEffect, Dispatch } from 'react'
import {
  CaseSearchStateContext,
  CaseSearchDispatchContext,
  SearchContextValue,
} from './CaseSearchContext'
import { searchReducer } from './CaseSearchReducer'
import { initialValue } from './SearchInitialValues'
import { useImmerReducer } from 'use-immer'
import { useLazyQuery, gql } from '@apollo/client'
import _ from 'lodash'
import * as qs from 'qs'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { SET_DEFAULT_SIDE_FILTERS, UPDATE_FILTER } from './ActionTypes'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'
import { useProfile } from '../../../Layout/UserProfile/UserProfileProvider'

const QUERY = gql`
  query Filters {
    reason_code: getReasonCodes {
      id: reasonCodeId
      label: reasonCodeText
      value: reasonCodeId
    }
    resolution_code: getResolutionCodes {
      id: resolutionCodeId
      label: resolutionCodeText
      value: resolutionCodeId
    }
    workflow: getWorkflows {
      id: workflow
      label: workflow
      value: workflow
    }
    document_type_id: getDocumentTypes {
      id: documentTypeId
      label: documentTypeText
      value: documentTypeId
    }
    document_sub_type_id: getDocumentSubTypes {
      id: documentSubTypeId
      label: documentSubTypeText
      value: documentSubTypeId
    }
    lan_id: getUserProfiles {
      id: userId
      label: fullName
      value: userId
    }
  }
`

const VENDOR_QUERY = gql`
  query Filters {
    reason_code: getReasonCodes {
      id: reasonCodeId
      label: reasonCodeText
      value: reasonCodeId
    }
    resolution_code: getResolutionCodes {
      id: resolutionCodeId
      label: resolutionCodeText
      value: resolutionCodeId
    }
  }
`

interface Props {
  children: React.ReactNode
}

const CaseSearchProvider: React.FC<Props> = (props) => {
  const [state, dispatch] = useImmerReducer<SearchContextValue, any>(
    searchReducer,
    initialValue
  )
  const location = useLocation()
  const { userProfile } = useProfile()

  const [loadFilters, { loading, error }] = useLazyQuery(
    userProfile?.isVendor ? VENDOR_QUERY : QUERY,
    {
      onCompleted: (data) => {
        setDefaultFilters(dispatch, data, location)
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (state.sideFilters === initialValue.sideFilters) loadFilters()
  }, [loadFilters, state])

  if (loading) {
    return (
      <Layout.Body includeRail>
        <h1>Loading Filters...</h1>
      </Layout.Body>
    )
  }

  if (error) {
    return <h1>Error</h1>
  }

  return (
    <CaseSearchStateContext.Provider value={state}>
      <CaseSearchDispatchContext.Provider value={dispatch}>
        {props.children}
      </CaseSearchDispatchContext.Provider>
    </CaseSearchStateContext.Provider>
  )
}

function useSearchState() {
  const context = React.useContext(CaseSearchStateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CaseSearchProvider')
  }
  return context
}
function useSearchDispatch() {
  const context = React.useContext(CaseSearchDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CaseSearchProvider')
  }
  return context
}

function useCaseSearch(): [SearchContextValue, any] {
  return [useSearchState() as SearchContextValue, useSearchDispatch()]
}

export { CaseSearchProvider, useCaseSearch, useSearchState, useSearchDispatch }

function setDefaultFilters(
  dispatch: Dispatch<any>,
  data: any,
  location: Location
) {
  const enrichedData = _.mapValues(data, function (test) {
    return test.map((obj: any) => {
      return {
        ..._.mapValues(obj, function (val) {
          return val.toString()
        }),
        isChecked: false,
      }
    })
  })

  // set filter values from API
  dispatch({ type: SET_DEFAULT_SIDE_FILTERS, payload: enrichedData })

  // set default filter values
  dispatch({
    type: UPDATE_FILTER,
    payload: qs.parse(location.search.replace('?', '')),
  })
}
