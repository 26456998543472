import Cookies from 'js-cookie'

export const getDomainName = (): string => {
  const hostName = window && window.location && window.location.hostname
  return (
    hostName &&
    hostName.substring(
      hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
    )
  )
}

function forceSignOut() {
  localStorage.removeItem('access_token')
  localStorage.removeItem('environment')
  localStorage.removeItem('id_token')
}

export const handleLogout = (auth: any) => {
  const { logout } = auth
  let userToken = localStorage.getItem('access_token')
  const userDetails = userToken?.split('.')[1]
  if (userDetails) {
    if (getDomainName() === 'partnersonline.com') {
      //If POL, remove access token and token IDs
      forceSignOut()
    }
  }
  logout()
}

export const isSSOSessionActive = () => {
  if (getDomainName() === 'partnersonline.com') {
    //Check SSO only for the POL route
    const tokenUserName = getUserIdFromAccessToken() //Get user name from access token
    const ssoSessionCookie = Cookies.get('SSOSESSIONINFO') //Get SSO Cookie details
    if (ssoSessionCookie && ssoSessionCookie.length > 0) {
      const ssoSessionCookieJson = JSON.parse(atob(ssoSessionCookie))
      if (
        tokenUserName &&
        ssoSessionCookieJson.login_status &&
        ssoSessionCookieJson.user &&
        ssoSessionCookieJson.user.toUpperCase() === tokenUserName.toUpperCase()
      ) {
        //Valid case. User is active and user name matches
        return true
      }
    }
    //Invalid case. Session expired, clear tokens and loggoff
    localStorage.removeItem('access_token') //Remove token from LS
    localStorage.removeItem('id_token')

    setTimeout(() => {
      window.location.href = '/' //Redirect to home page to login again
    }, 3000)
    return false
  } else {
    //No need to check SSO for internal users.
    return true
  }
}

const getUserIdFromAccessToken = () => {
  const accessToken = localStorage.getItem('access_token')
  if (accessToken) {
    const userDetails = accessToken.split('.')[1]
    if (userDetails) {
      const userDetailsJSON = JSON.parse(atob(userDetails))
      return userDetailsJSON.username
    }
  }
  return null
}
