import React from 'react'
import '../../Cases/Custom.css'
import { CaseResponse } from './Queries'
import { RESOLUTION_HEADING } from './Constants'
import { CaseType } from '../Services/ConstantData'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Section, SectionItem } from './Section'

interface MainProp {
  resolutionDetails: CaseResponse | undefined
}

const EditResolutionDetails: React.FC<MainProp> = ({ resolutionDetails }) => {
  const claim = resolutionDetails?.caseType === CaseType.INTERNALPROCESSING
  const dispute = resolutionDetails?.caseType === CaseType.DISPUTE
  return claim ? (
    <Section labelGridSize={6} detail heading={RESOLUTION_HEADING} expandable>
      <SectionItem label="Claim / Deduction #">
        {resolutionDetails?.claimDetails?.referenceDocumentNumber}
      </SectionItem>
      <SectionItem label="Claim / Deduction Filed date">
        <DateFormatter
          //@ts-ignore
          date={resolutionDetails?.claimDetails?.referenceDocumentCreateDate}
          format="L"
        />
      </SectionItem>
      <SectionItem label="Settlement / Resolution Explanation">
        {resolutionDetails?.resolutionCodeText}
      </SectionItem>
      <SectionItem label="Claim / Deduction ( $ )">
        {resolutionDetails?.resolutionAmount}
      </SectionItem>
      <SectionItem label="Settlement Note">
        {resolutionDetails?.resolutionText}
      </SectionItem>
      <SectionItem label="Resolution Date">
        <DateFormatter
          //@ts-ignore
          date={resolutionDetails?.resolutionDate}
          format="L"
        />
      </SectionItem>
    </Section>
  ) : dispute ? (
    <Section labelGridSize={6} detail heading={RESOLUTION_HEADING} expandable>
      <SectionItem label="New Document #">
        {resolutionDetails?.disputeDetails?.newDocumentNumber}
      </SectionItem>
      <SectionItem label="Settlement / Resolution Explanation">
        {resolutionDetails?.resolutionCodeText}
      </SectionItem>
      <SectionItem label="Resolution ( $ )">
        {resolutionDetails?.resolutionAmount}
      </SectionItem>
      <SectionItem label="Settlement Note">
        {resolutionDetails?.resolutionText}
      </SectionItem>
      <SectionItem label="Resolution Date">
        <DateFormatter
          //@ts-ignore
          date={resolutionDetails?.resolutionDate}
          format="L"
        />
      </SectionItem>
      <SectionItem label="New Paid Date">
        <DateFormatter
          //@ts-ignore
          date={resolutionDetails?.disputeDetails?.newPaidDate}
          format="L"
        />
      </SectionItem>
      <SectionItem label="New Check Number">
        {resolutionDetails?.disputeDetails?.newCheckNumber}
      </SectionItem>
    </Section>
  ) : (
    <Section labelGridSize={6} detail heading={RESOLUTION_HEADING} expandable>
      <SectionItem label="Settlement/Resolution Explanation">
        {resolutionDetails?.resolutionCodeText}
      </SectionItem>
      <SectionItem label="Settlement Note">
        {resolutionDetails?.resolutionText}
      </SectionItem>
      <SectionItem label="Resolution Date">
        <DateFormatter
          //@ts-ignore
          date={resolutionDetails?.resolutionDate}
          format="L"
        />
      </SectionItem>
    </Section>
  )
}

export default EditResolutionDetails
