import { gql } from '@apollo/client'

export const GET_USER_PROFILE = gql`
  query GetUserProfile($userId: String!) {
    getUserProfile(id: $userId) {
      userId
      firstName
      secondName
      team {
        teamId
        teamName
      }
      defaultVendorId
      defaultVendorName
      isVendor
    }
  }
`

export const CREATE_USER_PROFILE = gql`
  mutation CreateUserProfile($input: NewUserProfileRequest!) {
    createUserProfile(newUserProfileRequest: $input) {
      userId
      firstName
      secondName
      team {
        teamId
      }
      defaultVendorId
      defaultVendorName
      isVendor
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($id: String!, $input: UpdateUserProfileRequest!) {
    updateUserProfile(id: $id, updateUserProfileRequest: $input) {
      userId
      firstName
      secondName
      team {
        teamId
      }
      defaultVendorId
      defaultVendorName
      isVendor
    }
  }
`

export interface GetUserProfile {
  getUserProfile: UserProfile
}

export interface CreateUserProfile {
  createUserProfile: UserProfile
}

export interface UserProfile {
  userId: string
  firstName: string
  secondName: string
  team?: Team
  isVendor?: boolean
  defaultVendorId?: number
  defaultVendorName?: string
}

export interface Team {
  teamId: string
  teamName: string
}

export interface NewUserProfileRequest {
  userId: string
  firstName: string
  secondName: string
  teamId?: string
  defaultVendorId?: number
  defaultVendorName?: string
}

export interface UpdateUserProfileRequest {
  firstName: string
  secondName: string
  teamId?: string
  defaultVendorId?: string
  defaultVendorName?: string
}

export interface UpdateUserProfileVar {
  id: String
  input: UpdateUserProfileRequest
}

export interface UserProfileVar {
  userId: string
}
