import { gql } from '@apollo/client'

export const GET_TEAMS = gql`
  query GetTeams {
    getTeams {
      teamId
      teamName
    }
  }
`
export interface GetTeams {
  getTeams: [Team]
}

export interface Team {
  teamId: string
  teamName: string
}
